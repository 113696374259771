import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'readMore'
})
export class ReadMorePipe implements PipeTransform {

  transform( text: string, readMore: boolean = false, textLength: number): unknown {
    if (!text) {
      return '';
    }
    if (text.length < textLength || readMore === true) {
      return text;
    } else {
      return text.substring(0, textLength-3) + '...';
    }
  }

}
