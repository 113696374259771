import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {InstrumentAndEquipmentService} from "../../../services/instruments.equipments.service";
import {HelperService} from "../../../services/helper.service";

@Component({
  selector: 'app-tn-instrument',
  templateUrl: './tn-instrument.component.html',
  styleUrls: ['./tn-instrument.component.scss']
})
export class TnInstrumentComponent implements OnInit {

  @Input() instrument;
  @Input() rentFlag;
  @Output() addEventEmitter = new EventEmitter();
  @Output() removeEventEmitter = new EventEmitter();
  @Input() isSelectedForRent: boolean;
  @Input() darkMode = false;
  @Input() currency = 'EUR';

  constructor(
      private instrumentService: InstrumentAndEquipmentService,
      private helperService: HelperService,
  ) {

  }
  isAddedForRental: boolean;
  instrumentsMap;
  ngOnInit(): void {
    this.isAddedForRental = this.isSelectedForRent;
    this.instrumentsMap = this.instrumentService.getInstrumentsMap();
    this.instrument.categoryLabel = this.instrumentService.getInstrumentsAndEquipments().find(e => e.type === this.instrument.equipment.category).name;
    this.instrument.icon = this.instrumentService.getInstrumentsAndEquipments().find(e => e.type === this.instrument.equipment.category).icon;

  }

  addThisForRental(){
    if (this.rentFlag) {
      this.isAddedForRental = true;
      this.addEventEmitter.emit(this.instrument);
    }
  }

  removeThisForRental(){
    if (this.rentFlag) {
      this.isAddedForRental = false;
      this.removeEventEmitter.emit(this.instrument);
    }
  }


  getCurrency( symbol = true ) {
    if (symbol) {
      return this.helperService.getCurrencySymbol(this.currency)
    } else {
      return this.currency;
    }
  }

}
