import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  newPassword = '';
  newPasswordRepeat = '';
  resetToken;

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private authenticationService: AuthenticationService,
      private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.resetToken = this.route.snapshot.queryParamMap.get('token');
    if ( !this.resetToken || this.resetToken.length === 0) {
      this.router.navigate(['login']);
    }
  }

  changePass(){
    if ( this.newPassword.length === 0 || this.newPasswordRepeat.length === 0 ) {
      this.messageService.clear();
      this.messageService.add({
        severity: 'error',
        summary: 'Invalid form',
        detail: 'Fill in both passwords first'
      });
    } else if ( this.newPassword !== this.newPasswordRepeat ) {
      this.messageService.clear();
      this.messageService.add({
        severity: 'error',
        summary: 'Invalid form',
        detail: 'Passwords do not match!'
      });
    } else {
      this.authenticationService.resetPassword(this.resetToken, this.newPassword)
          .then((data) => {

            this.router.navigate(['login']);

            setTimeout(() => {
              this.messageService.add(
                  {
                    key: 'globalToast',
                    severity: 'success',
                    summary: 'Successful Reset',
                    detail: 'Please Login'
                  });
            }, 1000);
          }).catch((err) => {
            this.messageService.clear();
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: err.error.message
            });
      });
    }
  }

}
