import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ProfileService} from '../services/profile.service';
import {HelperService} from '../services/helper.service';
import {StudiosService} from '../services/studios.service';
import { Carousel } from 'primeng/carousel';


interface feature {
  image: string;
  title: string;
  index: string;
  caption: string;
  body: string;
}

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  featuresItems: feature[];

  responsiveOptions;
  loggedIn = false;
  nearStudios = [];

  constructor(
      private router: Router,
      private helperService: HelperService,
      private profileService: ProfileService,
      private studiosService: StudiosService,
  ) {
    Carousel.prototype.onTouchMove = () => { };
    this.responsiveOptions = [
      {
        breakpoint: '991px',
        numVisible: 1,
        numScroll: 1
      }
    ];

    this.featuresItems = [
        {
      image: '../../assets/images/landing/explore.gif',
      title: 'Explore',
      index: '1',
      caption: 'Take a look around Find the perfect space',
      body: 'We know it\'s not as easy as it seems! Looking for a studio to rehearse for your next gig, jam with your bandmates or record your next masterpiece? Maybe you\'re trying to find the studio with your favorite guitar amp from the 60s? We sure do!'
    },
      {
        image: '../../assets/images/landing/book.gif',
        title: 'Book',
        index: '2',
        caption: 'Alone or with your band Booking made easy',
        body: 'Find the perfect studio at the perfect time as it best suits your needs! Want to practice alone your vocal lines? Want to rehearse with your 10-piece band? All your booking communication hassles should be a thing of the past. Get onboard and focus on the one thing you really love!'
      },
      {
        image: '../../assets/images/landing/create.gif',
        title: 'Create',
        index: '3',
        caption: '...it\'s a music thing after all',
        body: 'Our ultimate common goal is the desire for more (and more!) beautiful music! Take your ideas to the next level, let them be heard with the rest of us!  Join our worldwide community of like-minded artists and collaborate, inspire, share, create!'
      },
    ];

    this.studiosService.fetchStudios('active').then((studios) => {
      this.nearStudios = studios.results.reverse();

      if (this.nearStudios) {
        this.nearStudios.forEach((studio) => {

          studio = this.studiosService.mapStudioData(studio);
          studio.minPrice = 10000;
          studio.maxPrice = 0;
          studio.rooms.forEach((room) => {
            room.services.forEach((service) => {
              if (service.price > studio.maxPrice) {
                studio.maxPrice = service.price;
              }
              if (service.price < studio.minPrice) {
                studio.minPrice = service.price;
              }
            });
          });
        });
      }

    });
  }

  ngOnInit(): void {
    this.loggedIn = this.userHasLoggedIn();

    this.helperService.trackViewContent('LANDING');
  }

  searchStudios() {
    this.router.navigate(['listing']);
  }

  goToLogin() {
    this.router.navigate(['login']);
  }
  userHasLoggedIn(): boolean{
    return Object.keys(this.profileService.getUserData()).length !== 0;
  }

  scrollToEl(target: HTMLElement){
    target.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'nearest'});
  }

}
