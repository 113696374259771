import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {AuthenticationService} from '../services/authentication.service';
import {environment} from '../../environments/environment';
import {RegistrationsService} from '../services/registrations.service';
import {FacebookLoginProvider, SocialAuthService} from '@abacritt/angularx-social-login';
import {filter} from 'rxjs/operators';
import {HelperService} from '../services/helper.service';
import {UserService} from '../services/user.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit, OnDestroy {

  public env = environment;
  userName: string;
  userEmail: string;
  userPassword: string;

  userConfirmPassword: string;
  userPhoneNumber: number;
  referralCode: string;
  isBeta = false;
  isNotificationRegistration = false;
  termsAndConditionAccepted = false;
  newsletterAccepted: boolean = true;

  authorizationSubscription;

  phonePrefix = '';
  countries: any[];
  userCountry: any;

  private bandId;

  constructor(
      private router: Router,
      private route: ActivatedRoute,
      private authenticationService: AuthenticationService,
      private registrationService: RegistrationsService,
      private userService: UserService,
      private messageService: MessageService,
      private authService: SocialAuthService,
      private helperService: HelperService
  ) {
      if (
          environment.hasOwnProperty('betaMode') &&
          typeof environment.betaMode === 'boolean'
      ) {
          this.isBeta = environment.betaMode;
      }
      this.bandId = this.route.snapshot.queryParamMap.get('team') && this.route.snapshot.queryParamMap.get('team').length > 0 ? this.route.snapshot.queryParamMap.get('team') : undefined;
      this.countries = this.helperService.getCountriesData();
  }

  ngOnInit(): void {

      // this.authService.signOut(false);


      this.authorizationSubscription = this.authService.authState
          .pipe(
              filter(val =>
                  val !== null
              )
          )
          .subscribe((user) => {
              let registration;
              if (user.provider === 'FACEBOOK') {
                  registration = this.authenticationService.registerFacebookUser(
                      user.firstName ,
                      user.lastName,
                      user.email,
                      user.id,
                      user.authToken,
                      user.photoUrl,
                      this.bandId ? this.bandId : undefined,
                  );
              } else {
                  registration = this.authenticationService.registerGoogleUser(
                      user.firstName ,
                      user.lastName,
                      user.email,
                      user.id,
                      user.idToken,
                      user.photoUrl,
                      this.bandId ? this.bandId : undefined,
                  );
              }

              registration.then((data) => {
                  this.router.navigate(['wizard']);

                  setTimeout(() => {
                      this.messageService.add(
                          {
                              key: 'globalToast',
                              severity: 'success',
                              summary: 'Successful SignUp',
                              detail: 'Welcome to Bandbeat!'
                          });
                  }, 1000);
              }).catch((err) => {

                  this.authService.signOut(false);
                  this.messageService.clear();
                  this.messageService.add({
                  severity: 'error', summary: 'Something went wrong', detail: err.error.message});
          });
      });
  }

    ngOnDestroy(): void {
      if (this.authorizationSubscription) {
        this.authorizationSubscription.unsubscribe();
      }
    }


  public signup() {
    if(!this.userName || !this.userEmail){
        this.messageService.clear();
        this.messageService.add(
            {
                severity: 'error',
                summary: 'Wrong data',
                detail: 'Please fill all required fields'
            });
    } else if(!this.userPassword){
        this.messageService.clear();
        this.messageService.add(
            {
                severity: 'error',
                summary: 'No password  ',
                detail: 'Please enter a password to continue'
            });
    } else if (this.userConfirmPassword !== this.userPassword) {
          this.messageService.clear();
          this.messageService.add(
              {
                  severity: 'error',
                  summary: 'Wrong data',
                  detail: 'Passwords do not match!'
              });
      } else if (!this.termsAndConditionAccepted) {
          this.messageService.clear();
          this.messageService.add(
              {
                  severity: 'error',
                  summary: 'Wrong data',
                  detail: 'Please accept Terms & Conditions first to continue!'
              });
      } else if (!this.userCountry) {
          this.messageService.clear();
          this.messageService.add(
              {
                  severity: 'error',
                  summary: 'Wrong data',
                  detail: 'You need to select your country!'
              });
      } else {

          let userId;
          this.authenticationService.registerUser(
              this.userName,
              this.userCountry,
              this.userEmail,
              this.userPassword,
              this.userPhoneNumber,
              'user',
              this.termsAndConditionAccepted,
              this.isBeta ? this.referralCode.toUpperCase() : undefined,
              this.bandId ? this.bandId : undefined,
          )
              .then((data) => {
                  userId = data.user.id;
                  if (this.newsletterAccepted) {
                      return this.userService.addRegistration(userId, {event : 'user_newsletter'});
                  } else {
                      return Promise.resolve(data);
                  }
              })
              // .then((data) => {
              //     return this.userService.addRegistration(userId, {event : 'user_wizard'})
              // })
              .then((data) => {

                  this.router.navigate(
                      ['wizard']);

                  this.trackCompleteRegistration();

                  setTimeout(() => {
                      this.messageService.add(
                          {
                              key: 'globalToast',
                              severity: 'success',
                              summary: 'Successful SignUp',
                              detail: 'Welcome to Bandbeat!'
                          });
                  }, 1000);
              }).catch((err) => {
              this.messageService.clear();
              this.messageService.add({
                  severity: 'error', summary: 'Wrong Input ', detail: this.capitalizeFirstLetter(err.error.message)});
          });
      }
  }

  trackCompleteRegistration() {
      this.helperService.trackCompleteRegistration({
      content_name: 'USER REGISTRATION',
      value: this.userEmail,
      });
  }

  public registerEmail() {
    this.registrationService.createRegistration(this.userEmail , 'beta_launch')
        .then((data) => {
            this.messageService.add(
                {
                    severity: 'success',
                    summary: 'Yeah!',
                    detail: 'We will notify you when we have open signups!'
                });
        })
        .catch((err) => {
            this.messageService.clear();
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: err.error.message
            });
        });
  }

    public signInWithFB(): void {
        this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
    }

    public getButtonSize(parentElem) {
        if (parentElem) {
            return parentElem.getBoundingClientRect().width - 30 + '';
        }
        if (window.innerWidth < 991) {
            return 300;
        } else if (window.innerWidth < 1200) {
            return 250;
        } else {
            return 200;
        }
    }

    public selectCountry(event) {
      const selected = this.countries.find(country => this.userCountry === country.label);
      this.phonePrefix = '(+' + selected.phone + ') ';
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

}
