import { Pipe, PipeTransform } from '@angular/core';
import {environment} from "../../../environments/environment";

@Pipe({
  name: 'sortingListing'
})
export class SortingListingPipe implements PipeTransform {

  public env = environment;
  transform(studios: any[], selectedSorting: string = 'default'): any[] {
    const orderedStudios = [...studios]

    if (selectedSorting === 'maxPriceASC') {
      orderedStudios.sort((a, b) => {
        return a.minPrice - b.minPrice
      })

    } else if (selectedSorting === 'maxPriceDESC') {
      orderedStudios.sort((a, b) => {
        return b.minPrice - a.minPrice
      })

    } else if (selectedSorting === 'distanceASC') {
      orderedStudios.sort((a, b) => {
        return a.distance - b.distance
      })

    } else if (selectedSorting === 'default') {
      if (this.env.studioScoreEnabled && this.env.studioScoreEnabled === true) {
        orderedStudios.sort((a, b) => {
          return b.studioScore - a.studioScore
        })

      } else {
        orderedStudios.sort((a, b) => {
          // @ts-ignore
          return new Date(a.createdAt) - new Date(b.createdAt)
        })

      }
    }

    return orderedStudios;
  }

}
