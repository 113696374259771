<p-card
        [style]="isAddedForRental ? {border: '3px solid #3CD1C5', borderRadius : 12} : {border: '1px solid #938F99', borderRadius : 12}" styleClass="p-card-shadow">

        <div class="grid grid-nogutter align-items-center">
            <div class="col-2"><app-filled-icon [flatIcon]="instrumentsMap.get(instrument.equipment.category)"></app-filled-icon></div>
            <div class="col-6"><h5 style="margin: auto">{{instrument.equipment.brand | titleCaseExtendedPipe}}</h5>
                <div>{{instrument.equipment.name}}</div>
            </div>
            <div class="col-4" style="color:#FFFFFF; text-align: right;"><strong>{{instrument.price}}€/hour</strong></div>
        </div>

    <ng-template pTemplate="footer" *ngIf = rentFlag>
        <div style="text-align:right" *ngIf="!isAddedForRental">
            <button class="p-button-text " label="Rent this instrument" (click)="addThisForRental()" pButton pRipple type="button"></button>
        </div>
        <div style="text-align:right" *ngIf="isAddedForRental">
            <button class="p-button-text" style="color:#F2B8B5" label="Remove" (click)="removeThisForRental()" pButton pRipple type="button"></button>
        </div>
    </ng-template>
</p-card>
