<p-card
        [ngClass]="{'selected' : selected}"
        [style]="{borderRadius : '0.75rem'}"
        (click)="selectRoom()"
>
    <div class="body_wrapper p-3 md:p-5" >

        <span
                class="tn-title "
                [ngClass]="{'tn-text-green' : selected}"
        >{{room.name}}</span><span [ngClass]="{'tn-text-green' : selected}" style="font-size: 1.75rem;
font-style: normal;
font-weight: 400;
line-height: 2.25rem;"> Room</span>
        <p class="pt-2 tn-text-simple"> <span class="tn-text-green tn-text-big">{{lowestPrice}}</span> {{this.getCurrency()}} / hour</p>

        <div>
                <p-tag class="tn-text-small offer-tag {{studioOffers.percentage > -1?'':'hidden-offer'}} {{studioOffers.percentage == -1 && studioOffers.amount.cutoff > -1?'hide-offer':''}}" [rounded]="true"  [style]="{'margin-right': '1rem'}">
                        <span class="offer-text"><b>-{{studioOffers.percentage}}%</b></span>
                </p-tag>
                <p-tag class="tn-text-small offer-tag {{studioOffers.amount.cutoff > -1?'':'hidden-offer'}}" [rounded]="true">
                        <span class="offer-text">{{studioOffers.amount.cutoff}} hr deal&nbsp;<b>{{studioOffers.amount.final}}{{getCurrency()}}</b></span>
                </p-tag>
        </div>
    </div>
    <ng-template pTemplate="content">
        <div
                class="card-cover"
        >
                <div
                        class="img-gradient"
                >
                    <img id="cover"
                         alt="Card"
                         [src]="(room.hasOwnProperty('media') && room.media.length > 0)  ? room.media[0]['link'] : 'assets/images/sample_studio.png'"
                    />
                </div>
        </div>
    </ng-template>

</p-card>
