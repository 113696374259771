<div class="grid bandbeatPage">
    <div class="col-12 ">
        <p class="tn-title ">Create New Band</p>
    </div>
    <div class="col-12 md:col-8 lg:col-6 ">

        <div class=" grid">
            <div class=" col-12 pb-4">
                <p-avatar shape="circle"
                          [image]="bandLogo"
                          (click)="logoUpload.choose()"
                >
                </p-avatar>
                <button pButton
                        id="logoButton"
                        pRipple type="button"
                        icon="till-noon-icon tnicon-Pencil"
                        (click)="logoUpload.choose()"
                        class="p-button-rounded p-button-icon-only p-button-text tn-primary-button  uploadButton">

                </button>
                <p-fileUpload
                    #logoUpload
                    class="logoUploader hidden-mobile"
                    [customUpload]="true"
                    [auto]="true"
                    accept="image/*"
                    (uploadHandler)="imageUpload($event, 'logo' , 'studio')"
                ></p-fileUpload>
            </div>
            <div class=" col-12 lg:col-6">
                <p class="tn-text-simple tn-text-green ">BAND NAME</p>
                <input
                        inputId="bandname"
                        type="text"
                        pInputText
                        class="w-full"
                        id="bandname"
                        [(ngModel)]="bandName"
                />
            </div>
            <div class="hidden lg:flex col-6"></div>
            <div class="col-12 md:col-6">
                <p class="tn-title">Add a Mate</p>
                <p class="tn-text-simple tn-text-green ">BANDBEAT EMAIL</p>

                <input inputTrim
                       id="inviteEmail"
                       type="text"
                       pInputText
                       [(ngModel)]="inviteEmail"
                       class="w-full"
                >
            </div>


            <div class="col-12 md:col-4 flex align-items-end ">
                <button
                        pButton
                        type="button"
                        label="INVITE BAND MEMBER"
                        class=" tn-primary-button  w-auto md:ml-4"
                        (click)="checkUser()"
                ></button>
            </div>

            <div class="col-12">

                <p-chip
                        *ngFor="let inviteMember of bandUsers"
                        [label]="inviteMember.email"
                        [image]="inviteMember.hasOwnProperty('avatar') ? inviteMember.avatar['link'] : 'assets/images/sample_user.png'"
                        styleClass="custom-chip"
                ></p-chip>
            </div>


<!--            <div class="field col-12 pb-4">-->
<!--                <div class="flex align-items-center">-->

<!--                    <p-chip-->
<!--                            *ngFor="let inviteMember of bandUsers"-->
<!--                            [label]="inviteMember.email"-->
<!--                            [image]="inviteMember.hasOwnProperty('avatar') ? env.uploadUrl + inviteMember.avatar.link : 'assets/images/sample_user.png'"-->
<!--                            styleClass="custom-chip"-->
<!--                    ></p-chip>-->

<!--                </div>-->
<!--            </div>-->
<!--                <div class="field col-8 lg:col-6 ">-->

<!--                    <span class="p-float-label">-->
<!--                        <input inputTrim-->
<!--                                id="inviteEmail"-->
<!--                                type="text"-->
<!--                                pInputText-->
<!--                                [(ngModel)]="inviteEmail"-->
<!--                                style="width: 100%"-->
<!--                        >-->
<!--                        <label for="inviteEmail">Bandbeat Email</label>-->
<!--                    </span>-->


<!--                </div>-->
<!--            <div class="field col-4 mb-2">-->
<!--                <button-->
<!--                        pButton-->
<!--                        type="button"-->
<!--                        label="Invite member"-->
<!--                        class="p-button secondary-button"-->
<!--                        (click)="checkUser()"-->
<!--                ></button>-->
<!--            </div>-->


            <div class="col-12 md:col-3 ">
                <button
                        pButton
                        type="button"
                        label="Back"
                        class="p-button-primary tn-primary-button "
                        (click)="previousPage()"
                        icon="till-noon-icon tnicon-Arrow-Left"
                        iconPos="left"
                        style="width:100%"
                ></button>
            </div>

            <div class="col-12 md:col-3  ">
                <button
                        pButton
                        type="button"
                        label="CREATE BAND"
                        class="p-button  "
                        style="width:100%"
                        (click)="createBand()"
                ></button>
            </div>

        </div>

    </div>








</div>
