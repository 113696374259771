<div class="grid pt-5">
    <div class="col-12"  *ngIf="user | async as userData">
        <div class="p-fluid formgrid grid">
            <div class="field col-12 lg:col-2 ">

                <p class="tn-text-simple tn-text-green ">NAME</p>
                <input
                        inputId="userName"
                        type="text"
                        pInputText
                        class="w-full"
                        id="userName"
                        [(ngModel)]="userFirstName"
                />

            </div>

            <div class="hidden lg:flex col-10"></div>

            <div class="field col-12 lg:col-2 ">

                <p class="tn-text-simple tn-text-green">COUNTRY</p>

                <p-dropdown
                        inputId="country"
                        id="country"
                        required="true"
                        optionValue="label"
                        [options]="countries"
                        [(ngModel)]="userCountry"
                        (onChange)="selectCountry($event)"
                        placeholder="Select a Country"
                >
                    <ng-template let-country pTemplate="item">
                        <div class="flex align-items-center gap-2">
                            <div> <span>{{country.emoji}}</span> {{ country.name }}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
            </div>


            <div class="field col-12 lg:col-2">

                <p class="tn-text-simple tn-text-green">PHONE NUMBER</p>

                <p-inputNumber
                        id="userPhonenumber"
                        inputId="userPhonenumber"
                        [prefix]="phonePrefix"
                        [useGrouping]="false"
                        [(ngModel)]="userPhoneNumber"
                ></p-inputNumber>
            </div>

        </div>


    </div>

    <div class="col-12">
        <div class="grid grid-nogutter justify-content-between">
            <button pButton  class="tn-primary-button" label="BACK" (click)="previousPage()" icon="till-noon-icon tnicon-Arrow-Left" iconPos="left"></button>
<!--            <button pButton class="tn-helper-button" label="SKIP" (click)="nextPage(true)" icon="pi pi-angle-right" iconPos="right"></button>-->
            <button pButton class="tn-secondary-button" label="NEXT" (click)="nextPage()" icon="till-noon-icon tnicon-Arrow-Right" iconPos="right"></button>
        </div>
    </div>
</div>

