import {Component, Input, OnInit} from '@angular/core';
import {HelperService} from "../../../services/helper.service";

@Component({
  selector: 'app-tn-service',
  templateUrl: './tn-service.component.html',
  styleUrls: ['./tn-service.component.scss']
})
export class TnServiceComponent  implements OnInit {

  public serviceOffer;
  public multipleOffers = false;

  @Input() service;
  @Input() room;
  @Input() darkMode = false;
  @Input() currency = 'EUR';
  @Input() studioOffers = null;
  constructor(
    private helperService: HelperService,
  ) { }
  ngOnInit(): void {
    if(this.studioOffers && this.studioOffers[this.room.id]?.hasOffers) {
      this.loadOffers()
    }

  }

  getCurrency( symbol = true ) {
    if (symbol) {
      return this.helperService.getCurrencySymbol(this.currency)
    } else {
      return this.currency;
    }
  }

  loadOffers(){
    const serviceData = this.studioOffers[this.room.id][this.service.type];
    this.serviceOffer = this.getBestOffers(serviceData) 
  }

  getBestOffers(data) {
    const res = {percentage: -1, amount: {cutoff: -1, payoff: 0, final: 0},}
    const hourlyRate = data.hourlyRate

    data.percentage?.forEach(el =>{
      if(el.rules.percentage > res.percentage) {
        res.percentage = el.rules.percentage
      }
    })

    data.amount?.forEach(el =>{
      const {cutoff, payoff} = el.rules;
      if(cutoff > res.amount.cutoff) {
        res.amount = {
          cutoff,
          payoff,
          final: (cutoff * hourlyRate) - payoff
        }
      }
    })
    
    if( res.amount.cutoff > -1 && res.percentage > -1) this.multipleOffers = true;


    return res;
  }
}
