<p-card [style]="{border: '1px solid #00B6B2',borderRadius : 12, cursor:'pointer'}"  (click)="seeStudioDetails()"
        (mouseover)="showCover = false"
        (mouseleave)="showCover = true">

    <ng-template pTemplate="header">
        <div class="grid grid-nogutter" style="align-items: center">

            <div class="col-12">
                <p class="tn-title studio-text-overflow" style="padding-bottom: 0.2rem">{{studio.name}}</p>
                <span class="tn-text-green studio-text-overflow tn-text-small">{{userHasLoggedIn() ? studio.studioAddress : studio.studioAddressShort}}</span>
            </div>
        </div>
    </ng-template>

    <div class="body_wrapper" style="padding:2rem;">

        <div class="grid grid-nogutter align-items-center studio-features">
            <div class="col-4" >
                <span class="category-type">{{studio.rooms.length}}</span>
                <br>
                <span class="tn-text-big res-service">{{studio.rooms.length > 1 ? 'Rooms' : 'Room'}}</span>
            </div>
            <div class="col-4" *ngIf = "studioHasRecordingService()" >
                <span class="category-type"><i class="till-noon-icon tnicon-Recording"></i></span>
                <br>
                <span class="tn-text-big res-service">Recording</span>
            </div>
            <div class="col-4" *ngIf = "studioHasRehearsalService()" >
                <span class="category-type"><i class="till-noon-icon tnicon-Rehearsal"></i></span>
                <br>
                <span class="tn-text-big res-service">Rehearsal</span>
            </div>


            <div class="col-12" style="color:#FFFFFF;padding-top: 1rem;">
                <span class="tn-text-simple">from <span style="color: #00B6B2">{{studio.minPrice}}</span> {{getCurrency()}} / hour</span>
            </div>


            <div class="col-12"
                 style="min-height:33px;padding-top: 1rem;"
            >
                <ng-template
                    [ngIf]=" env.reviewEnabled && studio.studioReviews &&  studio.studioReviews.length > 0"
                >

                    <p-rating [(ngModel)]="studioRating" [readonly]="true" [cancel]="false" style="float:left;"></p-rating>
                    <span class="tn-text-green tn-text-small" style="float:left;margin-left:10px;">({{studio.studioReviews.length}})</span>
                </ng-template>
            </div>

            <div class="col-12 discount-tag-container">
                    <p-tag class="tn-text-small offer-tag" [rounded]="true" *ngIf="studioOffers.percentage > -1">
                        <span class="offer-text"><b>-{{studioOffers.percentage}}%</b></span>
                    </p-tag>
                    <p-tag class="tn-text-small offer-tag" [rounded]="true" *ngIf="studioOffers.amount.cutoff > -1">
                            <span class="offer-text">{{studioOffers.amount.cutoff}} hr deal&nbsp;<b>{{studioOffers.amount.final}}{{getCurrency()}}</b></span>
                    </p-tag>
            </div>
        </div>

    </div>
    <ng-template pTemplate="content">
        <div
             [ngClass]="{ 'advanced-card-content' : !showCover , 'card-cover' : showCover }"
        >

            <ng-template [ngIf]="!showCover">
                <p class="tn-text-small" style="height:140px;" >{{studio.description}}</p>


                <button
                        pButton
                        class="mr-3 tn-secondary-button"
                        [routerLink]="'/studio/'+studio.id"
                        label="BOOK NOW"
                        icon="till-noon-icon tnicon-Wave"
                ></button>

            </ng-template>
            <ng-template [ngIf]="showCover">

                <div
                        *ngIf="showCover"
                        class="img-gradient"

                >
                    <img id="cover"
                         alt="Card"
                         [src]="(studio.hasOwnProperty('cover') && studio.cover)  ? studio.cover['link'] : 'assets/images/sample_studio.png'"
                    />
                </div>



            </ng-template>

        </div>
    </ng-template>

</p-card>
