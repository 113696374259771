<div class="grid pt-5">
    <div class="col-12"  *ngIf="user | async as userData">
        <div class="grid">
            <div class=" col-12 lg:col-6 ">


                <p class="tn-text-big tn-text-green" >User Image</p>
                <p-avatar shape="circle"
                          [image]="userData.hasOwnProperty('avatar') ? userData.avatar['link'] : 'assets/images/bandbeat/sample_user_logo.png'"
                >
                </p-avatar>
                <button pButton
                        id="logoButton"
                        pRipple type="button"
                        icon="till-noon-icon tnicon-Pencil"
                        (click)="logoUpload.choose()"
                        class="p-button-rounded p-button-icon-only p-button-text tn-primary-button  uploadButton">

                </button>

                <p-fileUpload
                    #logoUpload
                    class="logoUploader hidden-mobile"
                    [customUpload]="true"
                    [auto]="true"
                    (uploadHandler)="imageUpload($event)"
                ></p-fileUpload>


            </div>



            <div class="col-12">

                <p class="tn-title">Instruments</p>
                <p class="mb-4 tn-text-green tn-text-simple"  >
                    Here you can add all the instruments you play! You can also select your main instrument simply by clicking on it after you add it.
                </p>




                <p-dataView #dvListingEq
                            [value]="userData.musicRoles"
                            styleClass="equipment-dv"
                            layout="grid">

                    <ng-template pTemplate="empty"></ng-template>
                    <ng-template let-musicRole pTemplate="gridItem">
                        <div class="col-6 md:col-6 lg:col-3">
                            <div class="equipment-list-item" (click)="setMusicRoleAsPrimary(musicRole)">
                                <div class="grid  align-items-center" style="margin-bottom:-1rem; width: 100%;" >
                                    <div class="col-12 md:col-2">
                                        <i class="md:inline-block hidden circular-icon till-noon-icon {{musicRole.categoryIcon}}"></i>
                                        <i class="md:hidden inline-block circular-icon till-noon-icon till-noon-icon-big {{musicRole.categoryIcon}}"></i>

                                        <button pButton
                                                *ngIf="musicRole.primary === true"
                                                pRipple type="button" icon="pi pi-star"
                                                class="p-button-rounded p-button-icon-only primary-music-role">
                                        </button>


                                    </div>
                                    <div class="col-12 md:col-10 pl-0">
                                        <p class="tn-text-simple">{{musicRole.categoryLabel | titleCaseExtendedPipe}}</p>

                                        <button
                                                *ngIf="allowEditInstruments"
                                                pButton
                                                class="remove-music-role"
                                                icon="pi pi-times"
                                                label="DELETE"
                                                pRipple
                                                type="button"
                                                (click)="removeMusicRole(musicRole, $event)"
                                        >
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </ng-template>

                        <ng-template
                                pTemplate="footer"
                        >
                            <div
                                    class="flex justify-content-start"
                                    *ngIf="!allowEditInstruments"
                            >
                                <button
                                        icon="pi pi-plus"
                                        pButton
                                        label="ADD NEW"
                                        type="button"
                                        class="tn-secondary-button w-auto"
                                        (click)="allowEditInstruments = !allowEditInstruments"
                                ></button>
                            </div>

                            <div
                                    *ngIf="allowEditInstruments"
                                    class="flex align-items-stretch"
                            >


                                <p-dropdown
                                        [options]="musicRolesOptions"
                                        [(ngModel)]="selectedMusicRole"
                                        placeholder="Select an instrument"
                                        autoDisplayFirst="false"
                                        optionLabel="label"
                                        styleClass="mr-4 instrument-options"
                                        (onChange)="saveMusicRole($event)"
                                >
                                    <ng-template let-musicRole pTemplate="item">
                                        <div class="instrument-item">
                                            <div class="{{musicRole.icon}}"></div>
                                            <div>{{musicRole.label}}</div>
                                        </div>
                                    </ng-template>
                                </p-dropdown>


<!--                                <button-->
<!--                                        icon="pi pi-check"-->
<!--                                        pButton-->
<!--                                        label="SAVE"-->
<!--                                        type="button"-->
<!--                                        class=" tn-secondary-button  w-auto ml-4"-->
<!--                                        (click)="allowEditInstruments = !allowEditInstruments;"-->
<!--                                ></button>-->

                            </div>

                        </ng-template>

                </p-dataView>





            </div>

        </div>


    </div>

    <div class="col-12">

        <div class="grid grid-nogutter justify-content-between">
            <button pButton  class="tn-primary-button" label="BACK" (click)="previousPage()" icon="till-noon-icon tnicon-Arrow-Left" iconPos="left"></button>
<!--            <button pButton class="tn-helper-button" label="SKIP" (click)="nextPage(true)" icon="pi pi-angle-right" iconPos="right"></button>-->
            <button pButton class="tn-secondary-button" label="NEXT" (click)="nextPage()" icon="till-noon-icon tnicon-Arrow-Right" iconPos="right"></button>
        </div>

    </div>
</div>

