import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InstrumentAndEquipmentService {


    instrumentsAndEquipments = [
        {
            type: 'acoustic_&_classical_guitar',
            name: 'Acoustic & Classical Guitars',
            category: [
                'instrument'
            ],
            icon: 'tnicon-Acoustic--Classical-Guitar',
            brands: [
                {
                    type: 'cort',
                    name: 'Cort',
                },
                {
                    type: 'epiphone',
                    name: 'Epiphone',
                },
                {
                    type: 'fender',
                    name: 'Fender',
                },
                {
                    type: 'gibson',
                    name: 'Gibson',
                },
                {
                    type: 'gretsch',
                    name: 'Gretsch',
                },
                {
                    type: 'guild',
                    name: 'Guild',
                },
                {
                    type: 'ibanez',
                    name: 'Ibanez',
                },
                {
                    type: 'prs',
                    name: 'PRS',
                },
                {
                    type: 'sigma',
                    name: 'Sigma',
                },
                {
                    type: 'takamine',
                    name: 'Takamine',
                },
                {
                    type: 'yamaha',
                    name: 'Yamaha',
                },
                {
                    type: 'other',
                    name: 'Other'
                }
            ]
        },
        {
            type: 'acoustic_bass_guitar',
            name: 'Acoustic Bass Guitars',
            icon: 'tnicon-Acoustic-Bass-Guitar',
            category: [
                'instrument'
            ],
            brands: [
                {
                    type: 'fender',
                    name: 'Fender',
                },
                {
                    type: 'harley_benton',
                    name: 'Harley Benton',
                },
                {
                    type: 'warwick',
                    name: 'Warwick',
                },
                {
                    type: 'höfner',
                    name: 'Höfner',
                },
                {
                    type: 'ibanez',
                    name: 'Ibanez',
                },
                {
                    type: 'other',
                    name: 'Other'
                }
            ]
        },
        {
            type: 'cymbals',
            name: 'Cymbals',
            icon: 'tnicon-Cymbals',
            category: [
                'instrument'
            ],
            brands: [
                {
                    type: 'istanbul_agop',
                    name: 'Istanbul Agop',
                },
                {
                    type: 'meinl',
                    name: 'Meinl',
                },
                {
                    type: 'paiste',
                    name: 'Paiste',
                },
                {
                    type: 'sabian',
                    name: 'Sabian',
                },
                {
                    type: 'zildjian',
                    name: 'Zildjian',
                },
                {
                    type: 'zultan',
                    name: 'Zultan',
                },
                {
                    type: 'other',
                    name: 'Other'
                }
            ]
        },
        {
            type: 'effects_pedals',
            name: 'Effect pedals',
            icon: 'tnicon-Effect-Pedals',
            category: [
                'instrument'
            ],
            brands: [
                {
                    type: 'behringer',
                    name: 'Behringer',
                },
                {
                    type: 'boss',
                    name: 'Boss',
                },
                {
                    type: 'dunlop',
                    name: 'Dunlop',
                },
                {
                    type: 'tc_electronic',
                    name: 'TC Electronic',
                },
                {
                    type: 'mxr',
                    name: 'MXR',
                },
                {
                    type: 'mooer',
                    name: 'Mooer',
                },
                {
                    type: 'electro_harmonix',
                    name: 'Electro Harmonix',
                },
                {
                    type: 'ibanez',
                    name: 'Ibanez',
                },
                {
                    type: 'strymon',
                    name: 'Strymon',
                },
                {
                    type: 'walrus_audio',
                    name: 'Walrus Audio',
                },
                {
                    type: 'other',
                    name: 'Other'
                }
            ]
        },
        {
            type: 'electric_bass_guitar',
            name: 'Electric Bass Guitars',
            icon: 'tnicon-Electric-Bass-Guitar',
            category: [
                'instrument'
            ],
            brands: [
                {
                    type: 'cort',
                    name: 'Cort',
                },
                {
                    type: 'esp',
                    name: 'ESP',
                },
                {
                    type: 'epiphone',
                    name: 'Epiphone',
                },
                {
                    type: 'fender',
                    name: 'Fender',
                },
                {
                    type: 'gibson',
                    name: 'Gibson',
                },
                {
                    type: 'ibanez',
                    name: 'Ibanez',
                },
                {
                    type: 'j&d',
                    name: 'J&D',
                },
                {
                    type: 'rickenbacker',
                    name: 'Rickenbacker',
                },
                {
                    type: 'squier',
                    name: 'Squier',
                },
                {
                    type: 'warwick',
                    name: 'Warwick',
                },
                {
                    type: 'yamaha',
                    name: 'Yamaha',
                },
                {
                    type: 'other',
                    name: 'Other'
                }
            ]
        },
        {
            type: 'electric_guitar',
            name: 'Electric Guitars',
            icon: 'tnicon-Electric-Guitar',
            category: [
                'instrument'
            ],
            brands: [
                {
                    type: 'fender',
                    name: 'Fender',
                },
                {
                    type: 'gibson',
                    name: 'Gibson',
                },
                {
                    type: 'ibanez',
                    name: 'Ibanez',
                },
                {
                    type: 'squier',
                    name: 'Squier',
                },
                {
                    type: 'yamaha',
                    name: 'Yamaha',
                },
                {
                    type: 'esp',
                    name: 'ESP',
                },
                {
                    type: 'epiphone',
                    name: 'Epiphone',
                },
                {
                    type: 'gretsch',
                    name: 'Gretsch',
                },
                {
                    type: 'harley_benton',
                    name: 'Harley Benton',
                },
                {
                    type: 'cort',
                    name: 'Cort',
                },
                {
                    type: 'other',
                    name: 'Other'
                }
            ]
        },
        {
            type: 'keyboards',
            name: 'Keyboards',
            icon: 'tnicon-Keyboards',
            category: [
                'instrument'
            ],
            brands: [
                {
                    type: 'yamaha',
                    name: 'Yamaha',
                },
                {
                    type: 'akai_professional',
                    name: 'AKAI Professional',
                },
                {
                    type: 'kawai',
                    name: 'Kawai',
                },
                {
                    type: 'korg',
                    name: 'Korg',
                },
                {
                    type: 'roland',
                    name: 'Roland',
                },
                {
                    type: 'startone',
                    name: 'Startone',
                },
                {
                    type: 'hammond',
                    name: 'Hammond',
                },
                {
                    type: 'rhodes',
                    name: 'Rhodes',
                },
                {
                    type: 'miditech',
                    name: 'Miditech',
                },
                {
                    type: 'casio',
                    name: 'Casio',
                },
                {
                    type: 'other',
                    name: 'Other'
                }
            ]
        },
        {
            type: 'pianos',
            name: 'Pianos',
            icon: 'tnicon-Pianos',
            category: [
                'instrument'
            ],
            brands: [
                {
                    type: 'bluther',
                    name: 'Bluther',
                },
                {
                    type: 'kawai',
                    name: 'Kawai',
                },
                {
                    type: 'roland',
                    name: 'Roland',
                },
                {
                    type: 'sauter',
                    name: 'Sauter',
                },
                {
                    type: 'schimmel',
                    name: 'Schimmel',
                },
                {
                    type: 'seiler',
                    name: 'Seiler',
                },
                {
                    type: 'steiway_&_sons',
                    name: 'Steiway & Sons',
                },
                {
                    type: 'wendl_&_lung',
                    name: 'Wendl & Lung',
                },
                {
                    type: 'yamaha',
                    name: 'Yamaha',
                },
                {
                    type: 'other',
                    name: 'Other'
                }
            ]
        },

        {
            type: 'audio-interfaces',
            name: 'Audio Interfaces',
            category: [
                'equipment'
            ],
            icon: 'tnicon-Audio-Interfaces',
            brands: [
                {
                    type: 'focusrite',
                    name: 'Focusrite',
                },
                {
                    type: 'pre_sonus',
                    name: 'PreSonus',
                },
                {
                    type: 'prism_sound',
                    name: 'Prism Sound',
                },
                {
                    type: 'rme',
                    name: 'RME',
                },
                {
                    type: 'universal_audio',
                    name: 'Universal Audio',
                },
                {
                    type: 'other',
                    name: 'Other'
                }
            ]
        },

        {
            type: 'bass_amps',
            name: 'Bass Amps',
            category: [
                'equipment'
            ],
            icon: 'tnicon-Bass-Amps',
            brands: [
                {
                    type: 'ampeg',
                    name: 'Ampeg',
                },
                {
                    type: 'darkglass',
                    name: 'Darkglass',
                },
                {
                    type: 'ebs',
                    name: 'EBS',
                },
                {
                    type: 'fender',
                    name: 'Fender',
                },
                {
                    type: 'glockenklang',
                    name: 'Glockenklang',
                },
                {
                    type: 'hartke',
                    name: 'Hartke',
                },
                {
                    type: 'laney',
                    name: 'Laney',
                },
                {
                    type: 'markbass',
                    name: 'Markbass',
                },
                {
                    type: 'orange',
                    name: 'Orange',
                },
                {
                    type: 'peavey',
                    name: 'Peavey',
                },
                {
                    type: 'tech_21',
                    name: 'Tech 21',
                },
                {
                    type: 'vox',
                    name: 'Vox',
                },
                {
                    type: 'warwick',
                    name: 'Warwick',
                },
                {
                    type: 'other',
                    name: 'Other'
                }
            ]
        },

        {
            type: 'dj_controllers_&_mixers',
            name: 'DJ Controllers & Mixers',
            category: [
                'equipment'
            ],
            icon: 'tnicon-DJ-Controllers--Mixers',
            brands: [
                {
                    type: 'behringer',
                    name: 'Behringer',
                },
                {
                    type: 'denon_dj',
                    name: 'Denon DJ',
                },
                {
                    type: 'hercules',
                    name: 'Hercules',
                },
                {
                    type: 'native_instruments',
                    name: 'Native Instruments',
                },
                {
                    type: 'pioneer',
                    name: 'Pioneer',
                },
                {
                    type: 'other',
                    name: 'Other'
                }
            ]
        },

        {
            type: 'drum_sets',
            name: 'Drums & Percussion',
            category: [
                'equipment'
            ],
            icon: 'tnicon-Drums--Percussion',
            brands: [
                {
                    type: 'dw',
                    name: 'DW',
                },
                {
                    type: 'gretsch',
                    name: 'Gretsch',
                },
                {
                    type: 'ludwig',
                    name: 'Ludwig',
                },
                {
                    type: 'mapex',
                    name: 'Mapex',
                },
                {
                    type: 'pearl',
                    name: 'Pearl',
                },
                {
                    type: 'sonor',
                    name: 'Sonor',
                },
                {
                    type: 'startone',
                    name: 'Startone',
                },
                {
                    type: 'tama',
                    name: 'Tama',
                },
                {
                    type: 'yamaha',
                    name: 'Yamaha',
                },
                {
                    type: 'other',
                    name: 'Other'
                }
            ]
        },

        {
            type: 'guitar_amps',
            name: 'Guitar Amps',
            category: [
                'equipment'
            ],
            icon: 'tnicon-Guitar-Amps',
            brands: [
                {
                    type: 'boss',
                    name: 'Boss',
                },
                {
                    type: 'darkglass',
                    name: 'Darkglass',
                },
                {
                    type: 'engl',
                    name: 'Engl',
                },
                {
                    type: 'evh',
                    name: 'Evh',
                },
                {
                    type: 'fender',
                    name: 'Fender',
                },
                {
                    type: 'laney',
                    name: 'Laney',
                },
                {
                    type: 'line6',
                    name: 'Line6',
                },
                {
                    type: 'marshall',
                    name: 'Marshall',
                },
                {
                    type: 'mesa_boogie',
                    name: 'Mesa Boogie',
                },
                {
                    type: 'prs',
                    name: 'PRS',
                },
                {
                    type: 'peavey',
                    name: 'Peavey',
                },
                {
                    type: 'randall',
                    name: 'Randall',
                },
                {
                    type: 'vox',
                    name: 'Vox',
                },
                {
                    type: 'other',
                    name: 'Other'
                }
            ]
        },

        {
            type: 'microphones',
            name: 'Microphones',
            category: [
                'equipment'
            ],
            icon: 'tnicon-Microphone',
            brands: [
                {
                    type: 'akg',
                    name: 'AKG',
                },
                {
                    type: 'beyerdynamic',
                    name: 'Beyerdynamic',
                },
                {
                    type: 'electro-voice',
                    name: 'Electro-voice',
                },
                {
                    type: 'neumann',
                    name: 'Neumann',
                },
                {
                    type: 'rode',
                    name: 'Rode',
                },
                {
                    type: 'sennheiser',
                    name: 'Sennheiser',
                },
                {
                    type: 'shure',
                    name: 'Shure',
                },
                {
                    type: 'telefunken',
                    name: 'Telefunken',
                },
                {
                    type: 'other',
                    name: 'Other'
                }
            ]
        },

        {
            type: 'mixboards',
            name: 'Mixboards',
            category: [
                'equipment'
            ],
            icon: 'tnicon-Mixboards',
            brands: [
                {
                    type: 'digital',
                    name: 'Digital',
                },
                {
                    type: 'analogue',
                    name: 'Analogue',
                }
            ]
        },

        {
            type: 'monitors',
            name: 'Monitors',
            category: [
                'equipment'
            ],
            icon: 'tnicon-Monitors',
            brands: [
                {
                    type: 'focal',
                    name: 'Focal',
                },
                {
                    type: 'genelec',
                    name: 'Genelec',
                },
                {
                    type: 'jbl',
                    name: 'JBL',
                },
                {
                    type: 'krk',
                    name: 'KRK',
                },
                {
                    type: 'neumann',
                    name: 'Neumann',
                },
                {
                    type: 'pre_sonus',
                    name: 'PreSonus',
                },
                {
                    type: 'yamaha',
                    name: 'Yamaha',
                },
                {
                    type: 'other',
                    name: 'Other'
                }
            ]
        },

        {
            type: 'preamps',
            name: 'Preamps',
            category: [
                'equipment'
            ],
            icon: 'tnicon-Preamps',
            brands: [
                {
                    type: 'focusrite',
                    name: 'Focusrite',
                },
                {
                    type: 'neve',
                    name: 'Neve',
                },
                {
                    type: 'rme',
                    name: 'RME',
                },
                {
                    type: 'universal_audio',
                    name: 'Universal Audio',
                },
                {
                    type: 'other',
                    name: 'Other'
                }
            ]
        },

        {
            type: 'software_&_plugins',
            name: 'Software & Plugins',
            category: [
                'equipment'
            ],
            icon: 'tnicon-Software--Plugins',
            brands: [
                {
                    type: 'ableton',
                    name: 'Ableton',
                },
                {
                    type: 'native_instruments',
                    name: 'Native Instruments',
                },
                {
                    type: 'steinberg_cubase',
                    name: 'Steinberg Cubase',
                },
                {
                    type: 'universal_audio',
                    name: 'Universal Audio',
                },
                {
                    type: 'other',
                    name: 'Other'
                }
            ]
        },

        {
            type: 'other',
            name: 'Other',
            icon: 'tnicon-Other',
            category: [
                'instrument',
                'equipment'
            ],
            brands: [
                {
                    type: 'other',
                    name: 'Other'
                }
            ]
        }
    ];


    musicRoles = [
        {
            type: 'vocals',
            label: 'Vocals',
            icon: 'tnicon-Microphone',
        },
        {
            type: 'guitar',
            label: 'Guitar',
            icon: 'tnicon-Electric-Guitar',

        },
        {
            type: 'bass',
            label: 'Bass Guitar',
            icon: 'tnicon-Electric-Bass-Guitar',
        },
        {
            type: 'bouzouki',
            label: 'Bouzouki',
            icon: 'tnicon-Bouzouki',
        },

        {
            type: 'percussion',
            label: 'Drums / Percussion',
            icon: 'tnicon-Drums--Percussion',
        },

        {
            type: 'keyboards',
            label: 'Keys/Piano',
            icon: 'tnicon-Keyboards',
        },
        {
            type: 'cello_double_bass',
            label: 'Cello/Double Bass',
            icon: 'tnicon-Double-Bass',
        },
        {
            type: 'trumpet',
            label: 'Trumpet',
            icon: 'tnicon-Trumpet',
        },
        {
            type: 'saxophone',
            label: 'Saxophone',
            icon: 'tnicon-Saxophone',
        },
        {
            type: 'dj',
            label: 'DJ',
            icon: 'tnicon-Mixboards',
        },
        {
            type: 'other',
            label: 'Other',
            icon: 'tnicon-Other',
        },
    ];

    musicRolesMap = new Map<string, {icon: string, label: string}>();
    instruments;
    equipments;
    instrumentBrandMap = new Map<string, any[]>();
    equipmentBrandMap = new Map<string, any[]>();
    constructor() {
        this.musicRoles.forEach(ms => {
            this.musicRolesMap.set(ms.type, {icon: ms.icon, label: ms.label});
        });

        this.instruments = this.instrumentsAndEquipments.filter(a => a.category.includes('instrument'));
        this.equipments = this.instrumentsAndEquipments.filter(a => a.category.includes('equipment'));

        this.instruments.forEach(inst => {
            this.instrumentBrandMap.set(inst.type, inst.icon);
        });

        this.equipments.forEach(inst => {
            this.equipmentBrandMap.set(inst.type, inst.icon);
        });
    }

    getMusicRoles(){
        return this.musicRoles;
    }

    getMusicRolesMap(){
        return this.musicRolesMap;
    }

    getInstrumentsMap(){
        return this.instrumentBrandMap;
    }
    getEquipmentMap(){
        return this.equipmentBrandMap;
    }

    getInstrumentsAndEquipments() {
        return this.instrumentsAndEquipments;
    }
}
