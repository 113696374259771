import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {InstrumentAndEquipmentService} from '../../../services/instruments.equipments.service';

@Component({
    selector: 'app-instrument',
    templateUrl: './instrument.component.html',
    styleUrls: ['./instrument.component.scss']
})
export class InstrumentComponent implements OnInit {

    @Input() instrument;
    @Input() rentFlag;
    @Output() addEventEmitter = new EventEmitter();
    @Output() removeEventEmitter = new EventEmitter();
    @Input() isSelectedForRent: boolean;
    constructor(private instrumentService: InstrumentAndEquipmentService) {

    }
    isAddedForRental: boolean;
    instrumentsMap;
    ngOnInit(): void {
        this.isAddedForRental = this.isSelectedForRent;
        this.instrumentsMap = this.instrumentService.getInstrumentsMap();
    }

    addThisForRental(){
        this.isAddedForRental = true;
        this.addEventEmitter.emit(this.instrument);
    }

    removeThisForRental(){
        this.isAddedForRental = false;
        this.removeEventEmitter.emit(this.instrument);
    }

}
