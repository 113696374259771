import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterBandsInvites'
})
export class FilterBandsInvitesPipe implements PipeTransform {

  transform( data: any, viewInvites: boolean = false): unknown {
    const returnValue = [];
    if (data.bandsData && !viewInvites ) {
      returnValue.push(...data.bandsData);
    }
    if (viewInvites && data.invitesData) {
      returnValue.push(...data.invitesData);
    }
    return returnValue;
  }

}
