<p-card
        [style]="{border: '1px solid #938F99', borderRadius : 12}"
        styleClass="p-card-shadow "
>

    <div class="grid grid-nogutter align-items-center">
        <div class="col-fixed" style="margin: auto;width: 3.4375rem;height: 3.4375rem;"
        >
            <p-avatar
                    [image]="band.hasOwnProperty('avatar') && band.avatar ? band.avatar['link'] : 'assets/images/bandbeat/sample_band_logo.png'"
                    shape="circle" styleClass="mr-2 band-avatar" ></p-avatar>
        </div>
        <div class="col ml-4"
        >
            <p style="margin:auto" class="tn-text-big">{{band.name}}</p>
            <div class="tn-text-small">{{band.members.length}} members</div>
        </div>

        <div class="col-fixed md:hidden"
             style="
                text-align: center;
                margin: auto;
                width: 7.4375rem;
                height: auto;
                display: flex;
                justify-content: end;
            ">
            <button
                    pButton
                    icon="pi pi-check"
                    pRipple
                    type="button"
                    class=" tn-secondary-button"
                    (click)="acceptInvite()"
            ></button>
            <button
                    class="tn-danger-button ml-4 "
                    icon="pi pi-times"
                    pButton
                    pRipple
                    type="button"
                    (click)="declineInvite()"
            ></button>

        </div>

        <div class="col-fixed  hidden md:flex"
             style="
                text-align: center;
                margin: auto;
                width: auto;
                height: 3.4375rem;
                display: flex;
                justify-content: end;
            "
        >

            <div style="text-align:center">
                <button
                        pButton
                        icon="pi pi-check"
                        pRipple
                        label="ACCEPT"
                        type="button"
                        class=" tn-primary-button"
                        (click)="acceptInvite()"
                ></button>
                <button
                        class="tn-danger-button ml-4 "
                        icon="pi pi-times"
                        label="REJECT"
                        pButton
                        pRipple
                        type="button"
                        (click)="declineInvite()"
                ></button>
            </div>

        </div>

    </div>

</p-card>



