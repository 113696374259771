import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterBookingTypes'
})
export class FilterBookingTypesPipe implements PipeTransform {

  transform(bookingTypes: any[], args: any[]): any[] {
    const selectedIndex = bookingTypes.findIndex(el => el.code === args[0]);
    if (selectedIndex < 0) {
      return bookingTypes;
    }
    if (args[1] === 0) {
      return bookingTypes.slice(0,selectedIndex + 1);
    }
    if (args[1] === 1) {
      return bookingTypes.slice(selectedIndex + 1)
    }

    return bookingTypes;
  }

}
