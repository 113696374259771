import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  userEmail = '';

  constructor(
      private router: Router,
      private authenticationService: AuthenticationService,
      private messageService: MessageService
  ) { }

  ngOnInit(): void {
  }

  public forgotPass() {
    if (this.userEmail.length === 0) {
      this.messageService.clear();
      this.messageService.add({
        severity: 'error',
        summary: 'Invalid email',
        detail: 'Fill in your email first'
      });
    } else {

      this.authenticationService.requestNewPass(this.userEmail)
          .then((data) => {
            this.messageService.clear();
            this.messageService.add(
                {
                  severity: 'success',
                  summary: 'Success',
                  detail: 'Please check your email!'
                });
          }).catch((err) => {
        this.messageService.clear();
        this.messageService.add({
          severity: 'error',
          summary: 'Invalid',
          detail: err.error.message
        });
      });
    }
  }

}
