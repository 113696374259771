import {AfterViewChecked, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {MessageService} from 'primeng/api';
import {StudiosService} from '../../services/studios.service';
import {AppMainComponent} from '../../app.main.component';
import {ProfileService} from '../../services/profile.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperService } from '../../services/helper.service';

declare var google: any;
@Component({
  selector: 'app-search-listing',
  templateUrl: './search-listing.component.html',
  styleUrls: ['./search-listing.component.scss']
})
export class SearchListingComponent implements OnInit, AfterViewChecked {

  bookTime;
  bookDate;
  bookLocation;
  bookDuration;
  availableDurations: any[] = [
    {value: 1, label: '1 hour'},
    {value: 2, label: '2 hours'},
    {value: 3, label: '3 hours'},
    {value: 4, label: '4 hours'},
    {value: 5, label: '5 hours'},
    {value: 6, label: '6 hours'},
  ];
  availabilityOptions = [];


  availableServices: any[] = [
    {name: 'All', code: 'all'},
    {name: 'Rehearsal', code: 'rehearsal'},
    {name: 'Recording', code: 'recording'},
    {name: 'Podcast', code: 'podcast'}
  ];
  selectedService = 'all';

  availableTimes: any[] = [
    '00:00',
    '00:30',
    '01:00',
    '01:30',
    '02:00',
    '02:30',
    '03:00',
    '04:30',
    '05:00',
    '05:30',
    '06:00',
    '06:30',
    '07:00',
    '07:30',
    '08:00',
    '08:30',
    '09:00',
    '09:30',
    '09:00',
    '10:00',
    '10:30',
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '14:00',
    '14:30',
    '15:00',
    '15:30',
    '16:00',
    '16:30',
    '17:00',
    '17:30',
    '18:00',
    '18:30',
    '19:00',
    '19:30',
    '20:00',
    '20:30',
    '21:00',
    '21:30',
    '22:00',
    '22:30',
    '23:00',
    '23:30',
  ];
  @ViewChild('locationSearch') locationSearch: ElementRef;
  locationAutoComplete;
  locationFound;
  searchDataView = false;

  public currentDate;
  public maxDate;


  // @Output() locationBasedSearch = new EventEmitter();
  constructor(
      private app: AppMainComponent,
      private messageService: MessageService,
      private studiosService: StudiosService,
      private profileService: ProfileService,
      private helperService: HelperService,
      private router: Router ,
      private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.currentDate = new Date();
    this.maxDate = new Date();
    this.maxDate.setMonth(this.maxDate.getMonth() + 3);
    this.loadPresearchedStudios();
  }
  ngAfterViewChecked() {
    if (this.locationSearch && !this.locationAutoComplete) {
      this.locationAutoComplete = new google.maps.places.Autocomplete(
        this.locationSearch.nativeElement,
        {
          fields: ['formatted_address', 'geometry', 'name', 'type'],
          strictBounds: false,
          // componentRestrictions: {country: [ 'gr' , 'cy' ]},
          // types: ['regions'],
          types: ['(regions)'],
        }
      );

      this.locationAutoComplete.addListener('place_changed' , () => {
        this.locationFound = this.locationAutoComplete.getPlace();
      });

    }
  }



  loadPresearchedStudios() {
    this.buildAvailabilityOptions();

    const searchObject: any = {
      coordinates: {
        x:this.route.snapshot.queryParamMap.get('coordinatesX') ,
        y:this.route.snapshot.queryParamMap.get('coordinatesY') ,
      },
      dateTime:this.route.snapshot.queryParamMap.get('dateTime') ,
      duration:this.route.snapshot.queryParamMap.get('duration') ,
      service:this.route.snapshot.queryParamMap.get('service') ,
      country: this.route.snapshot.queryParamMap.get('country')
    }

    if(searchObject.dateTime){
      const date = new Date(searchObject.dateTime)
      const offset = date.getTimezoneOffset()/60
      date.setHours(date.getHours() - offset)

      const timeStr = date.toISOString().split("T")[1].split(":");
      const time = `${timeStr[0]}:${timeStr[1]}`;

      this.bookDate = date;
      this.bookTime = this.availabilityOptions.find(el => el === time)
      this.bookDuration = parseInt(searchObject.duration)
    }

    if(searchObject.service) {
      this.selectedService = searchObject.service
    }
  }



  buildAvailabilityOptions(){
    this.availabilityOptions = [];
    this.availabilityOptions  = Object.assign([], this.availableTimes);
    const regex = new RegExp(':', 'g');
    if (this.bookDate === this.currentDate) {
      this.availableTimes.every(slot => {
        const bookDateMinutes = this.bookDate.getMinutes().length === 1 ? '0' + this.bookDate.getMinutes().toString() : this.bookDate.getMinutes();
        if (parseInt(slot.replace(regex, ''), 10) < parseInt(this.bookDate.getHours().toString() + bookDateMinutes, 10)) {
          this.availabilityOptions.splice(this.availabilityOptions.indexOf(slot), 1);
          return true;
        }
        return false;
      });
    }

    if (!this.availabilityOptions.includes(this.bookTime)){
      this.bookTime = null;
    }
  }
  clearSearch( event) {
    this.studiosService.fetchStudios((
        (
            this.profileService.isUser() ||
            !this.profileService.isLoggedIn()
        ) ? 'active' : null
    )).then((data) => {
      this.searchDataView = false;
      this.locationFound = undefined;
      this.bookLocation = undefined;
      this.bookDate = undefined;
      this.bookDuration = undefined;
      this.bookTime = undefined;
      // this.locationBasedSearch.emit(undefined);
      // this.app.onMenuClick();
      this.app.onMenuButtonClick(event);
    });
  }

  public getOptionBarGridClass() {
    if (window.innerWidth < 1320) {
      return 'col-12';
    } else {
      return 'col-10 col-offset-1';
    }
  }

  public getSearchStudioButtonWidth() {
    if (window.innerWidth < 1102) {
      return '100%';
    } else {
      return '90%';
    }
  }

  public getClearAllButtonClass() {
    if (window.innerWidth < 1102) {
      return 'col-12 lg:col-5';
    } else {
      return 'col-12 lg:col-4';
    }
  }

  public getSearchStudioButtonClass() {
    if (window.innerWidth < 1102) {
      return 'col-12 lg:col-7';
    } else {
      return 'col-12 lg:col-8';
    }
  }


  searchStudios(event){
    const searchObject: any = {};
    if (
        !this.bookLocation &&
        !this.bookDate &&
        !this.bookTime &&
        !this.bookDuration &&
        !this.locationFound &&
        this.selectedService === 'all'
    ) {
      this.messageService.add({
        key: 'globalToast',
        severity: 'error',
        summary: 'Error',
        detail: 'You need to fill at least Location to make a search!',
        life: 3000
      });
    }

    if (
        this.bookLocation &&
        this.locationFound
    ) {
      if (this.locationFound.types.indexOf('country') !== -1) {
        searchObject.country = this.locationFound.name;
      } else {
        searchObject.coordinates = {
          x : this.locationFound.geometry.location.lat(),
          y : this.locationFound.geometry.location.lng(),
        };
      }
    }else if(
        this.route.snapshot.queryParamMap.has('coordinatesX') &&
        this.route.snapshot.queryParamMap.has('coordinatesY')
    ){
      searchObject.coordinates = {
        x: this.route.snapshot.queryParamMap.get('coordinatesX'),
        y: this.route.snapshot.queryParamMap.get('coordinatesY')
      };
    }else if(
        this.route.snapshot.queryParamMap.has('country')
    ){
      searchObject.country = this.route.snapshot.queryParamMap.get('country');
    }
    if (
        this.bookDate ||
        this.bookTime ||
        this.bookDuration
    ) {

      let errMsg;
      if (!this.bookTime && !this.bookDuration) {
        errMsg = 'please select Start time & Duration!';
      } else if (!this.bookDuration && !this.bookDate) {
        errMsg = 'please select Date & Duration!';
      } else if (!this.bookDate && this.bookTime) {
        errMsg = 'please select Date & Start time!';
      } else if (!this.bookDuration) {
        errMsg = 'please select Duration!';
      } else if (!this.bookTime) {
        errMsg = 'please select Start time!';
      } else if (!this.bookDate) {
        errMsg = 'please select Date!';
      }

      if (errMsg) {
        this.messageService.add({
          key: 'globalToast',
          severity: 'error',
          summary: 'Error',
          detail: errMsg,
          life: 3000
        });
      } else {
        const timeslot = this.bookDate;
        const timeslotHours = this.bookTime.split(':')[0];
        const timeslotMinutes = this.bookTime.split(':')[1];
        timeslot.setHours(timeslotHours);
        timeslot.setMinutes(timeslotMinutes);
        searchObject.dateTime = timeslot.toISOString();
        searchObject.duration = this.bookDuration;
      }

    }

    if (this.selectedService && this.selectedService !== 'all') {
      searchObject.service = this.selectedService;
    }

    if (Object.keys(searchObject).length > 0) {
      if (
          this.profileService.isUser() ||
          !this.profileService.isLoggedIn()
      ) {
        searchObject.status = 'active';
      }
      this.studiosService.searchStudios(searchObject)
          .then((data) => {
            this.helperService.trackSearch(
                {
                  content_name: "STUDIO SEARCH",
                  search_string: JSON.stringify(searchObject)
                }
            );
            this.passSearchParamsToUrl({...searchObject});

            this.searchDataView = true;
            this.app.onMenuButtonClick(event);
          });
    }

  }


  passSearchParamsToUrl(searchObject) {
    let searchParams = Object.entries(searchObject)
        .reduce((acc, [key, val]) => {
          if (val){
            if(key === 'coordinates'){
              acc[`${key}X`] = (val as any)?.x
              acc[`${key}Y`] = (val as any)?.y
            }
            else if(key != 'status') acc[key] = val;
          }
          return acc;
        }, {})

    const existingParams = this.route.snapshot.queryParams

    if(this.locationFound === undefined){
      searchParams = {
        ...searchParams,
        coordinatesX: existingParams.coordinatesX,
        coordinatesY: existingParams.coordinatesY,
        country: existingParams.country
      }
    }
    this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: searchParams,
          queryParamsHandling: '',
        }
    );
  }

}
