import {Component} from '@angular/core';
import {environment} from '../../../environments/environment';
import {ProfileService} from "../../services/profile.service";
import {LocalStorageService} from "ngx-webstorage";
import {Router} from "@angular/router";
import {AppMainComponent} from "../../app.main.component";
import {SocialAuthService} from "@abacritt/angularx-social-login";

declare var google: any;
@Component({
  selector: 'app-menu-responsive',
  templateUrl: './menu-responsive.component.html',
  styleUrls: ['./menu-responsive.component.scss']
})
export class MenuResponsiveComponent {


  public env = environment;

  protected readonly environment = environment;

  public loggedIn = false;
  public user: any;
  constructor(public app: AppMainComponent,
              private localSt: LocalStorageService,
              private profileService: ProfileService,
              private authService: SocialAuthService,
              private router: Router
  ) {
    this.localSt.observe('user')
        .subscribe((user) => {
          if (user && Object.keys(user).length > 0) {
            this.loggedIn = true;
          } else {
            this.loggedIn = false;
          }
        });
    this.user = this.profileService.getUserData();
    if (Object.keys(this.user).length === 0) {
      this.loggedIn = false;
    } else {
      this.loggedIn = true;
    }
  }

  redirectToFaceBook() {
    window.open('https://www.facebook.com/bandbeat.official/', '_blank');
  }

  redirectToInstagram() {
    window.open('https://www.instagram.com/bandbeat.official/', '_blank');
  }

  redirectToSpotify() {
    window.open('https://open.spotify.com/user/312gt67zju3rbtl65nlhdqe7gxhm?si=c822cf5f9c864c98/', '_blank');
  }

  redirectToLinkedIn() {
    window.open('https://www.linkedin.com/company/bandbeat/', '_blank');
  }

  redirectToUserProfile() {
    this.itemClick(event);
    this.router.navigate(['/profile' , this.user.id]);
  }

  logoutUser() {
    this.authService.signOut(false).then(() => {
      this.profileService.logOutUser();
      this.loggedIn = false;
      this.router.navigate(['login']);
    })
        .catch((e) => {
          this.profileService.logOutUser();
          this.loggedIn = false;
          this.router.navigate(['login']);
        });
  }

  public getOptionBarGridClass() {
    if (window.innerWidth < 1320) {
      return 'col-12';
    } else {
      return 'col-10 col-offset-1';
    }
  }

  itemClick(event) {
      this.app.onMenuButtonClick(event);
  }
}
