<p-dialog
        [(visible)]="display"
        [breakpoints]="{'1620px': '35vw', '1280px': '50vw', '960px': '70vw', '640px': '100vw'}"
        [style]="{width: '30vw'}"
        [draggable]="false"
        [resizable]="false"
        [modal]="true"
        [blockScroll]="true"
        [dismissableMask]="false"
        [closable]="true"

        (onHide)="hideModal($event)"
>


    <div class="grid" style="display:block;">
        <div class="row">

            <div class="col-12 pb-4 pl-4 pr-4 md:text-left flex align-items-center ">
                <section style="width:100%">
                    <p class="tn-title-big" style="font-weight:400;line-height:3.6rem;">Welcome to <span class="tn-title-big keyword">Bandbeat</span></p>

<!--                    <h1 class="welcomeMessage" style="text-align: left;" >Welcome to <span class="keyword">Bandbeat</span></h1>-->
                    <p class="tn-text-small mb-4" >Please sign in to continue</p>

                    <div class="p-fluid grid formgrid">
                        <div class="field col-12">
                            <p class="tn-text-simple tn-text-green ">Email</p>
                                            <input inputTrim
                                                    id="email"
                                                    type="text"
                                                    pInputText
                                                    [(ngModel)]="userEmail"
                                            />
                        </div>
                        <div class="field col-12">
                            <p class="tn-text-simple tn-text-green ">Password</p>
                                            <p-password
                                                    id="password"
                                                    inputId="password"
                                                    class="mask-icon"
                                                    [(ngModel)]="userPassword"
                                                    [toggleMask]="true"
                                            ></p-password>
                        </div>
                        <div class="field col-12 ">
                            <button
                                    pButton
                                    icon="pi pi-check"
                                    pRipple
                                    label="LOGIN"
                                    type="button"
                                    style="width:auto;"
                                    class=" tn-secondary-button  "
                                    (click)="login()"
                            ></button>
                        </div>

                        <div class="field col-12">
                            <button
                                    pButton
                                    type="button"
                                    [label]="'FORGOT PASSWORD'"
                                    class="p-button-text "
                                    [routerLink]="['/forgotpassword']"
                            ></button>
                        </div>


                        <div class=" field col-12">
                            <p class="tn-text-small tn-text-green mb-4 login-txt text-center " style="font-weight: 500;" >Or Login with</p>
                        </div>

                        <div class="field col-12 text-center flex justify-content-center " *ngIf="env.socialLoginEnabled">
                            <asl-google-signin-button
                                    text="continue_with"
                                    type='standard'
                                    size='large'
                                    shape="pill"
                                    locale="en_US"
                                    class="login-button google-button"
                                    width="{{getButtonSize()}}"
                            ></asl-google-signin-button>
                        </div>

                        <div class="field col-12 text-center flex justify-content-center " *ngIf="env.socialLoginEnabled">

                            <button
                                    pButton
                                    type="button"
                                    icon="pi pi-facebook"
                                    class="p-button-raised p-button-rounded facebook-button login-button "
                                    label="Continue with Facebook"
                                    (click)="signInWithFB()"
                            ></button>
                        </div>


                        <div class="field col-12 mt-4">
                            <span class="tn-text-small">Don’t have an account yet? <span class="tn-text-normal tn-text-green cursor-pointer" [routerLink]="['/signup']">Sign up here!</span></span>
                        </div>

                    </div>





                </section>
            </div>

        </div>

    </div>
</p-dialog>
