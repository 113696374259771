<div class="layout-footer">

    <div class="display">
        <i class="till-noon-icon tnicon-Facebook contact-button"
           (click)="redirectToFaceBook()"></i>
        <i class="till-noon-icon tnicon-Instagram contact-button"
           (click)="redirectToInstagram()"></i>
        <i class="till-noon-icon tnicon-Spotify contact-button"
           (click)="redirectToSpotify()"></i>
        <i class="till-noon-icon tnicon-Linkedin contact-button"
           (click)="redirectToLinkedIn()"></i>
        <span id="bb-terms-and-condition">
        <span class="cursor-pointer" [routerLink]="['/privacy&policy']">PRIVACY POLICY</span> | <span class="cursor-pointer" [routerLink]="['/terms&conditions']">TERMS & CONDITIONS</span>

      </span>
    </div>

    <div>
        <img [src]="'assets/images/landing/bb_sticker_2.png'" id="logo" alt="logo"/>
    </div>

    <div class="contact-align">
        <button
                pButton
                class="mr-3 tn-primary-button display"
                [routerLink]="'/contact'"
                label="CONTACT US"
                icon="till-noon-icon tnicon-Contact">
        </button>
        <span id="bb-copyright">
        © {{year}} Bandbeat. All rights reserved.
      </span>
    </div>
</div>
