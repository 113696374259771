<div class="layout-wrapper layout-slim">
    <div class="layout-topbar">
        <div class="layout-topbar-left">
            <a class="app-name" href="/">
                <img alt="main logo" [src]="'assets/images/bandbeat/bb_logo_header.png'" class="app-logo">
                <span>Bandbeat</span>
            </a>
        </div>
    </div>

    <div class="layout-content-wrapper layout-simple-page">
        <div class="layout-content" >
            <div class="landing-body" style="background:transparent !important; height: 100%">
                <div class="landing-wrapper">
                    <div class="header-content grid " style="margin-right: unset;color: #FFFFFF;">
                        <div class="col-12 md:col-10 lg:col-6 text-center md:text-left flex align-items-center md:justify-content-start justify-content-center ">
                            <section style="width:80%" class="layout-section-mobile">
                                <p class="tn-title-big" style="font-weight:400;">Welcome to</p>
                                <p class="tn-title-big keyword">Bandbeat</p>
                                <p class="tn-text-small mb-4" >Please pick your new password</p>

                                <div class="p-fluid grid formgrid"> 
                                    <div class="col-12 md:col-6">
                                            <span class="tn-text-simple tn-text-green ">TYPE NEW PASSWORD</span>
                                            <p-password
                                                    id="password"
                                                    inputId="password"
                                                    class="mask-icon"
                                                    [(ngModel)]="newPassword"
                                                    [toggleMask]="true"
                                            ></p-password>
                                    </div>

                                    <div class="col-12 md:col-6">
                                        <span class="p-float-label">
                                             <span class="tn-text-simple tn-text-green ">RETYPE NEW PASSWORD</span>
                                            <p-password
                                                    id="passwordR"
                                                    inputId="passwordR"
                                                    class="mask-icon"
                                                    [toggleMask]="true"
                                                    [(ngModel)]="newPasswordRepeat"
                                            ></p-password>
                                        </span>
                                    </div>

                                    <div class="grid grid-nogutter col-12 mobile-styling" style="padding-top: 1rem">
                                        <div class="field col-offset-4 col-4 md:col-4 md:col-offset-0">
                                            <p-button
                                            label="Back"
                                            styleClass="p-button-primary tn-primary-button"
                                            [routerLink]="['/login']"
                                            icon="till-noon-icon tnicon-Arrow-Left"
                                            iconPos="left"
                                            ></p-button>
                                        </div>
                                        

                                        <div class="field col-12 md:col-6 md:col-offset-2">
                                            <button
                                                    pButton
                                                    pRipple
                                                    label="RESET PASSWORD"
                                                    type="button"
                                                    class="tn-secondary-button"
                                                    (click)="changePass()"
                                            ></button>
                                        </div>
                                    </div>

                                </div>

                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <p-toast
                [style]="{'overflow-wrap' : 'break-word'}"
                [baseZIndex]="5000"
                life="4000"
        ></p-toast>
        <app-footer></app-footer>
    </div>
</div>
