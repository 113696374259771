import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {AppMainComponent} from './app.main.component';
import {AppNotfoundComponent} from './theme/pages/app.notfound.component';
import {AppErrorComponent} from './theme/pages/app.error.component';
import {AppAccessdeniedComponent} from './theme/pages/app.accessdenied.component';
import {LandingComponent} from './landing/landing.component';
import {ListingComponent} from './listing/listing.component';
import {StudioComponent} from './studio/studio.component';
import {LoginComponent} from './login/login.component';
import {SignupComponent} from './signup/signup.component';
import {NewBookingComponent} from './new-booking/new-booking.component';
import {BookingInfoComponent} from './new-booking/booking-info/booking-info.component';
import {BookingServiceComponent} from './new-booking/booking-info/booking-service/booking-service.component';
import {BookingSettingsComponent} from './new-booking/booking-info/booking-settings/booking-settings.component';
import {BookingRoomComponent} from './new-booking/booking-info/booking-room/booking-room.component';
import {BookingInstrumentsComponent} from './new-booking/booking-info/booking-instruments/booking-instruments.component';
import {BookingSummaryComponent} from './new-booking/booking-summary/booking-summary.component';
import {BookingRequestComponent} from './new-booking/booking-request/booking-request.component';
import {NewBandComponent} from './new-band/new-band.component';
import {BookingMembersComponent} from './new-booking/booking-info/booking-members/booking-members.component';
import {BookingsComponent} from './bookings/bookings.component';
import {NotificationsComponent} from './notifications/notifications.component';
import {UserSettingsComponent} from './user-settings/user-settings.component';
import {UserProfileComponent} from './user-profile/user-profile.component';
import {BandProfileComponent} from './band-profile/band-profile.component';
import {ContactComponent} from './footer/contact/contact.component';
import {PrivacyAndPolicyComponent} from './footer/privacy-and-policy/privacy-and-policy.component';
import {TcComponent} from './footer/tc/tc.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {ChangePasswordComponent} from './change-password/change-password.component';
import {SocialCallbackComponent} from './social-callback/social-callback.component';
import {PaymentCallbackComponent} from './payment-callback/payment-callback.component';
import {SetupWizardComponent} from "./setup-wizard/setup-wizard.component";
import {WizardUserInfoComponent} from "./setup-wizard/wizard-user-info/wizard-user-info.component";
import {WizardUserMediaComponent} from "./setup-wizard/wizard-user-media/wizard-user-media.component";
import {WizardUserBandComponent} from "./setup-wizard/wizard-user-band/wizard-user-band.component";
import {UserPanelComponent} from "./user-panel/user-panel.component";
import {AuthenticatedGuard} from "./common/guards/authenticated.guard";
import {UserDataGuard} from "./common/guards/user-data.guard";
import {BookingPaymentComponent} from "./new-booking/booking-payment/booking-payment.component";

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', component: AppMainComponent,
                children: [
                    {path: '', component: LandingComponent},
                    {path: 'landing', component: LandingComponent},
                    {path: 'listing', component: ListingComponent},
                    {
                        path: 'studio',
                        children: [
                            {
                                path: '',
                                component: AppErrorComponent,
                            },
                            {
                                path: ':studioSlug',
                                component: StudioComponent,
                            }
                        ]
                    },
                    {
                        path: 'newbooking',
                        children: [
                            {
                                path: '',
                                redirectTo: 'info',
                                pathMatch: 'full'
                            },
                            {
                                path: 'info',
                                component: BookingInfoComponent,
                                children: [
                                    {
                                        path: '',
                                        redirectTo: 'type',
                                        pathMatch: 'full'
                                    },
                                    {
                                        path: 'type',
                                        component: NewBookingComponent,
                                    },
                                    {
                                        path: 'members',
                                        component: BookingMembersComponent,
                                    },
                                    {
                                        path: 'settings',
                                        component: BookingSettingsComponent,
                                    },
                                    {
                                        path: 'service',
                                        component: BookingServiceComponent,
                                    },
                                    {
                                        path: 'room',
                                        component: BookingRoomComponent,
                                    },
                                    {
                                        path: 'instrument',
                                        component: BookingInstrumentsComponent,
                                    },
                                    {
                                        path: 'summary',
                                        component: BookingSummaryComponent,
                                    },
                                ]
                            },
                            {
                                path: 'summary',
                                component: BookingSummaryComponent,
                            },
                            {
                                path: 'request',
                                component: BookingRequestComponent,
                            }

                        ]
                    },
                    {
                        path: 'newband',
                        component: NewBandComponent,
                    },
                    {
                        path: 'bookings',
                        children: [
                            {
                                path: '',
                                component: BookingsComponent,
                            },
                            {
                                path: ':id',
                                component: BookingSummaryComponent,
                            },
                        ]
                    },
                    {
                        path: 'notifications',
                        component: NotificationsComponent,
                    },
                    {
                        path: 'wizard',
                        children: [
                            {
                                path: '',
                                component: SetupWizardComponent,
                                children: [
                                    {
                                        path: 'user-info',
                                        component: WizardUserInfoComponent,
                                    },
                                    {
                                        path: 'user-media',
                                        component: WizardUserMediaComponent,
                                    },
                                    {
                                        path: 'user-band',
                                        component: WizardUserBandComponent,
                                    },
                                    // {
                                    //     path: 'studio-rooms',
                                    //     component: WizardStudioRoomsComponent,
                                    // },
                                    // {
                                    //     path: 'studio-rentals',
                                    //     component: WizardStudioRentalsComponent,
                                    // },
                                ]
                            },
                        ]
                    },
                    // {
                    //     path: 'profile',
                    //     children: [
                    //         {
                    //             path: '',
                    //             component: UserProfileComponent,
                    //         },
                    //         {
                    //             path: ':id',
                    //             component: UserProfileComponent,
                    //         },
                    //     ]
                    // },
                    {
                        path: 'profile',
                        children: [
                            {
                                path: '',
                                component: UserPanelComponent,
                            },
                            {
                                path: ':id',
                                component: UserPanelComponent,
                                children: [
                                    {
                                        path: '',
                                        redirectTo: 'user',
                                        pathMatch: 'full'
                                    },
                                    {
                                        path: 'user',
                                        component: UserProfileComponent,
                                    },
                                    {
                                        path: 'bookings',
                                        children: [
                                            {
                                                path: '',
                                                component: BookingsComponent,
                                                canActivate: [UserDataGuard],
                                            },
                                            {
                                                path: ':id',
                                                component: BookingSummaryComponent,
                                            },
                                        ],
                                    },
                                    {
                                        path: 'notifications',
                                        component: NotificationsComponent,
                                        canActivate: [AuthenticatedGuard,UserDataGuard],
                                    },
                                    {
                                        path: 'settings',
                                        children: [
                                            {
                                                path: '',
                                                component: UserSettingsComponent,
                                                canActivate: [AuthenticatedGuard, UserDataGuard]
                                            },
                                            {
                                                path: 'changepassword',
                                                component: ChangePasswordComponent,
                                                canActivate: [AuthenticatedGuard, UserDataGuard]
                                            }
                                        ]
                                    },
                                ],
                                // canActivate: [AuthenticatedGuard, UserDataGuard],
                            },
                        ]
                    },
                    {
                        path: 'band',
                        children: [
                            {
                                path: '',
                                component: BandProfileComponent,
                            },
                            {
                                path: ':bandSlug',
                                component: BandProfileComponent,
                            },
                        ]
                    },
                    {
                        path: 'user',
                        children: [
                            {
                                path: ':userSlug',
                                component: UserPanelComponent,
                                children: [
                                    {
                                        path: '',
                                        redirectTo: 'user',
                                        pathMatch: 'full'
                                    },
                                    {
                                        path: 'user',
                                        component: UserProfileComponent,
                                    },
                                ]
                            }
                        ]
                    },
                    {
                        path: 'payment',
                        children: [
                            {
                                path: 'redirect',
                                component: PaymentCallbackComponent,
                            },
                            {
                                path: 'response',
                                component: BookingPaymentComponent,
                            },
                        ]
                    },


                    {path: 'socialcallback', component: SocialCallbackComponent},
                    {path: 'contact', component: ContactComponent},
                    {path: 'terms&conditions', component: TcComponent},
                    {path: 'privacy&policy', component: PrivacyAndPolicyComponent},
                ],
            },

            {path: 'signup', component: SignupComponent},
            {path: 'login', component: LoginComponent},
            {path: 'forgotpassword', component: ForgotPasswordComponent},
            {path: 'resetpassword', component: ResetPasswordComponent},

            {path: 'error', component: AppErrorComponent},
            {path: 'access', component: AppAccessdeniedComponent},
            {path: 'notfound', component: AppNotfoundComponent},
            {path: '**', redirectTo: '/notfound'},
        ], {scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled'})
    ],
    exports: [RouterModule],
    providers: [
        AuthenticatedGuard,
        UserDataGuard,
    ]
})
export class AppRoutingModule {
}
