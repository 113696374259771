import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'normalizeBookingStatus'
})
export class NormalizeBookingStatusPipe implements PipeTransform {

  transform(value: string, arg?: string): string {
    switch (value) {
      case 'studio-declined':
        return 'Studio declined';
      case 'studio-confirmed':
        return 'Successfull Studio Confirmation';
      case 'pending-confirmation':
        return 'Pending Studio confirmation';
      case 'planning':
        return 'Pending bandmates confirmation';
      case 'planning-complete':
        return 'Bandmates confirmed - action required';
      case 'user-declined':
        return 'Bandmates canceled';
      case 'user-canceled':
        return 'Canceled';
      case 'studio-canceled':
        return 'Studio canceled';
      default:
        return 'Pending';
    }
  }

}
