import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BookingsService} from '../services/bookings.service';
import {ProfileService} from '../services/profile.service';
import {UserService} from '../services/user.service';
import {filter, map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {HelperService} from '../services/helper.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {InstrumentAndEquipmentService} from '../services/instruments.equipments.service';

@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.scss'],
})
export class BookingsComponent implements OnInit {

  public env = environment;

  public bookings: any;
  public loading = false;

  public selectedBookingType = 'pending-confirmation';

  public bookingTypes = [
      {name: 'Pending Requests', code: 'pending-confirmation' , amount : 0 , icon : 'pending'},
      {name: 'Upcoming Bookings', code: 'upcoming-bookings' , amount : 0 , icon : 'event'},
      {name: 'Completed Bookings', code: 'completed-bookings' , amount : 0 , icon : 'event_available'},
      {name: 'Rejected Bookings', code: 'rejected-bookings' , amount : 0 , icon : 'event_busy'},
      {name: 'Canceled Bookings', code: 'canceled-bookings' , amount : 0 , icon : 'event_busy', info: 'Canceled bookings'},
  ];

  userId;

  public today = new Date().toISOString();
  public timeOfDay = new Date().toLocaleString('en-US', { hour: 'numeric', hour12: false });

  public isMobile = false;

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private userService: UserService,
      private profileService: ProfileService,
      private bookingsService: BookingsService,
      private helperService: HelperService,
      private musicRolesService: InstrumentAndEquipmentService,
      private confirmationService: ConfirmationService,
      private messageService: MessageService
  ) {
    this.userId = this.profileService.getUserData().id;
    this.isMobile = this.helperService.isMobile();

  }

  ngOnInit(): void {

    this.bookings = this.userService.bookings$
        .pipe(
            filter( bookings => Object.keys(bookings).length !== 0),
            map((bookings) => {
                this.mapBookingTypes();
                const results = bookings.results
                    // .filter( booking => booking.status !== 'user-not-found' && booking.status !== 'user-canceled')
                ;
                results.forEach((booking) => {
                    booking.needsConfirmation = false;

                    if (booking.status === 'planning-complete') {
                        booking.status = 'pending-confirmation';
                    }
                    if (booking.status === 'pending-confirmation') {
                        booking.needsConfirmation = true;
                    }

                    booking.needResponseAction = false;
                    if ( booking.status === 'planning'  ) {
                        const memberUser = booking.members.find((member) => member.member.id === this.userId);
                        if (memberUser && memberUser.vote === 'pending') {
                            booking.needResponseAction = true;
                        }
                    }

                    booking.canDelete = false;
                    if (
                        booking.booker.id === this.userId &&
                        booking.status !== 'user-declined' &&
                        booking.status !== 'user-canceled' &&
                        booking.status !== 'user-not-found' &&
                        booking.status !== 'studio-declined' &&
                        booking.status !== 'studio-canceled' &&
                        booking.status !== 'user-delete-decline' &&
                        !( booking.status === 'studio-confirmed' && this.helperService.isBefore(new Date(booking.bookingDate)) ) &&
                        booking.reported !== true
                    ) {
                        booking.canDelete = true;
                    }

                    booking.details = false;

                    booking.instrumentsLabels = booking.instruments.map(inst => inst.equipment.name).join(', ');

                    if (booking.members.length > 0){
                        booking.primaryMusicRoles = booking.members
                            .map(member => member.member?.musicRoles.filter(role => role.primary).map(mr => mr.type))
                            .map(mr => this.musicRolesService.getMusicRolesMap().get(mr[0])?.label);
                    }else {
                        booking.primaryMusicRoles = booking.booker?.musicRoles
                            .filter(role => role.primary)
                            .map(mr => this.musicRolesService.getMusicRolesMap().get(mr.type)?.label);
                    }

                    if (
                        booking.status === 'studio-declined' ||
                        booking.status === 'user-declined' ||
                        booking.status === 'user-delete-decline'

                    ) {
                        this.bookingTypes.find((bookType) => bookType.code === 'rejected-bookings').amount = this.bookingTypes.find((bookType) => bookType.code === 'rejected-bookings').amount + 1;
                        booking.bookingCode = 'rejected-bookings';
                    } else if ( booking.status === 'studio-canceled' ) {
                        this.bookingTypes.find((bookType) => bookType.code === 'canceled-bookings').amount = this.bookingTypes.find((bookType) => bookType.code === 'canceled-bookings').amount + 1;
                        booking.bookingCode = 'canceled-bookings';
                    } else if ( booking.status === 'user-canceled' ) {
                        this.bookingTypes.find((bookType) => bookType.code === 'canceled-bookings').amount = this.bookingTypes.find((bookType) => bookType.code === 'canceled-bookings').amount + 1;
                        booking.bookingCode = 'canceled-bookings';
                    } else if ( booking.status === 'pending-confirmation' || booking.status === 'planning' ) {
                        this.bookingTypes.find((bookType) => bookType.code === 'pending-confirmation').amount = this.bookingTypes.find((bookType) => bookType.code === 'pending-confirmation').amount + 1;
                        booking.bookingCode = 'pending-confirmation';
                    } else if ( booking.status === 'studio-confirmed') {
                        if (booking.reported === true ) {
                            this.bookingTypes.find((bookType) => bookType.code === 'canceled-bookings').amount = this.bookingTypes.find((bookType) => bookType.code === 'canceled-bookings').amount + 1;
                            booking.bookingCode = 'canceled-bookings';
                        } else if (this.helperService.isAfter(new Date(booking.bookingDate))) {
                            this.bookingTypes.find((bookType) => bookType.code === 'upcoming-bookings').amount = this.bookingTypes.find((bookType) => bookType.code === 'upcoming-bookings').amount + 1;
                            booking.bookingCode = 'upcoming-bookings';
                        } else {
                            this.bookingTypes.find((bookType) => bookType.code === 'completed-bookings').amount = this.bookingTypes.find((bookType) => bookType.code === 'completed-bookings').amount + 1;
                            booking.bookingCode = 'completed-bookings';
                        }
                    }
                });
                return results;
            } )
        );

    this.userService.fetchUserBookings( this.userId );
  }

  confirmBooking(booking, accept = true): void {
    if (accept) {
      this.bookingsService.acceptMemberBooking(booking.id )
          .then((data) => {
            return this.userService.fetchUserBookings( this.userId );
          });
    } else {
      this.bookingsService.declineMemberBooking(booking.id )
          .then((data) => {
            return this.userService.fetchUserBookings( this.userId );
          });
    }
  }
    mapBookingTypes() {
        this.bookingTypes = [
            {name: 'Pending Requests', code: 'pending-confirmation' , amount : 0 , icon : 'pending'},
            {name: 'Upcoming Bookings', code: 'upcoming-bookings' , amount : 0 , icon : 'event'},
            {name: 'Completed Bookings', code: 'completed-bookings' , amount : 0 , icon : 'event_available'},
            {name: 'Rejected Bookings', code: 'rejected-bookings' , amount : 0 , icon : 'event_busy'},
            {name: 'Canceled Bookings', code: 'canceled-bookings' , amount : 0 , icon : 'event_busy', info: 'Accepted bookings canceled by users'},
        ];
    }

  deleteBooking(booking) {
        let serviceType = '';
        if (booking.service.type === 'rehearsal') {
            serviceType = 'Rehearsal';
        } else if (booking.service.type === 'recording') {
            serviceType = 'Recording';
        }
        this.confirmationService.confirm({
          message: 'Are you sure you want to cancel this ' + serviceType + '? Please read our <a href="/terms&conditions?scrollTo=userCancelation" target="_blank">cancellation policy</a> before you continue.',
          header: 'Confirm',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
              const newStatus: any = {};
              if (booking.status === 'studio-confirmed') {
                  newStatus.status = 'user-canceled';
              } else {
                  newStatus.status = 'user-declined';
              }
              this.bookingsService.editBooking(booking.id , newStatus)
                  .then((data) => {
                      this.messageService.add({severity: 'success', summary: 'Successful', detail: 'Booking Canceled', life: 3000});
                      return this.userService.fetchUserBookings( this.userId );
                  });
          }
        });
  }

  seeDetails(booking) {
    this.router.navigate(
        [booking.id ],
        {
          relativeTo: this.route,
        });
  }

    getCurrency( booking, symbol = true ) {
        if (symbol) {
            return this.helperService.getCurrencySymbol(booking.priceCurrency ? booking.priceCurrency : 'EUR')
        } else {
            return booking.priceCurrency ? booking.priceCurrency : 'EUR';
        }
    }

}
