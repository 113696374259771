<div
        style="padding: 2rem 2rem 0 2rem;width: 100%;"

        class="optionBar">
    <div class="grid grid-nogutter">
        <div [ngClass]="getOptionBarGridClass()">
            <div class="grid grid-nogutter">
                <div class="field col-12 lg:col-2">


                    <p class="tn-text-big tn-text-green">Location</p>
                    <input
                            id="bookLocation"
                            #locationSearch
                            inputId="bookLocation"
                            type="text"
                            pInputText
                            placeholder=""
                            [(ngModel)]="bookLocation"
                    />

<!--                    <div class="flex flex-column gap-2">-->
<!--                        <label for="bookLocation">Location</label>-->
<!--                        <input-->
<!--                                id="bookLocation"-->
<!--                                #locationSearch-->
<!--                                inputId="bookLocation"-->
<!--                                type="text"-->
<!--                                pInputText-->
<!--                                placeholder=""-->
<!--                                [(ngModel)]="bookLocation"-->
<!--                        />-->
<!--                    </div>-->
                </div>
                <div class="field col-12 lg:col-2">

                    <p class="tn-text-big tn-text-green">Date</p>
                    <p-calendar inputId="bookDate"
                                id="bookDate"
                                dateFormat="dd-mm-yy"
                                [minDate]="currentDate"
                                [maxDate]="maxDate"
                                [readonlyInput]="true"
                                firstDayOfWeek="1"
                                [(ngModel)]="bookDate"
                                [style]="{'width':'95%'}" [inputStyle]="{'width':'95%'}"
                                (onSelect)="buildAvailabilityOptions()"
                    ></p-calendar>

<!--                    <div class="flex flex-column gap-2">-->
<!--                        <label for="bookDate">Date</label>-->
<!--                        <p-calendar inputId="bookDate"-->
<!--                                    id="bookDate"-->
<!--                                    dateFormat="dd-mm-yy"-->
<!--                                    [minDate]="currentDate"-->
<!--                                    [maxDate]="maxDate"-->
<!--                                    [readonlyInput]="true"-->
<!--                                    firstDayOfWeek="1"-->
<!--                                    [(ngModel)]="bookDate"-->
<!--                                    (onSelect)="buildAvailabilityOptions()"-->
<!--                        ></p-calendar>-->
<!--                    </div>-->
                </div>

                <div class="field col-12 lg:col-2">

                    <p class="tn-text-big tn-text-green">Start Time</p>
                    <p-dropdown
                            id="bookTime"
                            [options]="availabilityOptions"
                            [autoDisplayFirst]="false"
                            [(ngModel)]="bookTime"
                    ></p-dropdown>

<!--                    <div class="flex flex-column gap-2">-->
<!--                        <label for="bookTime">Start Time</label>-->
<!--                        <p-dropdown-->
<!--                                id="bookTime"-->
<!--                                [options]="availabilityOptions"-->
<!--                                [autoDisplayFirst]="false"-->
<!--                                [(ngModel)]="bookTime"-->
<!--                        ></p-dropdown>-->
<!--                    </div>-->
                </div>
                <div class="field col-12 lg:col-2">

                    <p class="tn-text-big tn-text-green">Duration</p>
                    <p-dropdown
                            id="bookDuration"
                            [options]="availableDurations"
                            [(ngModel)]="bookDuration"
                            [autoDisplayFirst]="false"
                            optionLabel="label"
                            optionValue="value"
                    ></p-dropdown>

<!--                    <div class="flex flex-column gap-2">-->
<!--                        <label for="bookDuration">Duration</label>-->
<!--                        <p-dropdown-->
<!--                                id="bookDuration"-->
<!--                                [options]="availableDurations"-->
<!--                                [(ngModel)]="bookDuration"-->
<!--                                [autoDisplayFirst]="false"-->
<!--                                optionLabel="label"-->
<!--                                optionValue="value"-->
<!--                        ></p-dropdown>-->
<!--                    </div>-->
                </div>
                <div class="field col-12 lg:col-2">

                    <p class="tn-text-big tn-text-green">Service</p>
                    <p-dropdown
                            id="searchService"
                            placeholder="Service"
                            [options]="availableServices"
                            [(ngModel)]="selectedService"
                            optionLabel="name"
                            optionValue="code"
                    ></p-dropdown>

                    <!--                    <div class="flex flex-column gap-2">-->
                    <!--                        <label for="searchService">Service</label>-->
                    <!--                        <p-dropdown-->
                    <!--                                id="searchService"-->
                    <!--                                placeholder="Service"-->
                    <!--                                [options]="availableServices"-->
                    <!--                                [(ngModel)]="selectedService"-->
                    <!--                                optionLabel="name"-->
                    <!--                                optionValue="code"-->
                    <!--                        ></p-dropdown>-->
                    <!--                    </div>-->
                </div>
                <div class="col-12 lg:col-4">
                    <div class="grid grid-nogutter">

                        <div class="mt-4 col-6">
                            <button [ngStyle]="{width : getSearchStudioButtonWidth()}"
                                    pButton
                                    pRipple
                                    label="SEARCH"
                                    type="button"
                                    class="p-button-raised p-button-rounded tn-primary-button"
                                    (click)="searchStudios($event)"
                            ></button>
                        </div>
                        <div class="mt-4 col-6">
                            <button
                                    pButton
                                    type="button"
                                    [ngClass]="'clearAll'"
                                    [disabled]="!(this.bookLocation || this.bookDate || this.bookTime || this.bookDuration || this.selectedService)"
                                    [label]="'CLEAR ALL FILTERS'"
                                    [icon]="'pi pi-times'"
                                    class="p-button-text"
                                    style="width:100%;"
                                    (click)="clearSearch($event)"
                            ></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

