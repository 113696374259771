<p-card [style]="{border: '1px solid #938F99', borderRadius : 12}" styleClass="p-card-shadow">
    <ng-template pTemplate="header">
        <div class="grid align-items-center">
            <div class="col-2" style="margin: auto">
                <p-avatar
                        [image]="item.hasOwnProperty('avatar') && item.avatar ? item.avatar['link'] : 'assets/images/bandbeat/sample_band_logo.png'"
                        shape="circle" styleClass="mr-2"></p-avatar>
            </div>
            <div class="col-7"><h5 style="margin: auto">{{item.name}}</h5>
                <div>{{item.members.length}} members</div>
            </div>
            <div class="col-3" style="text-align: center">
                <div *ngIf="showMenu">
                    <button #btn type="button" class="p-button-text" pButton icon="pi pi-ellipsis-v"
                            (click)="menu.toggle($event)"></button>
                    <p-menu #menu [model]="items" [popup]="true" appendTo="body"></p-menu>
                </div>
            </div>
        </div>
    </ng-template>

    <p-avatarGroup styleClass="mb-3">
        <ng-template
                ngFor
                let-bandMember
                [ngForOf]="item.members.slice(0,6)"
                let-i="index">
            <p-avatar pTooltip="{{bandMember.user?.name}}" tooltipPosition="bottom"
                    [image]="bandMember.user.avatar ? bandMember.user.avatar['link'] : 'assets/images/bandbeat/sample_user.png'"
                    shape="circle"
                    size="large"
            ></p-avatar>
        </ng-template>
        <p-avatar
                *ngIf="item.members.length > 6"
                label="+{{item.members.length - 6}}"
                [style]="{'background-color': '#3CD1C5', 'color': '#000000', 'font-size': '1.5rem'}"
                shape="circle"
                size="large"
        ></p-avatar>
    </p-avatarGroup>

    <ng-template pTemplate="footer" *ngIf="seeBand || invite || startBookingDetails">
        <div *ngIf="seeBand" style="text-align:right">
            <button class="p-button-text" (click)="seeBand(item)" label="View bands profile" pButton pRipple
                    type="button"></button>
        </div>
        <div *ngIf="invite" style="text-align:right">
            <button class="p-button-text" (click)="acceptInvite()" label="Accept Invite" pButton pRipple
                    type="button"></button>
            <button class="p-button-text" (click)="declineInvite()" style="color:#F2B8B5" label="Decline Invite" pButton
                    pRipple type="button"></button>
        </div>
        <div *ngIf="startBookingDetails" style="text-align:right">
            <button class="p-button-text" (click)="startBookingDetails('band',item.id)" label="Select Band" pButton pRipple
                    type="button"></button>
        </div>
    </ng-template>
</p-card>


<p-dialog [(visible)]="memberDialog" [style]="{width: '450px'}" header="New Member Invite" [modal]="true"
          styleClass="p-fluid">
    <ng-template pTemplate="content">
        <img
                [src]="item.hasOwnProperty('avatar') && item.avatar ? item.avatar['link'] : 'assets/images/bandbeat/sample_band_logo.png'"
                style="width:100%;height:auto;" alt="new member"
             class="product-image">
        <div class="field">
            <label for="email">Email</label>
            <input inputTrim type="text" pInputText id="email" [(ngModel)]="memberEmail" required autofocus/>
            <small class="p-invalid" *ngIf="memberSubmitted && !memberEmail">Email is required.</small>
        </div>
    </ng-template>

    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
                (click)="hideInviteMemberModal()"></button>
        <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="inviteMember()"></button>
    </ng-template>
</p-dialog>

<p-dialog [(visible)]="editNameDialog" [style]="{width: '450px'}" header="Edit {{item.name}} name" [modal]="true"
          styleClass="p-fluid">
    <ng-template pTemplate="content">
        <img [src]="item.hasOwnProperty('avatar') && item.avatar ? item.avatar['link'] : 'assets/images/bandbeat/sample_band_logo.png'" style="width:100%;height:auto;" alt="edit name"
             class="product-image">
        <div class="field">
            <label for="name">Name</label>
            <input inputTrim type="text" pInputText id="name" [(ngModel)]="bandName" required autofocus/>
            <small class="p-invalid" *ngIf="editNameSubmitted && !bandName">Email is required.</small>
        </div>
    </ng-template>

    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
                (click)="hideEditNameModal()"></button>
        <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveBand()"></button>
    </ng-template>
</p-dialog>
