import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-wizard-step',
    templateUrl: './wizard-step.component.html',
    styleUrls: ['./wizard-step.component.scss']
})
export class WizardStepComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
