import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HelperService} from '../../../services/helper.service';
import {environment} from '../../../../environments/environment';
import {filter, map, skip} from 'rxjs/operators';
import {StudiosService} from '../../../services/studios.service';
import {combineLatest, of} from 'rxjs';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-booking-room',
  templateUrl: './booking-room.component.html',
  styleUrls: ['./booking-room.component.scss']
})
export class BookingRoomComponent implements OnInit {

    public selectedRoom: any;
    public selectedInstruments: any[] = [];
    public viewMoreRoom = this.helperService.isMobile();

    public studio: any;
    public env = environment;

    private studioId;
    private roomId;
    public service;
    
    public moreEquipment = false;
    public moreInstruments = false;

    public isMobile = false;

    public offersPerRoom;

  constructor(
      private route: ActivatedRoute,
      private router: Router ,
      private messageService: MessageService,
      private helperService: HelperService,
      private studioService: StudiosService,
  ) {
      this.isMobile = this.helperService.isMobile();
  }
  ngOnInit(): void {
      this.studioId = this.route.snapshot.queryParamMap.get('studio');
      this.roomId = this.route.snapshot.queryParamMap.get('room');
      this.service = this.route.snapshot.queryParamMap.get('service');
      this.selectedInstruments = this.route.snapshot.queryParamMap.getAll('instruments');
      const duration = this.route.snapshot.queryParamMap.get('duration');
      const date = this.route.snapshot.queryParamMap.get('date');
      const time = this.route.snapshot.queryParamMap.get('time');
      const bookingDate = new Date( date );
      bookingDate.setHours(Number(time.substring(0, time.indexOf(':'))));
      bookingDate.setMinutes(Number(time.substring(time.indexOf(':') + 1)));

      this.studio = combineLatest(
          this.studioService.studio$.pipe(filter(std => Object.keys(std).length !== 0)),
          this.studioService.availableRooms$,
          )
          .pipe(
          map((data: any) => {
              const studioData = data[0];
              const studioDataAvailableRoomsIds = data[1].map((room) => room.id);
              const availableRooms = [];
              studioData.rooms.forEach((room) => {
                  const filteredServices = room.services.filter((service) => service.type === this.service);
                  if (filteredServices.length > 0 && studioDataAvailableRoomsIds.includes(room.id)) {
                      availableRooms.push(Object.assign({availableServices : filteredServices}, room));
                  }
              });
              studioData.cover = studioData.media.find((img) => img.metadata.type === 'cover');
              studioData.logo = studioData.media.find((img) => img.metadata.type === 'logo');
              studioData.images = studioData.media.filter((img) => img.metadata.type !== 'logo');
              studioData.availableRooms = availableRooms;

              this.offersPerRoom = this.studioHasOffers(data[1])

              if (studioData.availableRooms.length === 0 ) {
                  this.messageService.add(
                      {
                          key: 'globalToast',
                          severity: 'error',
                          summary: 'There is no available room for this timeslot',
                          detail: 'Please select another!'
                      });
                  this.previous();
              } else {

                  if (studioData.availableRooms.length > 0) {
                      this.selectedRoom = studioData.availableRooms[0];
                  }
                  if (this.roomId) {
                      this.selectedRoom = studioData.availableRooms.find(room => room.id === this.roomId);
                      if ( !this.selectedRoom ) {
                          this.selectedRoom = studioData.availableRooms[0];
                      }
                  }
                  return studioData;
              }
          })
      );
  }

    selectRoom(event) {
        this.selectedRoom = event;
    }



  nextPage() {
      this.router.navigate(
          ['summary' ],
          {
              queryParamsHandling: 'merge',
              relativeTo: this.route.parent,
              queryParams: {
                  room: this.selectedRoom.id, step: 3,
                  instruments: this.selectedInstruments,
              }
          });
  }

  previous() {
    this.router.navigate(
        ['settings' ],
        {
          queryParamsHandling: 'merge',
          relativeTo: this.route.parent,
          queryParams: { step: 1}
        });
  }

    confirmBooking() {
        this.router.navigate(
            ['summary' ],
            {
                queryParamsHandling: 'merge',
                relativeTo: this.route.parent,
                queryParams: {room: this.selectedRoom.code, step: 4}
            });
    }


    addInstrument(instrument) {
        this.selectedInstruments.push(instrument.id);
    }

    isInstrumentSelectedForRent(instrument) {
        return this.selectedInstruments.includes(instrument.id);
    }

    removeInstrument(instrument) {
        const index: number = this.selectedInstruments.indexOf(instrument.id);
        if (index !== -1) {
            this.selectedInstruments.splice(index, 1);
        }
    }

    studioHasOffers(rooms){
        return rooms?.reduce((acc, room) =>{
            if(!acc[room.id]) acc[room.id] = {hasOffers: false};
            room.services.forEach(service => {
                service.discounts.forEach(discount => {
                    if(!acc[room.id][discount.type])acc[room.id][discount.type] = [];
                    acc[room.id][discount.type].push({rules: discount.rules, name: discount.name, hourlyRate: service.price})
                    acc[room.id].hasOffers = true;
                })
            })

            return acc;
        }, {})
    }

}
