<div class="grid grid grid-nogutter justify-content-center" style="padding: 2rem" >
    <div class="col-12 md:col-4" style="margin-top:40px; text-align: center;">
        <div class="booking-sent">
            <span class="tick-icon"></span>
            <p class="tn-title pt-4">
                <span class="tn-text-green ">Booking</span> request has been sent!
            </p>
        </div>
        <div class="pt-4">
            <span class="tn-text-simple" *ngIf="withInvites === 'true'">Great! Your booking request has been sent! We will let you know when your bandmates respond!</span>
            <span class="tn-text-simple" *ngIf="withInvites === 'false'">Great! Your booking request has been sent! We will let you know when the {{studioName}} responds!</span>
        </div>

        <div class="pt-8">
            <button
                    class="tn-primary-button"
                    pButton
                    type="button"
                    label="GO TO THE HOMEPAGE"
                    (click)="goToHomepage()"
            ></button>
        </div>
    </div>

</div>
