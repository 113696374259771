<div class="grid grid grid-nogutter justify-content-center" style="padding: 2rem">
    <div class="col-4" style="margin-top:40px; text-align: center;">
        <div>
            <img
                    [src]="'/assets/images/static-wave.png'"
                    alt="staticWave"
                    style="width: 50%;vertical-align:middle;"
            />
            <h1>
                Oops! Seems like we missed a beat
            </h1>
        </div>
        <div>
            <span>Sorry, the page you are looking for doesn't exist. Try checking the URL or going back to our homepage</span>
        </div>

        <div style="margin-top: 40px;">
            <button
                    pButton
                    type="button"
                    label="GO TO THE HOMEPAGE"
                    [routerLink]="['/listing']"
            ></button>
        </div>
    </div>
</div>