import { Pipe, PipeTransform } from '@angular/core';
import {environment} from '../../../environments/environment';

@Pipe({
  name: 'filterStudioCountry'
})
export class FilterStudioCountryPipe implements PipeTransform {

  public env = environment;

  transform(studios: any[], selectedCountry: string = 'All'): any[] {
    if (selectedCountry === 'All' || this.env.multipleCountriesEnabled === false) {
      return studios;
    }
    return studios.filter((studio) => {
      return studio.address && studio.address.country && studio.address.country === selectedCountry
    });
  }

}
