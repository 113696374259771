<div class="layout-wrapper layout-slim">
    <div class="layout-topbar">
        <div class="layout-topbar-left">
            <a class="app-name" href="/">
                <img alt="main logo" [src]="'assets/images/bandbeat/bb_logo_header.png'" class="app-logo">
                <span>Bandbeat</span>
            </a>
        </div>
    </div>

    <div class="layout-content-wrapper layout-simple-page"
    >
        <div class="layout-content" >
            <div class="landing-body" style="background:transparent !important; height: 100%">
                <div class="landing-wrapper">
                    <div class="header-content grid " style="margin-right: unset;color: #FFFFFF;">
                        <div class="col-12 md:col-6 text-center md:text-left flex align-items-center md:justify-content-start justify-content-center ">
                            <section style="width:80%" class="layout-section-mobile">
                                <p class="tn-title-big" style="font-weight:400;">Welcome to</p>
                                <p class="tn-title-big keyword">Bandbeat</p>
<!--                                <div class="col-12 md:col-4 md:col-offset-1 overflow-hidden wave-mobile">-->
<!--                                    <app-wave-animation></app-wave-animation>-->
<!--                                </div>-->
                                <p class="tn-text-small mb-4" >Please sign in to continue</p>

                                <div class="p-fluid grid formgrid">
                                    <div class="field col-12 ">
                                        <span class="tn-text-simple tn-text-green ">EMAIL</span>

                                        <input inputTrim
                                               id="email"
                                               type="text"
                                               pInputText
                                               autocomplete="username"
                                               [(ngModel)]="userEmail"
                                               (keydown.enter)="login()"
                                        />
                                    </div>
                                    <div class="field col-12 ">
                                        <span class="tn-text-simple tn-text-green">PASSWORD</span>
                                        <p-password
                                                id="password"
                                                inputId="password"
                                                class="mask-icon"
                                                [(ngModel)]="userPassword"
                                                (keydown.enter)="login()"
                                                [feedback]="false"
                                                [toggleMask]="true"
                                        ></p-password>
                                    </div>
                                    <div class="col-12 md:col-6 pt-4">
                                        <button
                                                pButton
                                                icon="pi pi-check"
                                                pRipple
                                                label="LOGIN"
                                                type="button"
                                                style="width:auto;"
                                                class=" tn-secondary-button login-btn  "
                                                (click)="login()"
                                        ></button>
                                    </div>

                                    <div class=" col-12 md:col-6 pt-4">

                                        <button
                                                pButton
                                                type="button"
                                                [label]="'FORGOT PASSWORD'"
                                                class="p-button-text "
                                                [routerLink]="['/forgotpassword']"
                                        ></button>
                                    </div>



                                    <div class="col-12 pt-4">
                                        <p class="tn-text-small tn-text-green mb-4 login-txt" style="font-weight: 500;" >Or Login with</p>
                                    </div>

                                    <div #googleButton class="col-12 xl:col-6 pt-4 login-txt" *ngIf="env.socialLoginEnabled">
                                        <asl-google-signin-button
                                                text="continue_with"
                                                type='standard'
                                                size='large'
                                                shape="pill"
                                                locale="en_US"
                                                class="google-button"
                                                width="{{getButtonSize(googleButton)}}"
                                        ></asl-google-signin-button>
                                    </div>

                                    <div #facebookButton class="col-12 xl:col-6 pt-4 login-txt" *ngIf="env.socialLoginEnabled">

                                        <button
                                                pButton
                                                type="button"
                                                icon="pi pi-facebook"
                                                class="p-button-raised p-button-rounded facebook-button  "
                                                label="Continue with Facebook"
                                                style="width: {{getButtonSize(facebookButton)}}"
                                                (click)="signInWithFB()"
                                        ></button>
                                    </div>

                                    <div class="col-12 pt-4">
                                        <span class="tn-text-small">Don’t have an account yet? <span class="tn-text-normal tn-text-green cursor-pointer" [routerLink]="['/signup']">Sign up here!</span></span>
                                    </div>

                                </div>



                                <div class="mt-4">
                                </div>

<!--                                <div class="mt-4">-->

<!--                                    <button-->
<!--                                            pButton-->
<!--                                            class="btn btn-social-icon btn-facebook"-->
<!--                                            (click)="signInWithFB()"-->
<!--                                    ><span class="fa fa-facebook"></span></button>-->
<!--                                </div>-->

                            </section>
                        </div>
<!--                        <div class="col-12 md:col-4 md:col-offset-1 overflow-hidden wave">-->
<!--                            <app-wave-animation></app-wave-animation>-->
<!--                        </div>-->
                    </div>
                </div>
            </div>
        </div>
        <p-toast
                [style]="{'overflow-wrap' : 'break-word'}"
                [baseZIndex]="5000"
                life="4000"
        ></p-toast>
        <app-footer></app-footer>
    </div>
</div>
