<div class="grid bandbeatPage">
    <div class="col-12 tn-title">
        <h1>
            Change Password
        </h1>
    </div>
    <div class="col-12">
        <div class="grid">
            <div class="col-12 md:col-4">

                <div class="p-fluid formgrid grid">
                    <div class="field col-12">
            <span class="p-float-label mb-3">
            <span class="tn-text-simple tn-text-green">CURRENT PASSWORD</span>
                <p-password
                        id="currentPassword"
                        inputId="currentPassword"
                        [(ngModel)]="currentPassword"
                        [toggleMask]="true"
                        class="w-full mask-icon"
                ></p-password>
            </span>
                    </div>

                    <div class="field col-12">
            <span class="p-float-label mb-3">
              <span class="tn-text-simple tn-text-green">NEW PASSWORD</span>
                <p-password
                        [(ngModel)]="newPassword"
                        id="newPassword"
                        inputId="newPassword"
                        [toggleMask]="true"
                        class="w-full mask-icon"
                ></p-password>
            </span>
                    </div>

                    <div class="field col-12">
            <span class="p-float-label mb-3">
              <span class="tn-text-simple tn-text-green">RETYPE NEW PASSWORD</span>
                <p-password
                        [(ngModel)]="newPasswordRetry"
                        id="newPasswordRetry"
                        inputId="newPasswordRetry"
                        [toggleMask]="true"
                        class="w-full mask-icon"
                ></p-password>

            </span>
                    </div>

                </div>
            </div>


        </div>
    </div>

    <div class="col-12">
        <div class="grid">

            <div class="col-12 md:col-4" style="text-align:right">
                <button pButton pRipple label="CANCEL" icon="pi pi-times" class="p-button-text tn-danger-button md:inline-flex" (click)="goBack()" ></button>
                <button pButton pRipple label="SAVE" icon="pi pi-check" class="p-button-text tn-secondary-button md:inline-flex ml-3" (click)="changePassword()"></button>
            </div>

        </div>
    </div>

</div>

