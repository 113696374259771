<p-card
        class="bands-card"
        header="My Bands">

  <ng-container
    *ngIf="{
      bandsData : bands | async,
      invitesData : invites | async
    } as data"

  >
    <p-dataView #dvbands
                styleClass="bands-dv"
                [value]="data | filterBandsInvites"
                [rows]="9"
                filterBy="team.name"
                [loading]="loading"
                layout="grid">
      <ng-template let-item pTemplate="gridItem">
        <div class="col-12 md:col-6 bandcard" >
          <div class="product-grid-item">
            <ng-template
                    [ngIf]="item.dataType === 'band'">

              <app-tn-band-simple
                      [band]=item
                      [darkMode]="true"
                      [userId]="userId"
                      [showMenu]="allowEdit"
                      (bandSelectEmitter)="seeBand(item)"
              >
              </app-tn-band-simple>


            </ng-template>

            <ng-template
                    [ngIf]="item.dataType === 'invite'"
            >

              <app-tn-band-simple
                      [band]=item.team
                      [invite] = item
                      [darkMode]="true"
                      [userId]="userId"
                      [showMenu]="allowEdit"
                      (bandSelectEmitter)="seeBand(item)"
              >
              </app-tn-band-simple>

            </ng-template>
          </div>
        </div>
      </ng-template>
    </p-dataView>

    <div class="flex align-items-center justify-content-between add-band" *ngIf="allowEdit">
      <button
              pButton
              label="ADD BAND"
              type="button"
              icon="pi pi-plus"
              iconPos="left"
              class="p-button-rounded tn-primary-button"
              (click)="createNewBand()"
      ></button>

      <div
              *ngIf="data.invitesData && data.invitesData.length > 0"
      >

        <p class="pending-invites tn-text-xs">
          !You have <span style="font-weight:700;">{{data.invitesData.length}}</span> Pending Band Invitations
        </p>
        <a
                (click)="viewInvites = !viewInvites"
                class="tn-text-green tn-text-small cursor-pointer"
                style="
                    font-size: 0.75rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1.1875rem;
                    letter-spacing: 0.015rem;
                    float:right;
              "
        >
          <span *ngIf="!viewInvites">VIEW ALL</span>
          <span *ngIf="viewInvites">VIEW LESS</span>
          <i style="padding-left: 1rem;"
             [ngClass]="{'pi-chevron-down' : !viewInvites , 'pi-chevron-up' : viewInvites}"
             class="pi"></i>
        </a>


      </div>
    </div>


    <p-dataView #dvinvites
                *ngIf="viewInvites"
                styleClass="bands-dv pt-4"
                [value]="data | filterBandsInvites:viewInvites"
                [rows]="9"
                filterBy="team.name"
                [loading]="loading"
                layout="list">
      <ng-template let-item pTemplate="listItem">
        <div class="col-12 bandcard"
             style=" border-width: 1px 0 0 0 ;" >
          <div class="product-grid-item" style="padding: 0;">

            <ng-template
                    [ngIf]="item.dataType === 'invite'"
            >
              <app-tn-band-invite
                      [band]=item.team
                      [invite] = item
                      [userId]="userId"
                      [showMenu]="allowEdit"
                      (bandSelectEmitter)="seeBand(item)"
              >
              </app-tn-band-invite>
            </ng-template>
          </div>
        </div>
      </ng-template>
    </p-dataView>


  </ng-container>


</p-card>




<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
