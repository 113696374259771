<p-dialog
        [(visible)]="display"
        [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
        [style]="{width: '25vw'}"
        [draggable]="false"
        [resizable]="false"
        [modal]="true"
        [blockScroll]="true"
        [dismissableMask]="false"
        [closable]="true"
        (onHide)="hideModal($event)"
>
    <div class="grid">
        <div class="row">
            <div class="col-12 pb-8 pl-8 pr-8 text-center md:text-left flex align-items-center ">
                <section style="width:100%">
                    <h1 class="welcomeMessage" >Share on <span class="keyword">Social</span></h1>

<!--                    <div class="grid">-->
<!--                        <div class="row">-->
<!--                            <div class="col-6">-->
<!--                                <button-->
<!--                                        label="Facebook"-->
<!--                                        shareButton="facebook"-->
<!--                                        icon="pi pi-facebook"-->
<!--                                        pButton-->
<!--                                        type="button"-->
<!--                                        class="p-button-raised p-button-rounded social-buttons"-->
<!--                                ></button>-->
<!--                            </div>-->
<!--                            <div class="col-6">-->
<!--                                <button-->
<!--                                        label="Twitter"-->
<!--                                        shareButton="twitter"-->
<!--                                        icon="pi pi-twitter"-->
<!--                                        pButton-->
<!--                                        type="button"-->
<!--                                        class="p-button-raised p-button-rounded social-buttons"-->
<!--                                ></button>-->
<!--                            </div>-->
<!--                            <div class="col-12">-->
<!--                                <button-->
<!--                                        label="Copy Link"-->
<!--                                        icon="pi pi-copy"-->
<!--                                        shareButton="copy"-->
<!--                                        pButton-->
<!--                                        type="button"-->
<!--                                        class="p-button-raised p-button-rounded social-buttons"-->
<!--                                ></button>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->

                    <ul class="shareActions float">
                        <li class="m-2" style="float:left;text-align: center;">
                            <button
                                    shareButton="facebook"
                                    icon="pi pi-facebook"
                                    pButton
                                    type="button"
                                    class="p-button-raised p-button-rounded social-buttons"
                            ></button>
                            <span style="display: block">
                                Facebook
                            </span>
                        </li>
                        <li class="m-2" style="float:right;text-align: center;">
                            <button
                                    shareButton="twitter"
                                    icon="pi pi-twitter"
                                    pButton
                                    type="button"
                                    class="p-button-raised p-button-rounded social-buttons"
                            ></button>
                            <span style="display: block">
                                Twitter
                            </span>
                        </li>
                        <li class="m-2" style="float: left;padding-top: 1em;width: 100%;margin: 0px !important;">

                            <h5
                                style="
                                    padding-left: 0.5em;
                                    margin:0px;
                                    float: left;
                                "
                            >Page Link</h5>
                            <span
                                class="keyword"
                                style="
                                    padding-left: 0.5em;
                                    font-size: smaller;
                                "
                                *ngIf="linkCopied">link copied</span>
                            <div
                                style="padding:0.5em;"
                                class="p-inputgroup">
                                <input
                                        type="text"
                                        pInputText
                                        [ngModel]="shareLink"
                                        disabled="true"
                                         />
                                <button
                                        type="button"
                                        pButton
                                        icon="pi pi-copy"
                                        shareButton="copy"
                                        styleClass="social-buttons"
                                        (click)="linkCopied = true"
                                ></button>
                            </div>

<!--                            <button-->
<!--                                    label="Copy Link"-->
<!--                                    icon="pi pi-copy"-->
<!--                                    shareButton="copy"-->
<!--                                    pButton-->
<!--                                    type="button"-->
<!--                                    class="p-button-raised p-button-rounded social-buttons"-->
<!--                            ></button>-->
                        </li>
                    </ul>

                </section>
            </div>

        </div>

    </div>
</p-dialog>
