<div class="grid grid-nogutter pt-6" *ngIf="studio | async as studioData">

    <div class="col-12">
        <div class="grid grid-nogutter pt-5">
<!--            <div class="field col-12 lg:col-2">-->
<!--                    <span class="p-float-label mb-3">-->
<!--                    <p-dropdown-->
<!--                            id="searchService"-->
<!--                            [options]="availableServices"-->
<!--                            [(ngModel)]="selectedService"-->
<!--                            optionLabel="name"-->
<!--                            optionValue="code"-->
<!--                            (onChange)="checkAvailability()"-->
<!--                    ></p-dropdown>-->
<!--                        <label for="searchService">Service</label>-->
<!--                    </span>-->
<!--            </div>-->
            <div class="field col-12 lg:col-2">
                <p class="tn-text-big tn-text-green">Date</p>
                <p-calendar inputId="bookDate" dateFormat="dd-mm-yy" [(ngModel)]="bookDate"
                            [minDate]="currentDate"
                            id="bookDate"
                            [maxDate]="maxDate"
                            [readonlyInput]="true"
                            [disabledDays]="disabledDays"
                            [selectOtherMonths]="true"
                            appendTo="body"
                            firstDayOfWeek="1"
                            [style]="{'width':'95%'}" [inputStyle]="{'width':'95%'}"
                            (onSelect)="buildAvailabilityOptions();checkAvailability()"
                >
                </p-calendar>
            </div>

            <div class="field col-12 lg:col-2">
                <p class="tn-text-big tn-text-green">Start Time</p>
                <p-dropdown
                        id="bookTime"
                        [options]="availabilityOptions"
                        [(ngModel)]="bookTime"
                        [autoDisplayFirst]="false"
                        (onChange)="buildAvailableDurations();checkAvailability()"
                ></p-dropdown>
            </div>
            <div class="field col-12 lg:col-2">
                <p class="tn-text-big tn-text-green">Duration</p>
                <p-dropdown
                        id="bookDuration"
                        [options]="availableDurations"
                        [(ngModel)]="bookDuration"
                        [autoDisplayFirst]="false"
                        optionLabel="label"
                        optionValue="value"
                        (onChange)="checkAvailability()"
                ></p-dropdown>
            </div>

            <div class="field col-12 lg:col-2" >

                <p class="tn-text-big tn-text-green">Recurring Booking</p>

                <p-toggleButton 
                    onLabel="ON" 
                    offLabel="OFF" 
                    offIcon="pi pi-circle-fill" 
                    onIcon="pi pi-circle-fill" 
                    class="{{recurringBooking?'switch-label-on':'switch-label-off'}}"
                    iconPos="{{recurringBooking?'right':'left'}}" 
                    [(ngModel)]="recurringBooking" 
                    (onChange)="startRecurring();checkAvailability()" />

            </div>

            <ng-template
                    [ngIf]="recurringBooking"
            >

                <div class="col-12"></div>


                <div class="field col-12 lg:col-2 ">


                    <p class="tn-text-big tn-text-green">End Date</p>

                    <p-calendar inputId="endBookDate" dateFormat="dd-mm-yy" [(ngModel)]="recurringEndDate"
                                [minDate]="bookDate"
                                [maxDate]="maxDate"
                                id="endBookDate"
                                [readonlyInput]="true"
                                [disabledDays]="disabledDays"
                                [selectOtherMonths]="true"
                                appendTo="body"
                                firstDayOfWeek="1"
                                [style]="{'width':'95%'}" [inputStyle]="{'width':'95%'}"
                                (onSelect)="buildAvailabilityOptions();checkAvailability()"
                    >
                    </p-calendar>
                </div>

                <div class="field col-12 lg:col-2 occurence-selection align-self-end" >
                            <button
                                    pButton
                                    label="WEEKLY"
                                    class="p-ripple p-button p-component {{ checkSelectedOccurence(0) ? 'tn-primary-button' : ''}}"
                                    (click)="selectOccurence(0);checkAvailability()"
                            ></button>

                            <button
                                    pButton
                                    class="md:ml-4 p-ripple p-button p-component {{ checkSelectedOccurence(1) ? 'tn-primary-button' : ''}}"
                                    label="BIWEEKLY"
                                    (click)="selectOccurence(1);checkAvailability()"
                            ></button>
                </div>

                <div class="field col-12 lg:col-6 align-self-end">
                    <p-selectButton
                            styleClass="weekDaysClass"
                            [options]="weekDays"
                            [(ngModel)]="recurringDays"
                            optionLabel="label"
                            multiple="multiple"
                            (onChange)="checkAvailability()"
                    >
                        <!--                        <ng-template let-weekDay>-->
                        <!--                            <span style="margin: 0 8px 0 7.5px;">{{weekDay}}</span>-->
                        <!--                        </ng-template>-->
                    </p-selectButton>
                </div>


            </ng-template>



            <div
                    class=" col-12 "
                    *ngIf="!recurringBooking &&  availabilityLoading === false && availabilityFound && availabilityFound.length === 0"
            >
                <p class="not-available-slot" style="text-align:left;">
                    ! Date not Available
                </p>
<!--                <i-->
<!--                        class="pi"-->
<!--                        [ngClass]="{ 'pi-spin pi-spinner loading-state': availabilityLoading === true , 'pi-check-circle success-state': availabilityLoading === false && availabilityFound && availabilityFound.length > 0 , 'pi-times-circle error-state': availabilityLoading === false && availabilityFound && availabilityFound.length === 0 }"-->
<!--                        style="font-size: 2rem"-->
<!--                        [pTooltip]="availabilityMessage"-->
<!--                ></i>-->
            </div>


            <div
                    *ngIf="recurringBooking"
                    class="field col-12 lg:col-4" >

                <p-dataView #dvListingEq
                            [value]="selectedDays"
                            layout="grid">
                    <ng-template let-availableDay pTemplate="gridItem">
                        <div class="col-12 ">
                            <div style="padding: 1rem;">

                                <p-card
                                        [style]="{ borderRadius : 12, width : '100%'}" styleClass="p-card-shadow availability-day-card">
                                    <div class="grid grid-nogutter align-items-center">

                                        <div class="col-6"><h5 style="margin: auto">{{availableDay.selectedDay | dateParser:'dd-MM-yyyy'}}</h5>
                                        </div>
                                        <div class="col-6" style="color:#FFFFFF; text-align: center;">
                                            <span class="tn-text-small"
                                                [ngClass]="{'available-slot':availableDay.availability === 'Available', 'not-available-slot' : availableDay.availability !== 'Available'}"
                                            >{{availableDay.availability | titleCaseExtendedPipe}}</span></div>
                                    </div>
                                </p-card>


                            </div>
                        </div>
                    </ng-template>
                </p-dataView>



            </div>

        </div>
    </div>



</div>




<div class="grid grid-nogutter next-buttons">
    <p-button
            label="Back"
            styleClass="p-button-primary tn-primary-button"
            (onClick)="previous()"
            icon="till-noon-icon tnicon-Arrow-Left"
            iconPos="left"
    ></p-button>
    <p-button
            [disabled]="checkNextStep()"
            label="Next"
            (onClick)="nextPage()"
            styleClass="tn-secondary-button"
            icon="till-noon-icon tnicon-Arrow-Right"
            iconPos="right"
    ></p-button>
</div>
