<div class="grid grid grid-nogutter justify-content-center" style="padding: 2rem" >
    <div class="col-12 md:col-6" style="margin-top:40px; text-align: center;">
        <div class="booking-sent">
            <span *ngIf="paymentResponse"
                  class="tick-icon"
                    ></span>
            <p class="tn-title pt-4" *ngIf="paymentResponse === true">
                Your <span class="tn-text-green ">payment</span> was successful!
            </p>
            <p class="tn-title pt-4" *ngIf="paymentResponse === false">
                Uh-oh! There was an issue processing your payment
            </p>
        </div>
        <div class="pt-4">
            <span class="tn-text-simple" *ngIf="paymentResponse === true">Soon you will recieve an email from us as well.</span>
            <span class="tn-text-simple" *ngIf="paymentResponse === false">But don't you worry! Your <span class="tn-text-green ">booking request has been sent to the studio</span>. Please try again to complete this payment from the booking details page.
            </span>
        </div>

        <div class="pt-8">
            <button
                    *ngIf="paymentResponse === true"
                    class="tn-primary-button"
                    pButton
                    type="button"
                    label="GO TO THE HOMEPAGE"
                    (click)="goToNextPage()"
            ></button>
            <button
                    *ngIf="paymentResponse === false"
                    class="tn-primary-button"
                    pButton
                    type="button"
                    label="BOOKING DETAILS"
                    (click)="goToNextPage()"
            ></button>
        </div>
        <div class="pt-4" *ngIf="paymentResponse === false">
            <span class="tn-text-simple" >
                We have notified the studio of this unsuccessful payment. If your booking is not paid before the scheduled date, you will be asked to pay directly at the studio.
            </span>
        </div>
        <div class="pt-8" *ngIf="paymentResponse === false">
            <span class="tn-text-simple" >
                Was this a mistake? You can always <span class="tn-text-red cursor-pointer" (click)="goToNextPage('cancel')">cancel this booking</span>.
            </span>
        </div>
    </div>

</div>
