import {Component, Input, OnInit} from '@angular/core';
import {ConfirmationService, MessageService} from 'primeng/api';
import {UserService} from '../../services/user.service';
import {HelperService} from '../../services/helper.service';

@Component({
  selector: 'app-user-instruments',
  templateUrl: './user-instruments.component.html',
  styleUrls: ['./user-instruments.component.scss'],
  providers: [ConfirmationService]
})
export class UserInstrumentsComponent implements OnInit {

  @Input() instruments: any[] = [];
  @Input() userId: string;


  public instrumentDialog = false;
  public instrument: any;
  public instrumentTypes: any[];

  public submitted = false;

  constructor(
    private messageService: MessageService,
    private userService: UserService,
    private helperService: HelperService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {

    this.instrument = {};
    this.helperService.fetchInstrumentTypes()
      .then((data) => {
        this.instrumentTypes = data.results.map((instrument) => {
          return { name : instrument.name , code : instrument.id };
        });
      });
  }


  saveInstrument()  {
    this.submitted = true;

    if (this.instrument.name && this.instrument.name.trim()) {
      if (this.instrument.id) {

        const instrumentId = this.instrument.id;
        delete this.instrument.instrument;
        delete this.instrument.id;
        delete this.instrument.instrumentId;
        this.userService.editInstrument( this.userId , instrumentId , this.instrument)
          .then((result) => {
            this.messageService.add({severity: 'success', summary: 'Successful', detail: 'User Created', life: 3000});
            this.instrumentDialog = false;
            this.instrument = {};
          });
      } else {

        this.userService.addInstrument( this.userId , this.instrument)
          .then((result) => {
            this.messageService.add({severity: 'success', summary: 'Successful', detail: 'User Created', life: 3000});
            this.instrumentDialog = false;
            this.instrument = {};
          });
      }

    }

  }

  removeInstrument( instrument: any ) {

    this.confirmationService.confirm({
      message: 'Are you sure you want to remove this instrument?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.userService.removeInstrument( this.userId , instrument.id)
          .then((result) => {
            this.messageService.add({severity: 'success', summary: 'Successful', detail: 'User Created', life: 3000});
            this.instrumentDialog = false;
            this.instrument = {};
          });
      }
    });

  }

  editInstrument( instrument: any ) {
    Object.assign(this.instrument , instrument);
    this.instrument.instrumentId = this.instrument.instrument.id;
    this.instrumentDialog = true;

  }

  hideDialog() {
    this.instrument = {};
    this.instrumentDialog = false;
    this.submitted = false;
  }

  refresh() {
    this.userService.fetchUser(this.userId);
  }

}
