import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterStudioName'
})
export class FilterStudioNamePipe implements PipeTransform {

  transform(studios: any[], searchText: string = ''): any[] {
    if (searchText.length > 0) {
      const regex = new RegExp( searchText, 'gi');
      return studios.filter((studio) => regex.test(studio.name) );
    }
    return studios;
  }

}
