import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HelperService} from './helper.service';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StudiosService {

  private studios: BehaviorSubject<any>;
  public readonly studios$: Observable<any>;

  private studio: BehaviorSubject<any>;
  public readonly studio$: Observable<any>;

  private services: BehaviorSubject<any>;
  public readonly services$: Observable<any>;

  private equipment: BehaviorSubject<any>;
  public readonly equipment$: Observable<any>;

  private rooms: BehaviorSubject<any>;
  public readonly rooms$: Observable<any>;

  private bookings: BehaviorSubject<any>;
  public readonly bookings$: Observable<any>;

  private rentalEquipment: BehaviorSubject<any>;
  public readonly rentalEquipment$: Observable<any>;

  private availableRooms: BehaviorSubject<any>;
  public readonly availableRooms$: Observable<any>;

  private reviews: BehaviorSubject<any>;
  public readonly reviews$: Observable<any>;

  private searchedStudios: BehaviorSubject<any>;
  public readonly searchedStudios$: Observable<any>;

  private errors: BehaviorSubject<any>;
  public readonly errors$: Observable<any>;

  private dataStore: {
    studios: any,
    studio: any,
    services: any,
    equipment: any,
    rooms: any,
    rentalEquipment: any,
    bookings: any,
    availableRooms: any,
    reviews: any;
    searchedStudios: any;
  };

    public env = environment;

  constructor( private helperService: HelperService) {

    this.studios = new BehaviorSubject({}) as BehaviorSubject<any>;
    this.studios$ = this.studios.asObservable();

    this.studio = new BehaviorSubject({}) as BehaviorSubject<any>;
    this.studio$ = this.studio.asObservable();

    this.rooms = new BehaviorSubject([]) as BehaviorSubject<any>;
    this.rooms$ = this.rooms.asObservable();

    this.services = new BehaviorSubject([]) as BehaviorSubject<any>;
    this.services$ = this.services.asObservable();

    this.equipment = new BehaviorSubject([]) as BehaviorSubject<any>;
    this.equipment$ = this.equipment.asObservable();

    this.rentalEquipment = new BehaviorSubject([]) as BehaviorSubject<any>;
    this.rentalEquipment$ = this.rentalEquipment.asObservable();

    this.bookings = new BehaviorSubject({}) as BehaviorSubject<any>;
    this.bookings$ = this.bookings.asObservable();

    this.availableRooms = new BehaviorSubject([]) as BehaviorSubject<any>;
    this.availableRooms$ = this.availableRooms.asObservable();

    this.reviews = new BehaviorSubject([]) as BehaviorSubject<any>;
    this.reviews$ = this.reviews.asObservable();

    this.searchedStudios = new BehaviorSubject([]) as BehaviorSubject<any>;
    this.searchedStudios$ = this.searchedStudios.asObservable();

    this.errors = new BehaviorSubject({}) as BehaviorSubject<any>;
    this.errors$ = this.errors.asObservable();

    this.dataStore = {
      studios: {
        results : []
      },
      studio: {},
      services: [],
      equipment: [],
      rooms: [],
      rentalEquipment: [],
      bookings: [],
      availableRooms: [],
      reviews: [],
      searchedStudios: [],
    };
  }

  fetchStudios( status?: string) {
    const params: any = { limit : 1000 };
    if (this.env.studioScoreEnabled && this.env.studioScoreEnabled === true ) {
        params.sortBy = 'studioScore:desc';
    }
    if (status) {
      params.status = status;
    }
    return this.helperService.getAction('/studio', params  )
      .toPromise()
      .then((data) => {
        Object.assign(this.dataStore.studios, data);
        this.studios.next(data);
        return data;
      });
  }

  searchStudios(searchParameters: any) {
    const params = Object.entries(searchParameters)
    .reduce((acc, [key, val]) => {
      if (val){
        if(key === 'coordinates'){
          acc[`${key}X`] = (val as any)?.x
          acc[`${key}Y`] = (val as any)?.y
        }
        else  acc[key] = val;
      }
      return acc;   
    }, {})
      return this.helperService.getAction('/search/studio', params  )
          .toPromise()
          .then((data) => {
              Object.assign(this.dataStore.studios, data);
              this.studios.next(data);
              return data;
          });
  }

  searchStudiosByName(studioName: any) {
        return this.helperService.getAction('/search/studio/name', {name : studioName }  )
            .toPromise()
            .then((data) => {
                if (data.results.length > 0) {
                    data.results = data.results.map(studio => this.mapStudioData(studio));
                    this.dataStore.searchedStudios = [...data.results];
                    this.searchedStudios.next(this.dataStore.searchedStudios);
                } else {
                    this.searchedStudios.next([{ emptyResult : true }])
                }
                return this.dataStore.searchedStudios;
            });
  }

  fetchStudio( studioId: string ) {
    return this.helperService.getAction('/studio/' + studioId  )
      .toPromise()
      .then((data) => {
        Object.assign(this.dataStore.studio, data);
        this.dataStore.services = this.dataStore.studio.services;
        this.dataStore.rooms = this.dataStore.studio.rooms;
        this.studio.next(data);
        this.services.next(this.dataStore.services);
        this.rooms.next(this.dataStore.rooms);
        return data;
      });
  }

  fetchStudioAvailableRooms( studioId: string , service: string, dateTime: string , duration: string) {
      return this.helperService.getAction('/studio/' + studioId + '/available/rooms' , {service, dateTime, duration} )
          .toPromise()
          .then((data) => {
              this.dataStore.availableRooms = [...data];
              this.availableRooms.next(this.dataStore.availableRooms);
              return data;
          });
  }

  fetchStudioReviews( studioId: string, populate = false ) {
      return this.helperService.getAction('/studio/' + studioId + '/reviews', {populate} )
          .toPromise()
          .then((data) => {
              this.dataStore.reviews = [ ...data.results ];
              this.reviews.next(data);
              return data;
          });
  }

  fetchStudioBookings( studioId: string ) {
    return this.helperService.getAction('/studio/' + studioId + '/bookings' )
      .toPromise()
      .then((data) => {
        this.dataStore.bookings = [ ...data.results ];
        this.bookings.next(data);
        return data;
      });
  }

  fetchStudioRentalEquipment( studioId: string ) {
    return this.helperService.getAction('/studio/' + studioId + '/instruments' )
      .toPromise()
      .then((data) => {
        this.dataStore.rentalEquipment = [ ...data];
        this.rentalEquipment.next(data);
        return data;
      });
  }

  addStudio( newStudio: any ) {
    return this.helperService.postAction('/studio' , newStudio )
      .toPromise()
      .then((data) => {
        this.dataStore.studios.results = this.dataStore.studios.results.concat([data]);
        this.studios.next(this.dataStore.studios);
        return data;
      });
  }

  deleteStudio( studioId: string ) {
    return this.helperService.deleteAction('/studio/' + studioId )
      .toPromise()
      .then((data) => {
        this.dataStore.studios.results = this.dataStore.studios.results.filter(std => std.id !== studioId);
        this.studios.next(this.dataStore.studios);
        return data;
      });
  }

  editStudio( editedStudio: any) {
    const editBody: any = {};
    Object.assign(editBody, editedStudio);
    delete editBody.id;
    delete editBody.isEmailVerified;
    return this.helperService.patchAction('/studio/' + editedStudio.id , editBody )
      .toPromise()
      .then((data) => {
        const foundStudio = this.dataStore.studios.results.find(std => std.id === editedStudio.id);
        if (foundStudio) {
          Object.assign(foundStudio , data);
        }
        if (this.dataStore.studio && this.dataStore.studio.id === editedStudio.id) {
          Object.assign(this.dataStore.studio , data);
          this.studio.next(this.dataStore.studio);
        }
        this.studios.next(this.dataStore.studios);
        return data;
      });
  }

  addStudioImage( studioId: string , image: any , type: string) {
    const formData = new FormData();
    formData.append('studioImage', image);
    formData.append('type' , type);
    return this.helperService.postAction('/studio/' + studioId + '/media' , formData )
      .toPromise()
      .then((data) => {
        const foundStudio = this.dataStore.studios.results.find(std => std.id === studioId);
        if (foundStudio) {
          Object.assign(foundStudio , data);
        }
        if (this.dataStore.studio && this.dataStore.studio.id === studioId) {
          Object.assign(this.dataStore.studio , data);
          this.studio.next(this.dataStore.studio);
        }
        this.studios.next(this.dataStore.studios);
        return data;
      });
  }

  deleteStudioImage( studioId: string, mediaId: string) {
    return this.helperService.deleteAction('/studio/' + studioId + '/media/' + mediaId )
      .toPromise()
      .then((data) => {
        const foundStudio = this.dataStore.studios.results.find(std => std.id === studioId);
        if (foundStudio) {
          Object.assign(foundStudio , data);
        }
        if (this.dataStore.studio && this.dataStore.studio.id === studioId) {
          Object.assign(this.dataStore.studio , data);
          this.studio.next(this.dataStore.studio);
        }
        this.studios.next(this.dataStore.studios);
        return data;
      });
  }

  getRooms(studioId: string) {
    return this.helperService.getAction('/studio/' + studioId + '/rooms' )
      .toPromise()
      .then((data) => {
        this.dataStore.rooms = [...data];
        this.rooms.next(this.dataStore.rooms);
        return data;
      });
  }

  createRoom(studioId: string, newRoom: any) {
    return this.helperService.postAction('/studio/' + studioId + '/rooms' , newRoom )
      .toPromise()
      .then((data) => {
        this.dataStore.rooms = [...data.rooms];
        this.rooms.next(this.dataStore.rooms);
        return data;
      });
  }

  removeRoom(studioId: string, roomId: string) {
    return this.helperService.deleteAction('/studio/' + studioId + '/rooms/' + roomId )
      .toPromise()
      .then((data) => {
        this.dataStore.rooms = this.dataStore.rooms.filter(room => room.id !== roomId);
        this.rooms.next(this.dataStore.rooms);
        return data;
      });
  }

  editRoom(studioId: string, roomId: string, roomData: any) {
    return this.helperService.patchAction('/studio/' + studioId + '/rooms/' + roomId , roomData )
      .toPromise()
      .then((data) => {
        this.dataStore.rooms = [...data.services];
        this.rooms.next(this.dataStore.services);
        return data;
      });
  }

  addRoomImage( studioId: string , roomId: string, image: any , type: string) {
    const formData = new FormData();
    formData.append('roomImage', image);
    formData.append('type' , type);
    return this.helperService.postAction('/studio/' + studioId + '/rooms/' + roomId + '/media' , formData )
      .toPromise()
      .then((data) => {
        const foundStudio = this.dataStore.studios.results.find(std => std.id === studioId);
        if (foundStudio) {
          Object.assign(foundStudio , data);
        }
        if (this.dataStore.studio && this.dataStore.studio.id === studioId) {
          Object.assign(this.dataStore.studio , data);
          this.studio.next(this.dataStore.studio);
        }
        this.studios.next(this.dataStore.studios);
        return data;
      });
  }

  deleteRoomImage( studioId: string, roomId: string, mediaId: string) {
    return this.helperService.deleteAction('/studio/' + studioId + '/rooms/' + roomId + '/media/' + mediaId )
      .toPromise()
      .then((data) => {
        const foundStudio = this.dataStore.studios.results.find(std => std.id === studioId);
        if (foundStudio) {
          Object.assign(foundStudio , data);
        }
        if (this.dataStore.studio && this.dataStore.studio.id === studioId) {
          Object.assign(this.dataStore.studio , data);
          this.studio.next(this.dataStore.studio);
        }
        this.studios.next(this.dataStore.studios);
        return data;
      });
  }

  createInstrument(studioId: string, newEquipment: any) {
    return this.helperService.postAction('/studio/' + studioId + '/instruments' , newEquipment )
      .toPromise()
      .then((data) => {
        this.dataStore.rentalEquipment = [...data.instruments];
        this.rentalEquipment.next(this.dataStore.rentalEquipment);
        return data;
      });
  }

  removeInstrument(studioId: string, equipmentId: string) {
    return this.helperService.deleteAction('/studio/' + studioId + '/instruments/' + equipmentId )
      .toPromise()
      .then((data) => {
        this.dataStore.rentalEquipment = this.dataStore.rentalEquipment.filter(eq => eq.id !== equipmentId);
        this.rentalEquipment.next(this.dataStore.rentalEquipment);
        return data;
      });
  }

  editInstrument(studioId: string, equipmentId: string, equipmentData: any) {
    return this.helperService.patchAction('/studio/' + studioId + '/instruments/' + equipmentId , equipmentData )
      .toPromise()
      .then((data) => {
        this.dataStore.rentalEquipment = [...data.instruments];
        this.rentalEquipment.next(this.dataStore.rentalEquipment);
        return data;
      });
  }



  getServices(studioId: string) {
    return this.helperService.getAction('/studio/' + studioId + '/services' )
      .toPromise()
      .then((data) => {
        this.dataStore.services = [...data];
        this.services.next(this.dataStore.services);
        return data;
      });
  }

  createService(studioId: string, roomId: string,  newService: any) {
    return this.helperService.postAction('/studio/' + studioId + '/rooms/' + roomId + '/services' , newService )
      .toPromise()
      .then((data) => {
        this.dataStore.services = [...data.rooms.find((room) => room.id === roomId).services];
        if (this.dataStore.studio && this.dataStore.studio.id === studioId) {
          Object.assign(this.dataStore.studio , data);
          this.studio.next(this.dataStore.studio);
        }
        this.services.next(this.dataStore.services);
        return data;
      });
  }

  editService(studioId: string, roomId: string, serviceId: string, editedService: any) {
    return this.helperService.patchAction('/studio/' + studioId + '/rooms/' + roomId +  '/services/' + serviceId , editedService )
      .toPromise()
      .then((data) => {
        this.dataStore.services = [...data.rooms.find((room) => room.id === roomId).services];
        this.services.next(this.dataStore.services);
        return data;
      });
  }

  removeService(studioId: string, roomId: string, serviceId: string) {
    return this.helperService.deleteAction('/studio/' + studioId + '/rooms/' + roomId +  '/services/' + serviceId )
      .toPromise()
      .then((data) => {
        this.dataStore.services = this.dataStore.services.filter(service => service.id !== serviceId);
        if (this.dataStore.studio && this.dataStore.studio.id === studioId) {
          const foundRoom = this.dataStore.studio.rooms.find((room) => room.id === roomId);
          foundRoom.services = foundRoom.services.filter((service) => service.id !== serviceId);
          this.studio.next(this.dataStore.studio);
        }
        this.services.next(this.dataStore.services);
        return data;
      });
  }




  getAllEquipment(studioId: string, roomId: string) {
    return this.helperService.getAction('/studio/' + studioId + '/rooms/' + roomId + '/equipment' )
      .toPromise()
      .then((data) => {
        this.dataStore.equipment = [...data];
        this.equipment.next(this.dataStore.equipment);
        return data;
      });
  }

  createEquipment(studioId: string, roomId: string,  newEquipment: any) {
    return this.helperService.postAction('/studio/' + studioId + '/rooms/' + roomId + '/equipment' , newEquipment )
      .toPromise()
      .then((data) => {
        this.dataStore.equipment = [...data.rooms.find((room) => room.id === roomId).equipment];
        if (this.dataStore.studio && this.dataStore.studio.id === studioId) {
          Object.assign(this.dataStore.studio , data);
          this.studio.next(this.dataStore.studio);
        }
        this.equipment.next(this.dataStore.equipment);
        return data;
      });
  }

  removeEquipment(studioId: string, roomId: string, equipmentId: string) {
    return this.helperService.deleteAction('/studio/' + studioId + '/rooms/' + roomId +  '/equipment/' + equipmentId )
      .toPromise()
      .then((data) => {
        this.dataStore.equipment = this.dataStore.equipment.filter(equipment => equipment.id !== equipmentId);
        if (this.dataStore.studio && this.dataStore.studio.id === studioId) {
          const foundRoom = this.dataStore.studio.rooms.find((room) => room.id === roomId);
          foundRoom.equipment = foundRoom.equipment.filter((equipment) => equipment.id !== equipmentId);
          this.studio.next(this.dataStore.studio);
        }
        this.equipment.next(this.dataStore.equipment);
        return data;
      });
  }


  mapStudioData(studio) {
      const availableServices = {
          recording: [],
          rehearsal: [],
          podcast: []
      };
      studio.rooms.forEach((room) => {
          room.services.forEach((service) => {
              const currentService = Object.assign({}, service);
              currentService.roomId = room.id;
              availableServices[service.type].push(currentService);
          });
      });
      studio.cover = studio.media.find((img) => img.metadata.type === 'cover');
      studio.logo = studio.media.find((img) => img.metadata.type === 'logo');
      studio.images = studio.media.filter((img) => img.metadata.type !== 'logo');
      studio.availableServices = availableServices;

      let studioAddress = '';
      let studioAddressShort = '';
      if (studio.hasOwnProperty('address')) {
          if (studio.address.street) {
              studioAddress = studioAddress + studio.address.street;
          }
          if (studio.address.number) {
              studioAddress = studioAddress + ' ' + studio.address.number;
          }
          if (studio.address.city) {
              studioAddress = studioAddress + ', ' + studio.address.city;
              studioAddressShort = studioAddressShort + ', ' + studio.address.city;
          }
          if (studio.address.zip) {
              studioAddress = studioAddress + ', ' + studio.address.zip;
              studioAddressShort = studioAddressShort + ', ' + studio.address.zip;
          }
          if (studio.address.country) {
              studioAddress = studioAddress + ', ' + studio.address.country;
              studioAddressShort = studioAddressShort + ', ' + studio.address.country;
          }

          if (studioAddress.charAt(0) === ',') {
              studioAddress = studioAddress.slice(1);
          }

          if (studioAddressShort.charAt(0) === ',') {
              studioAddressShort = studioAddressShort.slice(1);
          }

          studio.studioAddress = studioAddress;
          studio.studioAddressShort = studioAddressShort;
      }

      return studio;
  }



}
