import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-social-share',
  templateUrl: './social-share.component.html',
  styleUrls: ['./social-share.component.scss']
})
export class SocialShareComponent implements OnInit {

  @Input() display = false;
  @Output() closeModalEmitter = new EventEmitter();

  public shareLink;
  public linkCopied = false;


  constructor(
  ) { }

  ngOnInit(): void {
    this.shareLink = window.location;
  }


  hideModal(event){
    this.closeModalEmitter.emit(false);
  }
}
