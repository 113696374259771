import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-booking-request',
  templateUrl: './booking-request.component.html',
  styleUrls: ['./booking-request.component.scss']
})
export class BookingRequestComponent implements OnInit {

  public studioName;
  public withInvites;

  constructor(
      private router: Router,
      private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.studioName = this.route.snapshot.queryParamMap.get('studioName');
    this.withInvites = this.route.snapshot.queryParamMap.get('withInvites');
  }

  goToHomepage() {
    this.router.navigate( ['/listing' ] );
  }
}
