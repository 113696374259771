import {Component, OnDestroy, OnInit} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {StudiosService} from '../../services/studios.service';
import {filter, map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {HelperService} from '../../services/helper.service';
import {Subscription} from 'rxjs';
import {UserService} from '../../services/user.service';
import {ProfileService} from '../../services/profile.service';

@Component({
    selector: 'app-booking-info',
    templateUrl: './booking-info.component.html',
    styleUrls: ['./booking-info.component.scss']
})
export class BookingInfoComponent implements OnInit, OnDestroy {

    public items: MenuItem[] = [{
        label: 'BASIC INFO',
    },
        {
            label: 'DATE',
        },
        {
            label: 'ROOM',
        },
        {
            label: 'OVERVIEW',
        }
    ];

    public activeIndex = 0;

    private routerParams: Subscription;

    public studio: any;
    public env = environment;
    public isMobile = false;

    private studioId;
    private roomId;
    private bandId;
    private userId;

    constructor(
        private helperService: HelperService,
        private studioService: StudiosService,
        private route: ActivatedRoute,
        private userService: UserService,
        private profileService: ProfileService,
        private router: Router,
    ) {
        this.isMobile = this.helperService.isMobile();
    }

    ngOnDestroy(): void {
        if (this.routerParams) {
            this.routerParams.unsubscribe();
        }
    }

    ngOnInit(): void {
        this.userId = this.profileService.getUserData().id;
        this.routerParams = this.route.queryParams.subscribe((params) => {
            if (params.hasOwnProperty('step') && params.step.length > 0) {
                this.activeIndex = parseInt(params.step, 10);
            } else {
                this.activeIndex = 0;
            }
        });


        this.studioId = this.route.snapshot.queryParamMap.get('studio');
        this.roomId = this.route.snapshot.queryParamMap.get('room');
        this.bandId = this.route.snapshot.queryParamMap.get('band');

        this.studio = this.studioService.studio$.pipe(
            filter(std => Object.keys(std).length !== 0),
            map((data) => {
                    data = this.studioService.mapStudioData(data);
                    return data;
                }
            )
        );

        this.studioService.fetchStudio(this.studioId);
        if (this.bandId) {
            this.userService.fetchUserBands(this.userId);
        }
    }


    openGMaps(studio) {
        this.helperService.openGMaps('search', studio.coordinates);
    }

}
