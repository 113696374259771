<p-card [style]="{border: '1px solid #938F99',borderRadius : 12}" styleClass="p-card-shadow" (click)="seeStudioDetails(studio)">

    <ng-template pTemplate="header">
        <div class="grid grid-nogutter" style="align-items: center">

            <div class="col-12">
                <h5 class="studio-text-overflow" style="padding-bottom: 0.2rem">{{studio.name}}</h5>
                <span class="studio-text-overflow">{{userHasLoggedIn() ? studio.studioAddress : studio.studioAddressShort}}</span>
            </div>
        </div>
    </ng-template>

    <div class="body_wrapper">
        <div class="review_text" *ngIf=" env.reviewEnabled && studio.studioReviews &&  studio.studioReviews.length > 0">
                        <i class="pi pi-star-fill"></i>
            <b>
            {{studioRating()}} ({{studio.studioReviews.length}}) </b>
        </div>
        <img id="cover"
             alt="Card"
             [src]="(studio.hasOwnProperty('cover') && studio.cover)  ? studio.cover['link'] : 'assets/images/sample_studio.png'">
            <p-avatar
                    [image]="(studio.hasOwnProperty('logo') && studio.logo)  ? studio.logo['link'] : 'assets/images/sample_studio.png'"
                    shape="circle"
                    styleClass="inner_wrapper"></p-avatar>
    </div>
    <ng-template pTemplate="content">
        <div class="advanced-card-content">
            <div class="grid align-items-center" style="text-align: center;">
                <div class="col-4" [ngClass]="getColClass()">
                    <i class="material-icons-outlined">meeting_room</i>
                    <br>
                    {{studio.rooms.length}}
                    {{studio.rooms.length > 1 ? 'Rooms' : 'Room'}}
                </div>
                <div *ngIf = "studioHasRecordingService()" [ngClass]="getColClass()"><i class="material-icons-outlined">keyboard_voice</i>
                    <br> Recording</div>
                <div *ngIf = "studioHasRehearsalService()" [ngClass]="getColClass()"><i style="vertical-align: middle" class="flaticon flaticon-014-drum-set"></i>
                    <br> Rehearsal</div>
            </div>
            <p>{{studio.description}}</p>
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <div style="color:#FFFFFF">
            <strong>from {{studio.minPrice}} {{this.getCurrency()}} / hour</strong>
        </div>
    </ng-template>
</p-card>
