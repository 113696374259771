<p-card
        [style]="isAddedForRental ? {border: '3px solid #3CD1C5', borderRadius : 12, height: '100%'} : {border: '1px solid #938F99', borderRadius : 12, height: '100%'}"
        styleClass="p-card-shadow"
        [ngClass]="{
        'dark-mode' : darkMode === true ,
        'grey-mode' : darkMode === false ,
        'rentable' : rentFlag === true,
        'selected' : rentFlag === true && isAddedForRental === true
        }"

        (click)=" isAddedForRental ? removeThisForRental() : addThisForRental()"
>

    <div class="dynamic-height" style="margin-bottom:-1rem">
        <div class="instr-card-header">
        <span>
                <i class="md:inline-block hidden circular-icon till-noon-icon {{instrument.icon}}"></i>
                <i class="md:hidden inline-block circular-icon till-noon-icon till-noon-icon-big {{instrument.icon}}"></i>
        </span>
            <span class="card-header-title">
            <p class="tn-text-normal tn-text-green">{{ instrument.categoryLabel }}</p>
        </span>
        </div>
        <div class="instr-card-body">
            <p class="tn-text-big"> {{instrument.equipment.brand !== 'other' ?  (instrument.equipment.brand | titleCaseExtendedPipe) +  ' - ' : ''}}
                {{ instrument.equipment.name | readMore:false:40}}</p>
        </div>
        <div class="instr-card-footer"><span class="tn-text-green">{{ instrument.price }}</span><span
                class="tn-text-small"> {{getCurrency()}} / hour</span></div>
    </div>

</p-card>
