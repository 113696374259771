<p-card
        [ngClass]="{'green-mode' : selected === true , 'grey-mode' : selected === false && darkMode === false , 'dark-mode' : darkMode === true}"
        [style]="{border: '1px solid #938F99', borderRadius : 12}"
        styleClass="p-card-shadow clickable-card"
        (click)="selectBand()"
>

    <div class="grid grid-nogutter align-items-center">
        <div class="col-fixed" style="margin: auto;width: 3.4375rem;height: 3.4375rem;"
             (click)="selectBand()"
        >
            <p-avatar
                    [image]="band.hasOwnProperty('avatar') && band.avatar ? band.avatar['link'] : 'assets/images/bandbeat/sample_band_logo.png'"
                    shape="circle" styleClass="mr-2 band-avatar" ></p-avatar>
        </div>
        <div class="col ml-4"
             style="
                word-wrap: break-word;
                word-break: break-all;
                white-space: pre-wrap;
            "
             (click)="selectBand()"
        >
            <p style="margin:auto" class="tn-text-big">{{band.name}}</p>
        </div>

        <div class="col-fixed"
             style="
                text-align: center;
                margin: auto;
                width: 3.4375rem;
                height: 3.4375rem;"
        >
            <div *ngIf="showMenu">
                <button #btn type="button" class="p-button-text" pButton icon="pi pi-ellipsis-v"
                        (click)="menu.toggle($event);$event.stopPropagation()"></button>
                <p-menu #menu [model]="items" [popup]="true" appendTo="body"></p-menu>
            </div>
        </div>

    </div>

</p-card>



<p-dialog [(visible)]="memberDialog" [style]="{width: '450px'}" [modal]="true"
          styleClass="p-fluid">

    <ng-template pTemplate="header">
        <p class="tn-text-big tn-text-green">Invite New Member</p>
    </ng-template>
    <ng-template pTemplate="content">


        <div class="flex align-items-center">
            <p-avatar
                    shape="circle"
                    styleClass="inviteAvatar"
                    [image]="band.hasOwnProperty('avatar') && band.avatar ? band.avatar['link'] : 'assets/images/bandbeat/sample_user_logo.png'"
            >
            </p-avatar>

            <div class="pl-5">
                <p class="tn-text-big">
                    {{band.name}}
                </p>
            </div>
        </div>


        <div class="field pt-4">
            <label for="name">Email</label>
            <input inputTrim type="text" pInputText id="name" [(ngModel)]="memberEmail" required autofocus />
            <small class="p-invalid" *ngIf="submitted && !memberEmail">Email is required.</small>
        </div>

<!--        <img-->
<!--                [src]="band.hasOwnProperty('avatar') && band.avatar ? env.uploadUrl + band.avatar.link : 'assets/images/bandbeat/sample_band_logo.png'"-->
<!--                style="width:100%;height:auto;" alt="new member"-->
<!--                class="product-image">-->
<!--        <div class="field">-->
<!--            <label for="email">Email</label>-->
<!--            <input inputTrim type="text" pInputText id="email" [(ngModel)]="memberEmail" required autofocus/>-->
<!--            <small class="p-invalid" *ngIf="memberSubmitted && !memberEmail">Email is required.</small>-->
<!--        </div>-->
    </ng-template>

    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="tn-danger-button" (click)="hideInviteMemberModal()"></button>
        <button pButton pRipple label="Save" icon="pi pi-check" class="tn-secondary-button" (click)="inviteMember()"></button>
    </ng-template>
</p-dialog>

<p-dialog [(visible)]="editNameDialog" [style]="{width: '450px'}" [modal]="true"
          styleClass="p-fluid">

    <ng-template pTemplate="header">
        <p class="tn-text-big tn-text-green">Edit Name</p>
    </ng-template>

    <ng-template pTemplate="content">



        <div class="flex align-items-center">
            <p-avatar
                    shape="circle"
                    styleClass="inviteAvatar"
                    [image]="band.hasOwnProperty('avatar') && band.avatar ? band.avatar['link'] : 'assets/images/bandbeat/sample_user_logo.png'"
            >
            </p-avatar>

            <div class="pl-5">
                <p class="tn-text-big">
                    {{band.name}}
                </p>
            </div>
        </div>


        <div class="field pt-4">
            <label for="name">Name</label>
            <input inputTrim type="text" pInputText id="name" [(ngModel)]="bandName" required autofocus />
            <small class="p-invalid" *ngIf="submitted && !bandName">Name is required.</small>
        </div>

    </ng-template>

    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="tn-danger-button" (click)="hideEditNameModal()"></button>
        <button pButton pRipple label="Save" icon="pi pi-check" class="tn-secondary-button" (click)="saveBand()"></button>
    </ng-template>
</p-dialog>
