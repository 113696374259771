<div class="grid pt-5">
    <div class="col-12" >

        <div class="grid">
            <div class="col-12 lg:col-6">
                <p class="tn-title">Create New Band</p>

                <p-avatar shape="circle"
                          [image]="bandLogo"
                >
                </p-avatar>
                <button pButton
                        id="logoButton"
                        pRipple type="button"
                        icon="till-noon-icon tnicon-Pencil"
                        (click)="logoUpload.choose()"
                        class="p-button-rounded p-button-icon-only p-button-text tn-primary-button  uploadButton">

                </button>
                <p-fileUpload
                    #logoUpload
                    class="logoUploader hidden-mobile"
                    [customUpload]="true"
                    [auto]="true"
                    accept="image/*"
                    (uploadHandler)="imageUpload($event, 'logo' , 'studio')"
                ></p-fileUpload>

            </div>

            <div class="hidden lg:flex col-6"></div>

            <div class="col-12 lg:col-6">
                <p class="tn-text-simple tn-text-green ">BAND NAME</p>
                <input
                        inputId="bandname"
                        type="text"
                        pInputText
                        class="w-full"
                        id="bandname"
                        [(ngModel)]="bandName"
                />
            </div>

            <div class="hidden lg:flex col-6"></div>

            <div class="col-6 ">
                <p class="tn-title">Add a Mate</p>
                <p class="tn-text-simple tn-text-green ">BANDBEAT EMAIL</p>

                <input inputTrim
                       id="inviteEmail"
                       type="text"
                       pInputText
                       [(ngModel)]="inviteEmail"
                       class="w-full"
                >
            </div>

            <div class=" col-6 flex align-items-end ">
                <button
                        pButton
                        type="button"
                        label="INVITE BAND MEMBER"
                        icon="pi pi-plus"
                        class=" tn-secondary-button  w-auto"
                        (click)="checkUser()"
                ></button>
            </div>

            <div class="col-12">

                <p-chip
                        *ngFor="let inviteMember of bandUsers"
                        [label]="inviteMember.email"
                        [image]="inviteMember.hasOwnProperty('avatar') ? inviteMember.avatar['link'] : 'assets/images/sample_user.png'"
                        styleClass="custom-chip"
                ></p-chip>
            </div>

        </div>



    </div>

    <div class="col-12">
        <div class="grid grid-nogutter justify-content-between">
            <button pButton  class="tn-primary-button" label="BACK" (click)="previousPage()" icon="till-noon-icon tnicon-Arrow-Left" iconPos="left"></button>
<!--            <button pButton class="tn-helper-button" label="SKIP" (click)="nextPage(true)" icon="pi pi-angle-right" iconPos="right"></button>-->
            <button pButton class="tn-secondary-button" label="DONE" (click)="nextPage()" icon="till-noon-icon tnicon-Arrow-Right" iconPos="right"></button>
        </div>
    </div>

</div>
