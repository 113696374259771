import {Component, OnInit} from '@angular/core';
import {HelperService} from '../../../services/helper.service';
import {ActivatedRoute, Router} from '@angular/router';
import {filter, map} from 'rxjs/operators';
import {StudiosService} from '../../../services/studios.service';
import {environment} from '../../../../environments/environment';
import {combineLatest} from 'rxjs';
import {UserService} from '../../../services/user.service';
import {ProfileService} from '../../../services/profile.service';
import {
    eachWeekOfInterval,
    isFriday,
    isMonday,
    isSameDay,
    isSameWeek,
    isSaturday,
    isSunday,
    isThursday,
    isTuesday,
    isWednesday
} from 'date-fns';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';

@Component({
    selector: 'app-booking-settings',
    templateUrl: './booking-settings.component.html',
    styleUrls: ['./booking-settings.component.scss']
})
export class BookingSettingsComponent implements OnInit {
    private userId;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private helperService: HelperService,
        private studioService: StudiosService,
        private userService: UserService,
        private profileService: ProfileService
    ) {
        this.userId = this.profileService.getUserData().id;
    }

    public bookDate;
    public currentDate;
    public maxDate;
    public disabledDays = [];
    public bookTime;
    public bookDuration;
    public env = environment;
    public bookingType;


    availableServices: any[] = [
        {name: 'Rehearsal', code: 'rehearsal'},
        {name: 'Recording', code: 'recording'},
        {name: 'Podcast', code: 'podcast'}
    ];

    availableDurations = [];

    public studio: any;
    public studioId: string;
    selectedService = '';
    switch: boolean;

    recurringBooking = false;
    recurringEndDate;
    recurringDays = [];
    selectedDays = [];
    weekDays: any[] = [
        {label: 'MO', value: 'monday', checker: isMonday},
        {label: 'TU', value: 'tuesday', checker: isTuesday},
        {label: 'WE', value: 'wednesday', checker: isWednesday},
        {label: 'TH', value: 'thursday', checker: isThursday},
        {label: 'FR', value: 'friday', checker: isFriday},
        {label: 'SA', value: 'saturday', checker: isSaturday},
        {label: 'SU', value: 'sunday', checker: isSunday},
    ];
    public occurenceOptions: any[] = [{label: 'Weekly', value: 'weekly'}, {label: 'Biweekly', value: 'biweekly'}];
    public recurringOccurence = this.occurenceOptions[0].value;

    timeAvailability = new Map<number, any[]>();

    availabilityOptions = [];

    public availabilityLoading = false;
    public availabilityFound;
    public availabilityMessage;

    public availableSlotsFound = false;

    ngOnInit(): void {
        this.bookingType = this.route.snapshot.queryParamMap.get('type');
        this.selectedService = this.route.snapshot.queryParamMap.get('service');
        this.currentDate = new Date();
        this.maxDate = new Date();
        this.maxDate.setMonth(this.maxDate.getMonth() + 3);

        this.studio = combineLatest(
            this.studioService.studio$.pipe(filter(std => Object.keys(std).length !== 0)),
            this.userService.bands$.pipe(filter(bnd => (Object.keys(bnd).length !== 0) || (this.bookingType === 'solo'))
            )).pipe(
            map((data: any[]) => {
                const studioData = data[0];

                const availableServices = {
                    recording: [],
                    rehearsal: [],
                    podcast: []
                };
                studioData.rooms.forEach((room) => {
                    room.services.forEach((service) => {
                        const currentService = Object.assign({}, service);
                        currentService.roomId = room.id;
                        availableServices[service.type].push(currentService);
                    });
                });
                studioData.cover = studioData.media.find((img) => img.metadata.type === 'cover');
                studioData.logo = studioData.media.find((img) => img.metadata.type === 'logo');
                studioData.images = studioData.media.filter((img) => img.metadata.type !== 'logo');
                Object.keys(availableServices).forEach((serviceType) => {
                    if (availableServices[serviceType].length === 0 && this.availableServices.find(el => el.code === serviceType)) {
                        this.availableServices.splice(this.availableServices.findIndex(el => el.code === serviceType) , 1);
                    }
                });
                studioData.availableServices = availableServices;
                studioData.instruments = studioData.instruments.filter((inst) => inst.available);

                const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
                days.forEach((day, index) => {
                    if (studioData.availability[day].available) {
                        this.timeAvailability.set(index, this.buildAvailabilitySlots(studioData.availability[day].from, studioData.availability[day].to));

                    } else {
                        this.disabledDays.push(index);
                    }
                });

                if (this.bookDate){
                    this.buildAvailabilityOptions();
                }

                return studioData;
            })
        );

        this.studioId = this.route.snapshot.queryParamMap.get('studio');

        if (this.route.snapshot.queryParamMap.get('date')) {
            this.bookDate = new Date(this.route.snapshot.queryParamMap.get('date'));
        }

        this.bookTime = this.route.snapshot.queryParamMap.get('time');
        if (this.route.snapshot.queryParamMap.get('duration')) {
            this.bookDuration = Number(this.route.snapshot.queryParamMap.get('duration'));
        }



        if (this.route.snapshot.queryParamMap.get('recurringBooking')) {
            this.recurringBooking = this.route.snapshot.queryParamMap.get('recurringBooking') === 'true';
        }
        if (this.route.snapshot.queryParamMap.getAll('recurringDates').length > 0) {
            this.selectedDays = this.route.snapshot.queryParamMap.getAll('recurringDates').map((selectedDay) => {
                return {
                    selectedDay ,
                    availability : 'Available'
                };
            });

            this.recurringEndDate = new Date(this.route.snapshot.queryParamMap.getAll('recurringDates')[this.route.snapshot.queryParamMap.getAll('recurringDates').length -1]);
            this.recurringDays = this.weekDays

                .filter((el) => {

                    return this.selectedDays.some(t => el.checker( new Date(t.selectedDay) ));
                });

        }
        this.checkAvailability();
    }

    buildAvailabilitySlots(availableMinTime: Date, availableMaxTime: Date) {
        const slots = [];

        const minTime = new Date(this.currentDate);
        const maxTime = new Date(this.currentDate);

        minTime.setHours(new Date(availableMinTime).getHours(), new Date(availableMinTime).getMinutes());
        maxTime.setHours(new Date(availableMaxTime).getHours() - 1, new Date(availableMaxTime).getMinutes()); // Available slot is 1 hour before closing hour

        if (maxTime.getHours() === 0) {
            maxTime.setDate(maxTime.getDate() + 1);
        }
        if (maxTime < minTime) {
            maxTime.setDate(maxTime.getDate() + 1);
        }

        for (const date = minTime; date <= maxTime; date.setMinutes(date.getMinutes() + 60)){
            slots.push(date.getHours() + ':' +  String(date.getMinutes()).padStart(2, '0'));
        }
        return slots;
    }
    buildAvailabilityOptions(){
        this.availabilityOptions = [];
        this.availabilityOptions  = Object.assign([], this.timeAvailability.get(this.bookDate.getDay()));

        const regex = new RegExp(':', 'g');
        if (isSameDay(this.bookDate, this.currentDate)) {
            this.timeAvailability.get(this.bookDate.getDay()).every(slot => {
                const bookDateMinutes = this.bookDate.getMinutes().toString().length === 1 ? '0' + this.bookDate.getMinutes() : this.bookDate.getMinutes();
                if (parseInt(slot.replace(regex, ''), 10) < parseInt(this.bookDate.getHours().toString() + bookDateMinutes, 10)) {
                    this.availabilityOptions.splice(this.availabilityOptions.indexOf(slot), 1);
                    return true;
                } else {
                    return false;
                }
            });
        }

        if (!this.availabilityOptions.includes(this.bookTime)) {
            this.bookTime = null;
            this.bookDuration = null;
        } else {
            this.buildAvailableDurations();
        }
    }


    buildAvailableDurations(){
        this.availableDurations = [];
        const bookTime = new Date();
        const [startHour, startMinute] = this.bookTime.split(':');
        bookTime.setHours(Number(startHour));
        bookTime.setMinutes(Number(startMinute));

        const endTime = new Date();
        const [endHour, endMinute] = this.availabilityOptions[this.availabilityOptions.length - 1].split(':');
        endTime.setHours(Number(endHour) + 1);
        endTime.setMinutes(Number(endMinute));

        if (endTime < bookTime) {
            endTime.setDate(endTime.getDate() + 1);
        }

        const maxDuration = (endTime.getTime() - bookTime.getTime()) / (1000 * 60 * 60);

        for (let i = 1; i <= maxDuration && i <= 6; i++) {
            if (i === 1) {
                this.availableDurations.push({value: i, label: i + ' hour'});
            } else {
                this.availableDurations.push({value: i, label: i + ' hours'});
            }
        }

        if (!this.availableDurations.some(obj => obj.value === this.bookDuration)) {
            this.bookDuration = null;
        }
    }

    checkSelectedOccurence(t) {
        return this.recurringOccurence !== this.occurenceOptions[t].value
    }

    selectOccurence( occ: number) {
        this.recurringOccurence = this.occurenceOptions[occ].value;
    }

    checkAvailability() {

        if (this.recurringBooking) {


            if (
                this.bookDate &&
                this.bookTime &&
                this.bookDuration &&
                this.selectedService &&
                this.recurringEndDate &&
                this.recurringDays.length > 0 &&
                this.recurringOccurence
            ) {
                this.availableSlotsFound = false;
                this.selectedDays = eachDayOfInterval(
                    {
                        start : this.bookDate,
                        end : this.recurringEndDate
                    }
                );
                if (this.recurringOccurence !== 'weekly') {
                    const availableWeeks = eachWeekOfInterval(
                        {
                            start : this.bookDate,
                            end : this.recurringEndDate
                        }, {weekStartsOn: 1})
                        .filter((week, index) => {
                            return index % 2 === 0;
                        });

                    this.selectedDays = this.selectedDays.filter((day: any) => {
                        return availableWeeks.some(week => isSameWeek(week , day, {weekStartsOn: 1}));
                    });
                }
                this.selectedDays = this.selectedDays
                    .filter((availableDay) => {
                        return this.recurringDays.some(t => t.checker(availableDay));
                    });

                this.selectedDays = this.selectedDays.map((selectedDay, index, original) => {

                    selectedDay.setHours(Number(this.bookTime.substring(0, this.bookTime.indexOf(':'))));
                    selectedDay.setMinutes(Number(this.bookTime.substring(this.bookTime.indexOf(':') + 1)));
                    const availableDay = {
                        selectedDay ,
                        availability : 'loading'
                    };
                    this.studioService.fetchStudioAvailableRooms(
                        this.studioId,
                        this.selectedService,
                        availableDay.selectedDay.toISOString(),
                        this.bookDuration
                    ).then((data) => {
                        availableDay.availability = data.length > 0 ? 'Available' : '! Not Available';
                    });
                    return availableDay;
                });


            }



        } else {


            if (this.bookDate && this.bookTime && this.bookDuration && this.selectedService) {
                this.availabilityMessage = '';

                this.bookDate.setHours(Number(this.bookTime.substring(0, this.bookTime.indexOf(':'))));
                this.bookDate.setMinutes(Number(this.bookTime.substring(this.bookTime.indexOf(':') + 1)));

                this.availabilityFound = undefined;
                this.availabilityLoading = true;
                this.studioService.fetchStudioAvailableRooms(
                    this.studioId,
                    this.selectedService,
                    this.bookDate.toISOString(),
                    this.bookDuration
                ).then((data) => {
                    this.availabilityLoading = false;
                    this.availabilityFound = data;
                    this.availabilityMessage = this.availabilityFound.length > 0 ? 'There is availability for your picked options!' : 'There is no availability for your picked options!';
                });
            }
        }
    }

    startRecurring() {
        if (this.recurringBooking && this.bookDate) {
            this.recurringDays = this.weekDays.filter(el => el.checker(this.bookDate))
        }
    }

    nextPage() {
        if (this.recurringBooking) {
            this.bookDate = this.selectedDays[0].selectedDay
        }
        this.router.navigate(
            ['room'],
            {
                queryParamsHandling: 'merge',
                relativeTo: this.route.parent,
                queryParams: {
                    service: this.selectedService,
                    date: this.bookDate,
                    recurringBooking: this.recurringBooking,
                    recurringDates: this.selectedDays.map(el => el.selectedDay),
                    time: this.bookTime,
                    duration: this.bookDuration,
                    step: 2,
                }
            });
    }

    previous() {
        this.router.navigate(
            ['type'],
            {
                relativeTo: this.route.parent,
                queryParams: {studio: this.studioId}
            }
        );
    }

    checkNextStep() {
        if (this.recurringBooking) {
            return !(this.selectedDays.length > 0 &&
                this.selectedDays.filter(el => el.availability === 'Available').length === this.selectedDays.length);
        } else {
            return !this.availabilityFound || this.availabilityFound.length === 0 ;
        }
    }

}
