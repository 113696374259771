import {Pipe, PipeTransform} from '@angular/core';
import {toTitleCase} from 'codelyzer/util/utils';

@Pipe({
    name: 'titleCaseExtendedPipe'
})
export class TitleCaseExtendedPipePipe implements PipeTransform {

    transform(value: string): string {
        return toTitleCase(value.replace('_', ' '));
    }

}
