<div class="grid">
    <div class="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center ">
        <section>
            <div class="p-fluid grid formgrid">
                <div class="field col-12 md:col-6">
                    <label >SERVICE</label>
                    <p-radioButton
                            label="Recording"
                            name="bookservice"
                            value="recording"
                            [(ngModel)]="selectedService"
                    ></p-radioButton>
                    <p-radioButton
                            label="Rehearsal"
                            name="bookservice"
                            value="rehearsal"
                            [(ngModel)]="selectedService"
                    ></p-radioButton>
                </div>
            </div>
        </section>
    </div>
</div>

<div class="grid grid-nogutter next-buttons">
    <p-button label="Previous" (onClick)="previous()" icon="till-noon-icon tnicon-Arrow-Left" styleClass="secondary-button" iconPos="left"></p-button>
    <p-button label="Next" (onClick)="nextPage()" icon="till-noon-icon tnicon-Arrow-Right" iconPos="right"></p-button>
</div>
