<div id="header" class="flex flex-column">
    <div class="header-top flex align-items-center justify-content-between">
        <h4 class="font-bold m-0 cursor-pointer">Bandbeat</h4>
        <div>
            <a id="menu-button" class="align-items-center justify-content-center cursor-pointer"
               onclick="openMenu()">
                <i class="pi pi-bars"></i>
            </a>

            <ul id="landing-menu" class="landing-menu list-none">
                <li class="flex justify-content-end">
                    <a onclick="closeMenu()" id="landing-menu-close" tabindex="0" class="cursor-pointer">
                        <i class="pi pi-times"></i>
                    </a>
                </li>

                <li>
                    <a href="#header">HOME</a>
                </li>
                <li>
                    <a href="#features">FEATURES</a>
                </li>
                <li>
                    <a href="#statistics">STATISTICS</a>
                </li>
                <li>
                    <a href="#pricing">PRICING</a>
                </li>
            </ul>
        </div>
    </div>


</div>
