import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';

declare var google: any;

@Pipe({
  name: 'mapOverlays'
})
export class MapOverlaysPipe implements PipeTransform {

  transform(studios: any[], args?: any): unknown {


    return studios
        .filter((studio) => studio.coordinates && studio.coordinates.x && studio.coordinates.y)
        .map((studio) => new google.maps.Marker(
            {
              position: {lat: studio.coordinates.x, lng: studio.coordinates.y},
              title: studio.name,
              icon: '/assets/images/bandbeat/tn_bb_logo_map_layer_marker.png',
              studioData: studio
            }
            ));

  }

}
