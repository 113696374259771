import {Component, OnDestroy, OnInit} from '@angular/core';
import {Route, Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {AuthenticationService} from '../services/authentication.service';
import {NotificationsService} from '../services/notifications.service';
import {FacebookLoginProvider, GoogleLoginProvider, SocialAuthService} from '@abacritt/angularx-social-login';
import {filter} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {UserService} from "../services/user.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  public env = environment;
  userEmail = '';
  userPassword = '';

  authorizationSubscription;

  constructor(
      private router: Router,
      private authenticationService: AuthenticationService,
      private notificationsService: NotificationsService,
      private userService: UserService,
      private messageService: MessageService,
      private authService: SocialAuthService,
  ) { }

  ngOnInit(): void {


      this.authorizationSubscription = this.authService.authState
          .pipe(
              filter(val =>
                  val !== null
              )
          )
          .subscribe((user) => {
              let registration;
              if (user.provider === 'FACEBOOK') {
                  registration = this.authenticationService.registerFacebookUser(
                      user.firstName ,
                      user.lastName,
                      user.email,
                      user.id,
                      user.authToken,
                      user.photoUrl
                  );
              } else {
                  registration = this.authenticationService.registerGoogleUser(
                      user.firstName ,
                      user.lastName,
                      user.email,
                      user.id,
                      user.idToken,
                      user.photoUrl
                  );
              }

              registration.then((data) => {
                      this.router.navigate(['listing']);

                      setTimeout(() => {
                          this.messageService.add(
                              {
                                  key: 'globalToast',
                                  severity: 'success',
                                  summary: 'Successful login',
                                  detail: 'Welcome back ' + data.user.name
                              });
                      }, 1000);
                  }).catch((err) => {

                  this.authService.signOut(false);
                  this.messageService.clear();
                  this.messageService.add({
                      severity: 'error', summary: 'Something went wrong', detail: err.error.message});
              });
          });
  }
  ngOnDestroy(): void {
      this.authorizationSubscription.unsubscribe();
  }

  public login() {
    this.authenticationService.loginUser(this.userEmail, this.userPassword)
        .then((data) => {

          this.notificationsService.startPolling();

          this.userService.fetchUserRegistrations(data.user.id);
          this.router.navigate(['listing']);

          setTimeout(() => {
            this.messageService.add(
                {
                  key: 'globalToast',
                  severity: 'success',
                  summary: 'Successful login',
                  detail: 'Welcome back ' + data.user.name
                });
          }, 1000);
        }).catch((err) => {
      this.messageService.clear();
      this.messageService.add({
          severity: 'error',
          summary: 'Invalid login',
          detail: 'Wrong email or password'
      });
    });
  }

    public signInWithFB(): void {
        this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
    }

    public getButtonSize( parentElem ) {
      if (parentElem) {
          return parentElem.getBoundingClientRect().width - 30 + '';
      }
      if (window.innerWidth < 991) {
          return 300;
      } else if (window.innerWidth < 1200) {
          return 250;
      } else {
          return 200;
      }
    }

}
