import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filterStudioService'
})
export class FilterStudioServicePipe implements PipeTransform {

  transform(studios: any[], selectedService: string = 'all'): any[] {
    if (selectedService === 'all') {
      return studios;
    }
    return studios.filter((studio) => {
      const nbr = studio.rooms.filter((room) => {
        return room.services.find((service) => service.type === selectedService);
      });
      return nbr.length > 0;
    });
  }

}
