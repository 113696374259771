<div class="exception-body flex align-items-center justify-content-center">
    <div class="exception-panel p-5 flex flex-column justify-content-center">
        <h4 class="font-bold">ACCESS DENIED</h4>
        <span class="line-height-3">You are not authorized to access this resource.</span>

        <img src="../../../assets/layout/images/pages/access-denied.svg" alt="access-denied" class="my-5" height="140">

        <button type="button" pButton label="Go to Dashboard" [routerLink]="['/']"></button>
    </div>
</div>
