<div class="layout-wrapper layout-slim">
    <div class="layout-topbar">
        <div class="layout-topbar-left">
            <a class="app-name" href="/">
                <img alt="main logo" [src]="'assets/images/bandbeat/bb_logo_header.png'" class="app-logo">
                <span>Bandbeat</span>
            </a>
        </div>
    </div>

    <div class="layout-content-wrapper layout-simple-page">
        <div class="layout-content" >
            <div class="landing-body" style="background:transparent !important; height: 100%">
                <div class="landing-wrapper">
                    <div class="header-content grid " style="margin-right: unset;color: #FFFFFF;">
                        <div class="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center md:pr-0 lg:pr-6  md:justify-content-start justify-content-center ">
                            <section style="width:100%" class="layout-section-mobile">
                                <p class="tn-title-big" style="font-weight:400;">Welcome to</p>
                                <p class="tn-title-big keyword">Bandbeat</p>
<!--                                <div class="col-12 md:col-4 md:col-offset-1 overflow-hidden wave-mobile">-->
<!--                                    <app-wave-animation></app-wave-animation>-->
<!--                                </div>-->
                                <p class="subtitle mb-4" >We will just need the following information from you to become part of the
                                    Bandbeat community</p>

                                <div class="p-fluid grid formgrid" >
                                        <div class="field col-12 md:col-6">
                                            <span class="tn-text-simple tn-text-green ">NAME *</span>

                                            <input inputTrim
                                                   id="name"
                                                   type="text"
                                                   pInputText
                                                   [(ngModel)]="userName"
                                                   (keydown.enter)="signup()"
                                                   required
                                            />

                                        </div>

                                        <div class="field col-12 md:col-6">
                                            <span class="tn-text-simple tn-text-green ">EMAIL *</span>

                                            <input inputTrim
                                                   id="email"
                                                   type="text"
                                                   pInputText
                                                   autocomplete="username"
                                                   required
                                                   [(ngModel)]="userEmail"
                                                   (keydown.enter)="signup()"
                                            />

                                        </div>


                                        <div class="field col-12 md:col-6">
                                            <span class="tn-text-simple tn-text-green ">COUNTRY *</span>

                                            <p-dropdown
                                                    inputId="country"
                                                    id="country"
                                                    required="true"
                                                    optionValue="label"
                                                    [options]="countries"
                                                    [(ngModel)]="userCountry"
                                                    (onChange)="selectCountry($event)"
                                                    placeholder="Select a Country"
                                            >
                                                <ng-template let-country pTemplate="item">
                                                    <div class="flex align-items-center gap-2">
                                                        <div> <span>{{country.emoji}}</span> {{ country.name }}</div>
                                                    </div>
                                                </ng-template>
                                            </p-dropdown>

                                        </div>

                                        <div class="field col-12 md:col-6">
                                            <span class="tn-text-simple tn-text-green ">PHONE NUMBER *</span>

                                            <p-inputNumber
                                                    id="phoneNumber"
                                                    inputId="phoneNumber"
                                                    [prefix]="phonePrefix"
                                                    [useGrouping]="false"
                                                    [(ngModel)]="userPhoneNumber"
                                                    (keydown.enter)="signup()"
                                                    required
                                            ></p-inputNumber>
                                        </div>

                                        <div class="field col-12 md:col-6">
                                            <span class="tn-text-simple tn-text-green ">PASSWORD *</span>
                                            <p-password
                                                    id="password"
                                                    inputId="password"
                                                    class="mask-icon"
                                                    [(ngModel)]="userPassword"
                                                    [toggleMask]="true"
                                                    required
                                                    (keydown.enter)="signup()"
                                            ></p-password>
                                        </div>

                                        <div class="field col-12 md:col-6">
                                            <span class="tn-text-simple tn-text-green ">CONFIRM PASSWORD *</span>
                                            <p-password
                                                    id="confirmpassword"
                                                    inputId="confirmpassword"
                                                    required
                                                    class="mask-icon"
                                                    [(ngModel)]="userConfirmPassword"
                                                    [toggleMask]="true"
                                                    (keydown.enter)="signup()"
                                            ></p-password>
                                        </div>


                                        <div class="field-checkbox col-12 pt-4">
                                            <div class="col-1">
                                                <p-checkbox [(ngModel)]="termsAndConditionAccepted" [binary]="true" inputId="binary"
                                                            required="true"></p-checkbox>
                                            </div>
                                            <div class="col-11">
                                                <p class="tn-text-small">I agree to the <span class="tn-text-normal cursor-pointer" [routerLink]="['/terms&conditions']">Terms & Conditions</span>
                                                    and <span class="tn-text-normal cursor-pointer" [routerLink]="['/privacy&policy']">Privacy policy</span>. *
                                                </p>
                                            </div>
                                        </div>
                                        <div class="field-checkbox col-12">
                                            <div class="col-1">
                                                <p-checkbox [(ngModel)]="newsletterAccepted" [binary]="true" inputId="binary"
                                                            required="true"></p-checkbox>
                                            </div>
                                            <div class="col-11">
                                                <p class="tn-text-small">I agree to sign up to the <span class="tn-text-normal tn-text-green" >Bandbeat</span> newsletter.
                                                </p>
                                            </div>
                                        </div>

                                    <div class="col-12 md:col-6 mt-4">
                                            <button
                                                    pButton
                                                    icon="pi pi-check"
                                                    pRipple
                                                    label="SIGN UP"
                                                    style="width:auto;"
                                                    type="button"
                                                    class=" tn-secondary-button  "
                                                    (click)="signup()"
                                            ></button>
                                        </div>

                                    <div class="col-12 md:col-6 mt-4" style=" text-align: end;">
                                        <span class="tn-text-small"> *required fields</span>
                                    </div>


                                    <div class="col-12 pt-4">
                                        <p class="tn-text-small tn-text-green mb-4" style="font-weight: 500;" >Or Sign in with</p>
                                    </div>


                                    <div #googleButton class="col-12 xl:col-6 pt-4 login-txt" *ngIf=" env.socialLoginEnabled" >
                                        <asl-google-signin-button
                                                text="continue_with"
                                                type='standard'
                                                size='large'
                                                shape="pill"
                                                class="google-button "
                                                width="{{getButtonSize(googleButton)}}"
                                        ></asl-google-signin-button>
                                    </div>

                                    <div #facebookButton class="col-12 xl:col-6 pt-4 login-txt" *ngIf=" env.socialLoginEnabled" >

                                        <button
                                                pButton
                                                type="button"
                                                icon="pi pi-facebook"
                                                class="p-button-raised p-button-rounded facebook-button"
                                                label="Continue with Facebook"
                                                style="width: {{getButtonSize(facebookButton)}}"
                                                (click)="signInWithFB()"
                                        ></button>
                                    </div>



                                    <div class="col-12 pt-4">
                                        <span class="tn-text-small" >Already have an account? <span
                                                class="tn-text-normal tn-text-green cursor-pointer"
                                                [routerLink]="['/login']">Sign in here!</span>
                                        </span>
                                    </div>

                                </div>







                                <!-- <p-messages></p-messages> -->

                            </section>
                        </div>
<!--                        <div class="col-12 md:col-4 md:col-offset-1 overflow-hidden wave">-->
<!--                            <app-wave-animation></app-wave-animation>-->
<!--                        </div>-->
                    </div>
                </div>
            </div>
        </div>
        <p-toast
                [style]="{'overflow-wrap' : 'break-word'}"
                [baseZIndex]="5000"
                life="4000"
        ></p-toast>
        <app-footer></app-footer>
    </div>
</div>



