<div class="grid bandbeatPage" style="padding-bottom:0;" *ngIf="studio | async as studioData">


    <div class="col-12 md:col-6">
        <div class="grid">
            <div class="col-6">
                <span
                        *ngIf="studioRating && env.reviewEnabled && env.reviewEnabled === true"
                        (click)="reviewModal = true"
                        class="cursor-pointer"
                >
                    <p-rating [(ngModel)]="studioRating" [readonly]="true" [cancel]="false" style="float:left;"></p-rating>
                    <span style="float:left;margin-left:10px;color : #00B6B2">({{studioRatings}})</span>
                            </span>
            </div>
            <div class="col-6 special-offers-container" *ngIf="hasOffers">
                <p-tag >
                    <span class="special-offers-text">
                        SPECIAL OFFERS
                    </span>
                </p-tag>
                    
            </div>
            <div class="col-12">
                    <span
                        class="tn-title-big tn-text-green"
                    >
                        {{studioData.name}}
                    </span>
            </div>
            <div class="col-12">

                <ng-template [ngIf]="true">
                    <ul class="shareActions">
                        <li>
                            <button
                                    icon="till-noon-icon tnicon-Facebook"
                                    pButton
                                    type="button"
                                    [disabled]="!(studioData.facebookHandle && studioData.facebookHandle.length >0)"
                                    (click)="shareClicked('facebook' , studioData.facebookHandle)"
                                    class="p-button-text tn-primary-button socialButton"
                            ></button>
                        </li>
                        <li>
                            <button
                                    icon="till-noon-icon tnicon-Instagram"
                                    pButton
                                    type="button"
                                    [disabled]="!(studioData.instagramHandle && studioData.instagramHandle.length >0)"
                                    (click)="shareClicked('instagram', studioData.instagramHandle)"
                                    class="p-button-text tn-primary-button socialButton"
                            ></button>
                        </li>
                        <li>
                            <button
                                    (click)="socialModalVisible = !socialModalVisible"
                                    icon="pi pi-share-alt"
                                    pButton
                                    type="button"
                                    label="SHARE"
                                    class="p-button-raised p-button-rounded tn-primary-button"
                            ></button>
                        </li>
                    </ul>
                </ng-template>

            </div>

            <div class="col-12">

                <div class="grid grid-nogutter">
                    <div class="col-4 mobile-cat-type">
                        <span class="category-type">{{studioData.rooms.length}}</span>
                        <br>
                        <span class="tn-text-big">{{studioData.rooms.length > 1 ? 'Rooms' : 'Room'}}</span>
                    </div>
                    <div class="col-4" *ngIf = "availableServices.includes('recording')" >
                        <span class="category-type"><i class="till-noon-icon tnicon-Recording"></i></span>
                        <br>
                        <span class="tn-text-big">Recording</span>
                    </div>
                    <div class="col-4" *ngIf = "availableServices.includes('rehearsal')" >
                        <span class="category-type"><i class="till-noon-icon tnicon-Rehearsal"></i></span>
                        <br>
                        <span class="tn-text-big">Rehearsal</span>
                    </div>
                </div>

            </div>


            <div class="col-12">
                    <span
                            class="tn-text-small "
                    >
                    {{studioData.description | readMore:moreDescription:500}}
                    </span>


                    <div
                            class="flex justify-content-center md:justify-content-end pt-2"
                            *ngIf="studioData.description && studioData.description.length > 500"
                    >
                        <a (click)="moreDescription = !moreDescription"  class="tn-text-green tn-text-xs cursor-pointer">
                            <span *ngIf="!moreDescription">READ MORE</span>
                            <span *ngIf="moreDescription">READ LESS</span>
                            <i style="padding-left: 1rem;"
                               [ngClass]="{'pi-chevron-down' : !moreDescription , 'pi-chevron-up' : moreDescription}"
                               class="pi tn-text-xs"></i>
                        </a>
                    </div>
            </div>

            <div class="col-12">
                <app-tn-opening-hours [availability]="studioData.availability"></app-tn-opening-hours>
            </div>

            <div class="col-12">
                <span
                    class="tn-text-normal tn-text-green studioAddress"
                >
                    {{userHasLoggedIn() ? studioData.studioAddress : studioData.studioAddressShort}}
                </span>


                <button
                        *ngIf="studioCoordinates"
                        pButton
                        pRipple
                        type="button"
                        label="OPEN MAP"
                        icon="pi pi-external-link"
                        class="p-button-primary tn-primary-button mt-4 md:mt-0"
                        (click)="openGMaps()"
                ></button>

            </div>

            <div class="col-12" >

                <button
                        pButton
                        pRipple
                        type="button"
                        label="BOOK NOW"
                        icon="till-noon-icon tnicon-Wave"
                        class="p-button-primary tn-secondary-button"
                        (click)="startBooking()"
                ></button>
            </div>

        </div>
    </div>

    <div class="col-12 md:col-6 coverImage">



        <img
                [src]="(studioData.hasOwnProperty('logo') && studioData.logo) ? studioData.logo['link'] : 'assets/images/sample_studio.png'"
                alt="studioLogo"
                class="studioLogo"
        />



        <img
                [src]="(studioData.hasOwnProperty('cover') && studioData.cover) ? studioData.cover['link'] : 'assets/images/sample_studio.png'"
                alt="studioCover"
                class="studioCover lg:pt-8"
                width="100%"
                height="auto"
        />



        <button
                *ngIf="studioData.images.length > 1"
                icon="pi pi-images"
                iconPos="left"
                pButton
                pRipple
                type="button"
                label="View more photos"
                class="p-button-secondary viewMore tn-secondary-button"
                (click)="showMoreVisible = true"
        ></button>

        <p-galleria
                [value]="studioData.images"
                [(visible)]="showMoreVisible"
                [containerStyle]="{'max-width': '850px'}"
                [numVisible]="7"
                [circular]="true"
                [fullScreen]="true"
                [showItemNavigators]="true"
                [showThumbnails]="false"
                [baseZIndex]="100000"
        >
            <ng-template pTemplate="item" let-item>
                <img [src]="item['link']" [alt]="item.name" style="width: 100%; display: block;"/>
            </ng-template>
        </p-galleria>
    </div>


    <div class="col-12" *ngIf="instruments && instruments.length > 0">
        <p class="tn-text-big tn-text-green">
            Studio Instruments for Rent
        </p>

        <p-dataView #dvListingInst
                    [value]="instruments | viewMore:moreInstruments"
                    layout="grid">
            <ng-template let-instrument pTemplate="gridItem">
                <div class="col-6 md:col-6 lg:col-3">
                    <div class="equipment-list-item">

                        <app-tn-instrument
                                style="width: inherit; height:100%"
                                [instrument]="instrument"
                                [rentFlag]=false
                                [currency]="studioData.businessCurrency"
                        >
                        </app-tn-instrument>

                    </div>
                </div>
            </ng-template>

            <ng-template
                    pTemplate="footer"
            >
                <div
                        class="flex justify-content-center md:justify-content-end pt-2"
                        *ngIf="instruments && instruments.length > (isMobile ? 4 : 8)"
                >
                    <a (click)="moreInstruments = !moreInstruments"  class="tn-text-green cursor-pointer  tn-text-xs">
                        <span *ngIf="!moreInstruments">VIEW MORE</span>
                        <span *ngIf="moreInstruments">VIEW LESS</span>
                        <i style="padding-left: 1rem;"
                           [ngClass]="{'pi-chevron-down' : !moreInstruments , 'pi-chevron-up' : moreInstruments}"
                           class="pi  tn-text-xs"></i>
                    </a>
                </div>
            </ng-template>
        </p-dataView>

    </div>

    <div
            class="col-12 p-0 "
    >

        <p-tabView >
            <p-tabPanel  *ngFor="let room of studioData.rooms; let i = index"
                        [selected]="i == 0"
            >
                <ng-template pTemplate="header">
                    <div class="offers-badge" *ngIf="offersPerRoom[room.id].hasOffers"></div>
                    <span class="tn-title" >{{room.name}}</span>
                </ng-template>

                <div class="grid room-tab full-width-container " >
                    <!--ROOM SERVICES-->

                    <div class="col-12 md:col-4">
                        <p class="hidden md:block tn-text-big tn-text-green">
                            Services
                        </p>

                        <p-dataView #dvListingEq
                                    [value]="room?.services"
                                    styleClass="services-dv"
                                    layout="grid">
                            <ng-template let-service pTemplate="gridItem">
                                <div class="col-6">
                                    <div class="equipment-list-item">
                                        <app-tn-service
                                                [room]="room"
                                                [service]=service
                                                [darkMode]="false"
                                                [currency]="studioData.businessCurrency"
                                                [studioOffers]="offersPerRoom"
                                        ></app-tn-service>
                                    </div>
                                </div>
                            </ng-template>
                        </p-dataView>
                    </div>

                    <!--ROOM IMAGES-->
                    <div class="col-12 md:col-8">
                        <p-galleria
                                [value]="room.media"
                                [numVisible]="room.media.length"
                                [circular]="true"
                                [showItemNavigators]="true"
                                [showIndicators]="true"
                                [showThumbnails]="false"

                        >
                            <ng-template pTemplate="item" let-item>

                                <img
                                        [defaultImage]="'/assets/images/loading_icon.gif'"
                                        [lazyLoad]="item['link']"
                                        [alt]="item.name"
                                />

                            </ng-template>
                        </p-galleria>
                    </div>

                    <!--ROOM EQUIPMENT-->
                    <div class="col-12 room-equipment">
                        <p class="tn-text-big tn-text-green">
                            Equipment
                        </p>

                        <p-dataView #dvListingEq
                                    [value]="room?.equipment | viewMore: moreEquipment"
                                    styleClass="equipment-dv"
                                    layout="grid">
                            <ng-template let-equipment pTemplate="gridItem">
                                <div class="col-6 md:col-6 lg:col-3">
                                    <div class="equipment-list-item">


                                        <app-tn-equipment
                                                style="width: inherit; height:100%"
                                                [darkMode]="true"
                                                [equipment]=equipment
                                        >
                                        </app-tn-equipment>

                                    </div>
                                </div>
                            </ng-template>

                            <ng-template
                                    pTemplate="footer"
                            >
                                <div
                                        class="flex justify-content-center md:justify-content-end pt-2"
                                        *ngIf="room?.equipment && room.equipment.length >  (isMobile ? 4 : 8)"
                                >
                                    <a (click)="moreEquipment = !moreEquipment"  class="tn-text-green cursor-pointer tn-text-xs">
                                        <span *ngIf="!moreEquipment">VIEW MORE</span>
                                        <span *ngIf="moreEquipment">VIEW LESS</span>
                                        <i style="padding-left: 1rem;"
                                                [ngClass]="{'pi-chevron-down' : !moreEquipment , 'pi-chevron-up' : moreEquipment}"
                                                class="pi tn-text-xs"></i>
                                    </a>
                                </div>
                            </ng-template>
                        </p-dataView>
                    </div>


                </div>
            </p-tabPanel>
        </p-tabView>
    </div>


    <div class="col-12  full-width-container action-buttons"  >

        <div class="grid md:hidden full-width-container pl-3 pr-3">
            <div class="col-6">
                <button
                        pButton
                        pRipple
                        type="button"
                        icon = 'till-noon-icon tnicon-Arrow-Left'
                        [routerLink]="['/listing']"
                        label="BACK TO STUDIO SEARCH"
                        style="height:100%;width:100%;text-align:left"
                        class="p-button-primary tn-primary-button"
                ></button>
            </div>
            <div class="col-6">
                <button
                        pButton
                        pRipple
                        type="button"
                        label="BOOK NOW"
                        icon="till-noon-icon tnicon-Wave"
                        style="height:100%;width:100%;"
                        class="p-button-primary tn-secondary-button"
                        (click)="startBooking()"
                ></button>
            </div>
        </div>

        <div
                class="hidden md:flex"
            style="
                padding: 1rem 7.5rem !important;
                justify-content: space-between;
            "
        >

            <button
                    pButton
                    pRipple
                    type="button"
                    icon = 'till-noon-icon tnicon-Arrow-Left'
                    [routerLink]="['/listing']"
                    label="BACK TO STUDIO SEARCH"
                    class="p-button-primary tn-primary-button"
            ></button>

            <button
                    pButton
                    pRipple
                    type="button"
                    label="BOOK NOW"
                    icon="till-noon-icon tnicon-Wave"
                    class="p-button-primary tn-secondary-button"
                    (click)="startBooking()"
            ></button>

        </div>

    </div>

</div>

<p-dialog
        [(visible)]="reviewModal"
        [header]="studioName"
        [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
        [style]="{width: '43.75rem'}"
        [modal]="true"
        [blockScroll]="true"
        [dismissableMask]="true"
>
    <div class="grid">
        <div class="col-6">

            <p class="tn-title tn-text-green" style="float:left">All Reviews</p>
        </div>
        <div class="col-6">

            <div  style="float:right">
                <span
                        (click)="reviewModal = true"
                        class="cursor-pointer"
                >
                    <p-rating [(ngModel)]="studioRating" [readonly]="true" [cancel]="false" style="float:left;"></p-rating>
                    <span style="float:left;margin-left:10px;color : #00B6B2">({{studioRatings}})</span>
                            </span>
            </div>
        </div>

    </div>
    <p-scrollPanel [style]="{width: '100%', height: '500px', margin: '10px'}">

        <div class="grid">
            <div class="col-12 md:col-6 lg:col-12" *ngFor="let review of reviews | async; let i = index">
                <app-comment
                        [review]="review"
                ></app-comment>
            </div>
        </div>

    </p-scrollPanel>
</p-dialog>


<app-login-popup
        [display]="loginModalVisible"
        (closeModalEmitter)="closeModal($event)"
></app-login-popup>

<app-social-share
        [display]="socialModalVisible"
        (closeModalEmitter)="closeModal($event)"
></app-social-share>


