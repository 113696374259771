import {Component, EventEmitter, Input, Output} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {Router} from "@angular/router";
import {StudiosService} from "../../../services/studios.service";
import {ProfileService} from "../../../services/profile.service";
import {HelperService} from "../../../services/helper.service";

@Component({
  selector: 'app-tn-room-card',
  templateUrl: './tn-room-card.component.html',
  styleUrls: ['./tn-room-card.component.scss']
})
export class TnRoomCardComponent {



  @Input() room;
  @Input() selectedService;
  @Input() selected = false;
  @Input() currency = 'EUR';
  @Input() offerData;

  @Output() selectEventEmitter = new EventEmitter();

  public env = environment;
  public lowestPrice;
  public studioOffers;


  constructor(
      private helperService: HelperService,
  ) {
  }

  ngOnInit(): void {
    this.studioOffers = this.getBestOffersForRoom()
    if (this.selectedService) {
      const service = this.room.services.find(el => el.type === this.selectedService);
      this.lowestPrice = service.price;
    } else {
      this.room.services.forEach(el => {
        if (!this.lowestPrice) {
          this.lowestPrice = el.price;
        } else if (el.price < this.lowestPrice) {
          this.lowestPrice = el.price;
        }
      })
    }
  }

  selectRoom() {
    if (!this.selected) {
      this.selected = !this.selected;
      this.selectEventEmitter.emit(this.room);
    }
  }

  getCurrency( symbol = true ) {
    if (symbol) {
      return this.helperService.getCurrencySymbol(this.currency)
    } else {
      return this.currency;
    }
  }

  getBestOffersForRoom() {
    const res = {percentage: -1, amount: {cutoff: -1, payoff: 0, final: 0},}
    
    this.offerData.percentage?.forEach(el =>{
      if(el.rules.percentage > res.percentage) {
        res.percentage = el.rules.percentage
      }
    })
    
    this.offerData.amount?.forEach(el =>{
      const hourlyRate = el.hourlyRate
      const {cutoff, payoff} = el.rules;
      if(cutoff > res.amount.cutoff) {
        res.amount = {
          cutoff,
          payoff,
          final: (cutoff * hourlyRate) - payoff
        }
      }
    })
    

    return res;
  }


}
