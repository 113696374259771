<div class="grid"  *ngIf="bookings | async as bookingsData">

    <div class="col-12 md:hidden">
                <span
                        class="tn-text-small tn-text-green"
                >{{today | dateParser:'EEE dd,MMM yyyy'}}</span>
        <p class="tn-title"
           style="margin-top: 4px;margin-bottom: 8px;font-weight:400;"
        >Good {{timeOfDay | timeDay}}!</p>
        <p class="tn-title" style="font-weight:400;">Here's what happening today</p>
    </div>

    <div class="col-12 md:col-4 md:hidden">

        <ng-template ngFor let-child let-i="index" [ngForOf]="bookingTypes | filterBookingTypes:[selectedBookingType,0]">

            <div
                    (click)="selectedBookingType = child.code"
                    [ngClass]="{'booking-option-selected' : selectedBookingType === child.code}"
                    class="mb-4 px-3 flex align-items-center justify-content-start booking-option">
                <div class="option-chevron" >
                    <span class="tn-text-big">
                        <i style="padding: 1rem;"
                         [ngClass]="{'pi-chevron-down' : selectedBookingType !== child.code , 'pi-chevron-up' : selectedBookingType === child.code}"
                           [ngStyle]="{'color' : selectedBookingType !== child.code ? '#00B6B2' : '#FFFFFF'}"
                         class="pi tn-text-xs"
                        >
                        </i>
                    </span>
                </div>
                <div class="option-title">
                    <span class="tn-text-big" style="font-weight:600;"> {{child.name}} </span>
                </div>
                <div class="option-num" >
                    <span class="tn-text-big">{{child.amount}}</span>
                </div>
            </div>

        </ng-template>

    </div>

    <div class="col-12 md:col-4 hidden md:block">

        <ng-template ngFor let-child let-i="index" [ngForOf]="bookingTypes">

            <div
                    (click)="selectedBookingType = child.code"
                    [ngClass]="{'booking-option-selected' : selectedBookingType === child.code}"
                    class="mb-4 px-3 flex align-items-center justify-content-start booking-option">
                <div class="option-title">
                    <span class="tn-text-big" style="font-weight:600;"> {{child.name}} </span>
                </div>
                <div class="option-num" >
                    <span class="tn-text-big">{{child.amount}}</span>
                </div>
            </div>

        </ng-template>

    </div>

    <div class="col-12 lg:col-8">
        <div class="grid">

            <div class="col-12 hidden md:block">
                <span
                        class="tn-text-small tn-text-green"
                >{{today | dateParser:'EEE dd,MMM yyyy'}}</span>
                <p class="tn-title"
                        style="margin-top: 4px;margin-bottom: 8px;font-weight:400;"
                >Good {{timeOfDay | timeDay}}!</p>
                <p class="tn-title" style="font-weight:400;">Here's what happening today</p>
            </div>

            <div class="col-12">

                <p-dataView #dv
                            [value]="bookingsData | filterBookings:selectedBookingType"
                            [paginator]="true"
                            [rows]="9"
                            filterBy="invite.user.name"
                            [showFirstLastIcon]="false"
                            [loading]="loading"
                            layout="list">

                    <ng-template let-booking pTemplate="listItem">
                        <div class="col-12 booking-item">
                            <div class="product-list-item grid grid-nogutter">

                                <div class="col-12">
                                    <div class="grid">
                                        <div class="col-fixed" style="width:74px">
                                            <div class="bookingLogo">
                                                <img
                                                        [routerLink]="['/user' , booking.booker.id]"
                                                        [src]="booking.booker.avatar ? booking.booker.avatar['link'] : 'assets/images/sample_user.png'"
                                                        style="border-radius: 50%; vertical-align:middle;"
                                                        [alt]="booking.label"
                                                />
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="grid">

                                                <div class="col-12 md:col-8">
                                                    <div class="product-name">
                                                      <span
                                                              class="tn-text-big"
                                                              >{{booking.booker.name}}
                                                      </span>
                                                      <span class="tn-text-big" style="font-weight:400;"> {{booking.status | verbalizeBookingStatus }} a </span>
                                                      <span class="tn-text-big">{{booking.service.type}}</span>
                                                      <br>
                                                      <p class="tn-text-normal tn-text-green" style="font-weight:400;">{{ booking.bookingType === 'offline' ? 'Reserved' : 'Requested' }} on {{booking.createdAt | dateParser}}</p>
                                                    </div>
                                                </div>
                                                <div class="hidden md:flex md:flex-row-reverse md:col-4" >
                                                    <a
                                                            (click)="booking.details = !booking.details"
                                                            class="tn-text-green tn-text-xs cursor-pointer"
                                                            style="
                                                                font-size: 0.75rem;
                                                                font-style: normal;
                                                                font-weight: 400;
                                                                line-height: 1.1875rem;
                                                                letter-spacing: 0.015rem;
                                                            "
                                                    >
                                                        <span class="tn-text-xs">{{isMobile ? '' : ( booking.details ? 'View Less' : 'View More')}}</span>
                                                        <i style="padding-left: 1rem;"
                                                           [ngClass]="{'pi-chevron-down' : !booking.details , 'pi-chevron-up' : booking.details}"
                                                           class="pi tn-text-xs"></i>
                                                    </a>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="col md:col-12 booking-notes hidden md:block">

                                    <span class="tn-text-green tn-text-big"><i class="till-noon-icon tnicon-Location"></i>{{booking.studio.name}}</span>
                                    <span class="tn-text-simple" > <i class="till-noon-icon tnicon-Calendar-Month"></i>{{booking.bookingDate | dateParser:'dd / MM / yyyy'}}</span>
                                    <span class="tn-text-simple" > <i class="till-noon-icon tnicon-Schedule"></i>{{booking.bookingDate | dateParser:'HH:mm'}}</span>
                                    <span class="tn-text-simple" > <i class="material-icons-outlined">timelapse</i>{{booking.duration}} hour(s)</span>
                                    <span class="tn-text-simple"
                                          *ngIf="booking.bookingType === 'solo'"
                                    > <i class="till-noon-icon tnicon-User-Outlined"></i> Solo
                                    </span>
                                    <span class="tn-text-simple text-ellipsis"
                                          *ngIf="booking.bookingType === 'band'"
                                    > <i class="till-noon-icon tnicon-Users"></i> {{booking.team.name}}
                                    </span>

                                    <span class="tn-text-simple"
                                          *ngIf="(booking.status === 'planning' || booking.status === 'pending-confirmation' || booking.status === 'studio-declined') && booking.recurringDates && booking.recurringDates.length > 1 "
                                    > <i class="pi pi-history"></i> {{booking.recurringDates.length - 1}} more dates</span>
                                </div>

                                <div class="col md:col-12 booking-notes md:hidden">

                                    <span class="tn-text-green tn-text-big"><i class="till-noon-icon tnicon-Location"></i>{{booking.studio.name}}</span>
                                    <br>
                                    <span class="tn-text-simple pb-3"
                                          *ngIf="booking.bookingType === 'solo'"
                                    > <i class="till-noon-icon tnicon-User-Outlined"></i> Solo
                                    </span>
                                    <span class="tn-text-simple text-ellipsis pb-3"
                                          *ngIf="booking.bookingType === 'band'"
                                    > <i class="till-noon-icon tnicon-Users"></i> {{booking.team.name}}
                                    </span>

                                    <br>
                                    <span class="tn-text-simple" > <i class="till-noon-icon tnicon-Calendar-Month"></i>{{booking.bookingDate | dateParser:'dd / MM / yyyy'}}</span>
                                    <span class="tn-text-simple ml-2 mr-2" > <i class="till-noon-icon tnicon-Schedule"></i>{{booking.bookingDate | dateParser:'HH:mm'}}</span>
                                    <span class="tn-text-simple" > <i class="material-icons-outlined mr-1">timelapse</i>{{booking.duration}} hour(s)</span>

                                </div>


                                <div class="col-12 md:hidden" *ngIf="!booking.details">
                                    <div
                                            class="flex justify-content-end"
                                    >
                                        <a (click)="booking.details = !booking.details"
                                           class="tn-text-green tn-text-small cursor-pointer"
                                           style="
                            font-size: 0.75rem;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 1.1875rem;
                            letter-spacing: 0.015rem;
                        "
                                        >
                                            <span *ngIf="!booking.details">VIEW MORE</span>
                                            <span *ngIf="booking.details">VIEW LESS</span>
                                            <i style="padding-left: 1rem;"
                                               [ngClass]="{'pi-chevron-down' : !booking.details , 'pi-chevron-up' : booking.details}"
                                               class="pi"></i>
                                        </a>
                                    </div>
                                </div>



                                <div class="col-12 bookingDetails" *ngIf="booking.details" style="padding-top: 20px; padding-bottom: 0px;">


                                    <div class="grid md:hidden">

                                        <div
                                             [ngClass]="{'col-6' : booking.bookingType !== 'band' , 'col-4' :  booking.bookingType === 'band' }"
                                        >
                                            <p class="tn-text-simple tn-text-green">BOOKING ID</p>
                                            <span *ngIf="booking.shortId" class="tn-text-simple">#{{booking.shortId}}</span>
                                            <span *ngIf="!booking.shortId" class="tn-text-simple">#xxxxxxx</span>
                                        </div>
                                        <div
                                                [ngClass]="{'col-3' : booking.bookingType !== 'band' , 'col-4' :  booking.bookingType === 'band' }"
                                        >
                                            <p class="tn-text-simple tn-text-green">PRICE</p>
                                            <span class="tn-text-simple">{{booking.totalPrice}}{{getCurrency(booking)}}</span>
                                        </div>
                                        <div class="col-3"
                                             [ngClass]="{'hidden' : booking.bookingType !== 'band' , 'col-4' :  booking.bookingType === 'band'}"

                                        >
                                            <p class="tn-text-simple tn-text-green">MEMBERS</p>
                                            <ng-template
                                                    [ngIf]="booking.bookingType === 'band'"
                                            >
                                                <span class="tn-text-simple">{{booking.members.length}}</span>
                                            </ng-template>
                                        </div>
                                        <div class="col-6">
                                            <p class="tn-text-simple tn-text-green">ROOM</p>
                                            <span class="tn-text-simple">{{booking.room.name}}</span>
                                        </div>
                                        <div class="col-6">
                                            <p class="tn-text-simple tn-text-green">CONTACT PHONE</p>
                                            <span class="tn-text-simple">{{booking.studio.landLine}}</span>
                                        </div>
                                        <div class="col-6">
                                            <p class="tn-text-simple tn-text-green">INSTRUMENTS</p>
                                            <span class="tn-text-simple">{{booking.primaryMusicRoles.join(', ')  | titlecase}}</span>
                                        </div>
                                        <div class="col-6"
                                             [ngClass]="{'hidden' : booking.instruments.length === 0}"
                                        >
                                            <p class="tn-text-simple tn-text-green">RENTALS</p>
                                            <ng-template
                                                    [ngIf]="booking.instruments.length > 0"
                                            >
                                                <span class="tn-text-simple">{{booking.instrumentsLabels}}</span>
                                            </ng-template>
                                        </div>
                                        <div class="col-12">
                                            <p class="tn-text-simple tn-text-green">NOTES</p>
                                            <span style="overflow-wrap: break-word;line-break: anywhere;" class="tn-text-small">{{booking.notes[0]?.message.substring(0,30)}}</span>
                                        </div>

                                    </div>



                                    <div class="grid hidden md:flex">
<!--                                        <div class="col-4 md:col-4 lg:col-2" *ngIf="booking.bookingType === 'band'">-->
<!--                                            <p class="tn-text-simple tn-text-green">Band</p>-->
<!--                                            <span class="tn-text-simple">{{booking.team.name}}</span>-->
<!--                                        </div>-->
<!--                                        <div class="col-4 md:col-4 lg:col-2" *ngIf="booking.bookingType === 'solo'">-->
<!--                                            <p class="tn-text-simple tn-text-green">Solo</p>-->
<!--                                            <span class="tn-text-simple">{{booking.user.name}}</span>-->
<!--                                        </div>-->
                                        <div class="col-4 md:col-4 lg:col-4">
                                            <p class="tn-text-simple tn-text-green">Booking id</p>
                                            <span *ngIf="booking.shortId" class="tn-text-simple">#{{booking.shortId}}</span>
                                            <span *ngIf="!booking.shortId" class="tn-text-simple">#xxxxxxx</span>
                                        </div>
                                        <div class="col-6 md:col-4 lg:col-4">
                                            <p class="tn-text-simple tn-text-green">Room</p>
                                            <span class="tn-text-simple">{{booking.room.name}}</span>
                                        </div>
                                        <div class="col-6 md:col-4 lg:col-4">
                                            <p class="tn-text-simple tn-text-green">Contact Phone</p>
                                            <span class="tn-text-simple">{{booking.studio.landLine}}</span>
                                        </div>
<!--                                        <div class="col-4 md:col-4 lg:col-2">-->
<!--                                            <p class="tn-text-simple tn-text-green">Service</p>-->
<!--                                            <span *ngIf="booking.service" class="tn-text-simple">{{booking.service.type}}</span>-->
<!--                                        </div>-->

                                        <div class="col-8">

                                            <div class="grid">

                                                <div class="col-6 md:col-6">
                                                    <p class="tn-text-simple tn-text-green">Price</p>
                                                    <span class="tn-text-simple">{{booking.totalPrice}}{{getCurrency(booking)}}</span>
                                                </div>
                                                <div class="col-6 md:col-6">
                                                    <p class="tn-text-simple tn-text-green">Instruments</p>
                                                    <span class="tn-text-simple">{{booking.primaryMusicRoles.join(', ')  | titlecase}}</span>
                                                </div>
                                                <div class="col-6 md:col-6"
                                                     [ngClass]="{'hidden' : booking.bookingType !== 'band'}"

                                                >
                                                    <p class="tn-text-simple tn-text-green">Members</p>
                                                    <ng-template
                                                            [ngIf]="booking.bookingType === 'band'"
                                                    >
                                                        <span class="tn-text-simple">{{booking.members.length}}</span>
                                                    </ng-template>
                                                </div>


                                                <div class="col-6 md:col-6"
                                                     [ngClass]="{'hidden' : booking.instruments.length === 0}"
                                                >
                                                    <p class="tn-text-simple tn-text-green">Rentals</p>
                                                    <ng-template
                                                            [ngIf]="booking.instruments.length > 0"
                                                    >
                                                        <span class="tn-text-simple">{{booking.instrumentsLabels}}</span>
                                                    </ng-template>
                                                </div>


                                            </div>


                                        </div>




                                        <div class="col-12 md:col-4 ">
                                            <p class="tn-text-simple tn-text-green">Notes</p>
                                            <span style="overflow-wrap: break-word;line-break: anywhere;" class="tn-text-small">{{booking.notes[0]?.message.substring(0,30)}}</span>
                                        </div>

<!--                                        <div class="col-3">-->



<!--                                            <div class="col-12 md:col-4 ">-->
<!--                                                <p class="tn-text-simple tn-text-green">Notes</p>-->
<!--                                                <span style="overflow-wrap: break-word;line-break: anywhere;" class="tn-text-small">{{booking.notes[0]?.message.substring(0,30)}}</span>-->
<!--                                            </div>-->
<!--                                        </div>-->
                                    </div>

                                    <div class="col-12 " style="padding: 1rem 0px;">
                                        <div class="grid grid-nogutter next-buttons" style="min-height: 48px;">

                                            <a
                                                    (click)="seeDetails(booking)"
                                                    class="tn-text-green tn-text-small cursor-pointer hidden md:block "
                                                    style="
                                                        font-size: 0.625rem;
                                                        font-style: normal;
                                                        font-weight: 600;
                                                        line-height: normal;
                                                        letter-spacing: 0.01875rem;
                                                        flex: auto;
                                                    "
                                            >
                                                <span >VIEW DETAILS</span>
                                                <i style="padding-left: 1rem;"
                                                   class="pi pi-chevron-right"></i>
                                            </a>

                                            <button
                                                    *ngIf="booking.canDelete"
                                                    pButton
                                                    type="button"
                                                    [label]="'Cancel Booking'"
                                                    [icon]="'pi pi-times'"
                                                    class="p-button-text tn-danger-button hidden md:inline-flex"
                                                    (click)="deleteBooking(booking)"
                                            ></button>

                                            <div class="col-12 md:hidden">
                                                <button
                                                        pButton
                                                        type="button"
                                                        label="View Details"
                                                        class="p-button-text md:hidden col-12"
                                                        (click)="seeDetails(booking)"
                                                ></button>
                                            </div>

                                            <div class="col-12 md:hidden">

                                                <button
                                                        *ngIf="booking.canDelete"
                                                        pButton
                                                        type="button"
                                                        [label]="'Cancel Booking'"
                                                        [icon]="'pi pi-times'"
                                                        class="p-button-text tn-danger-button col-12"
                                                        (click)="deleteBooking(booking)"
                                                ></button>
                                            </div>


                                            <ng-template
                                                [ngIf]="booking.needResponseAction"
                                            >
                                                <button
                                                        label="ACCEPT REQUEST"
                                                        pButton type="button"
                                                        icon="pi pi-check"
                                                        class="p-button-text tn-secondary-button hidden md:inline-flex"
                                                        (click)="confirmBooking(booking, true)"
                                                ></button>
                                                <button
                                                        label="DECLINE REQUEST"
                                                        [icon]="'pi pi-times'"
                                                        class="p-button-text tn-danger-button hidden md:inline-flex md:ml-3"
                                                        pButton type="button"
                                                        (click)="confirmBooking(booking, false)"
                                                ></button>



                                                <div class="col-12 md:hidden">
                                                    <button
                                                            label="ACCEPT REQUEST"
                                                            pButton type="button"
                                                            icon="pi pi-check"
                                                            class="p-button-text tn-secondary-button "
                                                            style="width:100%;"
                                                            (click)="confirmBooking(booking, true)"
                                                    ></button>
                                                </div>

                                                <div class="col-12 md:hidden">
                                                    <button
                                                            label="DECLINE REQUEST"
                                                            [icon]="'pi pi-times'"
                                                            class="p-button-text tn-danger-button "
                                                            pButton type="button"
                                                            style="width:100%;"
                                                            (click)="confirmBooking(booking, false)"
                                                    ></button>
                                                </div>


                                            </ng-template>

                                        </div>

                                        <div
                                                class="flex justify-content-end md:hidden"
                                        >
                                            <a (click)="booking.details = !booking.details"
                                               class="tn-text-green tn-text-small cursor-pointer"
                                               style="
                            font-size: 0.75rem;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 1.1875rem;
                            letter-spacing: 0.015rem;
                        "

                                            >
                                                <span *ngIf="!booking.details">VIEW MORE</span>
                                                <span *ngIf="booking.details">VIEW LESS</span>
                                                <i style="padding-left: 1rem;"
                                                   [ngClass]="{'pi-chevron-down' : !booking.details , 'pi-chevron-up' : booking.details}"
                                                   class="pi"></i>
                                            </a>
                                        </div>


                                    </div>



                                </div>
                            </div>
                        </div>
                    </ng-template>

                </p-dataView>

            </div>
        </div>
    </div>



    <div class="col-12 md:col-4 md:hidden">

        <ng-template ngFor let-child let-i="index" [ngForOf]="bookingTypes | filterBookingTypes:[selectedBookingType,1]">

            <div
                    (click)="selectedBookingType = child.code"
                    [ngClass]="{'booking-option-selected' : selectedBookingType === child.code}"
                    class="mb-4 px-3 flex align-items-center justify-content-start booking-option">
                <div class="option-chevron" >
                    <span class="tn-text-big">
                        <i style="padding: 1rem;"
                           [ngClass]="{'pi-chevron-down' : selectedBookingType !== child.code , 'pi-chevron-up' : selectedBookingType === child.code}"
                           [ngStyle]="{'color' : selectedBookingType !== child.code ? '#00B6B2' : '#FFFFFF'}"
                           class="pi tn-text-xs"
                        >
                        </i>
                    </span>
                </div>
                <div class="option-title">
                    <span class="tn-text-big" style="font-weight:600;"> {{child.name}} </span>
                </div>
                <div class="option-num" >
                    <span class="tn-text-big">{{child.amount}}</span>
                </div>
            </div>

        </ng-template>

    </div>

</div>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>

