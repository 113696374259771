<p-card header="{{studio.name}}" styleClass="p-card-shadow"
        subheader="{{ userLoggedIn ? studio.studioAddress : studio.studioAddressShort}}">
    <p><strong>Price range | </strong>{{studio.minPrice}}{{getCurrency()}}-{{studio.maxPrice}}{{getCurrency()}}/hr</p>
    <p><strong>Service | {{services.join(', ')}}</strong></p>
    <p><strong>Rooms | </strong> {{studio.rooms.length}}</p>
    <ng-template pTemplate="footer">
        <span style="text-align:left"
        *ngIf="userLoggedIn">
            <button
                    class="p-button-text"
                    icon="pi pi-external-link"
                    iconPos="left"
                    style="padding: 0.75rem"
                    (click)="openGMaps(studio)"
                    label="Open Maps"
                    pButton
                    pRipple
                    type="button"
            ></button>
        </span>
        <span >
            <button class="p-button-text"
                    style="padding: 0.75rem" (click)="callbackFunction(studio)" label="View Studio" pButton pRipple
                    type="button"></button>
        </span>
    </ng-template>
</p-card>
