import {Component, EventEmitter, Input, Output} from '@angular/core';
import {InstrumentAndEquipmentService} from "../../../services/instruments.equipments.service";
import {environment} from "../../../../environments/environment";
import {ConfirmationService, MenuItem, MessageService} from "primeng/api";
import {UserService} from "../../../services/user.service";
import {BandsService} from "../../../services/bands.service";
import {HelperService} from "../../../services/helper.service";
import {ProfileService} from "../../../services/profile.service";

@Component({
  selector: 'app-tn-band-simple',
  templateUrl: './tn-band-simple.component.html',
  styleUrls: ['./tn-band-simple.component.scss']
})
export class TnBandSimpleComponent {

  @Input() band;
  @Input() invite: any;
  @Output() bandSelectEmitter = new EventEmitter();
  @Input() selected = false;
  @Input() darkMode = false;
  @Input() showMenu = false;
  @Input() userId: string;
  public env = environment;
  public items: MenuItem[] = []
  public submitted = false;
  public bandDialog = false;

  public memberDialog = false;
  public memberEmail: string;
  public memberSubmitted = false;

  public editNameDialog = false;
  public bandName: string;
  public editNameSubmitted = false;
  constructor(
      private confirmationService: ConfirmationService,
      private userService: UserService,
      private profileService: ProfileService,
      private helperService: HelperService,
      private messageService: MessageService,
      private bandsService: BandsService,
  ) {
  }

  ngOnInit(): void {

    const userRole = this.band.members.filter(member => member.user.id === this.userId)[0]?.role;

    this.items = !this.invite ? [
      {
        label: 'Invite new Member',
        command: event => this.openInviteMemberModal()
      },
      {
        label: 'Edit name',
        command: event => this.openEditNameModal(),
        visible: userRole === 'admin'
      },
      {
        label: 'Leave Band',
        command: event => this.leaveBand(this.band),
      },
      {
        label: 'Delete Band',
        command: event => this.deleteBand(this.band),
        visible: userRole === 'admin',
      }
    ] : [
      {
        label: 'Accept Invite',
        command: event => this.acceptInvite()
      },
      {
        label: 'Decline Invite',
        command: event => this.declineInvite(),
      },

    ];

    this.band.avatar = this.band.media.reverse().find((media => media.metadata.type === 'avatar'));

  }

  selectBand() {
    this.bandSelectEmitter.emit(this.band)
  }

  saveBand() {
    if (this.bandName.length > 0) {
      this.bandsService.editBand(this.band.id , {name: this.bandName}).then((data) => {
        this.messageService.add({
          key: 'globalToast', severity: 'success', summary: 'Successful', detail: 'Band Edited', life: 3000});
      });
      this.band.name = this.bandName;
    }
    this.editNameDialog = false;
    this.bandName = '';
    this.editNameSubmitted = true;
  }

  leaveBand(band) {
    if (band.members.length === 1) {
      this.confirmationService.confirm({
        message: 'You are the last member of the band! Are you sure you want to leave?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.userService.leaveUserBand(this.userId, band.id).then((data) => {
            this.messageService.add({
              key: 'globalToast', severity: 'success', summary: 'Successful', detail: 'You left the band', life: 3000
            });
          });
        }
      });
    } else {
      this.confirmationService.confirm({
        message: 'Are you sure you want to leave?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.userService.leaveUserBand(this.userId, band.id).then((data) => {
            this.messageService.add({
              key: 'globalToast', severity: 'success', summary: 'Successful', detail: 'You left the band', life: 3000
            });
          });
        }
      });
    }
  }

  deleteBand(band) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this band?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        if (band.name && band.name.trim()) {
          this.bandsService.deleteBand(band.id)
              .then((result) => {
                this.messageService.add({severity: 'success', summary: 'Successful', detail: 'Band Deleted', life: 3000});
                band = {};
                this.userService.fetchUserBands(this.userId);
              });
        }
      }
    });
  }

  inviteMember() {
    if (this.memberEmail.trim().length > 0) {
      this.memberSubmitted = true;
      this.helperService.checkUserExists(this.memberEmail)
          .then((data :any) => {
            if (data.exists === true) {
              return this.bandsService.inviteBandMemberByMail(this.band.id , this.memberEmail)
            } else {
              return this.helperService.inviteUserToBandBeat(this.profileService.getUserData().name , this.memberEmail, this.band.id)
            }
          })
          .then((data) => {
            this.messageService.add({
              key: 'globalToast', severity: 'success', summary: 'Successful', detail: 'Invite Sent', life: 3000});
            this.memberEmail = '';
            this.memberDialog = false;
            this.bandsService.fetchBandInvites(this.band.id);
          })
          .catch((err) => {
            this.messageService.add({
              key: 'globalToast', severity: 'error', summary: 'Error', detail: err.error.message, life: 3000});
            this.memberEmail = '';
            this.memberDialog = false;
          });
    }
  }


  acceptInvite() {
    this.userService.acceptUserInvite(this.userId , this.invite.id).then((data) => {
      this.messageService.add({
        key: 'globalToast', severity: 'success', summary: 'Successful', detail: 'Invite Accepted', life: 3000});
      this.userService.fetchUserBands(this.userId);
    });
  }

  declineInvite() {

    this.userService.declineUserInvite(this.userId , this.invite.id).then((data) => {
      this.messageService.add({
        key: 'globalToast', severity: 'success', summary: 'Successful', detail: 'Invite Declined', life: 3000});
    });
  }







  openInviteMemberModal() {
    this.memberEmail = '';
    this.memberSubmitted = false;
    this.memberDialog = true;
  }
  openEditNameModal() {
    this.bandName = '';
    this.editNameSubmitted = false;
    this.editNameDialog = true;
  }
  hideEditNameModal() {
    this.bandName = '';
    this.editNameDialog = false;
    this.editNameSubmitted = false;
  }
  hideInviteMemberModal() {
    this.memberEmail = '';
    this.memberDialog = false;
    this.memberSubmitted = false;
  }

}
