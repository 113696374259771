<app-menu *ngIf="helperService.isMobile()"></app-menu>

<div class="grid bandbeatPage" *ngIf="studios | async as studiosData">
  <div class="col-12 md:col-6">
    <div class="flex flex-column gap-2">
      <span class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <p-autoComplete
          type="text"
          pInputText
          id="searchName"
          [inputStyle]="{ width: '100%' }"
          [panelStyle]="{ width: '100%', border: '1px solid #00B6B2' }"
          [(ngModel)]="searchText"
          placeholder="Search by Name"
          [suggestions]="autoCompleteResults | async"
          (completeMethod)="searchStudio($event)"
          (onSelect)="seeDetails($event)"
          field="name"
        >
          <ng-template let-searchedStudio pTemplate="item">
            <div
              class="flex align-items-center gap-2"
              *ngIf="!searchedStudio.emptyResult"
            >
              <img
                id="cover"
                style="width: 4rem; height: 4rem; border-radius: 50%"
                alt="Card"
                [src]="
                  searchedStudio.hasOwnProperty('cover') && searchedStudio.cover
                    ? searchedStudio.cover['link']
                    : 'assets/images/sample_studio.png'
                "
              />
              <div>
                <span class="tn-text-big">{{ searchedStudio.name }}</span>
                <div class="tn-text-normal">
                  {{ searchedStudio.studioAddress }}
                </div>
              </div>
            </div>

            <div
              class="flex align-items-center gap-2"
              *ngIf="searchedStudio.emptyResult"
            >
              <div>
                <span class="tn-text-big">No result</span>
              </div>
            </div>
          </ng-template>
        </p-autoComplete>
      </span>
    </div>
  </div>

  <div class="hidden md:block md:col-6"></div>

  <div class="col-12">
    <p class="keyword tn-title-big">
      <span style="font-weight: 400">Find a </span
      ><span class="tn-title-big">studio</span>
    </p>
  </div>

  <div class="md:hidden col-12">
    <button
      style="width: 100%"
      pButton
      pRipple
      label="SEARCH WITH FILTERS"
      icon="pi pi-arrow-right"
      iconPos="right"
      type="button"
      class="p-button-raised p-button-rounded p-button-lg tn-primary-button"
      (click)="searchWithFilters($event)"
    ></button>
  </div>

  <div class="hidden md:block col-3">
    <div class="flex flex-column gap-2">
      <label for="bookLocation">Location</label>
      <input
        id="bookLocation"
        #locationSearch
        inputId="bookLocation"
        type="text"
        pInputText
        placeholder=""
        [(ngModel)]="bookLocation"
      />
    </div>
  </div>

  <div class="hidden md:block col-3">
    <div class="flex flex-column gap-2">
      <label for="bookDate">Date</label>
      <p-calendar
        inputId="bookDate"
        id="bookDate"
        dateFormat="dd-mm-yy"
        [minDate]="currentDate"
        [maxDate]="maxDate"
        [readonlyInput]="true"
        firstDayOfWeek="1"
        [(ngModel)]="bookDate"
        (onSelect)="buildAvailabilityOptions()"
      ></p-calendar>
    </div>
  </div>

  <div class="hidden md:block col-3">
    <div class="flex flex-column gap-2">
      <label for="bookTime">Start Time</label>
      <p-dropdown
        id="bookTime"
        [options]="availabilityOptions"
        [autoDisplayFirst]="false"
        [(ngModel)]="bookTime"
      ></p-dropdown>
    </div>
  </div>

  <div class="md:block col-3" *ngIf="!helperService.isMobile()">
    <div
      class="field clearField"
      style="justify-content: flex-end; display: flex"
    >
      <button
        pButton
        type="button"
        [ngClass]="'clearAll'"
        [label]="'CLEAR ALL FILTERS'"
        [icon]="'pi pi-times'"
        class="p-button-text"
        (click)="fetchAllStudios()"
      ></button>
    </div>
  </div>

  <!--    <div class="col-4 md:col-3">-->
  <!--        <div class="flex flex-column gap-2">-->
  <!--            <label for="searchCountry">Country</label>-->
  <!--            <p-dropdown-->
  <!--                    inputId="searchCountry"-->
  <!--                    id="searchCountry"-->
  <!--                    optionValue="label"-->
  <!--                    [options]="countries"-->
  <!--                    [(ngModel)]="selectedCountry"-->
  <!--                    placeholder="Select a Country"-->
  <!--            >-->
  <!--                <ng-template let-country pTemplate="item">-->
  <!--                    <div class="flex align-items-center gap-2">-->
  <!--                        <div> <span>{{country.emoji}}</span> {{ country.name }}</div>-->
  <!--                    </div>-->
  <!--                </ng-template>-->
  <!--            </p-dropdown>-->
  <!--        </div>-->
  <!--    </div>-->

  <div class="hidden md:block col-3">
    <div class="flex flex-column gap-2">
      <label for="bookDuration">Duration</label>
      <p-dropdown
        id="bookDuration"
        [options]="availableDurations"
        [(ngModel)]="bookDuration"
        [autoDisplayFirst]="false"
        optionLabel="label"
        optionValue="value"
      ></p-dropdown>
    </div>
  </div>

  <div class="col-4 md:col-3 hidden md:block">
    <div class="flex flex-column gap-2">
      <label for="searchService">Service</label>
      <p-dropdown
        id="searchService"
        placeholder="Service"
        [options]="availableServices"
        [(ngModel)]="selectedService"
        optionLabel="name"
        optionValue="code"
      ></p-dropdown>
    </div>
  </div>

  <div class="col-4 md:col-3 hidden md:block">
    <div class="flex flex-column gap-2">
      <label for="searchSorting">Sorting</label>
      <p-dropdown
        id="searchSorting"
        [options]="availableSortings"
        [(ngModel)]="selectedSorting"
        (onChange)="changeSorting(); changePage()"
        optionLabel="name"
        optionValue="code"
      ></p-dropdown>
    </div>
  </div>

  <div
    class="hidden md:flex col-3"
    style="justify-content: flex-end; flex-direction: column"
  >
    <button
      style="width: 100%"
      pButton
      pRipple
      label="SEARCH"
      type="button"
      class="p-button-raised p-button-rounded p-button-lg tn-primary-button"
      (click)="searchStudios($event)"
    ></button>
  </div>

  <div class="col-12">
    <span class="tn-title">
      <span style="font-weight: 400"
        >We found
        <span class="keyword">{{ (studiosData?.results).length }}</span> music
        studios </span
      ><span class="tn-title">near you</span>.
    </span>
  </div>

  <div class="col-12 md:hidden">
    <div class="flex flex-column gap-2">
      <label for="searchSorting">Sorting</label>
      <p-dropdown
        id="searchSorting"
        [options]="availableSortings"
        [(ngModel)]="selectedSorting"
        (onChange)="changeSorting(); changePage()"
        optionLabel="name"
        optionValue="code"
      ></p-dropdown>
    </div>
  </div>

  <div class="col-12 full-width-container">
    <button
      pButton
      class="tn-primary-button-button mapView-toggle"
      type="button"
      icon="till-noon-icon tnicon-Map"
      iconPos="left"
      [label]="!mapView ? 'OPEN MAP' : 'CLOSE MAP'"
      (click)="mapView = !mapView"
    ></button>
    <p-gmap
      #gmap
      [ngStyle]="{ height: mapView ? '800px' : '10rem' }"
      [options]="mapOptions"
      [overlays]="studiosData?.results | mapOverlays"
      [style]="{ width: '100%', height: mapView ? '800px' : '10rem' }"
      (onOverlayClick)="handleOverlayClick($event)"
      (onMapClick)="handleOverlayClick($event)"
      (onMapReady)="mapReady(gmap.getMap())"
    ></p-gmap>
  </div>

  <div class="col-12" *ngIf="!mapView">
    <p-dataView
      #studioDataView
      [value]="studiosData?.results | sortingListing : selectedSorting"
      [paginator]="true"
      [pageLinks]="5"
      [rows]="9"
      [showFirstLastIcon]="false"
      [loading]="loading"
      layout="grid"
    >
      <ng-template let-studio pTemplate="gridItem">
        <div [ngClass]="'col-12 md:col-6 lg:col-4'">
          <div class="product-list-item">
            <app-tn-studio-card
              style="width: 100%; cursor:pointer;"
              [studio]="studio"
            ></app-tn-studio-card>
          </div>
        </div>
      </ng-template>
    </p-dataView>
  </div>
</div>

<p-dialog
  *ngIf="selectedMarker"
  showEffect="fade"
  [(visible)]="dialogVisible"
  [style]="{ width: '300px' }"
>
  <app-studio-simple
    style="width: inherit"
    [callbackFunction]="seeDetails"
    [studio]="selectedMarker"
    [openGMaps]="openGMaps"
  ></app-studio-simple>
</p-dialog>

<!--<p-dialog-->
<!--        [(visible)]="newRegistration"-->
<!--        [breakpoints]="{'1620px': '35vw', '1280px': '50vw', '960px': '70vw', '640px': '100vw'}"-->
<!--        [style]="{width: '30vw'}"-->
<!--        [draggable]="false"-->
<!--        [resizable]="false"-->
<!--        [modal]="true"-->
<!--        [blockScroll]="true"-->
<!--        [dismissableMask]="false"-->
<!--        [closable]="true"-->
<!--        (onHide)="newRegistration = false"-->
<!--&gt;-->

<!--    <div class="grid grid-nogutter" style="display:block;">-->
<!--        <div class="row">-->

<!--            <div class="col-12 pb-4 pl-4 pr-4 text-center md:text-left flex align-items-center ">-->
<!--                <section style="width:100%">-->
<!--                    <h1 class="welcomeMessage" style="text-align: center;" >Welcome to <span class="keyword">Band</span>beat</h1>-->

<!--                    <div class="mt-4 mb-4" style="text-align:center;">-->
<!--                        <button-->
<!--                                pButton-->
<!--                                type="button"-->
<!--                                label="Meanwhile you can set up your Profile"-->
<!--                                (click)="goToWizard()"-->
<!--                        ></button>-->
<!--                    </div>-->

<!--                </section>-->
<!--            </div>-->

<!--        </div>-->

<!--    </div>-->
<!--</p-dialog>-->
