import { Injectable } from '@angular/core';
import {HelperService} from './helper.service';
import {ProfileService} from './profile.service';
import {LocalStorageService} from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private userTokens: any = {};

  constructor(
    private helperService: HelperService,
    private profileService: ProfileService,
    private localSt: LocalStorageService,
  ) {
    if (this.localSt.retrieve('tokens')) {
      Object.assign(this.userTokens, this.localSt.retrieve('tokens'));
    }
  }

  loginUser(email: string, password: string) {

    return this.helperService.postAction(
      '/auth/login',
      {email , password}
    ).toPromise()
      .then((data) => {
        if (data.user.role === 'partner') {
          throw new Error('test');
        } else {
          this.storeData(data);
          return data;
        }
      });

  }

  registerUser(
      name: string,
      country: string,
      email: string,
      password: string,
      phoneNumber: number,
      role?: string,
      termsAndConditionAccepted?: boolean,
      referralCode?: string,
      teamId?: string,
  ) {

    return this.helperService.postAction(
      '/auth/register',
      {name , email, password, phoneNumber, role, address: {country}, termsAndConditionAccepted, referralCode, team : teamId}
    ).toPromise()
      .then((data) => {
        this.storeData(data);
        return data;
      });

  }

  registerGoogleUser(
      name: string,
      lastName: string,
      email: string,
      googleId: string,
      googleToken: string,
      photoUrl: string,
      teamId?: string,
  ) {

    return this.helperService.postAction(
        '/auth/continue-with/google',
        {name , lastName,  email, googleId, googleToken, photoUrl, team : teamId}
    ).toPromise()
        .then((data) => {
          this.storeData(data);
          return data;
        });
  }

  registerFacebookUser(
      name: string,
      lastName: string,
      email: string,
      facebookId: string,
      facebookToken: string,
      photoUrl: string,
      teamId?: string,
  ) {

    return this.helperService.postAction(
        '/auth/continue-with/facebook',
        {name , lastName,  email, facebookId, facebookToken, photoUrl, team : teamId}
    ).toPromise()
        .then((data) => {
          this.storeData(data);
          return data;
        });
  }

  requestNewPass(email: string) {

    return this.helperService.postAction(
        '/auth/forgot-password',
        {email , type: 'user'}
    ).toPromise();

  }

  resetPassword(token: string, password: string) {

    return this.helperService.postAction(
        '/auth/reset-password?token=' + token,
        {password}
    ).toPromise();
  }

  storeData(data) {
    Object.assign(this.userTokens, data.tokens);
    this.localSt.store('tokens', data.tokens);
    this.profileService.setUserData(data.user);
    if (data.studio) {
      this.profileService.setOwnerStudio(data.studio);
    }
    this.helperService.setApplicationState(data.user.role);
  }

  refreshToken() {
    return this.helperService.postAction(
      '/auth/refresh-tokens',
      {refreshToken : this.userTokens.refresh.token }
    ).toPromise()
      .then((data) => {
        Object.assign(this.userTokens, data);
        this.localSt.store('tokens', data);
        return data;
      });
  }

  getTokens() {
    return this.userTokens;
  }

  getToken( type = 'access') {
    return this.userTokens[type].token;
  }

  checkTokenExpiration( type = 'access') {
    return this.userTokens[type].expires;
  }

}
