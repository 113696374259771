import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationsService} from '../services/notifications.service';
import { MessageService} from 'primeng/api';
import {ProfileService} from '../services/profile.service';
import {UserService} from '../services/user.service';
import {PaymentsService} from '../services/payments.service';
import {filter, map, skip, tap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {HelperService} from "../services/helper.service";

@Component({
  selector: 'app-user-payments',
  templateUrl: './user-payments.component.html',
  styleUrls: ['./user-payments.component.scss']
})
export class UserPaymentsComponent implements OnInit {

  protected readonly parseFloat = parseFloat;

  public userId;
  public user;
  public payments;
  public loading = true;
  public env = environment;

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private profileService: ProfileService,
      private helperService: HelperService,
      private paymentService: PaymentsService,
  ) {
    this.userId = this.profileService.getUserData().id;
    this.payments = this.paymentService.payments$
        .pipe(
            skip( 1 ),
            map((pm) => {
              pm.forEach((payment) => {
                payment.studio.logo = payment.studio.media.find((img) => img.metadata.type === 'logo');
              });
              return pm;
            }),
            tap(pm => this.loading = false)
        );


    this.paymentService.fetchPayments(this.userId);


  }
  ngOnInit(): void {
  }

    getCurrency( currency = 'EUR' , symbol = true ) {
        if (symbol) {
            return this.helperService.getCurrencySymbol(currency)
        } else {
            return currency;
        }
    }

}
