import {Component, OnInit} from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import {NotificationsService} from './services/notifications.service';
import {LocalStorageService} from 'ngx-webstorage';
import { NavigationService } from './services/navigation.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{

    inputStyle = 'outlined';

    ripple: boolean;

    theme = 'indigo';

    layoutColor = 'white';

    colorScheme = 'light';

    menuMode = 'slim';

    constructor(
        private localSt: LocalStorageService,
        private notificationsService: NotificationsService,
        private navigationService: NavigationService,
        private primengConfig: PrimeNGConfig
    ) {
        if (this.localSt.retrieve('user')) {
            this.notificationsService.startPolling();
        }
    }

    ngOnInit() {
        this.primengConfig.ripple = true;
        this.ripple = true;
    }
}
