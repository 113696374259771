import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Router} from '@angular/router';
import {HelperService} from '../../../services/helper.service';
import {StudiosService} from '../../../services/studios.service';
import {ProfileService} from '../../../services/profile.service';

@Component({
    selector: 'app-studio-simple',
    templateUrl: './studio-simple.component.html',
    styleUrls: ['./studio-simple.component.scss']
})
export class StudioSimpleComponent implements OnInit ,OnChanges{

    @Input() studio;
    @Input() callbackFunction: (args: any) => void;
    @Input() openGMaps: (args: any) => void;
    public userLoggedIn = false;

    public services: any[] = [];

    constructor(
        private router: Router,
        private helperService: HelperService,
        private profileService: ProfileService,
        private studioService: StudiosService) {
    }

    ngOnInit(): void {
        this.userLoggedIn = this.profileService.isLoggedIn();
    }

    studioHasRecordingService(){
        let flag = false;
        this.studio.rooms.forEach((room) => {
            room.services.forEach((service) => {
                if (service.type === 'recording'){
                    flag = true;
                }
            });
        });
        return flag;
    }

    studioHasRehearsalService(){
        let flag = false;
        this.studio.rooms.forEach((room) => {
            room.services.forEach((service) => {
                if (service.type === 'rehearsal'){
                    flag = true;
                }
            });
        });
        return flag;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.studioService.mapStudioData(this.studio);
        this.services = [];
        if (this.studioHasRecordingService()){
            this.services.push('Recording');
        }
        if (this.studioHasRehearsalService()){
            this.services.push('Rehearsal');
        }
    }

    getCurrency( symbol = true ) {
        if (symbol) {
            return this.helperService.getCurrencySymbol(this.studio.businessCurrency)
        } else {
            return this.studio.businessCurrency;
        }
    }

}
