<p-card [style]="{borderRadius : 4 }" header="Opening hours" styleClass="p-card-shadow">
    <p><strong>Monday: </strong> <span *ngIf = "availability?.monday.available; else closed">{{convertDateToTime(availability?.monday.from)}} - {{convertDateToTime(availability?.monday.to)}}</span></p>
    <p><strong>Tuesday: </strong> <span *ngIf = "availability?.tuesday.available; else closed">{{convertDateToTime(availability?.tuesday.from)}} - {{convertDateToTime(availability?.tuesday.to)}}</span></p>
    <p><strong>Wednesday: </strong> <span *ngIf = "availability?.wednesday.available; else closed">{{convertDateToTime(availability?.wednesday.from)}} - {{convertDateToTime(availability?.wednesday.to)}}</span></p>
    <p><strong>Thursday: </strong> <span *ngIf = "availability?.thursday.available; else closed">{{convertDateToTime(availability?.thursday.from)}} - {{convertDateToTime(availability?.thursday.to)}}</span></p>
    <p><strong>Friday: </strong> <span *ngIf = "availability?.friday.available; else closed">{{convertDateToTime(availability?.friday.from)}} - {{convertDateToTime(availability?.friday.to)}}</span></p>
    <p><strong>Saturday: </strong> <span *ngIf = "availability?.saturday.available; else closed">{{convertDateToTime(availability?.saturday.from)}} - {{convertDateToTime(availability?.saturday.to)}}</span></p>
    <p><strong>Sunday: </strong> <span *ngIf = "availability?.sunday.available; else closed">{{convertDateToTime(availability?.sunday.from)}} - {{convertDateToTime(availability?.sunday.to)}}</span></p>
</p-card>

<ng-template #closed>
    Closed
</ng-template>
