<div class="exception-body flex align-items-center justify-content-center">
    <div class="exception-panel p-5 flex flex-column justify-content-center">
        <h4 class="font-bold">ERROR</h4>
        <span class="line-height-3">Unexpected error happened. Resource is not available.</span>

        <img src="../../../assets/layout/images/pages/error.svg" alt="error" class="my-5" height="150">

        <button type="button" pButton label="Go to Dashboard" [routerLink]="['/']"></button>
    </div>
</div>
