import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-social-callback',
  templateUrl: './social-callback.component.html',
  styleUrls: ['./social-callback.component.scss']
})
export class SocialCallbackComponent implements OnInit {

  constructor(
      private router: Router ,
      private route: ActivatedRoute,
  ) {
  }


  ngOnInit(): void {
    console.log('----')
    console.log('Social Callback')
    console.log('----')
    console.log(this.route.paramMap);
    console.log(this.route.params);
    console.log(this.route.queryParamMap);
    console.log(this.route.queryParams);
    console.log('----')
  }

}
