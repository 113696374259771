import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {UserService} from '../../services/user.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {filter, map} from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import {BandsService} from '../../services/bands.service';
import {HelperService} from '../../services/helper.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ProfileService} from '../../services/profile.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-user-bands',
  templateUrl: './user-bands.component.html',
  styleUrls: ['./user-bands.component.scss'],
  providers: [ConfirmationService]
})
export class UserBandsComponent implements OnInit, OnChanges {


  @Input() userId: string;
  @Input() userName: string;
  @Input() viewInvites = false;

  public bandDialog = false;
  public band: any = {};
  public submitted = false;


  public bands: any = {};
  public invites: any = {};

  public loading = true;
  public allowEdit = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router ,
    private userService: UserService,
    private bandsService: BandsService,
    private profileService: ProfileService,
    private helperService: HelperService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
    ) { }

  ngOnInit(): void {

    this.bands = combineLatest(
      this.userService.bands$,
      (one: any) => {
        return {bands : one};
      }
    ).pipe(
      filter((data) => (data.bands)),
      map((data: any) => {
        this.loading = false;
        data.bands.map(band => band.dataType = 'band');
        const allItems = [
          ...data.bands
        ];
        return allItems;
    }));

    this.invites = combineLatest(
      this.userService.invites$,
      (one: any) => {
        return {invites : one.results};
      }
  ).pipe(
      filter((data) => (data.invites)),
      map((data: any) => {
        this.loading = false;
        data.invites.map(invite => invite.dataType = 'invite');
        const allItems = [
          ...data.invites.filter(invite => invite.status === 'pending' && invite.team),
        ];
        if (allItems.length === 0) {
          this.viewInvites = false;
        }
        return allItems;
      }));
}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('userId') && changes.userId.currentValue) {
      this.userId = changes.userId.currentValue;
      this.userService.fetchUserInvites(this.userId);
      this.userService.fetchUserBands(this.userId);
    }
    if (this.userId === this.profileService.getUserData().id) {
      this.allowEdit = true;
    }
    else{
      this.allowEdit = false;
    }
  }

  acceptInvite(invite) {
    this.userService.acceptUserInvite(this.userId , invite.id).then((data) => {
      this.messageService.add({
        key: 'globalToast', severity: 'success', summary: 'Successful', detail: 'Invite Accepted', life: 3000});
      this.userService.fetchUserBands(this.userId);
    });
  }

  declineInvite(invite) {

    this.userService.declineUserInvite(this.userId , invite.id).then((data) => {
      this.messageService.add({
        key: 'globalToast', severity: 'success', summary: 'Successful', detail: 'Invite Declined', life: 3000});
    });
  }

  leaveBand(band) {

    if (band.members.length === 1) {
      this.confirmationService.confirm({
        message: 'You are the last member of the band! Are you sure you want to leave?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.userService.leaveUserBand(this.userId , band.id).then((data) => {
            this.messageService.add({
              key: 'globalToast', severity: 'success', summary: 'Successful', detail: 'You left the band', life: 3000});
          });
        }
      });
    } else {
      this.userService.leaveUserBand(this.userId , band.id).then((data) => {
        this.messageService.add({
          key: 'globalToast', severity: 'success', summary: 'Successful', detail: 'You left the band', life: 3000});
      });
    }
  }

  createNewBand() {
    this.router.navigate(['..' , 'newband' ]);
  }
  hideDialog() {
    this.band = {};
    this.bandDialog = false;
    this.submitted = false;
  }

  refresh() {
    this.loading = true;
    this.userService.fetchUserInvites(this.userId);
    this.userService.fetchUserBands(this.userId);
  }

  seeBand(band) {
    this.router.navigate(['..' , 'band' , band.slug ? band.slug : band.id ]);
  }

    protected readonly environment = environment;
}
