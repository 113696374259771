<div class="grid" *ngIf="user | async as userData">
  <div class="col-12 md:col-4 hidden md:block">
    <ng-template ngFor let-child let-i="index" [ngForOf]="settingsTypes">
      <div
        (click)="selectSettingType(child)"
        [ngClass]="{
          'setting-option-selected': selectedSettingType === child.code
        }"
        class="mb-4 px-3 flex align-items-center justify-content-start setting-option"
      >
        <div class="option-title">
          <span class="tn-text-big"> {{ child.name }} </span>
        </div>
      </div>
    </ng-template>
  </div>

  <div class="col-12 md:col-4 md:hidden">
    <ng-template
      ngFor
      let-child
      let-i="index"
      [ngForOf]="settingsTypes | filterAccountTypes : [selectedSettingType, 0]"
    >
      <div
        (click)="selectSettingType(child)"
        [ngClass]="{
          'setting-option-selected': selectedSettingType === child.code
        }"
        class="mb-4 px-3 flex align-items-center justify-content-start setting-option"
      >
        <div class="option-chevron">
          <span class="tn-text-big">
            <i
              style="padding: 1rem"
              [ngClass]="{
                'pi-chevron-down': selectedSettingType !== child.code,
                'pi-chevron-up': selectedSettingType === child.code
              }"
              [ngStyle]="{
                color:
                  selectedSettingType !== child.code ? '#00B6B2' : '#FFFFFF'
              }"
              class="pi tn-text-xs"
            >
            </i>
          </span>
        </div>
        <div class="option-title">
          <span class="tn-text-big"> {{ child.name }} </span>
        </div>
      </div>
    </ng-template>
  </div>

  <div class="col-12 lg:col-8">
    <div class="grid">
      <ng-template [ngIf]="selectedSettingType === 'account'">
        <div class="col-12">
          <p class="tn-title" style="font-weight: 400">Account settings</p>

          <div class="grid">
            <div class="col-12">
              <span class="tn-text-green tn-text-small"
                >All account settings are private.</span
              >
            </div>

            <div class="col-6 md:col-3">
              <p class="tn-text-simple tn-text-green">EMAIL</p>
              <p *ngIf="!allowEdit" class="tn-text-simple">
                {{ userEmail }}
              </p>
              <input
                *ngIf="allowEdit"
                id="userEmail"
                inputId="userEmail"
                type="text"
                pInputText
                [(ngModel)]="userEmail"
              />
            </div>

            <div class="col-12 md:col-2 block md:hidden">
              <p class="tn-text-simple tn-text-green">PHONE NUMBER</p>
              <p *ngIf="!allowEdit" class="tn-text-simple">
                {{ userPhoneNumber }}
              </p>
              <p-inputNumber
                *ngIf="allowEdit"
                id="userPhonenumber"
                inputId="userPhonenumber"
                class="w-full"
                [prefix]="phonePrefix"
                [useGrouping]="false"
                [(ngModel)]="userPhoneNumber"
              ></p-inputNumber>
            </div>

            <div class="col-4 md:col-3">
              <p class="tn-text-simple tn-text-green">STREET</p>
              <p *ngIf="!allowEdit" class="tn-text-simple">
                {{ userStreet }}
              </p>
              <input
                *ngIf="allowEdit"
                inputTrim
                id="street"
                inputId="street"
                class="w-full"
                type="text"
                pInputText
                [(ngModel)]="userStreet"
              />
            </div>

            <div class="col-4 md:col-2">
              <!-- <div class="max-w-8rem"> -->
              <p class="tn-text-simple tn-text-green">NUMBER</p>
              <p *ngIf="!allowEdit" class="tn-text-simple">
                {{ userNumber }}
              </p>
              <input
                *ngIf="allowEdit"
                inputTrim
                id="number"
                class="w-full"
                inputId="number"
                type="text"
                pInputText
                [(ngModel)]="userNumber"
              />
              <!-- </div> -->
            </div>

            <div class="col-4 md:col-3">
              <div class="max-w-8rem">
                <p class="tn-text-simple tn-text-green">ZIP CODE</p>
                <p *ngIf="!allowEdit" class="tn-text-simple">
                  {{ userZipCode }}
                </p>
                <input
                  *ngIf="allowEdit"
                  inputTrim
                  id="zip"
                  class="w-full"
                  inputId="zip"
                  type="text"
                  pInputText
                  [(ngModel)]="userZipCode"
                />
              </div>
            </div>
            <!-- <div class="col-12 md:col-4 hidden md:block"></div> -->

            <div class="col-12 md:col-3 hidden md:block">
              <p class="tn-text-simple tn-text-green">PHONE NUMBER</p>
              <p *ngIf="!allowEdit" class="tn-text-simple">
                {{ userPhoneNumber }}
              </p>
              <p-inputNumber
                *ngIf="allowEdit"
                id="userPhonenumber"
                inputId="userPhonenumber"
                class="w-full"
                [prefix]="phonePrefix"
                [useGrouping]="false"
                [(ngModel)]="userPhoneNumber"
              ></p-inputNumber>
            </div>

            <div class="col-4 md:col-3">
              <p class="tn-text-simple tn-text-green">CITY</p>
              <p *ngIf="!allowEdit" class="tn-text-simple">
                {{ userCity }}
              </p>
              <input
                *ngIf="allowEdit"
                inputTrim
                id="city"
                class="w-full"
                inputId="city"
                type="text"
                pInputText
                [(ngModel)]="userCity"
              />
            </div>

            <div class="col-6 md:col-4">
              <p class="tn-text-simple tn-text-green">COUNTRY</p>
              <p *ngIf="!allowEdit" class="tn-text-simple">
                {{ userCountry }}
              </p>
              <p-dropdown
                *ngIf="allowEdit"
                inputId="country"
                id="country"
                required="true"
                optionValue="label"
                [options]="countries"
                [(ngModel)]="userCountry"
                (onChange)="selectCountry($event)"
                placeholder="Select a Country"
              >
                <ng-template let-country pTemplate="item">
                  <div class="flex align-items-center gap-2">
                    <div>
                      <span>{{ country.emoji }}</span>
                      {{ country.name }}
                    </div>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>

            <div class="col-4">
              <button
                *ngIf="!allowEdit"
                icon="till-noon-icon tnicon-Pencil"
                pButton
                label="EDIT"
                type="button"
                class="tn-primary-button"
                (click)="allowEdit = !allowEdit"
              ></button>
              <button
                *ngIf="allowEdit"
                icon="pi pi-check"
                pButton
                label="SAVE"
                type="button"
                class="tn-secondary-button"
                (click)="saveChanges(); allowEdit = !allowEdit"
              ></button>
            </div>
          </div>
        </div>

        <div class="col-12 md:hidden">
          <hr class="tn-hr" />
        </div>

        <div class="col-12">
          <div class="p-fluid formgrid grid">
            <div class="field col-12">
              <p-checkbox
                id="newsletter"
                inputId="newsletter"
                [binary]="true"
                [(ngModel)]="userNewsletter"
                (onChange)="subscribeUser($event)"
              ></p-checkbox>
              <span
                for="newsletter"
                style="
                  padding-left: 1em;
                  color: #fff !important;
                  font-size: 1rem;
                  font-weight: 700;
                "
                >Subscribe to newsletter</span
              >
            </div>
          </div>
        </div>

        <div
          class="col-12 justify-content-start md:justify-content-end pt-0 md:pt-1"
          style="display: grid"
        >
          <button
            *ngIf="!(userData.provider && userData.provider !== 'BANDBEAT')"
            pButton
            type="button"
            class="mr-2 mb-2 tn-primary-button settingsButtons"
            label="Change Password"
            iconPos="left"
            icon="pi pi-unlock"
            (click)="toChangePass()"
          ></button>
          <button
            pButton
            type="button"
            class="mr-2 mb-2 tn-danger-button settingsButtons"
            label="Delete my account"
            (click)="deleteUser()"
          ></button>
        </div>
      </ng-template>

      <ng-template [ngIf]="selectedSettingType === 'payments'">
        <div class="col-12" *ngIf="env.paymentEnabled">
          <app-user-payments></app-user-payments>
        </div>
      </ng-template>
    </div>
  </div>

  <div class="col-12 md:col-4 md:hidden">
    <ng-template
      ngFor
      let-child
      let-i="index"
      [ngForOf]="settingsTypes | filterAccountTypes : [selectedSettingType, 1]"
    >
      <div
        (click)="selectSettingType(child)"
        [ngClass]="{
          'setting-option-selected': selectedSettingType === child.code
        }"
        class="mb-4 px-3 flex align-items-center justify-content-start setting-option"
      >
        <div class="option-chevron">
          <span class="tn-text-big">
            <i
              style="padding: 1rem"
              [ngClass]="{
                'pi-chevron-down': selectedSettingType !== child.code,
                'pi-chevron-up': selectedSettingType === child.code
              }"
              [ngStyle]="{
                color:
                  selectedSettingType !== child.code ? '#00B6B2' : '#FFFFFF'
              }"
              class="pi tn-text-xs"
            >
            </i>
          </span>
        </div>
        <div class="option-title">
          <span class="tn-text-big"> {{ child.name }} </span>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<p-confirmDialog #cd [style]="{ width: '450px' }">
  <ng-template pTemplate="header">
    <p class="tn-text-big">Sad to see you go!</p>
  </ng-template>

  <ng-template pTemplate="message">
    <p class="tn-text-normal">Are you sure you want to delete your account?</p>
  </ng-template>
  <ng-template pTemplate="footer">
    <button
      type="button"
      class="tn-danger-button"
      pButton
      icon="pi pi-times"
      label="No"
      (click)="cd.reject()"
    ></button>
    <button
      type="button"
      class="tn-primary-button"
      pButton
      icon="pi pi-check"
      label="Yes"
      (click)="cd.accept()"
    ></button>
  </ng-template>
</p-confirmDialog>
