import { Pipe, PipeTransform } from '@angular/core';
import { formatDistance, differenceInHours, format } from 'date-fns'


@Pipe({
  name: 'timeDay'
})
export class TimeDayPipe implements PipeTransform {
  transform(value: number, arg?: string): string {
    return (value < 12) ? 'Morning' : (value <= 17) ? 'Afternoon' : 'Evening';
  }
}
