import { Pipe, PipeTransform } from '@angular/core';
import {InstrumentAndEquipmentService} from "../../services/instruments.equipments.service";
import {HelperService} from "../../services/helper.service";

@Pipe({
  name: 'viewMore'
})
export class ViewMorePipe implements PipeTransform {

  private isMobile = false

  constructor(
      private helperService: HelperService,
  ) {
    this.isMobile = this.helperService.isMobile();

  }
  transform(listItems: any[], viewMore: boolean = false): any[] {

    if (listItems.length <= ( this.isMobile ? 4 : 8 ) || viewMore) {
      return listItems;
    } else {
      return listItems.slice(0,( this.isMobile ? 4 : 8 ));
    }
  }

}
