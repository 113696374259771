import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterBookings'
})
export class FilterBookingsPipe implements PipeTransform {

  transform(bookings: any[], bookingCode: string): unknown {
    if (bookings) {
      if (bookingCode === 'all') {
        return bookings;
      }
      return bookings.filter((val) => val.bookingCode === bookingCode);
    }
    return bookings;
  }

}
