<div class="grid bandbeatPage" *ngIf="studio | async as studioData">


    <div class="col-12 md:mb-8"  >
        <div>

            <ul style="list-style-type: none; padding-left: 0px;">
                <li>

                    <button
                            pButton
                            pRipple
                            type="button"
                            icon = 'pi pi-arrow-left'
                            [routerLink]="['/listing']"
                            label="BACK TO STUDIO SEARCH"
                            class="p-button-primary tn-primary-button"
                    ></button>
                </li>
                <li class="mt-4 md:mt-0">
                    <span  class="keyword tn-title-big">Book</span><span class="keyword tn-title-big" style="font-weight: 400;"> studio</span>
                    <span class="tn-title ml-4" > {{studioData.name}}</span>
                </li>
            </ul>
        </div>
    </div>

    <div class="col-12 bookingSteps" >
        <p-steps
            #bookingSteps
            [model]="items"
            [(activeIndex)]="activeIndex"
            [readonly]="true"
        ></p-steps>
    </div>
    <div
            class="col-12 full-width-container bookingStepsContainer"
    >
        <div class="grid grid-nogutter "
        >
            <div class="col-12 " >
                <router-outlet></router-outlet>
            </div>
        </div>

    </div>




</div>

