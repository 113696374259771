import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import {MessageService} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import { differenceInHours, format, isAfter, isBefore } from 'date-fns';
import * as countries from 'i18n-iso-countries';
// @ts-ignore
import * as englishLocale from 'i18n-iso-countries/langs/en.json';
countries.registerLocale(englishLocale);

import * as countriesList from 'countries-list';
import {PixelService} from "ngx-pixel";
// var countriesList = require("countries-list")

import currencyToSymbolMap from 'currency-symbol-map/map';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  private applicationState = 'admin';

  private instrumentTypes: BehaviorSubject<any>;
  public readonly instrumentTypes$: Observable<any>;

  private userCoordinates: BehaviorSubject<any>;
  public readonly userCoordinates$: Observable<any>;



  private dataStore: {
    instrumentTypes: any,
    hasCoordinates: boolean,
  };

  constructor(
    private http: HttpClient,
    private router: Router,
    private pixelService: PixelService,
  ) {

    this.instrumentTypes = new BehaviorSubject({}) as BehaviorSubject<any>;
    this.instrumentTypes$ = this.instrumentTypes.asObservable();

    this.userCoordinates = new BehaviorSubject({}) as BehaviorSubject<any>;
    this.userCoordinates$ = this.userCoordinates.asObservable();


    this.dataStore = {
      instrumentTypes: {
      },
      hasCoordinates : false,
    };
  }



  /**
   * ----------------------------
   * HTTP REQUEST RELATED FUNCTIONS
   * ----------------------------
   */

  createHeaders(acceptType?: string, contentType?: string) {
    let headers = new HttpHeaders();
    if (acceptType && acceptType !== '') {
      headers = headers.set('Accept', acceptType);
    }
    if (contentType) {
      headers = headers.set('Content-Type', contentType);
    }
    return headers;
  }

  getAction(endpoint: string, parameters?: any, acceptType?: string, contentType?: string) {
    const httpOptions: any = {
      headers: this.createHeaders(acceptType, contentType),
      params: parameters,
    };
    if (acceptType && (acceptType === 'application/text')) {
      httpOptions.responseType = 'text';
    }
    return this.http.get(
      environment.backendUrl + endpoint,
      httpOptions
    ) as Observable<any>;
  }

  postAction(endpoint: string,
             data?: any,
             acceptType?: string,
             contentType?: string
  ) {

    const httpOptions: any = {
      headers: this.createHeaders(acceptType, contentType)
    };
    return this.http.post(
      environment.backendUrl + endpoint,
      data,
      httpOptions
    ) as Observable<any>;
  }

  putAction(endpoint: string, acceptType?: string, contentType?: string, parameters?: HttpParams) {
    const httpOptions: any = {
      headers: this.createHeaders(acceptType, contentType),
      params: parameters,
    };
    return this.http.put(
      environment.backendUrl + endpoint,
      httpOptions
    ) as Observable<any>;
  }

  patchAction(endpoint: string,
              data?: any,
              acceptType?: string,
              contentType?: string
  ) {
    const httpOptions: any = {
      headers: this.createHeaders(acceptType, contentType)
    };
    return this.http.patch(
      environment.backendUrl + endpoint,
      data,
      httpOptions
    ) as Observable<any>;
  }

  deleteAction(endpoint: string, acceptType?: string, contentType?: string, parameters?: HttpParams) {
    const httpOptions: any = {
      headers: this.createHeaders(acceptType, contentType),
      params: parameters,
    };
    return this.http.delete(
      environment.backendUrl + endpoint,
      httpOptions
    ) as Observable<any>;
  }

  setApplicationState(state: string) {
    this.applicationState = state;
  }

  getApplicationState() {
    return this.applicationState;
  }

  openGMaps(type: string = 'dir' , coordinates?: any) {
    let googleURl = 'https://www.google.com/maps/' + type + '/?api=1';
    if (coordinates) {
      googleURl = googleURl + '&query=' + coordinates.x + '%2C' + coordinates.y;
    }
    window.open(googleURl);
  }

  navigateTo(navigationPath: string) {
    return this.router.navigateByUrl( navigationPath);
  }

  fetchInstrumentTypes() {
    if (Object.keys(this.dataStore.instrumentTypes).length > 0) {
      return Promise.resolve(this.dataStore.instrumentTypes);
    } else {
      return this.getAction('/instruments' )
        .toPromise()
        .then((data) => {
          Object.assign(this.dataStore.instrumentTypes, data);
          this.instrumentTypes.next(data);
          return data;
        });
    }
  }

  inviteUserToBandBeat( username , invitemail, bandId? ) {
    const params = {username , invitemail};
    if (bandId) {
      params['team'] = bandId;
    }
    return this.postAction('/general/sendinvite', params  )
        .toPromise();
  }

  checkUserExists( userEmail : string ) {
    const params = { usermail : userEmail };
    return this.getAction('/general/checkuserexists', params)
        .toPromise();
  }

  checkPromoCode( promo : string, service : string, recurring = false , paymentMethod : string) {
    const params: any = {promo , service, recurring};
    if (paymentMethod) {
      params.paymentMethod = paymentMethod;
    }
    return this.getAction('/promocodes/check', params)
        .toPromise();
  }

  isAfter(dateOne: Date , dateTwo = new Date){
    return isAfter( dateOne , dateTwo);
  }

  isBefore(dateOne: Date , dateTwo = new Date){
    return isBefore( dateOne , dateTwo);
  }

  formatDate(date: string, dateFormat?) {
    if (!dateFormat) {
      dateFormat = 'yyyy-MM-dd hh:mm';
    }
    const dateValue = new Date(date);
    return format(dateValue , dateFormat);
  }

  timeDifference( dateOne: Date, dateTwo: Date) {
    return differenceInHours(dateTwo, dateOne);
  }

  checkUserHasCoordinates() {
    return this.dataStore.hasCoordinates;
  }

  getUserCoordinates() {
    if (navigator.geolocation) {
      return this.getPosition().then((userCoordinates) => {
            this.dataStore.hasCoordinates = true;
            this.userCoordinates.next(userCoordinates.coords);
      }).catch((err) => {
        return this.getLocationEstimateFromIp();
      });
    } else {
      return this.getLocationEstimateFromIp();
    }
  }

  getPosition(options?: PositionOptions): Promise<any> {
    return new Promise((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(resolve, reject, options)
    );
  }

  getLocationEstimateFromIp(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>('https://ipapi.co/json/?key=Vf5rdd9XlyuhGlSFaaobMqNvx8H1i9PwRxJ86se7NHAC7I8WxK').subscribe(
          (response) => {
            const coords = {
              latitude: response.latitude,
              longitude: response.longitude,
            };
            this.dataStore.hasCoordinates = true;
            this.userCoordinates.next(coords);
            resolve(coords);
          },
          (error) => {
            this.userCoordinates.next({});
            reject('Failed to get approximate location. Please allow location access.');
          }
      );
    });
  }

  isMobile() {
    return window.innerWidth < 1025;
  }

  isTablet() {
    const width = window.innerWidth;
    return width <= 1024 && width > 640;
  }

  getAvailableCountries() {
    return countries.getNames('en', {select: 'official'});
  }

  getCountriesCode( countryName: string ) {
    return countries.getAlpha2Code(countryName, 'en') ? countries.getAlpha2Code(countryName, 'en') : 'GR';
  }

  getCountriesName( countryCode: string ) {
    return countries.getName(countryCode, 'en');
  }

  getCountriesData() {
    const countryCodes = Object.keys(countriesList.countries);

    return countryCodes.map((code) => {
      return {
        code,
        value : code,
        name : countriesList.countries[code].name,
        label : countriesList.countries[code].name,
        emoji : countriesList.countries[code].emoji,
        emojiU : countriesList.countries[code].emojiU,
        phone : countriesList.countries[code].phone
      };
    })
    .sort((a, b) => a.name > b.name ? 1 : -1);
  }


  trackViewContent(viewName: string , studioId?: any) {
    const viewObject: any = {
      content_category : 'PageView',
      content_name : viewName
    };
    if (studioId) {
      viewObject.content_ids = [studioId]
    }
    this.pixelService.track("ViewContent",
        viewObject
    );
  }

  trackPurchase(purchaseData) {
    this.pixelService.track("Purchase",
        purchaseData
    );
  }

  trackInitiateCheckout(checkoutData) {
    this.pixelService.track("InitiateCheckout",checkoutData
    );
  }
  trackAddPaymentInfo(paymentInfoAdded) {
    this.pixelService.track("AddPaymentInfo",paymentInfoAdded
    );
  }

  trackSearch(searchData) {
    this.pixelService.track("Search",searchData
    );
  }

  trackCompleteRegistration(registrationData) {
    this.pixelService.track('CompleteRegistration', registrationData);
  }

  getCurrencySymbol(currency: string) {
    if ( currencyToSymbolMap[currency] ) {
      return currencyToSymbolMap[currency];
    } else {
      return currency;
    }
  }


}
