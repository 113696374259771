export const environment = {
  production: true,
  backendUrl: 'https://api-dev.bandbeat.com/v1' ,
  uploadUrl: 'https://api-dev.bandbeat.com/public/uploads/',
  studioUrl: 'https://dev-studio.bandbeat.com',
  blogUrl: 'https://blog.bandbeat.com/' ,
  betaMode : false,
  reviewEnabled: true,
  socialLoginEnabled: true,
  googleOauth: '928100291570-i6v34q0o7p2ld20bqdva6f6nhvqaomhj.apps.googleusercontent.com',
  facebookOauth: '1322582721597531',
  paymentEnabled: true,
  paymentMid: '9000000314',
  paymentLink: 'https://eurocommerce-test.cardlink.gr/vpos/shophandlermpi',
  multipleCountriesEnabled: true,
  studioScoreEnabled: true,
  promoCodesEnabled: true,
  facebookTracking: false,
  googleTracking: false,
  revolutMode: 'sandbox',
};
