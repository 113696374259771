import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../services/authentication.service';
import {MessageService} from 'primeng/api';
import {FacebookLoginProvider, SocialAuthService} from '@abacritt/angularx-social-login';
import {environment} from '../../../environments/environment';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-login-popup',
  templateUrl: './login-popup.component.html',
  styleUrls: ['./login-popup.component.scss']
})
export class LoginPopupComponent implements OnInit, OnDestroy {

  @Input() display = false;
  @Output() closeModalEmitter = new EventEmitter();


    public env = environment;
  userEmail;
  userPassword;

    authorizationSubscription;

  constructor(
      private router: Router,
      private authenticationService: AuthenticationService,
      private messageService: MessageService,
      private authService: SocialAuthService,
  ) { }

  ngOnInit(): void {




      this.authorizationSubscription = this.authService.authState
          .pipe(
              filter(val =>
                  val !== null
              )
          )
          .subscribe((user) => {
              let registration;
              if (user.provider === 'FACEBOOK') {
                  registration = this.authenticationService.registerFacebookUser(
                      user.firstName ,
                      user.lastName,
                      user.email,
                      user.id,
                      user.authToken,
                      user.photoUrl
                  );
              } else {
                  registration = this.authenticationService.registerGoogleUser(
                      user.firstName ,
                      user.lastName,
                      user.email,
                      user.id,
                      user.idToken,
                      user.photoUrl
                  );
              }

              registration.then((data) => {

                  setTimeout(() => {
                      this.messageService.add(
                          {
                              key: 'globalToast',
                              severity: 'success',
                              summary: 'Successful login',
                              detail: 'Welcome back ' + data.user.name
                          });
                  }, 1000);

                  this.display = false;
                  this.hideModal(false);
              }).catch((err) => {
                  this.messageService.clear();
                  this.messageService.add({
                      severity: 'error', summary: 'Something went wrong', detail: err.error.message});

                  this.display = false;
                  this.hideModal(false);
              });
          });


  }

  public login() {
    this.authenticationService.loginUser(this.userEmail, this.userPassword)
        .then((data) => {
          setTimeout(() => {
            this.messageService.add(
                {
                  key: 'globalToast',
                  severity: 'success',
                  summary: 'Successful login',
                  detail: 'Welcome back ' + data.user.name
                });
            this.display = false;
            this.hideModal(false);
          }, 1000);
        }).catch((err) => {
      this.messageService.clear();
      this.messageService.add({
        key: 'globalToast',
        severity: 'error',
        summary: 'Invalid login',
        detail: 'Wrong email or password'
      });
    });
  }

    hideModal(event){
      this.closeModalEmitter.emit(false);
    }

    public signInWithFB(): void {
        this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
    }

    ngOnDestroy(): void {
        this.authorizationSubscription.unsubscribe();
    }


    public getButtonSize() {
        if (window.innerWidth < 991) {
            return 300;
        } else {
            return 200;
        }
    }

}
