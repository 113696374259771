<div class="grid" *ngIf="studio | async as studioData">
    <div class="col-12">

        <H2 >Instruments for rental</H2>

        <p-dataView #dvListing
                    [value]="instruments"
                    layout="grid">
            <ng-template let-inst pTemplate="gridItem">
                <div class="col-12 md:col-3">
                    <div class="equipment-list-item">

                        <app-instrument style="width: inherit"
                                       [instrument]="inst"></app-instrument>

                    </div>
                </div>
            </ng-template>

        </p-dataView>
    </div>

</div>

<div class="grid grid-nogutter next-buttons">
    <p-button label="Previous" (onClick)="previous()" styleClass="secondary-button" icon="till-noon-icon tnicon-Arrow-Left" iconPos="left"></p-button>
    <p-button label="Book The Studio" (onClick)="confirmBooking()" class="p-button-success" icon="till-noon-icon tnicon-Arrow-Right" iconPos="right"></p-button>
</div>
