import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DatePipe} from "@angular/common";
import {da} from "date-fns/locale";

@Component({
  selector: 'app-tn-opening-hours',
  templateUrl: './tn-opening-hours.component.html',
  styleUrls: ['./tn-opening-hours.component.scss']
})
export class TnOpeningHoursComponent implements OnInit, OnChanges {

  @Input() availability;
  availabilityArray = [];

  constructor(private datePipe: DatePipe) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.availability && Object.values(this.availability).length > 0){
      this.transformAvailabilities();
      this.availabilityArray = this.verbalizeAvailabilities();
    }
  }

  ngOnInit(): void {
  }

  convertDateToTime(date): string{
    try {
      return this.datePipe.transform(new Date(date), 'HH:mm');
    } catch (e) {
      return '';
    }
  }

  transformAvailabilities() {
    Object.values(this.availability).forEach((dayAvailability:any) => {
      dayAvailability.from = this.convertDateToTime(dayAvailability.from);
      dayAvailability.to = this.convertDateToTime(dayAvailability.to);
    })
  }

  verbalizeAvailabilities(): string[] {
    const availabilityTextArray = [];

    const days = ['monday' , 'tuesday' , 'thursday' , 'wednesday' , 'friday' , 'saturday' , 'sunday'];

    for (let index = 0; index < days.length; index++) {
      const availabilityDay = { day : '' , status : ''};
      const day = days[index];
      availabilityDay.day = availabilityDay.day + ' ' + day;
      let subDay;
      while (
          index !== days.length - 1 &&
          this.availability[day].from === this.availability[days[index+1]].from &&
          this.availability[day].to === this.availability[days[index+1]].to &&
          this.availability[day].available === this.availability[days[index+1]].available
          ) {
        subDay = days[index+1];
        index = index + 1;
      }
      if (subDay) {
        availabilityDay.day = availabilityDay.day + ' - ' + subDay;
      }

      if ( this.availability[day].available ) {
        availabilityDay.status =  this.availability[day].from + ' - ' + this.availability[day].to
      } else {
        availabilityDay.status =  'Closed'
      }

      availabilityTextArray.push(availabilityDay);
    }



    return availabilityTextArray;
  }

}
