import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ProfileService} from "../../services/profile.service";
import {BookingsService} from "../../services/bookings.service";
import {HelperService} from "../../services/helper.service";

@Component({
  selector: 'app-booking-payment',
  templateUrl: './booking-payment.component.html',
  styleUrls: ['./booking-payment.component.scss']
})
export class BookingPaymentComponent implements OnInit {
  public paymentResponse;
  public bookingId;

  constructor(
      private router: Router,
      private route: ActivatedRoute,
      private profileService: ProfileService,
      private bookingsService: BookingsService,
      private helperService: HelperService,
  ) { }

  ngOnInit(): void {

      this.paymentResponse = this.route.snapshot.queryParamMap.get('paymentResponse') && this.route.snapshot.queryParamMap.get('paymentResponse') === 'true';
      this.bookingId = this.route.snapshot.queryParamMap.get('bookingId');

      if (this.paymentResponse) {
        this.bookingsService.fetchBooking(this.bookingId).then((bookingData) => {
          this.trackPurchase(bookingData)
        })
      }

  }


  trackPurchase(bookingData) {
      this.helperService.trackPurchase(
          {
              content_name: bookingData.studio.name,
              content_ids: [this.bookingId], // Item SKUs
              value: bookingData.totalPrice, // Value of all items
              currency: "EUR", // Currency of the value
          }
      )
  }

  goToNextPage( reason?: string) {
    if (this.paymentResponse === true) {
      this.router.navigate( ['/listing' ] );
    } else {
      const routeDest = ['profile' , this.profileService.getUserData().id , 'bookings' ];
      if (reason !== 'cancel') {
        routeDest.push(this.bookingId);
      }
      this.router.navigate(routeDest);
    }

  }
}
