import {Component, EventEmitter, Input, Output} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {InstrumentAndEquipmentService} from "../../../services/instruments.equipments.service";

@Component({
  selector: 'app-tn-band-member',
  templateUrl: './tn-band-member.component.html',
  styleUrls: ['./tn-band-member.component.scss']
})
export class TnBandMemberComponent {

  @Input() member;
  @Output() memberSelectEmitter = new EventEmitter();
  @Input() selected = false;
  public env = environment;
  constructor(private instrumentService: InstrumentAndEquipmentService) {
  }

  ngOnInit(): void {

  }

  selectMember() {
    this.memberSelectEmitter.emit(this.member)
  }

}
