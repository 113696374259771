<div
        style="padding: 2rem 2rem 0 2rem;width: 100%;"
        class="optionBar">
    <div class="grid grid-nogutter">
        <div [ngClass]="getOptionBarGridClass()">
            <div class="grid grid-nogutter">
                <div class="field col-12 lg:col-2" *ngIf="!loggedIn">
                    <div class="flex flex-column gap-2">
                        <button
                                pButton
                                class="tn-primary-button p-button-large"
                                [routerLink]="'/login'"
                                label="LOG IN"
                                icon="till-noon-icon tnicon-User icon-large"
                                routerLinkActive="tn-primary-button-active"
                        ></button>
                    </div>
                </div>
                <div class="field col-12 lg:col-2" *ngIf="loggedIn">
                    <div class="flex flex-column gap-2">
                        <button
                                pButton
                                class="tn-primary-button p-button-large"
                                (click)="redirectToUserProfile()"
                                label="PROFILE"
                                icon="till-noon-icon tnicon-User icon-large"
                                routerLinkActive="tn-primary-button-active"
                        ></button>
                    </div>
                </div>
                <div class="field col-12 lg:col-2">
                    <div class="flex flex-column gap-2">
                        <button
                                pButton
                                class="tn-primary-button p-button-large"
                                [routerLink]="'/listing'"
                                (click)="itemClick($event)"
                                label="FIND A STUDIO"
                                icon="till-noon-icon tnicon-Wave"
                                routerLinkActive="tn-primary-button-active"
                        ></button>
                    </div>
                </div>

                <div class="field col-12 lg:col-2">
                    <div class="flex flex-column gap-2">
                        <a [href]="env.blogUrl" target="_blank">
                            <button
                                    pButton
                                    style="width: 100%"
                                    class="tn-primary-button p-button-large tn-helper-button"
                                    label="OUR BLOG"
                            ></button>
                        </a>
                    </div>
                </div>
                <div class="field col-12 lg:col-2">
                    <div class="flex flex-column gap-2">
                        <a [href]="env.studioUrl" target="_blank">
                            <button
                                    pButton
                                    style="width: 100%"
                                    class="tn-primary-button p-button-large tn-helper-button"
                                    label="FOR STUDIOS"
                            ></button>
                        </a>
                    </div>
                </div>
                <div class="field col-12 lg:col-2" *ngIf="loggedIn" style="margin-top:6vw">
                    <div class="flex flex-column gap-2">
                        <button
                                pButton
                                class="sign-out-button tn-primary-button p-button-large"
                                (click)="logoutUser()"
                                label="SIGN OUT"
                                routerLinkActive="tn-primary-button-active"
                        ></button>
                    </div>
                </div>

                <section class="grid-nogutter" [ngStyle]="loggedIn ? {'margin-top':'4vw', 'width': '100%' }: {'margin-top':'12vw', 'width': '100%'}">
                    <div class="field col-12 lg:col-2">
                        <div class="flex flex-column gap-2">
                            <button
                                    pButton
                                    class="tn-primary-button"
                                    [routerLink]="'/contact'"
                                    (click)="itemClick($event)"
                                    label="CONTACT US"
                                    icon="till-noon-icon tnicon-Contact">
                            </button>
                        </div>
                    </div>
                    <span style="
        display: block;
        color: #FFF;
        text-align: center;
        font-size: 2.7vw;
        font-style: normal;
        font-weight: 400;
        line-height: normal;"><span (click)="itemClick($event)" routerLink="privacy&policy">PRIVACY POLICY</span> | <span (click)="itemClick($event)" routerLink="terms&conditions">TERMS & CONDITIONS</span></span>

                    <div style="margin-top: 19vw; text-align: center">
                        <i class="till-noon-icon tnicon-Facebook contact-button"
                           (click)="redirectToFaceBook()"></i>
                        <i class="till-noon-icon tnicon-Instagram contact-button"
                           (click)="redirectToInstagram()"></i>
                        <i class="till-noon-icon tnicon-Spotify contact-button"
                           (click)="redirectToSpotify()"></i>
                        <i class="till-noon-icon tnicon-Linkedin contact-button"
                           (click)="redirectToLinkedIn()"></i>

                    </div>

                    <span style="
        display: block;
        padding-top:1em;
        text-align: center;
        color: #FFF;
        font-size: 2vw;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        ">
        © 2023 Bandbeat. All rights reserved.
      </span>
                </section>
            </div>
        </div>
    </div>
</div>

