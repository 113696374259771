import { Pipe, PipeTransform } from '@angular/core';
import {InstrumentAndEquipmentService} from "../../services/instruments.equipments.service";

@Pipe({
  name: 'equipmentParser'
})
export class EquipmentParserPipe implements PipeTransform {


  private equipmentMap: any;
  constructor(
      private instrumentService: InstrumentAndEquipmentService,
  ) {
    this.equipmentMap = this.instrumentService.getInstrumentsAndEquipments();

  }

  transform(equipment: any, args?: string): unknown {
    return this.equipmentMap.find(e => e.type === equipment.category).name;
  }

}
