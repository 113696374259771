import {Component, OnInit} from '@angular/core';
import {MenuItem} from "primeng/api";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-setup-wizard',
  templateUrl: './setup-wizard.component.html',
  styleUrls: ['./setup-wizard.component.scss']
})
export class SetupWizardComponent implements OnInit {


  items: MenuItem[] = [
    {
      label: 'User Information',
      routerLink: ['user-info']
    },
    {
      label: 'User Media',
      routerLink: ['user-media']
    },
    {
      label: 'Create a Band',
      routerLink: ['user-band']
    },
  ];
  activeIndex = 0;

  constructor(
      private route: ActivatedRoute,
      private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.router.navigate(this.items[0].routerLink, { relativeTo: this.route });

  }


}
