<div class="contact-body text-left">
  <h2>Get in touch</h2>
  <div class="main-text">
    <p>
      we strive to keep our platform in shape but we all know it: <br/>
      <i>things tend to fail</i>
    </p>
    <div class="contact-list">
        <p>if this is what brought you here <i>(and we're sorry for this)</i> feel free to contact us at: <a href="mailto:support@bandbeat.com">support@bandbeat.com</a></p>
        <p>if you just want to reach out to us, we'll be happy to get to know you at: <a href="hello@bandbeat.com">hello@bandbeat.com</a></p>
        <p> or at : <a href="tel:+30694-38-88-956">+30697-38-88-956</a></p>
    </div>
    <div class="logo-bottom">
      <img src="../../../assets/images/bandbeat/bb_logo_header.png" />
      <p>The Bandbeat Team</p>
    </div>
  </div>
</div>
