import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-band-booking-overview',
    templateUrl: './band-booking-overview.component.html',
    styleUrls: ['./band-booking-overview.component.scss']
})
export class BandBookingOverviewComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
