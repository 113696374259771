<div class="grid grid-nogutter pt-6" *ngIf="studio | async as studioData">


    <div class="col-12">

        <p class="tn-title" style="font-weight:400;">Choose a room</p>

        <div class="grid pt-5">
            <div class="col-12 md:col-4" *ngFor="let room of studioData.availableRooms; let i = myIndex">
                <app-tn-room-card
                        [room]="room"
                        [selected]="selectedRoom.id === room.id"
                        [selectedService]="service"
                        [currency]="studioData.businessCurrency"
                        [offerData]="offersPerRoom[room.id]"
                        (selectEventEmitter)="selectRoom($event)"
                ></app-tn-room-card>
            </div>
        </div>

        <ng-template
            [ngIf]="selectedRoom"
        >

            <div
                    class="flex justify-content-between pt-4"
            >

                <h3 class="tn-text-green tn-text-big" style="margin-bottom:0;">Room's info</h3>
                <a
                        (click)="viewMoreRoom = !viewMoreRoom"
                        class="tn-text-green tn-text-small cursor-pointer"
                        style="
                            font-size: 0.75rem;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 1.1875rem;
                            letter-spacing: 0.015rem;
                        "
                >
                    <span *ngIf="!viewMoreRoom">VIEW MORE</span>
                    <span *ngIf="viewMoreRoom">VIEW LESS</span>
                    <i style="padding-left: 1rem;"
                       [ngClass]="{'pi-chevron-down' : !viewMoreRoom , 'pi-chevron-up' : viewMoreRoom}"
                       class="pi"></i>
                </a>
            </div>
            <hr class="tn-hr" *ngIf="viewMoreRoom">



            <div class="grid " *ngIf="viewMoreRoom"  >
                <!--ROOM SERVICES-->

                <div class="col-12 md:col-4">
                    <p class="tn-text-big tn-text-green">
                        Services
                    </p>

                    <p-dataView #dvListingEq
                                [value]="selectedRoom?.services"
                                styleClass="services-dv"
                                layout="grid">
                        <ng-template let-service pTemplate="gridItem">
                            <div class="col-6">
                                <div class="equipment-list-item">
                                    <app-tn-service
                                            [room]="selectedRoom"
                                            [service]=service
                                            [darkMode]="false"
                                            [currency]="studioData.businessCurrency"
                                    ></app-tn-service>
                                </div>
                            </div>
                        </ng-template>
                    </p-dataView>
                </div>

                <!--ROOM IMAGES-->
                <div class="col-12 md:col-8">
                    <p-galleria
                            [value]="selectedRoom?.media"
                            [numVisible]="selectedRoom?.media?.length"
                            [circular]="true"
                            [showItemNavigators]="true"
                            [showIndicators]="true"
                            [showThumbnails]="false"

                    >
                        <ng-template pTemplate="item" let-item>

                            <img
                                    [defaultImage]="'/assets/images/loading_icon.gif'"
                                    [lazyLoad]="item['link']"
                                    [alt]="item.name"
                            />

                        </ng-template>
                    </p-galleria>
                </div>

                <!--ROOM EQUIPMENT-->
                <div class="col-12 room-equipment">
                    <p class="tn-text-big tn-text-green">
                        Equipment
                    </p>

                    <p-dataView #dvListingEq
                                [value]="selectedRoom?.equipment | viewMore:moreEquipment"
                                styleClass="equipment-dv"
                                layout="grid">
                        <ng-template let-equipment pTemplate="gridItem">
                            <div class="col-6 md:col-6 lg:col-3">
                                <div class="equipment-list-item">


                                    <app-tn-equipment
                                            style="width: inherit; height: 100%"
                                            [darkMode]="true"
                                            [equipment]=equipment
                                    >
                                    </app-tn-equipment>

                                </div>
                            </div>
                        </ng-template>

                        <ng-template
                                pTemplate="footer"
                        >
                            <div
                                    class="flex justify-content-center md:justify-content-end"
                                    *ngIf="selectedRoom?.equipment && selectedRoom.equipment.length > (isMobile ? 4 : 8)"
                            >
                                <a (click)="moreEquipment = !moreEquipment"  class="tn-text-green cursor-pointer">
                                    <span *ngIf="!moreEquipment">VIEW MORE</span>
                                    <span *ngIf="moreEquipment">VIEW LESS</span>
                                    <i style="padding-left: 1rem;"
                                       [ngClass]="{'pi-chevron-down' : !moreEquipment , 'pi-chevron-up' : moreEquipment}"
                                       class="pi"></i>
                                </a>
                            </div>
                        </ng-template>

                    </p-dataView>
                </div>


            </div>



        </ng-template>


    </div>

    <div class="col-12">


        <hr class="tn-hr pt-8">
        <p class="tn-title" style="font-weight:400;">Select additional instruments for rent</p>

<!--        <div>-->
<!---->
<!---->
<!--        </div>-->

        <div class="grid grid-nogutter pt-5" >
            <div class="col-12 p-0">
                <p-dataView #dvListing
                            [value]="studioData.instruments | viewMore:moreInstruments"
                            styleClass="services-dv"
                            layout="grid">
                    <ng-template let-instrument pTemplate="gridItem">
                        <div class="col-6 md:col-6 lg:col-3">
                            <div class="equipment-list-item">

                                <app-tn-instrument style="width: inherit; height:100%"
                                                   [rentFlag]="true"
                                                   [instrument]=instrument
                                                   [currency]="studioData.businessCurrency"
                                                   (addEventEmitter)="addInstrument($event)"
                                                   (removeEventEmitter)="removeInstrument($event)"
                                                   [isSelectedForRent]=isInstrumentSelectedForRent(instrument)
                                ></app-tn-instrument>
                            </div>
                        </div>
                    </ng-template>

                    <ng-template
                            pTemplate="footer"
                    >
                        <div
                                class="flex justify-content-center md:justify-content-end"
                                *ngIf="studioData.instruments && studioData.instruments.length > (isMobile ? 4 : 8)"
                        >
                            <a (click)="moreInstruments = !moreInstruments"  class="tn-text-green cursor-pointer">
                                <span *ngIf="!moreInstruments">VIEW MORE</span>
                                <span *ngIf="moreInstruments">VIEW LESS</span>
                                <i style="padding-left: 1rem;"
                                   [ngClass]="{'pi-chevron-down' : !moreInstruments , 'pi-chevron-up' : moreInstruments}"
                                   class="pi"></i>
                            </a>
                        </div>
                    </ng-template>
                </p-dataView>
            </div>
        </div>




    </div>
</div>



<div class="grid grid-nogutter next-buttons">
    <p-button
            label="Back"
            styleClass="p-button-primary tn-primary-button"
            (onClick)="previous()"
            icon="till-noon-icon tnicon-Arrow-Left"
            iconPos="left"
    ></p-button>
    <p-button
            label="Next"
            (onClick)="nextPage()"
            styleClass="tn-secondary-button"
            icon="till-noon-icon tnicon-Arrow-Right"
            iconPos="right"
    ></p-button>
</div>

