import {Component, Input, OnInit} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {Router} from "@angular/router";
import {StudiosService} from "../../../services/studios.service";
import {ProfileService} from "../../../services/profile.service";
import {HelperService} from "../../../services/helper.service";

@Component({
  selector: 'app-tn-studio-card',
  templateUrl: './tn-studio-card.component.html',
  styleUrls: ['./tn-studio-card.component.scss']
})
export class TnStudioCardComponent implements OnInit {

  @Input() studio;
  public env = environment;

  public studioAddress = '';
  public showCover = true;

  public studioOffers;

  constructor(
      private router: Router,
      private studioService: StudiosService,
      private profileService: ProfileService,
      private helperService: HelperService,
  ) {
  }

  ngOnInit(): void {
    this.studioService.mapStudioData(this.studio);
    this.studioAddress = this.studio.studioAddress;

    this.studioOffers = this.getBestOffers()
  }



  seeStudioDetails() {
    this.router.navigate(['/studio' , (this.studio.slug && this.studio.slug.length > 0) ? this.studio.slug : this.studio.id ]);

  }


  userHasLoggedIn(): boolean{
    return Object.keys(this.profileService.getUserData()).length !== 0;
  }

  studioRating(): number {
    if (this.studio.studioReviews && this.studio.studioReviews.length > 0 ) {
      const studioRating = this.studio.studioReviews
          .reduce( ( sum, { rating } ) => sum + rating , 0) / this.studio.studioReviews.length;
      return Math.round( studioRating * 10) / 10;
    } else {
      return 0;
    }
  }

  studioHasRecordingService(){
    let flag = false;
    this.studio.rooms.forEach((room) => {
      room.services.forEach((service) => {
        if (service.type === 'recording'){
          flag = true;
        }
      });
    });
    return flag;
  }

  studioHasRehearsalService(){
    let flag = false;
    this.studio.rooms.forEach((room) => {
      room.services.forEach((service) => {
        if (service.type === 'rehearsal'){
          flag = true;
        }
      });
    });
    return flag;
  }

  studioHasPodcastService(){
    let flag = false;
    this.studio.rooms.forEach((room) => {
      room.services.forEach((service) => {
        if (service.type === 'podcast'){
          flag = true;
        }
      });
    });
    return flag;
  }

  getColClass() {
    if (this.studioHasRehearsalService() && this.studioHasRecordingService() && this.studioHasPodcastService()){
      return 'col-4';
    }
    return 'col-6';
  }

  getCurrency() {
    return this.helperService.getCurrencySymbol(this.studio.businessCurrency ? this.studio.businessCurrency : 'EUR')
  }

  getBestOffers() {
    const res = {percentage: -1, amount: {cutoff: -1, payoff: 0, final: 0}}
    this.studio.rooms.map(room => {
      room.services.filter(service => !service.isDeleted).map(service => {
        const hourlyRate = service.price;
        service.discounts.map(discount => {
          if(discount.type === "percentage"){
            if(discount.rules.percentage > res.percentage) {
              res.percentage = discount.rules.percentage
            }
          }
          if(discount.type === "amount"){
            const {cutoff, payoff } = discount.rules
            if(cutoff > res.amount.cutoff) {
              res.amount = {
                cutoff,
                payoff,
                final: (cutoff * hourlyRate) - payoff
              }
            }
          }
        })
      })
    })

    return res;
  }
}
