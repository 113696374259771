<div class="grid bandbeatPage" *ngIf="user | async as userData">

    <div class="col-12">
        <div class="grid">
            <div class="col-12 md:col-5">

                <div class="grid">
                    <div class="col-fixed" style="width: 14rem">

                        <p-avatar
                                *ngIf="!allowEdit"
                                shape="circle"
                                class="top-avatar"
                                [image]="userData.hasOwnProperty('avatar') ? userData.avatar['link'] : 'assets/images/bandbeat/sample_user_logo.png'"
                        >
                        </p-avatar>

                        <ng-template
                                [ngIf]="allowEdit"
                        >


                            <p-avatar
                                    class="top-avatar"
                                    shape="circle"
                                    [image]="userData.hasOwnProperty('avatar') ? userData.avatar['link'] : 'assets/images/bandbeat/sample_user_logo.png'"
                            >
                            </p-avatar>
                            <button pButton
                                    id="logoButton"
                                    pRipple type="button"
                                    icon="till-noon-icon tnicon-Pencil"
                                    (click)="logoUpload.choose()"
                                    class="p-button-rounded p-button-icon-only p-button-text tn-primary-button  uploadButton"
                            >

                            </button>

                            <p-fileUpload
                                #logoUpload
                                class="logoUploader"
                                [customUpload]="true"
                                [auto]="true"
                                accept="image/*"
                                (uploadHandler)="imageUpload($event)"
                            ></p-fileUpload>

                        </ng-template>
                    </div>


                    <div class="col">
                        <p class="tn-title" style="margin-bottom:0">
                            {{userData.name}}
                        </p>
                        <span class="tn-text-small tn-text-green"  >
                            Jamming on Bandbeat since {{userData.createdAt | dateParser:'MMMM yyyy'}}
                        </span>


                        <ul class="socialList  pt-2 md:pt-4">
                            <li>
                                <button
                                        icon="till-noon-icon tnicon-Facebook"
                                        pButton
                                        type="button"
                                        [disabled]="!(userData.facebookHandle && userData.facebookHandle.length >0)"
                                        (click)="shareClicked('facebook' , userData.facebookHandle)"
                                        class="p-button-text tn-primary-button socialButton"
                                ></button>
                            </li>
                            <li>
                                <button
                                        icon="till-noon-icon tnicon-Instagram"
                                        pButton
                                        type="button"
                                        [disabled]="!(userData.instagramHandle && userData.instagramHandle.length >0)"
                                        (click)="shareClicked('instagram', userData.instagramHandle)"
                                        class="p-button-text tn-primary-button socialButton"
                                ></button>
                            </li>
                            <li>
                                <button
                                    icon="till-noon-icon tnicon-Spotify"
                                    pButton
                                    type="button"
                                    [disabled]="!(userData.youtubeHandle && userData.youtubeHandle.length >0)"
                                    (click)="shareClicked('youtube', userData.youtubeHandle)"
                                    class="p-button-text tn-primary-button socialButton"
                                ></button>
                            </li>
                            <li>
                                <button
                                    icon="till-noon-icon tnicon-Spotify"
                                    pButton
                                    type="button"
                                    [disabled]="!(userData.spotifyHandle && userData.spotifyHandle.length >0)"
                                    (click)="shareClicked('spotify', userData.spotifyHandle)"
                                    class="p-button-text tn-primary-button socialButton"
                                ></button>
                            </li>
                            <li>
                                <button
                                    icon="till-noon-icon tnicon-Spotify"
                                    pButton
                                    type="button"
                                    [disabled]="!(userData.tiktokHandle && userData.tiktokHandle.length >0)"
                                    (click)="shareClicked('tiktok', userData.tiktokHandle)"
                                    class="p-button-text tn-primary-button socialButton"
                                ></button>
                            </li>
                            <li>
                                <button
                                        icon="pi pi-share-alt"
                                        pButton
                                        type="button"
                                        label="Share Profile"
                                        class="p-button-text tn-primary-button"
                                        (click)="socialModalVisible = !socialModalVisible"
                                ></button>
                            </li>
                        </ul>

                    </div>

                </div>




            </div>

            <div class="col-12 md:col-7">

                <ul class="panelRoutes pt-2 md:pt-4" *ngIf="!previewMode">
                    <li *ngFor="let option of routeOptions">

                        <button
                                pButton
                                class="mr-3 mb-3 tn-primary-button"
                                [routerLink]="option.link"
                                [label]="option.label"
                                routerLinkActive="tn-primary-button-active"
                        ></button>

                    </li>

                </ul>


            </div>
        </div>

    </div>

    <div class="col-12">
        <router-outlet></router-outlet>
    </div>



</div>




<app-social-share
        [display]="socialModalVisible"
        (closeModalEmitter)="closeModal($event)"
></app-social-share>





