<div
        [ngClass]="{
        'pt-6' : summaryType !== 'overview',
        'booking-overview' : summaryType === 'overview'
        }"
        class="grid" *ngIf="studio | async as studioData">
    <div class="col-12" *ngIf="summaryType === 'overview'">
        <span  class="keyword tn-title-big">Booking</span><span class="keyword" style="font-weight: 400; font-size: 64px; line-height:68px"> Details </span>
<!--        <span class="tn-title ml-4" > {{studioData.name}}</span>-->
    </div>
    <div class="col-12 md:col-8">
        <div class="grid">
<!--            GIA TO OVERVIEW LOGIKA DEN THA XRISIMOPIITHI-->
            <div class="col-12 " *ngIf="summaryType === 'overview'">
                <div>
<!--                    <img-->
<!--                            [src]="(studioData.hasOwnProperty('logo') && studioData.logo) ? env.uploadUrl + studioData.logo.link : 'https://www.primefaces.org/primeng/assets/showcase/images/galleria/galleria11.jpg'"-->
<!--                            alt="studioLogo"-->
<!--                            style=" float:left;border-radius: 50%; width: 100px; height: 100px;vertical-align:middle;"-->
<!--                    />-->
                    <ul style="list-style-type: none; float:left;padding-left:0;">
                        <li>
                            <p class="tn-text-green tn-text-simple">STUDIO</p>
                    <p
                            class="tn-title"
                    >
                        {{studioData.name}}
                    </p>
                            <p
                                    class="tn-text-simple"
                            >
                    {{studioData.studioAddress}}
                    </p>
                        </li>

                        <li class="mt-4"  *ngIf="bookingShortId">
                            <p class="tn-text-green tn-text-big">BOOKING ID</p>
                            <p class=" tn-text-big">
                                #{{bookingShortId}}
                            </p>
                        </li>

                        <li class="mt-4">
                            <p class="tn-text-green tn-text-simple">STATUS</p>
                            <p class=" tn-text-big">
                                {{bookingStatus | normalizeBookingStatus}}
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
<!--            GIA TO OVERVIEW LOGIKA DEN THA XRISIMOPIITHI-->


            <div class="col" style="display: flex;" *ngIf="!band">
                <div style="margin-block: auto;">
                    <p class="tn-text-green tn-text-simple">SOLO ARTIST</p>
                    <p class="tn-title">{{userName}}</p>
                </div>
                <div>
                    <img
                            [src]="'assets/images/solo_booking.png'"
                            alt="solo artist studio booking"
                    />
                </div>
            </div>

            <div class="col-12" style="display: flex;" *ngIf="band">
                <div style="margin-block: auto;">
                    <p class="tn-text-green tn-text-simple">BAND</p>
                    <p class="tn-title">{{band.name}}</p>
                </div>
                <div>
                    <img
                            [src]="'assets/images/band_booking.png'"
                            alt="band studio booking"
                    />
                </div>
            </div>

            <div class="col-12" *ngIf="band">
                <p class="tn-text-green tn-text-simple">BANDMATES</p>
                <p class=" tn-text-simple" >{{ (invitedMembers | bandMembersParser).join(',')}}</p>
            </div>

            <div class="col-12">
                <p class="tn-text-green tn-text-simple">SERVICE</p>
                <p class=" tn-text-simple">{{serviceType | titlecase}}</p>
            </div>

            <div class="col-12">
                <p class="tn-text-green tn-text-simple">DATE & TIME</p>
                <ng-template
                        [ngIf]="recurringBooking === false"
                >
                    <p class=" tn-text-simple">
                        {{bookingDate  |  dateParser: 'EEEE, dd MMMM yyyy / '}}
                        {{bookingDate | durationParser:bookingDuration}}
                    </p>
                </ng-template>
                <ng-template
                        [ngIf]="recurringBooking === true"
                >
                    <p  class=" tn-text-simple"
                        *ngFor="let i of recurringDates; let index;let originalDates"
                    >
                            <span>{{i  |  dateParser: 'EEEE, dd MMMM yyyy / '}}{{i | durationParser:bookingDuration}}</span><span *ngIf="index < originalDates.length -1 ">,</span>
                    </p>
                </ng-template>
            </div>

            <div class="col-12">
                <p class="tn-text-green tn-text-simple">ROOM</p>
                <p class=" tn-text-simple">{{selectedRoom.name}}</p>
            </div>

            <div class="col-12" *ngIf="selectedInstruments.length > 0">
                <p class="tn-text-green tn-text-simple">INSTRUMENTS</p>
                <ul class="instrument-list">
                    <li *ngFor="let inst of selectedInstruments;">
                        <span class=" tn-text-simple">{{inst.equipment | equipmentParser}} - {{inst.equipment.name}} </span>
                    </li>
                </ul>
            </div>






<!--            GIA TO OVERVIEW LOGIKA DEN THA XRISIMOPIITHI-->
<!--            <div class="col-6 md:col-2" *ngIf="summaryType==='overview'">-->
<!--                <p class="tn-text-green tn-text-simple">Status</p>-->
<!--                    <p class=" tn-text-simple">-->
<!--                        {{bookingStatus | normalizeBookingStatus}}-->
<!--                    </p>-->
<!--&lt;!&ndash;                    <span *ngIf="bookingPaymentDone"> & Paid</span>&ndash;&gt;-->
<!--&lt;!&ndash;                <div *ngIf="needPaymentAction">&ndash;&gt;-->
<!--&lt;!&ndash;                    <span >Pending Payment</span>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--            </div>-->
<!--            GIA TO OVERVIEW LOGIKA DEN THA XRISIMOPIITHI-->



<!--            GIA TO OVERVIEW LOGIKA DEN THA XRISIMOPIITHI-->
            <div class="col-12">
            </div>
            <div
                class="col-12 md:col-6"
                *ngIf="userReview && env.reviewEnabled"
            >
                <p class="tn-text-green tn-text-simple">REVIEW</p>
                <app-comment
                    [review]="userReview"
                >
                </app-comment>
            </div>
<!--            GIA TO OVERVIEW LOGIKA DEN THA XRISIMOPIITHI-->


            <div class="col-12 md:col-8"
                 *ngIf="
            summaryType !== 'overview' ||
            (
                summaryType === 'overview' &&
                studioNotes &&
                studioNotes.length > 0
            )
         "
            >
                <p class="tn-title" style="font-weight:400;">Notes to the studio</p>
                <textarea
                        [(ngModel)]="studioNotes"
                        [rows]="5"
                        [cols]="30"
                        *ngIf="summaryType !== 'overview'"
                        class="notes-area"
                        pInputTextarea
                        [autoResize]="true"
                ></textarea>

                <p
                        *ngIf="summaryType === 'overview'"
                        style="
                line-break: anywhere;
                padding: 0.75rem 0.75rem;
                width:100%
            "
                >
                    {{studioNotes}}
                </p>

            </div>


        </div>
    </div>
    <div class="col-12 md:col-4">
        <app-booking-overview [studioId]=studioId
                              [studioName]="studioData.name"
                              [totalPrice]=totalPrice
                              [servicePrice]=totalServicePrice
                              [rentalPrice]=totalRentalPrice
                              [bookingId]="bookingId"
                              [bookingType]=bookingType
                              [bookingDate]=bookingDate
                              [bookingTime]="summaryType!=='overview' ? bookingTime : bookingTime | dateParser:'HH:mmaaa'"
                              [bookingDuration]=bookingDuration
                              [studioNotes]=studioNotes
                              [selectedService]=selectedService
                              [selectedDiscount]="selectedDiscount"
                              [selectedRoom]=selectedRoom
                              [rentedInstruments]=rentedInstruments
                              [promoCode]="selectedPromoCode"
                              [band]=bandId
                              [selectedMembers]=selectedMembers
                              [bookingsService]=bookingsService
                              [needBookAction]="needBookAction"
                              [needResponseAction]="needResponseAction"
                              [needReviewAction]="needReviewAction"
                              [needPaymentAction]="needPaymentAction"
                              [needConfirmAction]="needConfirmAction"
                              [bookingOverview]="bookingOverview"
                              [paymentDone]="bookingPaymentDone"
                              [recurringDates]="recurringBooking ? recurringDates : []"
                              [currency]="studioData.businessCurrency"
                              [showPaymentMethods]="showPaymentMethods"
                              (addPromoCodeEventEmitter)="addPromoCode($event)"
                              (addReviewEventEmitter)="showAddReview($event)"
                              (paymentEventEmitter)="showPaymentForm($event)"
        ></app-booking-overview>


        <div>
<!--            <form hidden="true" method="POST" ngNoForm  [action]="env.paymentLink">-->
<!--                <input-->
<!--                        *ngFor="let payKey of paymentKeys"-->
<!--                        type="text"-->
<!--                        [id]="payKey"-->
<!--                        [name]="payKey"-->
<!--                        [value]="paymentData[payKey]"-->
<!--                >-->
<!--                <input #paymentSubmit type="submit" value="Submit">-->
<!--            </form>-->



            <p-dialog
                    header="Billing Information"
                    [(visible)]="paymentFormVisible"
                    [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
                    [style]="{width: '25vw'}"
                    [draggable]="false"
                    [resizable]="false"
                    [modal]="true"
                    [blockScroll]="true"
                    [dismissableMask]="false"
                    [closable]="true"
            >
                <ng-template pTemplate="content">
                    <div class="p-fluid formgrid grid">
                        <div class="field col-6">
                            <label for="address">Address</label>
                            <input inputTrim type="text" pInputText id="address" [(ngModel)]="billingAddress.address" required autofocus />
                            <small class="p-invalid" *ngIf="submitted && !billingAddress.address">Address is required.</small>
                        </div>
                        <div class="field col-6">
                            <label for="address">Street Number</label>
                            <input inputTrim type="text" pInputText id="number" [(ngModel)]="billingAddress.number" required autofocus />
                            <small class="p-invalid" *ngIf="submitted && !billingAddress.number">Number is required.</small>
                        </div>
                        <div class="field col-6">
                            <label for="city">City</label>
                            <input inputTrim type="text" pInputText id="city" [(ngModel)]="billingAddress.city" required autofocus />
                            <small class="p-invalid" *ngIf="submitted && !billingAddress.city">City is required.</small>
                        </div>
                        <div class="field col-6">
                            <label for="zip">Zip Code</label>
                            <input inputTrim type="text" pInputText id="zip" [(ngModel)]="billingAddress.zip" required autofocus />
                            <small class="p-invalid" *ngIf="submitted && !billingAddress.zip">Zip Code is required.</small>
                        </div>
                        <div class="field col-12">
                            <label for="country">Country</label>
                            <p-dropdown
                                    [options]="countries"
                                    [(ngModel)]="billingAddress.country"
                                    optionLabel="country"
                                    optionValue="code"
                                    id="country"
                                    required
                            ></p-dropdown>
                            <small class="p-invalid" *ngIf="submitted && !billingAddress.country">Country is required.</small>
                        </div>

                        <div class="field col-12">
                            <p-checkbox id="saveBillingInfo" [(ngModel)]="saveBillingInfo" [binary]="true"></p-checkbox>
                            <label class="tn-text-big" for="saveBillingInfo" style="margin-left:1em;margin-bottom:auto !important;color: #FFFFFF;">Save Billing Info</label>
                        </div>


                    </div>
                </ng-template>

                <ng-template pTemplate="footer">
                    <button pButton pRipple label="Cancel" icon="pi pi-times" class="tn-danger-button" (click)="hideDialog()"></button>
                    <button pButton pRipple label="Continue to Payment" icon="pi pi-check" class="tn-primary-button mt-2" (click)="payBooking()"></button>

<!--                    <button-->
<!--                            pButton-->
<!--                            pRipple-->
<!--                            label="Continue to Payment"-->
<!--                            id="pay-button"-->
<!--                            icon="pi pi-check"-->
<!--                            class="tn-primary-button mt-2"-->
<!--                            (click)="initRevolutPopup()"-->
<!--                    ></button>-->

                </ng-template>
            </p-dialog>
        </div>
    </div>

<!--    <div class="col-12 md:col-3" *ngIf="band">-->
<!--        <app-band-simple-->
<!--                [item]=band-->
<!--                [userId]="userId"-->
<!--        ></app-band-simple>-->
<!--    </div>-->

<!--    <div class="col-12">-->
<!--        <div class = "grid">-->
<!--             <div class="col-4" *ngIf="membersAccepted && membersAccepted.length > 0">-->
<!--            <h5>Joined Members</h5>-->

<!--            <p-selectButton-->
<!--                    [options]="membersAccepted"-->
<!--                    multiple="multiple"-->
<!--                    disabled="true"-->
<!--                    optionValue="_id">-->
<!--                <ng-template let-member>-->
<!--                    <p-avatar-->
<!--                        [style]="{display: 'inline-flex'}"-->
<!--                        [image]="member.member.avatar ? env.uploadUrl + member.member.avatar['link'] : 'assets/images/sample_member.png'"-->
<!--                        shape="circle"-->
<!--                    ></p-avatar>-->
<!--                    <ng-template-->
<!--                            [ngIf]="member.user"-->
<!--                            [ngIfElse]="memberMember"-->
<!--                    >-->
<!--                        <span style="margin: 0 8px 0 7.5px;">{{member.user.name}}</span>-->
<!--                    </ng-template>-->
<!--                    <ng-template-->
<!--                        #memberMember-->
<!--                    >-->
<!--                        <span style="margin: 0 8px 0 7.5px;">{{member.member.name}}</span>-->
<!--                    </ng-template>-->

<!--                </ng-template>-->
<!--            </p-selectButton>-->
<!--        </div>-->

<!--        <div class="col-8" *ngIf="membersPending && membersPending.length > 0">-->
<!--            <h5>Pending Members</h5>-->

<!--            <p-selectButton-->
<!--                    [options]="membersPending"-->
<!--                    multiple="multiple"-->
<!--                    disabled="true"-->
<!--                    optionValue="_id">-->
<!--                <ng-template let-member>-->
<!--                    <p-avatar-->
<!--                        [style]="{display: 'inline-flex'}"-->
<!--                        [image]="member.member.avatar ? env.uploadUrl + member.member.avatar['link'] : 'assets/images/sample_member.png'"-->
<!--                        shape="circle"-->
<!--                    ></p-avatar>-->
<!--                    <ng-template-->
<!--                            [ngIf]="member.user"-->
<!--                            [ngIfElse]="memberMember"-->
<!--                    >-->
<!--                        <span style="margin: 0 8px 0 7.5px;">{{member.user.name}}</span>-->
<!--                    </ng-template>-->
<!--                    <ng-template-->
<!--                        #memberMember-->
<!--                    >-->
<!--                        <span style="margin: 0 8px 0 7.5px;">{{member.member.name}}</span>-->
<!--                    </ng-template>-->

<!--                </ng-template>-->
<!--            </p-selectButton>-->
<!--        </div>-->
<!--        </div>-->
<!--    </div>-->

</div>

<div
        [ngClass]="{ 'booking-overview' : summaryType === 'overview'}"
        class="grid pt-6">
    <div class="col-6 next-buttons">
        <p-button
                label="Back"
                (onClick)="previous()"
                styleClass="p-button-primary tn-primary-button"
                icon="till-noon-icon tnicon-Arrow-Left"
                iconPos="left"
        ></p-button>
    </div>
</div>




<p-dialog
        (onHide)="resetReview()"
        [(visible)]="reviewVisible"
        [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
        [style]="{width: '43.75rem'}"
        [header]="studioName"
        [modal]="true"
        styleClass="p-fluid new-review"
>
    <ng-template pTemplate="content">
        <!--        <img [src]="bandData.hasOwnProperty('avatar') && bandData.avatar ? env.uploadUrl + bandData.avatar.link : 'assets/images/bandbeat/sample_user_logo.png'" style="width:100%;height:auto;" alt="new member" class="product-image" >-->

        <div class="grid">
            <div class="col-12">
                <p class="tn-title tn-text-green">Your opinion matters!</p>
            </div>

            <div class="col-12">
                <div class="field">
                    <p class="tn-text-simple">Add a Rating*</p>
                    <p-rating [cancel]=false id="rating" [(ngModel)]="reviewRating"></p-rating>

                    <small class="p-invalid" *ngIf="!reviewRating">* Required</small>
                </div>
                <div class="field">
                    <p  class="tn-text-simple">Add a note</p>
                    <textarea
                            [(ngModel)]="reviewNote"
                            [rows]="5"
                            [cols]="30"
                            style="width:100%"
                            id="reviewnote"
                            pInputTextarea
                            [autoResize]="true"
                    ></textarea>
                </div>
            </div>


        </div>




    </ng-template>

    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="tn-danger-button" (click)="resetReview()"></button>
        <button pButton pRipple label="Submit" icon="pi pi-check" class="tn-primary-button mt-2" (click)="addReview()"></button>
    </ng-template>
</p-dialog>






<!--<p-dialog-->
<!--        [(visible)]="paymentResponseVisible"-->
<!--        [breakpoints]="{'960px': '75vw', '640px': '100vw'}"-->
<!--        [style]="{width: '50vw'}"-->
<!--        [draggable]="false"-->
<!--        [resizable]="false"-->
<!--        [modal]="true"-->
<!--        [blockScroll]="true"-->
<!--        [dismissableMask]="false"-->
<!--        [closable]="true"-->
<!--&gt;-->


<!--    <div class="grid">-->
<!--        <div class="row">-->

<!--            <div class="col-12 pb-8 pl-8 pr-8 text-center md:text-left flex align-items-center ">-->
<!--                <section style="width:100%" *ngIf="paymentResponse === true">-->
<!--                    <h1 class="welcomeMessage" >Your payment was <span class="keyword">successful</span>.</h1>-->
<!--                    <p class="subtitle mb-4" >Soon you will recieve an email from us as well.</p>-->
<!--                </section>-->
<!--                <section style="width:100%" *ngIf="paymentResponse === false">-->
<!--                    <h1 class="welcomeMessage" >Your payment was <span class="keyword">unsuccessful</span>.</h1>-->
<!--                    <p class="subtitle mb-4" >Please try again.</p>-->
<!--                </section>-->
<!--            </div>-->

<!--        </div>-->

<!--    </div>-->
<!--</p-dialog>-->
