<p-card [style]="{border: '1px solid #938F99', borderRadius : 12}" styleClass="p-card-shadow p-card-comment">

    <div class="grid grid-nogutter align-items-center">

        <div class="col-6">
            <p class="tn-text-simple" style="margin: auto">{{review.user.name | titleCaseExtendedPipe}}</p>
            <div class="tn-text-green">{{review.createdAt | dateParser:'dd-MM-yyyy'}}</div>
        </div>
        <div class="col-6">
            <p-rating style="float:right" [(ngModel)]="review.rating" stars="5" [readonly]="true" [cancel]="false"></p-rating>
        </div>

        <div
            *ngIf="review.note"
            class="col-12 pt-3"
        >
            <p>{{review.note}}</p>
        </div>

        <div class="col-12 comment-response mt-3"
                *ngIf="review.response"
        >
            <div class="grid grid-nogutter pl-3">

                <div class="col">
                    <p-avatar
                            shape="circle"
                            size="large"
                            [image]="review.studio.avatar ? review.studio.avatar['link'] : 'assets/images/bandbeat/sample_user_logo.png'"
                    >
                    </p-avatar>
                </div>
                <div class="col-10" >
                    <div class="tn-text-green">{{review.createdAt | dateParser:'dd-MM-yyyy'}}</div>
                    <p>{{review.response.note}}</p>
                </div>

            </div>
        </div>

    </div>

</p-card>
