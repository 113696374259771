<p-card [style]="{border: '1px solid #938F99', borderRadius : 12}" header="Step 1" styleClass="p-card-shadow"
        subheader="Subhead">
    <ng-template pTemplate="header">
        <img alt="Card" src="../../../../assets/images/instruments/sample_bass.png">
    </ng-template>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam
        deserunt
        quisquam repellat libero asperiores earum nam nobis, culpa ratione quam perferendis esse, cupiditate neque
        quas!</p>
</p-card>
