import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {MessageService} from "primeng/api";
import {UserService} from "../../services/user.service";
import {HelperService} from "../../services/helper.service";
import {ProfileService} from "../../services/profile.service";
import {filter, tap} from "rxjs/operators";
import {environment} from "../../../environments/environment";
import {InstrumentAndEquipmentService} from "../../services/instruments.equipments.service";

@Component({
  selector: 'app-wizard-user-media',
  templateUrl: './wizard-user-media.component.html',
  styleUrls: ['./wizard-user-media.component.scss']
})
export class WizardUserMediaComponent implements OnInit {

  public env = environment;

  public user;
  private userId;


  public selectedMusicRole;
  public showNewMusicRoleRow = false;

  public musicRolesOptions = [];

  public allowEdit = false;

  public allowEditInstruments = false;

  public musicRolesMap: Map<string, {
      icon: string,
      label: string
  }>;

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private messageService: MessageService,
      private userService: UserService,
      private helperService: HelperService,
      private musicRolesService: InstrumentAndEquipmentService,
      private profileService: ProfileService
  ) {

    this.userId = this.profileService.getUserData().id;

    this.user = this.userService.user$.pipe(
        filter((userData) => Object.keys(userData).length > 0),
        tap((data) => {
          this.userId = data.id;
            data.musicRoles = data.musicRoles.map((musicRole) => {
                musicRole.categoryLabel = this.musicRolesMap.get(musicRole.type).label;
                musicRole.categoryIcon = this.musicRolesMap.get(musicRole.type).icon;

                return musicRole;
            })
        })
    );


    this.userService.fetchUser(this.userId);

  }

  ngOnInit(): void {
      this.musicRolesOptions = this.musicRolesService.getMusicRoles();
      this.musicRolesMap = this.musicRolesService.getMusicRolesMap();

  }



    imageUpload(event) {
        this.userService.addUserImage(this.userId, event.files[0], 'avatar')
            .then((data) => {
                this.profileService.setUserData(data);
                this.messageService.add({
                    key: 'globalToast', severity: 'success', summary: 'Successful', detail: 'Image Uploaded', life: 3000
                });
            })
            .catch((err) => {
                const error = err.error;
                if (error.code === 500 && error.message === 'File too large') {
                    this.messageService.add({
                        key: 'globalToast',
                        severity: 'error',
                        summary: 'Error',
                        detail: 'File is over 4mb!',
                        life: 3000
                    });
                }
            });
    }


    addMusicRole() {
        this.showNewMusicRoleRow = true;
    }


    removeMusicRole(musicRole: any, event) {
        event.stopPropagation();
        this.userService.removeMusicRole(this.userId, musicRole._id)
            .then((result) => {
                this.messageService.add({
                    key: 'globalToast',
                    severity: 'success',
                    summary: 'Successful',
                    detail: 'Instrument Removed',
                    life: 3000
                });
            });
    }

    setMusicRoleAsPrimary(musicRole: any) {
        this.userService.editMusicRole(this.userId, musicRole._id)
            .then((result) => {
                this.messageService.add({
                    key: 'globalToast',
                    severity: 'success',
                    summary: 'Successful',
                    detail: 'Primary Instrument Selected',
                    life: 3000
                });
            });
    }

    saveMusicRole(event) {
        this.showNewMusicRoleRow = false;
        this.userService.addMusicRole(this.userId, {type: this.selectedMusicRole.type})
            .then((result) => {
                this.messageService.add({
                    key: 'globalToast',
                    severity: 'success',
                    summary: 'Successful',
                    detail: 'Instrument Added',
                    life: 3000
                });
                this.selectedMusicRole = undefined;
            })
            .catch((err) => {
                this.selectedMusicRole = undefined;
                this.messageService.add({
                    key: 'globalToast', severity: 'error', summary: 'Error', detail: err.error.message, life: 3000});
            });
    }



  public nextPage( skip = false) {
      this.router.navigate(['user-band'], { relativeTo: this.route.parent });
  }



  public previousPage() {
      this.router.navigate(['user-info'], { relativeTo: this.route.parent });
  }


}
