import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {AuthenticationService} from '../../services/authentication.service';
import {LocalStorageService} from 'ngx-webstorage';

@Injectable()
export class AuthenticatedGuard implements CanActivate {

  constructor(
    private authenticationService: AuthenticationService,
    private localSt: LocalStorageService,
    private router: Router,
  ) { }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (environment.production === false) {
      return true;
    }
    if (this.localSt.retrieve('user')) {
      return true;
    } else {
      const navigate: any[] = ['login'];
      if (state && state.url && state.url.length > 0) {
        navigate.push ( { redirect : state.url } );
      }
      this.router.navigate(navigate);
      return false;
    }

  }
}

