import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {MessageService} from "primeng/api";
import {UserService} from "../../services/user.service";
import {HelperService} from "../../services/helper.service";
import {InstrumentAndEquipmentService} from "../../services/instruments.equipments.service";
import {ProfileService} from "../../services/profile.service";
import {filter, tap} from "rxjs/operators";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-wizard-user-info',
  templateUrl: './wizard-user-info.component.html',
  styleUrls: ['./wizard-user-info.component.scss']
})
export class WizardUserInfoComponent implements OnInit {

  public env = environment;

  public user;
  private userId;

  public userFirstName;
  public userPhoneNumber;
  public userCountry;

  public phonePrefix = '(+30) ';
  public countries: any[];


  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private messageService: MessageService,
      private userService: UserService,
      private helperService: HelperService,
      private profileService: ProfileService
  ) {

    this.countries = this.helperService.getCountriesData();
    this.userId = this.profileService.getUserData().id;

    this.user = this.userService.user$.pipe(
        filter((userData) => Object.keys(userData).length > 0),
        tap((data) => {
          this.userId = data.id;
          this.userFirstName = data.name;
          this.userPhoneNumber =
              (data.phoneNumber && data.phoneNumber !== 6900000000) ? data.phoneNumber : null;
          this.userCountry = data.address.country;

        })
    );


    this.userService.fetchUser(this.userId);

  }


  ngOnInit(): void {
  }

  public selectCountry(event) {
    const selected = this.countries.find(country => this.userCountry === country.label);
    this.phonePrefix = '(+' + selected.phone + ') ';
  }

  public nextPage( skip = false) {

    if (!skip) {
      const editObject = {
        name : this.userFirstName ,
        phoneNumber : this.userPhoneNumber,
        address : {
          country: this.userCountry
        }
      };
      Object.keys(editObject).forEach((key) => {
        if (!editObject[key]) {
          delete editObject[key];
        }
      });
      this.userService.editUser( this.userId, editObject)
          .then((data) => {
            this.profileService.setUserData(data);
            this.messageService.add({
              key: 'globalToast',
              severity: 'success',
              summary: 'Confirmed',
              detail: 'Your settings changed',
              life: 3000
            });
            this.router.navigate(['user-media'], { relativeTo: this.route.parent });

          })
          .catch((err) => {
            this.messageService.add({
              key: 'globalToast', severity: 'error', summary: 'Error', detail: err.error.message, life: 3000});
            throw err;
          });
    } else {
      this.router.navigate(['user-media'], { relativeTo: this.route.parent });

    }
  }



  public previousPage() {
    this.router.navigate(['listing']);
  }


}
