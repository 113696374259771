import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';
import {MessageService} from 'primeng/api';
import {PaymentsService} from '../services/payments.service';

@Component({
  selector: 'app-payment-callback',
  templateUrl: './payment-callback.component.html',
  styleUrls: ['./payment-callback.component.scss']
})
export class PaymentCallbackComponent implements OnInit{

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private authenticationService: AuthenticationService,
      private messageService: MessageService,
      private paymentsService: PaymentsService
  ) { }

  ngOnInit(): void {
    const authCode = this.route.snapshot.queryParamMap.get('code');
    if (authCode) {

    } else {
        console.log(this.route.snapshot.queryParamMap)
    }
  }

}
