import {Component, Input, OnInit} from '@angular/core';
import {InstrumentAndEquipmentService} from "../../../services/instruments.equipments.service";

@Component({
  selector: 'app-tn-equipment',
  templateUrl: './tn-equipment.component.html',
  styleUrls: ['./tn-equipment.component.scss']
})
export class TnEquipmentComponent implements OnInit {

  @Input() equipment;
  equipmentMap;
  @Input() darkMode = false;
  constructor(private instrumentService: InstrumentAndEquipmentService) {
  }

  ngOnInit(): void {
    this.equipmentMap = this.instrumentService.getEquipmentMap();
    this.equipment.categoryLabel = this.instrumentService.getInstrumentsAndEquipments().find(e => e.type === this.equipment.category).name;
    this.equipment.icon = this.instrumentService.getInstrumentsAndEquipments().find(e => e.type === this.equipment.category).icon;

  }

}