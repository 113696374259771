import { Component } from '@angular/core';
import { AppComponent} from '../app.component';
import {HelperService} from '../services/helper.service';

@Component({
  selector: 'app-footer',
  templateUrl: './app.footer.component.html',
  styles: [`a {color: #FFFFFF}`],
  styleUrls: ['./app.footer.component.scss']
})
export class AppFooterComponent {
    year = new Date().getFullYear().toString();
    constructor(
        public app: AppComponent,
        public helperService: HelperService
    ) {}


    redirectToFaceBook() {
        window.open('https://www.facebook.com/bandbeat.official/', '_blank');
    }

    redirectToInstagram() {
        window.open('https://www.instagram.com/bandbeat.official/', '_blank');
    }

    redirectToSpotify() {
        window.open('https://open.spotify.com/user/312gt67zju3rbtl65nlhdqe7gxhm?si=c822cf5f9c864c98/', '_blank');
    }

    redirectToLinkedIn() {
        window.open('https://www.linkedin.com/company/bandbeat/', '_blank');
    }
}
