import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HelperService} from './helper.service';

@Injectable({
    providedIn: 'root'
})
export class RegistrationsService {


    private registration: BehaviorSubject<any>;
    public readonly registration$: Observable<any>;

    private registrations: BehaviorSubject<any>;
    public readonly registrations$: Observable<any>;

    private errors: BehaviorSubject<any>;
    public readonly errors$: Observable<any>;

    private dataStore: {
        registration: any,
        registrations: any[],
    };

    constructor( private helperService: HelperService ) {

        this.registration = new BehaviorSubject({}) as BehaviorSubject<any>;
        this.registration$ = this.registration.asObservable();

        this.registrations = new BehaviorSubject([]) as BehaviorSubject<any>;
        this.registrations$ = this.registrations.asObservable();

        this.errors = new BehaviorSubject({}) as BehaviorSubject<any>;
        this.errors$ = this.errors.asObservable();

        this.dataStore = {
            registration: {},
            registrations: [],
        };

    }

    createRegistration( email: string, event: string, userId?: string) {
        return this.helperService.postAction('/registrations', {email , event , user: userId})
            .toPromise()
            .then((data) => {
                Object.assign(this.dataStore.registration, data);
                this.registration.next(this.dataStore.registration);
                return data;
            });
    }

    fetchRegistration( registrationId: string) {
        return this.helperService.getAction('/registrations/' + registrationId )
            .toPromise()
            .then((data) => {
                Object.assign(this.dataStore.registration, data);
                this.registration.next(data);
                return data;
            });
    }


    fetchUserRegistrations( userId: string) {
        return this.helperService.getAction('/registrations/', {user : userId})
            .toPromise()
            .then((data) => {
                Object.assign(this.dataStore.registrations, data);
                this.registrations.next(data);
                return data;
            });
    }

    fetchEmailRegistrations( email: string) {
        return this.helperService.getAction('/registrations/', {email })
            .toPromise()
            .then((data) => {
                Object.assign(this.dataStore.registrations, data);
                this.registrations.next(data);
                return data;
            });
    }



}

