import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ProfileService} from '../../../services/profile.service';
import {BookingsService} from '../../../services/bookings.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PaymentsService} from '../../../services/payments.service';
import {environment} from '../../../../environments/environment';
import {HelperService} from '../../../services/helper.service';
import {MessageService} from 'primeng/api';

@Component({
    selector: 'app-booking-overview',
    templateUrl: './booking-overview.component.html',
    styleUrls: ['./booking-overview.component.scss']
})
export class BookingOverviewComponent implements OnInit, OnChanges {

    @Input() totalPrice;
    @Input() servicePrice;
    @Input() rentalPrice;
    @Input() studioId;
    @Input() bookingType;
    @Input() bookingDate;
    @Input() bookingTime;
    @Input() bookingDuration;
    @Input() studioNotes;
    @Input() selectedService;
    @Input() selectedDiscount;
    @Input() selectedRoom;
    @Input() rentedInstruments;
    @Input() promoCode;
    @Input() band;
    @Input() bookingId;
    @Input() needResponseAction;
    @Input() needBookAction;
    @Input() needReviewAction;
    @Input() needPaymentAction;
    @Input() needConfirmAction;
    @Input() paymentDone;
    @Input() selectedMembers;
    @Input() bookingOverview;
    @Input() bookingsService: BookingsService;
    @Input() studioName;
    @Input() recurringDates = [];
    @Input() currency = 'EUR';
    @Input() showPaymentMethods = false;

    @Output() addReviewEventEmitter = new EventEmitter();
    @Output() paymentEventEmitter = new EventEmitter();
    @Output() addPromoCodeEventEmitter = new EventEmitter();

    paymentOptions = [
        {label: 'At Studio', value: 'cash', icon: 'pi pi-money-bill' , disabled : false},
        {label: 'Online', value: 'card', icon: 'pi pi-credit-card' , disabled : false}
    ];
    selectedPayment = 'card';

    requestVoteOptions = [
        {label: 'Send Request to Bandmates', value: true, icon: 'pi pi-money-bill'},
        {label: 'Send Request to Studio', value: false, icon: 'pi pi-money-bill'},
    ];
    requestVote = false;

    promoCodePromo;

    public soloBand = false;


    public env = environment;

    constructor(
        private profileService: ProfileService,
        private helperService: HelperService,
        private messageService: MessageService,
        private router: Router,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
        this.soloBand = this.selectedMembers.length === 1 && this.selectedMembers[0]=== this.profileService.getUserData().id;
    }

    ngOnChanges(changes: SimpleChanges) {
        if ( this.totalPrice === 0 ) {
            this.selectedPayment = 'cash';
            this.paymentOptions.find(el => el.value === 'card').disabled = true;
        }
    }

    sendToStudio() {
        this.bookingsService.editBooking(this.bookingId ,
            {
                status : this.selectedPayment === 'card' ? 'pre-send-booking' : 'pending-confirmation',
                paymentMethod : this.selectedPayment === 'card' ? 'online' : 'offline'
            })
            .then((data) => {
                if (this.selectedPayment === 'card') {
                    this.router.navigate(['/']).then(() => {
                        this.router.navigate(
                            ['profile', this.profileService.getUserData().id, 'bookings', data.id ],
                            {
                                queryParams: {requirePayment: true}
                            });
                    })

                } else {
                    if(this.showPaymentMethods){
                        this.router.navigate(
                        ['request' ],
                        {
                            relativeTo: this.route.parent.parent,
                            queryParams: {withInvites: "false", studioName: this.studioName}
                        });
                    }else{
                        this.router.navigate(['/']).then(() => {
                            this.router.navigate(
                                ['profile', this.profileService.getUserData().id, 'bookings', data.id ],
                                {
                                });
                        })
                    }

                }


            })
            .catch((err) => {
                /*console.log(err);*/
            });
    }

    confirmBooking( ) {
        const bookingDate = new Date( this.bookingDate );
        bookingDate.setHours(this.bookingTime.substring(0, this.bookingTime.indexOf(':')));
        bookingDate.setMinutes(this.bookingTime.substring(this.bookingTime.indexOf(':') + 1));

        const bookingObject: any = {
            studio : this.studioId,
            bookingType : this.bookingType,
            bookingDate : bookingDate.toISOString(),
            duration : parseInt(this.bookingDuration, 10),
            notes : [],
            service : this.selectedService.id,
            room : this.selectedRoom.id,
        };

        if (this.recurringDates.length > 0) {
            bookingObject.recurringDates = this.recurringDates.filter(el => el.toISOString !== bookingObject.bookingDate)
        }
        if (this.studioNotes) {
            bookingObject.notes.push(this.studioNotes);
        }
        if (this.rentedInstruments && this.rentedInstruments.length > 0) {
            bookingObject.instruments = this.rentedInstruments;
        }
        if (this.bookingType === 'band') {
            bookingObject.team = this.band;
            bookingObject.members = this.selectedMembers;
        } else {
            bookingObject.user = this.profileService.getUserData().id;
        }

        if ( this.requestVote ) {
            bookingObject.status = 'planning';
        } else {
            if ( this.selectedPayment === 'card' ) {
                bookingObject.status = 'pre-send-booking';
            }else{
                bookingObject.status = 'pending-confirmation';
            }
        }

        if ( this.selectedPayment === 'card' ) {
            bookingObject.paymentMethod = 'online';
        }

        if (this.promoCode) {
            bookingObject.promoCode = this.promoCode.id;
        }

        this.bookingsService.createBooking(bookingObject)
            .then((data) => {
                if (this.selectedPayment === 'card') {
                    const qParams = {};
                    qParams['id'] = data.id;
                    if (bookingObject.status !== 'planning') {
                        qParams['requirePayment'] = true;
                        qParams['showPaymentMethods'] = true;
                        this.showPayBooking(data.id)
                        // this.router.navigate([], {queryParams: qParams, queryParamsHandling: "merge"});
                    }else{
                        this.router.navigate(
                                ['profile' , this.profileService.getUserData().id , 'bookings', data.id ],
                                {
                                    relativeTo: this.route.parent.parent.parent,
                                    queryParams: qParams
                                });
                    }
                } else {
                    this.router.navigate(
                        ['request' ],
                        {
                            relativeTo: this.route.parent.parent,
                            queryParams: {withInvites: bookingObject.status === 'planning', studioName: this.studioName}
                        });
                }

            })
            .catch((err) => {
                /*console.log(err);*/
            });
    }

    responseInvite(accept = true): void {
        if (accept) {
            this.bookingsService.acceptMemberBooking( this.bookingId )
                .then((data) => {
                    this.router.navigate(
                        ['profile' , this.profileService.getUserData().id , 'bookings' ],
                        // {
                        //     relativeTo: this.route.parent.parent
                        // }
                        );
                });
        } else {
            this.bookingsService.declineMemberBooking( this.bookingId )
                .then((data) => {
                    this.router.navigate(
                        ['profile' , this.profileService.getUserData().id , 'bookings' ],
                        // {
                        //     relativeTo: this.route.parent.parent
                        // }
                        );
                });
        }
    }

    showAddReview() {
        this.addReviewEventEmitter.emit(true);
    }

    showPayBooking(bookingId) {
        this.paymentEventEmitter.emit({bookingId});
    }

    checkPromoCode() {
        this.addPromoCodeEventEmitter.emit( { promoCode : this.promoCodePromo , paymentMethod : this.selectedPayment === 'card' ? 'online' : 'offline' } );
    }

    changePaymentMethod(event) {
        if (this.promoCode) {
            this.checkPromoCode()
        }
    }

    getCurrency( symbol = true ) {
        if (symbol) {
            return this.helperService.getCurrencySymbol(this.currency)
        } else {
            return this.currency;
        }
    }

}
