<div class="grid " *ngIf="user | async as userData">

    <div class="col-12 md:hidden align-content-start" *ngIf="!previewMode">
        <button
                pButton
                label="INVITE YOUR FRIENDS ON BANDBEAT"
                type="button"
                class=" tn-primary-button "
                (click)="openInvite()"
        ></button>
    </div>

    <div class="col-12 md:col-4">

        <p class="tn-title" style="padding-bottom:2rem;font-weight:400;">Personal Info</p>

        <ul class="profile-elements" >
            <li>
                <p class="tn-text-simple tn-text-green ">FIRST NAME</p>
                <p *ngIf="!allowEdit" class="tn-text-simple">{{userData.name}}</p>
                <input
                        *ngIf="allowEdit"
                        id="userFname"
                        inputId="userFname"
                        type="text"
                        pInputText
                        [(ngModel)]="userFirstName"
                />
            </li>
            <li>
                <p class="tn-text-simple tn-text-green">INSTAGRAM PROFILE</p>
                <p *ngIf="!allowEdit" class="tn-text-simple">{{userData.instagramHandle}}</p>
                <div class="p-inputgroup"
                     *ngIf="allowEdit"
                >
                    <span class="p-inputgroup-addon">https://www.instagram.com/</span>

                    <input
                            inputTrim
                            id="userInstagramProfile"
                            inputId="userInstagramProfile"
                            type="text"
                            pInputText
                            [(ngModel)]="userInstagramProfile"
                    />

                </div>


            </li>
            <li>
                <p class="tn-text-simple tn-text-green">FACEBOOK PROFILE</p>
                <p *ngIf="!allowEdit" class="tn-text-simple">{{userData.facebookHandle}}</p>
                <div class="p-inputgroup"
                     *ngIf="allowEdit"
                >
                    <span class="p-inputgroup-addon">https://www.facebook.com/</span>
                    <input
                        inputTrim
                        id="userFacebookProfile"
                        inputId="userFacebookProfile"
                        type="text"
                        pInputText
                        [(ngModel)]="userFacebookProfile"
                    />
                </div>
            </li>
            <li>
                <p class="tn-text-simple tn-text-green">YOUTUBE PROFILE</p>
                <p *ngIf="!allowEdit" class="tn-text-simple">{{userData.youtubeHandle}}</p>
                <div class="p-inputgroup"
                     *ngIf="allowEdit"
                >
                    <span class="p-inputgroup-addon">https://www.youtube.com/</span>
                    <input
                        inputTrim
                        id="userYoutubeProfile"
                        inputId="userYoutubeProfile"
                        type="text"
                        pInputText
                        [(ngModel)]="userYoutubeProfile"
                    />
                </div>
            </li>
            <li>
                <p class="tn-text-simple tn-text-green">TIKTOK PROFILE</p>
                <p *ngIf="!allowEdit" class="tn-text-simple">{{userData.tiktokHandle}}</p>
                <div class="p-inputgroup"
                     *ngIf="allowEdit"
                >
                    <span class="p-inputgroup-addon">https://www.tiktok.com/</span>
                    <input
                        inputTrim
                        id="userTiktokProfile"
                        inputId="userTiktokProfile"
                        type="text"
                        pInputText
                        [(ngModel)]="userTiktokProfile"
                    />
                </div>
            </li>
            <li>
                <p class="tn-text-simple tn-text-green">SPOTIFY PROFILE</p>
                <p *ngIf="!allowEdit" class="tn-text-simple">{{userData.spotifyHandle}}</p>
                <div class="p-inputgroup"
                     *ngIf="allowEdit"
                >
                    <span class="p-inputgroup-addon">https://open.spotify.com/artist/</span>
                    <input
                        inputTrim
                        id="userSpotifyProfile"
                        inputId="userSpotifyProfile"
                        type="text"
                        pInputText
                        [(ngModel)]="userSpotifyProfile"
                    />
                </div>
            </li>
            <li>
                <p class="tn-text-simple tn-text-green">ABOUT</p>
                <p *ngIf="!allowEdit" class="tn-text-simple">{{userData.description | readMore:moreDescription:300}}</p>
                <div
                        class="flex justify-content-center md:justify-content-end pt-2"
                        *ngIf="!allowEdit && userData.description && userData.description.length > 300"
                >
                    <a (click)="moreDescription = !moreDescription"  class="tn-text-green tn-text-xs cursor-pointer">
                        <span *ngIf="!moreDescription">READ MORE</span>
                        <span *ngIf="moreDescription">READ LESS</span>
                        <i style="padding-left: 1rem;"
                           [ngClass]="{'pi-chevron-down' : !moreDescription , 'pi-chevron-up' : moreDescription}"
                           class="pi tn-text-xs"></i>
                    </a>
                </div>
                <textarea
                        *ngIf="allowEdit"
                        [(ngModel)]="userAbout"
                        [rows]="5"
                        [cols]="30"
                        style="width:100%"
                        pInputTextarea
                        [autoResize]="true"
                ></textarea>
            </li>
            <li>
                <button
                        *ngIf="!allowEdit && !previewMode"
                        icon="till-noon-icon tnicon-Pencil"
                        pButton
                        label="EDIT"
                        type="button"
                        class=" tn-primary-button "
                        (click)="allowEdit = !allowEdit"
                ></button>
                <button
                        *ngIf="allowEdit"
                        icon="pi pi-check"
                        pButton
                        label="SAVE"
                        type="button"
                        class=" tn-secondary-button "
                        (click)="saveUser();"
                ></button>
            </li>
        </ul>
    </div>

    <div class="col-12 md:col-8">

        <div class="grid ">

            <div class="col-12 hidden md:block " *ngIf="!previewMode">
                <button
                        pButton
                        label="INVITE YOUR FRIENDS ON BANDBEAT"
                        type="button"
                        style="float:right"
                        class=" tn-primary-button "
                        (click)="openInvite()"
                ></button>
            </div>

            <div class="col-12">

                <p-card
                        class="instrument-card"
                        header="Instruments">

                    <p class="tn-text-small tn-text-green hidden md:block" >
                        Here you can add all the instruments you play!
                    </p>

                    <p class="tn-text-small tn-text-green hidden md:block" >
                        You can also select your main instrument simply by clicking on it after you add it.
                    </p>


                    <p class="tn-text-small tn-text-green md:hidden" >
                        Here you can add all the instruments you play! You can also select your main instrument simply by clicking on it after you add it.
                    </p>



                    <p-dataView #dvListingEq
                                [value]="userData.musicRoles"
                                styleClass="equipment-dv"
                                layout="grid">
                        <ng-template let-musicRole pTemplate="gridItem">
                            <div class="col-12 md:col-6 lg:col-3">
                                <div class="equipment-list-item" (click)="setMusicRoleAsPrimary(musicRole)">
                                    <div class="grid  align-items-center" style="margin-bottom:-1rem; width: 100%;" >
                                        <div class="col-4 md:col-4 lg:col-3">
                                            <i class="md:inline-block hidden circular-icon till-noon-icon {{musicRole.categoryIcon}}"></i>
                                            <i class="md:hidden circular-icon circular-icon till-noon-icon till-noon-icon-big {{musicRole.categoryIcon}}"></i>

                                            <button pButton
                                                    *ngIf="musicRole.primary === true"
                                                    pRipple type="button" icon="pi pi-star-fill"
                                                    class="p-button-rounded p-button-icon-only primary-music-role">
                                            </button>


                                        </div>
                                        <div class="col-4 md:col-8 pl-0">
                                            <p class="tn-text-simple instrument-name">{{musicRole.categoryLabel | titleCaseExtendedPipe}}</p>

                                            <button
                                                    *ngIf="allowEditInstruments"
                                                    pButton
                                                    class="remove-music-role"
                                                    icon="pi pi-times"
                                                    label="DELETE"
                                                    pRipple
                                                    type="button"
                                                    (click)="removeMusicRole(musicRole, $event);allowEditInstruments = !allowEditInstruments;"
                                                    >
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </ng-template>

                        <ng-template

                                [ngIf]="!previewMode"
                        >
                            <ng-template
                                    pTemplate="footer"
                            >
                                <div
                                        class="flex justify-content-start"
                                        *ngIf="!allowEditInstruments"
                                >
                                    <button
                                            icon="till-noon-icon tnicon-Pencil"
                                            pButton
                                            label="EDIT"
                                            type="button"
                                            class=" tn-primary-button "
                                            (click)="allowEditInstruments = !allowEditInstruments"
                                    ></button>
                                </div>

                                <div
                                        *ngIf="allowEditInstruments"
                                        class="flex align-items-stretch edit-instruments"
                                >


                                    <p-dropdown
                                            [options]="musicRolesOptions"
                                            [(ngModel)]="selectedMusicRole"
                                            placeholder="Select an instrument"
                                            autoDisplayFirst="false"
                                            optionLabel="label"
                                            styleClass="mr-4 instrument-options"
                                    >
                                        <ng-template let-musicRole pTemplate="item">
                                            <div class="instrument-item">
                                                <div class="{{musicRole.icon}}"></div>
                                                <div>{{musicRole.label}}</div>
                                            </div>
                                        </ng-template>
                                    </p-dropdown>


                                    <button
                                            icon="pi pi-check"
                                            pButton
                                            label="SAVE"
                                            type="button"
                                            class=" tn-secondary-button "
                                            (click)="saveMusicRole();allowEditInstruments = !allowEditInstruments;"
                                    ></button>

                                </div>

                            </ng-template>
                        </ng-template>

                    </p-dataView>

                </p-card>

            </div>

            <div class="col-12" id="userBands">
                <app-user-bands
                        [userId]="userData.id"
                        [userName]="userData.name"
                        [viewInvites] = viewBandsInvites
                ></app-user-bands>
            </div>

        </div>

    </div>




    <p-dialog [(visible)]="inviteDialog" [style]="{width: '450px'}" header="Invite a Friend" [modal]="true" [closable]="true">
        <ng-template pTemplate="content">
            <div class="p-fluid">

                <div class="field">
                    <label for="email">Email</label>
                    <input inputTrim type="text" pInputText id="email" [(ngModel)]="inviteEmail" required autofocus/>
                    <small class="p-invalid" *ngIf="inviteSubmitted && !inviteEmail">Email is required.</small>
                </div>

            </div>
        </ng-template>

        <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text tn-danger-button" (click)="hideDialog()"></button>
            <button pButton label="Invite" icon="pi pi-check" class="p-button-text tn-primary-button" (click)="inviteUser()"></button>

        </ng-template>
    </p-dialog>



</div>


<app-social-share
        [display]="socialModalVisible"
        (closeModalEmitter)="closeModal($event)"
></app-social-share>


