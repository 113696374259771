import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterAccountTypes'
})
export class FilterAccountTypesPipe implements PipeTransform {

  transform(accountTypes: any[], args: any[]): any[] {
    const selectedIndex = accountTypes.findIndex(el => el.code === args[0]);
    if (selectedIndex < 0) {
      return accountTypes;
    }
    if (args[1] === 0) {
      return accountTypes.slice(0,selectedIndex + 1);
    }
    if (args[1] === 1) {
      return accountTypes.slice(selectedIndex + 1)
    }

    return accountTypes;
  }

}
