import { Injectable } from '@angular/core';
import {LocalStorage, LocalStorageService} from 'ngx-webstorage';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  private userData: any = {};
  private studioData: any = {};

  constructor(
    private localSt: LocalStorageService,
  ) {
    if (this.localSt.retrieve('user')) {
      this.setUserData(this.localSt.retrieve('user'));
    }
    if (this.localSt.retrieve('studio')) {
      this.setOwnerStudio(this.localSt.retrieve('studio'));
    }
  }

  setUserData(userData) {
    Object.assign(this.userData, userData);
    this.localSt.store('user', userData);
  }

  getUserData() {
    return this.userData;
  }

  clearUserData() {
    this.userData = {};
  }

  setOwnerStudio(studioData) {
    this.studioData = studioData;
    this.localSt.store('studio', studioData);
  }

  getOwnerStudio() {
    return this.studioData;
  }

  clearOwnerStudio() {
    this.studioData = {};
  }

  logOutUser() {
    this.clearUserData();
    this.clearOwnerStudio();
    this.localSt.clear();
  }

  isLoggedIn() {
    return Object.keys(this.getUserData()).length !== 0;
  }
  isAdmin() {
    return this.isLoggedIn() && this.userData.role === 'admin';
  }

  isStudio() {
    return this.isLoggedIn() && this.userData.role === 'partner';
  }

  isUser() {
    return this.isLoggedIn() && this.userData.role === 'user';
  }

}
