<p-card
        [ngClass]="{'dark-mode' : darkMode === true , 'grey-mode' : darkMode === false, height: '100%'}"
        [style]="{border: '1px solid #938F99', borderRadius : 12}"
        styleClass="p-card-shadow"
>

    <div class="dynamic-height" style="margin-bottom:-1rem" >
        <div class="equipment-card-header">
            <span>
            <i class="md:inline-block hidden circular-icon till-noon-icon {{equipment.icon}}"></i>
            <i class="md:hidden inline-block circular-icon till-noon-icon till-noon-icon-big {{equipment.icon}}"></i>
            </span>

        <span class="card-header-title">
            <p class="tn-text-green tn-text-normal">{{equipment.categoryLabel | titleCaseExtendedPipe}}</p>
        </span>
        </div>
        <div class="equipment-card-body" >
            <p class="tn-text-big" style="word-break: break-all">{{equipment.brand !== 'other' ? (equipment.brand | titleCaseExtendedPipe) + ' - ' : ''}} {{equipment.name | readMore:false:40}}</p>
        </div>
        <div >
            <span class="tn-text-small" style="word-break: break-all">{{equipment.description | readMore:false:125}}</span>
        </div>
    </div>

</p-card>
