import {Component, HostListener, OnInit} from '@angular/core';
import {StudiosService} from '../services/studios.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {filter, map, tap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {ProfileService} from '../services/profile.service';
import {MessageService} from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { OnDestroy } from '@angular/core';
import {HelperService} from "../services/helper.service";

@Component({
  selector: 'app-studio',
  templateUrl: './studio.component.html',
  styleUrls: ['./studio.component.scss']
})
export class StudioComponent implements OnInit, OnDestroy {

    public studio: any;
    public studioId: string;
    public studioSlug: string;
    public env = environment;
    public showMoreVisible = false;

    public availableServices = [];
    instruments: any;

    public studioCoordinates;

    public loginModalVisible = false;
    public socialModalVisible = false;

    public reviews: any;
    public studioName: any;
    public studioRating: any;
    public studioRatings: number;
    public reviewModal = false;

    public moreEquipment = false;
    public moreInstruments = false;
    public moreDescription = false;

    public isMobile = false;

    public offersPerRoom;
    public hasOffers = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private studioService: StudiosService,
        private profileService: ProfileService,
        private helperService: HelperService,
        private messageService: MessageService,
        private title: Title
    ) {
    }

    @HostListener('document:keyup.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        if (this.showMoreVisible) {
            this.showMoreVisible = false;
        }
    }

    ngOnInit(): void {
        this.isMobile = this.helperService.isMobile();

        this.reviews = this.studioService.reviews$.pipe(
            filter(std => Object.keys(std).length !== 0),
            map((data) => {
                return data.results;
            }),
            tap((data) => {
                if (data && data.length > 0) {
                    this.studioRating = data.reduce( ( sum, { rating } ) => sum + rating , 0) / data.length;
                    this.studioRatings = data.length;

                }
            })

        );

        this.studio = this.studioService.studio$.pipe(
            filter(std => Object.keys(std).length !== 0),
            map((data) => {
                this.studioId = data.id;
                this.studioName = data.name;
                data = this.studioService.mapStudioData(data);

                if (data.availableServices.rehearsal.length > 0)
                    {
                        this.availableServices.push('rehearsal');
                    }
                if (data.availableServices.recording.length > 0)
                    {
                        this.availableServices.push('recording');
                    }

                this.instruments = data.instruments
                    .filter((inst) => inst.available);

                this.studioCoordinates = data.coordinates;

                this.studioService.fetchStudioReviews(this.studioId, true);

                this.offersPerRoom = this.studioHasOffers(data)
                this.hasOffers = Object.values(this.offersPerRoom).some((el:any) => el.hasOffers)

                this.helperService.trackViewContent('STUDIO' , this.studioId);
                return data;
            }),
            tap((data) => this.title.setTitle('Bandbeat - ' + data.name))
        );

        this.route.params.subscribe((params: Params) => {
            this.studioSlug = params.studioSlug;
            this.studioService.fetchStudio(this.studioSlug);
        });
    }

    ngOnDestroy() {
        this.title.setTitle('Bandbeat');
    }

    closeModal(displayState) {
        this.loginModalVisible = false;
        this.socialModalVisible = false;
    }

    startBooking(roomId?: string) {
        if (Object.keys(this.profileService.getUserData()).length === 0) {
            if (this.loginModalVisible) {
                this.loginModalVisible = false;
            }
            this.loginModalVisible = true;
        } else {
            const parameters: any = {};
            parameters.studio = this.studioId;
            if (roomId) {
                parameters.room = roomId;
            }
            this.router.navigate(['newbooking'], {queryParams: parameters});
        }


    }

    shareClicked(buttonClicked: string, socialHande?: string) {
        if (buttonClicked === 'facebook') {
            window.open('https://www.facebook.com/' + socialHande, '_blank').focus();
        } else if (buttonClicked === 'instagram') {
            window.open('https://www.instagram.com/' + socialHande, '_blank').focus();
        }
    }

    userHasLoggedIn(): boolean{
        return Object.keys(this.profileService.getUserData()).length !== 0;
    }

    openGMaps() {
        this.helperService.openGMaps('search', this.studioCoordinates);
    }

    studioHasOffers(data){
        return data.rooms.reduce((acc, room) =>{
            if(!acc[room.id]) acc[room.id] = {hasOffers: false};
            room.services.forEach(service => {
                if(!acc[room.id][service.type])acc[room.id][service.type] = {};
                acc[room.id][service.type].hourlyRate = service.price;
                service.discounts.forEach(discount => {
                    if(!acc[room.id][service.type][discount.type])acc[room.id][service.type][discount.type] = [];
                    acc[room.id][service.type][discount.type].push({rules: discount.rules, name: discount.name})
                    acc[room.id].hasOffers = true;
                })
            })

            return acc;
        }, {})
    }
}
