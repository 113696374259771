import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'verbalizeBookingStatus'
})
export class VerbalizeBookingStatusPipe implements PipeTransform {
  transform(value: string, arg?: string): string {
    switch (value) {
      case 'studio-declined':
        return 'had a declined request for';
      case 'studio-confirmed':
        return 'booked';
      case 'pending-confirmation':
      case 'planning':
        return 'requested';
      default:
        return 'requested';
    }
  }
}
