import {Component, OnInit} from '@angular/core';
import {environment} from "../../environments/environment";
import {ActivatedRoute} from "@angular/router";
import {MessageService} from "primeng/api";
import {UserService} from "../services/user.service";
import {HelperService} from "../services/helper.service";
import {InstrumentAndEquipmentService} from "../services/instruments.equipments.service";
import {ProfileService} from "../services/profile.service";
import {filter, switchMap, tap} from "rxjs/operators";

@Component({
  selector: 'app-user-panel',
  templateUrl: './user-panel.component.html',
  styleUrls: ['./user-panel.component.scss']
})
export class UserPanelComponent implements OnInit {

  public env = environment;

  public user;
  private userId;


  public userFirstName;
  public userFacebookProfile;
  public userInstagramProfile;
  public userAbout;

  public allowEdit = false;
  public previewMode = false;
  public socialModalVisible = false;

  public routeOptions = [
      {
          label : 'PROFILE',
          link : 'user',
      },
      {
          label : 'BOOKINGS',
          link : 'bookings',
      },
      {
          label : 'NOTIFICATIONS',
          link : 'notifications',
      },
      {
          label : 'ACCOUNT',
          link : 'settings',
      }
  ]

  constructor(
      private route: ActivatedRoute,
      private messageService: MessageService,
      private userService: UserService,
      private helperService: HelperService,
      private musicRolesService: InstrumentAndEquipmentService,
      private profileService: ProfileService
  ) {
    this.route.params.pipe(
        switchMap((data) => {
            this.userId = data.userSlug ? data.userSlug : data.id;
            if (data.userSlug) {
                this.previewMode = true;
            }
            return this.userService.fetchUser(this.userId);
        })
        ,
    ).subscribe((data) => {
        if (this.userId === this.profileService.getUserData().id && !this.previewMode) {
            this.allowEdit = true;
            this.previewMode = false;
        } else {
            this.previewMode = true;
            this.allowEdit = false;
        }
    })
    this.user = this.userService.user$.pipe(
        filter((userData) => Object.keys(userData).length > 0),
        tap((data) => {
          this.userFirstName = data.name;
          this.userAbout = data.description;
          this.userFacebookProfile = data.facebookHandle ? data.facebookHandle : '';
          this.userInstagramProfile = data.instagramHandle ? data.instagramHandle : '';
        })
    );


  }


  ngOnInit(): void {
  }


  imageUpload(event) {
    this.userService.addUserImage(this.userId, event.files[0], 'avatar')
        .then((data) => {
          this.profileService.setUserData(data);
          this.messageService.add({
            key: 'globalToast', severity: 'success', summary: 'Successful', detail: 'Image Uploaded', life: 3000
          });
        })
        .catch((err) => {
          const error = err.error;
          if (error.code === 500 && error.message === 'File too large') {
            this.messageService.add({
              key: 'globalToast',
              severity: 'error',
              summary: 'Error',
              detail: 'File is over 4mb!',
              life: 3000
            });
          }
        });
  }



    shareClicked(buttonClicked: string, socialHande?: string) {
        if (buttonClicked === 'facebook') {
            window.open('https://www.facebook.com/' + socialHande, '_blank').focus();
        } else if (buttonClicked === 'instagram') {
            window.open('https://www.instagram.com/' + socialHande, '_blank').focus();
        } else if (buttonClicked === 'youtube') {
            window.open('https://www.youtube.com/' + socialHande, '_blank').focus();
        } else if (buttonClicked === 'tiktok') {
            window.open('https://www.tiktok.com/' + socialHande, '_blank').focus();
        } else if (buttonClicked === 'spotify') {
            window.open('https://open.spotify.com/artist/' + socialHande, '_blank').focus();
        }

    }

    closeModal(displayState) {
        this.socialModalVisible = false;
    }

}
