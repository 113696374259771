import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {StudiosService} from '../../../services/studios.service';
import {ProfileService} from '../../../services/profile.service';
import {HelperService} from "../../../services/helper.service";

@Component({
    selector: 'app-studio-advanced',
    templateUrl: './studio-advanced.component.html',
    styleUrls: ['./studio-advanced.component.scss']
})
export class StudioAdvancedComponent implements OnInit {

    @Input() studio;
    @Input() seeStudioDetails: (args: any) => void;
    public env = environment;

    public studioAddress = '';

    constructor(
        private router: Router,
        private studioService: StudiosService,
        private profileService: ProfileService,
        private helperService: HelperService,
    ) {
    }

    ngOnInit(): void {
        this.studioService.mapStudioData(this.studio);
        this.studioAddress = this.studio.studioAddress;
    }

    studioHasRecordingService(){
        let flag = false;
        this.studio.rooms.forEach((room) => {
            room.services.forEach((service) => {
                if (service.type === 'recording'){
                    flag = true;
                }
            });
        });
        return flag;
    }

    studioHasRehearsalService(){
        let flag = false;
        this.studio.rooms.forEach((room) => {
            room.services.forEach((service) => {
                if (service.type === 'rehearsal'){
                    flag = true;
                }
            });
        });
        return flag;
    }

    userHasLoggedIn(): boolean{
        return Object.keys(this.profileService.getUserData()).length !== 0;
    }

    studioRating(): number {
        if (this.studio.studioReviews && this.studio.studioReviews.length > 0 ) {
            const studioRating = this.studio.studioReviews
                .reduce( ( sum, { rating } ) => sum + rating , 0) / this.studio.studioReviews.length;
            return Math.round( studioRating * 10) / 10;
        } else {
            return 0;
        }
    }

    getColClass() {
        if (this.studioHasRehearsalService() && this.studioHasRecordingService()){
            return 'col-4';
        }
        return 'col-6';
    }

    getCurrency( symbol = true ) {
        if (symbol) {
            return this.helperService.getCurrencySymbol(this.studio.businessCurrency)
        } else {
            return this.studio.businessCurrency;
        }
    }
}
