import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bandMembersParser'
})
export class BandMembersParserPipe implements PipeTransform {

  transform(members: any[], type?: string ): unknown {
    return members.map(mbr => mbr.member ? mbr.member.name : mbr.user.name);
  }

}
