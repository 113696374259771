import {Component, Input, OnInit} from '@angular/core';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-opening-hours',
  templateUrl: './opening-hours.component.html',
  styleUrls: ['./opening-hours.component.scss']
})
export class OpeningHoursComponent implements OnInit {

  @Input() availability;
  constructor(private datePipe: DatePipe) { }

  ngOnInit(): void {
  }

  convertDateToTime(date): string{
    return this.datePipe.transform(new Date(date), 'HH:mm');
  }

}
