<p-card
        [ngClass]="{'dark-mode' : darkMode === true , 'grey-mode' : darkMode === false}"
         styleClass="p-card-shadow">
    <div class="grid align-items-center" >

        <div class="col-12" >
            <div class="grid " >
                <div class="col-12 md:col-8 flex align-content-end justify-content-end service-discount-tag {{multipleOffers?'shown-offer':'hidden-offer'}}">
                    <p-tag class="tn-text-small shown-offer-top" [rounded]="true" > 
                        <span class="offer-text" *ngIf="multipleOffers"><b>-{{serviceOffer.percentage}}%</b></span>
                    </p-tag>
                </div>

                <div class="md:col-4"></div>


                <div class="col-3 md:col-3 flex align-content-end flex-wrap">
                    <span class="tn-text-green">
                        <i *ngIf="service.type === 'recording'" class="till-noon-icon tnicon-Recording tn-icon"></i>
                        <i *ngIf="service.type === 'rehearsal'" class="till-noon-icon tnicon-Rehearsal tn-icon"></i>
                    </span>
                </div>


                <div class="col-10 md:col-6 flex align-content-end justify-content-end service-discount-tag bottom-tag">
                    <p-tag class="tn-text-small shown-offer shown-offer-bottom" [rounded]="true" *ngIf="serviceOffer && serviceOffer.amount.cutoff > -1">
                        <span class="offer-text" >{{serviceOffer.amount.cutoff}} hr deal&nbsp;<b>{{serviceOffer.amount.final}}{{getCurrency()}}</b></span>
                    </p-tag>
                    <p-tag class="tn-text-small shown-offer shown-offer-bottom" [rounded]="true" *ngIf="serviceOffer && serviceOffer.percentage > -1 && !multipleOffers"> 
                        <span class="offer-text" ><b>-{{serviceOffer.percentage}}%</b></span>
                    </p-tag>
                </div>
            </div>
            
             <div>
                <strong class="tn-title">{{service.type | titlecase}}</strong>
            </div>
        </div>

        <div class="col-12 service-price" ><span class="tn-text-green tn-text-big ">{{service.price}}</span><span class="tn-text-simple"> {{getCurrency()}} / hour</span></div>
    </div>
</p-card>
