import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HelperService} from './helper.service';

@Injectable({
  providedIn: 'root'
})
export class BandsService {


  private bands: BehaviorSubject<any>;
  public readonly bands$: Observable<any>;

  private band: BehaviorSubject<any>;
  public readonly band$: Observable<any>;

  private bandMembers: BehaviorSubject<any>;
  public readonly bandMembers$: Observable<any>;

  private invites: BehaviorSubject<any>;
  public readonly invites$: Observable<any>;

  private bookings: BehaviorSubject<any>;
  public readonly bookings$: Observable<any>;

  private errors: BehaviorSubject<any>;
  public readonly errors$: Observable<any>;

  private dataStore: {
    bands: any,
    band: any,
    bandMembers: any,
    invites: any,
    bookings: any,
  };

  constructor( private helperService: HelperService ) {

    this.bands = new BehaviorSubject([]) as BehaviorSubject<any>;
    this.bands$ = this.bands.asObservable();

    this.band = new BehaviorSubject({}) as BehaviorSubject<any>;
    this.band$ = this.band.asObservable();

    this.bandMembers = new BehaviorSubject([]) as BehaviorSubject<any>;
    this.bandMembers$ = this.bandMembers.asObservable();

    this.invites = new BehaviorSubject({}) as BehaviorSubject<any>;
    this.invites$ = this.invites.asObservable();

    this.bookings = new BehaviorSubject({}) as BehaviorSubject<any>;
    this.bookings$ = this.bookings.asObservable();

    this.errors = new BehaviorSubject({}) as BehaviorSubject<any>;
    this.errors$ = this.errors.asObservable();

    this.dataStore = {
      bands: {
        results : []
      },
      band: {

      },
      bandMembers: [],
      invites: [],
      bookings: []
    };
  }


  fetchBands() {
    return this.helperService.getAction('/teams'  )
      .toPromise()
      .then((data) => {
        Object.assign(this.dataStore.bands, data);
        this.bands.next(data);
        return data;
      });
  }


  addBand( newBand: any ) {

    return this.helperService.postAction('/teams' , newBand )
      .toPromise()
      .then((data) => {
        this.dataStore.bands.results = this.dataStore.bands.results.concat([data]);
        this.bands.next(this.dataStore.bands);
        return data;
      });
  }

  deleteBand( bandId: string ) {
    return this.helperService.deleteAction('/teams/' + bandId )
      .toPromise()
      .then((data) => {
        this.dataStore.bands.results = this.dataStore.bands.results.filter(std => std.id !== bandId);
        this.bands.next(this.dataStore.bands);
        return data;
      });
  }

    editBandMusicRole(bandId: string, userId: string, musicRoleId: string) {
        return this.helperService.patchAction('/teams/' + bandId + '/members/' + userId + '/musicrole/' + musicRoleId, {primary: true})
            .toPromise()
            .then((data) => {
                return this.fetchBand(bandId, true);
            });
    }

  editBand( bandId: string , editedBand: any) {
    return this.helperService.patchAction('/teams/' + bandId , editedBand )
      .toPromise()
      .then((data) => {
        delete data.members;
        if (this.dataStore.bands.results.length > 0) {
            Object.assign(this.dataStore.bands.results.find(std => std.id === bandId) , data);
            this.bands.next(this.dataStore.bands);
        }
        if (this.dataStore.band && this.dataStore.band.id === bandId) {
            Object.assign(this.dataStore.band , data);
            this.band.next(this.dataStore.band);
        }
        return data;
      });
  }

    addBandImage( bandId: string , image: any , type: string) {
        const formData = new FormData();
        formData.append('bandImage', image);
        formData.append('type' , type);
        return this.helperService.postAction('/teams/' + bandId + '/media' , formData )
            .toPromise()
            .then((data) => {
                const foundBand = this.dataStore.bands.results.find(std => std.id === bandId);
                if (foundBand) {
                    Object.assign(foundBand , data);
                }
                if (this.dataStore.band && this.dataStore.band.id === bandId) {
                    this.fetchBand(bandId, true);
                }
                this.bands.next(this.dataStore.bands);
                return data;
            });
    }

  fetchBand( bandId: string , withMembers = false ) {
    return this.helperService.getAction('/teams/' + bandId , {withMembers} )
      .toPromise()
      .then((data) => {
        Object.assign(this.dataStore.band, data);
        this.band.next(data);
        return data;
      });
  }

  fetchBandMembers( bandId: string ) {
    return this.helperService.getAction('/teams/' + bandId + '/members' )
      .toPromise()
      .then((data) => {
        this.dataStore.bandMembers = [ ...data ];
        this.bandMembers.next(data);
        return data;
      });
  }

  fetchBandInvites( bandId: string ) {
    return this.helperService.getAction('/teams/' + bandId + '/invites' )
      .toPromise()
      .then((data) => {
        this.dataStore.invites = [ ...data.results ];
        this.invites.next(data);
        return data;
      });
  }

  fetchBandBookings( bandId: string ) {
    return this.helperService.getAction('/teams/' + bandId + '/bookings' )
      .toPromise()
      .then((data) => {
        this.dataStore.bookings = [ ...data.results ];
        this.bookings.next(data);
        return data;
      });
  }

  addBandMember(bandId: string , userId: string) {
    return this.helperService.patchAction('/teams/' + bandId + '/members' , {userId} )
      .toPromise()
      .then((data) => {
        return this.fetchBand(bandId);
      });
  }

  inviteBandMember(bandId: string , userId: string) {
    return this.helperService.postAction('/invites' , {user : userId, team: bandId} )
      .toPromise()
      .then((data) => {
        return this.fetchBandInvites(bandId);
      });
  }

  inviteBandMemberByMail(bandId: string , userMail: string) {
    return this.helperService.postAction('/invites' , {email : userMail, team: bandId} )
      .toPromise();
  }

  removeMember(bandId: string , userId: string) {
    return this.helperService.deleteAction('/teams/' + bandId + '/members/' + userId  )
      .toPromise()
      .then((data) => {
        return this.fetchBand(bandId, true);
      });
  }

  deleteInvite(inviteId: string) {
    return this.helperService.deleteAction('/invites/' + inviteId  )
      .toPromise()
      .then((data) => {
        return data;
      });
  }


}
