import {Component, EventEmitter, Input, Output} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {ConfirmationService, MenuItem, MessageService} from "primeng/api";
import {UserService} from "../../../services/user.service";
import {BandsService} from "../../../services/bands.service";

@Component({
  selector: 'app-tn-band-invite',
  templateUrl: './tn-band-invite.component.html',
  styleUrls: ['./tn-band-invite.component.scss']
})
export class TnBandInviteComponent {
  @Input() band;
  @Input() invite: any;
  @Output() bandSelectEmitter = new EventEmitter();
  @Input() selected = false;
  @Input() darkMode = false;
  @Input() showMenu = false;
  @Input() userId: string;
  public env = environment;
  public items: MenuItem[] = []
  public submitted = false;
  public bandDialog = false;

  public memberDialog = false;
  public memberEmail: string;
  public memberSubmitted = false;

  public editNameDialog = false;
  public bandName: string;
  public editNameSubmitted = false;
  constructor(
      private confirmationService: ConfirmationService,
      private userService: UserService,
      private messageService: MessageService,
      private bandsService: BandsService,
  ) {
  }

  ngOnInit(): void {

    const userRole = this.band.members.filter(member => member.user.id === this.userId)[0]?.role;

    this.items =  [
      {
        label: 'Accept Invite',
        command: event => this.acceptInvite()
      },
      {
        label: 'Decline Invite',
        command: event => this.declineInvite(),
      },

    ];

    this.band.avatar = this.band.media.reverse().find((media => media.metadata.type === 'avatar'));

  }


  acceptInvite() {
    this.userService.acceptUserInvite(this.userId , this.invite.id).then((data) => {
      this.messageService.add({
        key: 'globalToast', severity: 'success', summary: 'Successful', detail: 'Invite Accepted', life: 3000});
      this.userService.fetchUserBands(this.userId);
      this.userService.fetchUserInvites(this.userId);
    });
  }

  declineInvite() {

    this.userService.declineUserInvite(this.userId , this.invite.id).then((data) => {
      this.messageService.add({
        key: 'globalToast', severity: 'success', summary: 'Successful', detail: 'Invite Declined', life: 3000});
      this.userService.fetchUserInvites(this.userId);
    });
  }

}
