import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationsService} from '../services/notifications.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ProfileService} from '../services/profile.service';
import {UserService} from '../services/user.service';
import {map, tap} from 'rxjs/operators';
import {combineLatest, Subscription} from 'rxjs';
import {HelperService} from '../services/helper.service';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit {
  public env = environment;

  public user;
  private userRegistrations = [];

  private userId;

  public userEmail;
  public userPhoneNumber;
  public changePassAvailable = true;

  public userStreet;
  public userNumber;
  public userZipCode;
  public userCity;
  public userCountry;
  public userNewsletter = true;

  public phonePrefix = '(+30) ';
  public countries: any[];

  userData;

    public settingsTypes = [
        {name: 'Account Settings', code: 'account' ,  icon : 'account_circle', info: 'Your account settings'},
    ];
    public selectedSettingType = this.settingsTypes[0].code;

    public allowEdit = false;

    private routerParams: Subscription;

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private notificationsService: NotificationsService,
      private messageService: MessageService,
      private confirmationService: ConfirmationService,
      private profileService: ProfileService,
      private userService: UserService,
      private helperService: HelperService
  ) {

      if (this.env.paymentEnabled && this.env.paymentEnabled === true) {
          this.settingsTypes.push(
              {name: 'Payment History', code: 'payments' , icon : 'payments', info: 'Your payments history'}
          )
      }

    this.countries = this.helperService.getCountriesData();
    this.userId = this.profileService.getUserData().id;

    this.user = combineLatest(
        this.userService.user$,
        this.userService.registrations$
    ).pipe(
        map((data) => {
            this.userData = data[0];
            this.userRegistrations = data[1];
            this.initUserData();
            return this.userData;
        })
    );

    this.userService.fetchUser(this.userId);
    this.userService.fetchUserRegistrations(this.userId);


  }

    ngOnDestroy(): void {
        if (this.routerParams) {
            this.routerParams.unsubscribe();
        }
    }
  ngOnInit(): void {

      this.routerParams = this.route.queryParams.subscribe((params) => {
          if (
              params.hasOwnProperty('settingType') &&
              params.settingType.length > 0 &&
              this.settingsTypes.findIndex(el => el.code === params.settingType) > -1
          ) {
              this.selectedSettingType = params.settingType;
          } else {
              this.selectedSettingType = this.settingsTypes[0].code;
          }
      });
  }

  initUserData() {
      this.userEmail = this.userData.email;
      this.userPhoneNumber =
          (this.userData.phoneNumber && this.userData.phoneNumber !== 6900000000) ? this.userData.phoneNumber : null;
      if (!this.userData.address) {
          this.userData.address = {};
      }
      this.userStreet = this.userData.address.street;
      this.userNumber = this.userData.address.number;
      this.userZipCode = this.userData.address.zip;
      this.userCity = this.userData.address.city;
      this.userCountry = this.userData.address.country;

      this.userNewsletter = !!this.userRegistrations.find((sub) => sub.event === 'user_newsletter');
  }

  saveChanges() {
      const editObject = {
          email : this.userEmail ,
          phoneNumber : this.userPhoneNumber,
          address : {
            street : this.userStreet,
            number : this.userNumber,
            zip: this.userZipCode,
            city: this.userCity,
            country: this.userCountry
          }
      };
      Object.keys(editObject).forEach((key) => {
          if (!editObject[key]) {
              delete editObject[key];
          }
      });
      this.userService.editUser( this.userId, editObject)
        .then((data) => {
          this.profileService.setUserData(data);
          this.messageService.add({
            key: 'globalToast',
            severity: 'success',
            summary: 'Confirmed',
            detail: 'Your settings changed',
            life: 3000
          });
        })
      .catch((err) => {
          this.initUserData();
          this.messageService.add({
              key: 'globalToast', severity: 'error', summary: 'Error', detail: err.error.message, life: 3000});
          throw err;
      });
  }

  subscribeUser(event) {
      if ( this.userNewsletter !== !!this.userRegistrations.find((sub) => sub.event === 'user_newsletter') )   {
          if (this.userNewsletter) {
              this.userService.addRegistration(this.userId, {event: 'user_newsletter'});
          } else {
              this.userService.removeRegistrationByType(this.userId, 'user_newsletter');
          }
      }
  }

  toChangePass() {
    this.router.navigate(['changepassword'], {relativeTo: this.route});
  }

  deleteUser() {

      this.confirmationService.confirm({
          message: 'Are you sure you want to delete your account?',
          header: 'Confirm',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
              this.userService.deleteUser(this.userId)
                  .then((data) => {
                      this.messageService.add({severity: 'success', summary: 'Account Deleted', detail: 'Your bandbeat account is now deleted!', life: 3000});
                      return this.profileService.logOutUser();
                  })
                  .then((data) => {
                      window.location.href = window.location.origin;
                  });
          }
      });

  }

  public selectCountry(event) {
      const selected = this.countries.find(country => this.userCountry === country.label);
      this.phonePrefix = '(+' + selected.phone + ') ';
  }

  public selectSettingType( selectSetting ) {
      this.router.navigate(
          [],
          {
              relativeTo: this.route,
              queryParams: {settingType: selectSetting.code}
          });
  }

}
