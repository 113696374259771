import { Directive, HostListener, ElementRef, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[inputTrim]'
})
export class TrimDirective {

    constructor(private el: ElementRef, @Optional() private ngControl: NgControl) {}

    @HostListener('blur', ['$event'])
    onBlur(event: any): void {
        const trimmedValue = this.el.nativeElement.value.trim();
        if (this.ngControl && this.ngControl.control) {
            this.ngControl.control.setValue(trimmedValue);
        } else {
            this.el.nativeElement.value = trimmedValue;
        }
    }
}