import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterPayments'
})
export class FilterPaymentsPipe implements PipeTransform {

  transform(payments: any, status: string[] = []): unknown {
    if (!payments) {
      return [];
    }
    if (status.length === 0) {
      return payments;
    } else {
      return payments.filter(pm => status.includes(pm.status))
    }
  }

}
