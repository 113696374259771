import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit {

  public env = environment;

  @Input() review;
  constructor() { }

  ngOnInit(): void {
    this.review.studio.avatar = this.review.studio.media.find((media) => media.metadata.type === 'logo');
  }

}
