import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ViewportScroller} from '@angular/common';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-tc',
  templateUrl: './tc.component.html',
  styleUrls: ['./tc.component.scss']
})
export class TcComponent implements OnInit, AfterViewInit {

  constructor(
      private viewportScroller: ViewportScroller,
      private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    const scrollTo = this.route.snapshot.queryParamMap.get('scrollTo');
    if (scrollTo) {
      setTimeout(() => {
        this.viewportScroller.scrollToAnchor(scrollTo);
      }, 1000);
    }
  }

}
