import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {LocalStorageService} from 'ngx-webstorage';

@Injectable()
export class UserDataGuard implements CanActivate {

  constructor(
    private localSt: LocalStorageService,
    private router: Router,
  ) { }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const user = this.localSt.retrieve('user');
    const url = state.url.split('/')
    if (user && (url.indexOf(user.id) !== -1 || url.length >=3 && url[3] === 'user'  )) {
      return true;
    } else {
      const navigate: any[] = url.slice(0,3);
      this.router.navigate(navigate);
      return false;
    }

  }
}

