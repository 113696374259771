<div class="grid" *ngIf="band | async as bandData">
    <div class="col-12">

        <H2 >{{bandData.name}}</H2>
        <H3 >{{bandData.description}}</H3>
        <div *ngFor="let member of bandData.members" class="field-checkbox">
            <p-checkbox
                    name="selectedMembers"
                    [value]="member.user.id"
                    [(ngModel)]="selectedMembers"
                    [inputId]="member.id"
            ></p-checkbox>
            <label [for]="member.id">{{member.user.name}} / {{member.user.email}}</label>
        </div>
    </div>

</div>

<div class="grid grid-nogutter next-buttons">
    <p-button disabled="true" label="Previous" styleClass="secondary-button" icon="till-noon-icon tnicon-Arrow-Left" iconPos="left"></p-button>
    <p-button label="Next" (onClick)="nextPage()" icon="till-noon-icon tnicon-Arrow-Right" iconPos="right"></p-button>
</div>
