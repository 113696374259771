import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import {BandsService} from '../../../services/bands.service';
import {UserService} from '../../../services/user.service';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-band-simple',
    templateUrl: './band-simple.component.html',
    styleUrls: ['./band-simple.component.scss']
})
export class BandSimpleComponent implements OnInit {

    @Input() item;
    @Input() seeBand: (args: any) => void;
    @Input() startBookingDetails: (args: any, id) => void;
    @Input() userId: string;
    @Input() invite: any;
    @Input() showMenu: boolean;
    items: MenuItem[];
    public submitted = false;
    public bandDialog = false;
    public env = environment;

    public memberDialog = false;
    public memberEmail: string;
    public memberSubmitted = false;

    public editNameDialog = false;
    public bandName: string;
    public editNameSubmitted = false;

    constructor(private router: Router, private bandsService: BandsService,
                private confirmationService: ConfirmationService,
                private userService: UserService,
                private messageService: MessageService,
                private route: ActivatedRoute) {
    }

    acceptInvite() {
        this.userService.acceptUserInvite(this.userId , this.invite.id).then((data) => {
            this.messageService.add({
                key: 'globalToast', severity: 'success', summary: 'Successful', detail: 'Invite Accepted', life: 3000});
            this.userService.fetchUserBands(this.userId);
        });
    }

    declineInvite() {

        this.userService.declineUserInvite(this.userId , this.invite.id).then((data) => {
            this.messageService.add({
                key: 'globalToast', severity: 'success', summary: 'Successful', detail: 'Invite Declined', life: 3000});
        });
    }

    ngOnInit(): void {
        const userRole = this.item.members.filter(member => member.user.id === this.userId)[0]?.role;

        this.items = [
            {
                label: 'Invite new Member',
                command: event => this.openInviteMemberModal()
            },
            {
                label: 'Edit name',
                command: event => this.openEditNameModal(),
                visible: userRole === 'admin'
            },
            {
                label: 'Leave Band',
                command: event => this.leaveBand(this.item),
            },
            {
                label: 'Delete Band',
                command: event => this.deleteBand(this.item),
                visible: userRole === 'admin',
            }
        ];
        this.item.avatar = this.item.media.reverse().find((media => media.metadata.type === 'avatar'));
    }


    leaveBand(band) {
        if (band.members.length === 1) {
            this.confirmationService.confirm({
                message: 'You are the last member of the band! Are you sure you want to leave?',
                header: 'Confirm',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.userService.leaveUserBand(this.userId, band.id).then((data) => {
                        this.messageService.add({
                            key: 'globalToast', severity: 'success', summary: 'Successful', detail: 'You left the band', life: 3000
                        });
                    });
                }
            });
        } else {
            this.confirmationService.confirm({
                message: 'Are you sure you want to leave?',
                header: 'Confirm',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.userService.leaveUserBand(this.userId, band.id).then((data) => {
                        this.messageService.add({
                            key: 'globalToast', severity: 'success', summary: 'Successful', detail: 'You left the band', life: 3000
                        });
                    });
                }
            });
        }
    }

    deleteBand(band) {
        this.confirmationService.confirm({
            message: 'Are you sure you want to delete this band?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                if (band.name && band.name.trim()) {
                    this.bandsService.deleteBand(band.id)
                        .then((result) => {
                            this.messageService.add({severity: 'success', summary: 'Successful', detail: 'Band Deleted', life: 3000});
                            band = {};
                            this.userService.fetchUserBands(this.userId);
                        });
                }
            }
        });
    }

    inviteMember() {
        if (this.memberEmail.trim().length > 0) {
            this.memberSubmitted = true;
            this.bandsService.inviteBandMemberByMail(this.item.id , this.memberEmail).then((data) => {
                this.messageService.add({
                    key: 'globalToast', severity: 'success', summary: 'Successful', detail: 'Invite Sent', life: 3000});
                this.memberEmail = '';
                this.memberDialog = false;
                this.bandsService.fetchBandInvites(this.item.id);
            })
            .catch((err) => {
                this.messageService.add({
                    key: 'globalToast', severity: 'error', summary: 'Error', detail: err.error.message, life: 3000});
                this.memberEmail = '';
                this.memberDialog = false;
            });
        }
    }

    openInviteMemberModal() {
        this.memberEmail = '';
        this.memberSubmitted = false;
        this.memberDialog = true;
    }

    hideInviteMemberModal() {
        this.memberEmail = '';
        this.memberDialog = false;
        this.memberSubmitted = false;
    }

    saveBand() {
        this.userService.editUserBand(this.item.id ,
            {name: this.bandName}).then((data) => {
            this.messageService.add({
                key: 'globalToast', severity: 'success', summary: 'Successful', detail: 'Band Edited', life: 3000});
        });
        this.item.name = this.bandName;
        this.editNameDialog = false;
        this.bandName = '';
        this.editNameSubmitted = true;
    }

    openEditNameModal() {
        this.bandName = '';
        this.editNameSubmitted = false;
        this.editNameDialog = true;
    }

    hideEditNameModal() {
        this.bandName = '';
        this.editNameDialog = false;
        this.editNameSubmitted = false;
    }
}


