import { Component, PLATFORM_ID, Inject  } from '@angular/core';
import { MenuService } from './menu/app.menu.service';
import { TopbarMenuService } from './topbar/app.topbarmenu.service';
import {ConfirmationService, PrimeNGConfig} from 'primeng/api';
import { AppComponent } from './app.component';
import {HelperService} from './services/helper.service';
import {GoogleTagManagerService} from "angular-google-tag-manager";
import {NavigationEnd, Router} from "@angular/router";
import { trigger, style, animate, transition, state } from '@angular/animations';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-main',
    templateUrl: './app.main.component.html',
    providers: [ConfirmationService],
    animations: [
        trigger('fadeInOut', [
            transition(':leave', [
                style({'max-height': '100px', opacity: 1}),
                animate('500ms', style({'max-height': '0px', opacity: 0}))
            ]),
            transition(':enter', [
                style({'max-height': '0px', opacity: 0}),
                animate('500ms', style({'max-height': '100px', opacity: 1}))
            ])
        ]),
        trigger('footerUpDown', [
            state('false', style({ 'margin-bottom': '0rem'})),
            state('true', style({ 'margin-bottom': '4rem'})),
            transition('false => true', animate('200ms ease-in')),
            transition('true => false', animate('200ms ease-out'))
        ])
    ]
})
export class AppMainComponent {

    staticMenuDesktopInactive: boolean;

    staticMenuMobileActive: boolean;

    menuClick: boolean;

    topbarItemClick: boolean;

    menuHoverActive = false;

    topbarMenuActive = false;

    activeTopbarItem: Element;

    searchClick = false;

    search = false;

    configActive: boolean;

    configClick: boolean;

    topbarMenuClick = false;
    comingFromTopBar: boolean;

    showGetApp = true;
    isBrowser;

    constructor(
        private menuService: MenuService,
        private topbarmenuService: TopbarMenuService,
        private router: Router,
        private primengConfig: PrimeNGConfig,
        private gtmService: GoogleTagManagerService,
        public app: AppComponent,
        public helperService: HelperService,
        @Inject(PLATFORM_ID) platformId: string
    ) {
        this.isBrowser = false;
        if(isPlatformBrowser(platformId)){
            this.isBrowser = true;
        }
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.tagPageViewEvent(this.router.url);
            }
        });

    }

    onLayoutClick() {
        if (!this.topbarItemClick) {
            this.activeTopbarItem = null;
            this.topbarMenuActive = false;
        }

        if (this.configActive && !this.configClick) {
            this.configActive = false;
        }

        if (!this.menuClick) {
            if (this.isSlim()) {
                this.menuService.reset();
            }

            this.menuHoverActive = false;
            this.staticMenuMobileActive = false;
        }

        if (this.topbarMenuClick) {
            if (this.isSlim()) {
                this.menuHoverActive = false;
            }
        }
        if (!this.topbarMenuClick) {
            this.topbarmenuService.reset();
        }

        if (!this.searchClick) {
            this.search = false;
        }

        this.searchClick = false;
        this.configClick = false;
        this.menuClick = false;
        this.topbarItemClick = false;
        this.topbarMenuClick = false;
    }

    tagPageViewEvent( urlTag: string ) {
        const gtmTag = {
            event: 'page-view',
            data: urlTag,
        };
        this.gtmService.pushTag(gtmTag);
    }

    onMenuButtonClick(event: Event, source?: string) {
        this.menuClick = true;
        this.comingFromTopBar = source === 'topBar';

        if (!this.isMobile()) {
            this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
        } else {
            this.staticMenuMobileActive = !this.staticMenuMobileActive;
        }

        event.preventDefault();
    }

    onMenuClick() {
        this.menuClick = true;
    }

    onTopbarMenuClick() {
        this.topbarMenuClick = true;
    }

    onTopbarItemClick(event: Event, item: Element) {
        this.topbarItemClick = true;

        if (this.activeTopbarItem === item) {
            this.activeTopbarItem = null;
        } else {
            this.activeTopbarItem = item;
        }

        if (item.className === 'search-item') {
            this.search = !this.search;
            this.searchClick = !this.searchClick;
        }

        event.preventDefault();
    }

    onTopbarSubItemClick(event) {
        event.preventDefault();
    }

    onRippleChange(event) {
        this.app.ripple = event.checked;
        this.primengConfig = event.checked;
    }

    onConfigClick(event) {
        this.configClick = true;
    }

    isMobile() {
        return window.innerWidth < 1025;
    }

    isSlim() {
        return this.app.menuMode === 'slim';
    }

    isStatic() {
        return this.app.menuMode === 'static';
    }

    isTablet() {
        const width = window.innerWidth;
        return width <= 1024 && width > 640;
    }

    openPlayStore(){
        if(this.isBrowser){
            document.location.href = "https://play.google.com/store/apps/details?id=com.bandbeat.userapp"
        }else{
            document.location.href = "market://details?id=com.bandbeat.userapp"
        }
    }

    ngOnInit() {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
            const url = window.location.href;
            window.location.href=url.replace(/http|https/gi, 'ionicbandbeat')
        }
    }
}
