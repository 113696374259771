import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {AppComponent} from '../app.component';
import {AppMainComponent} from '../app.main.component';
import {ProfileService} from '../services/profile.service';
import {ActivatedRoute, Router} from '@angular/router';
import {LocalStorageService} from 'ngx-webstorage';
import { environment } from '../../environments/environment';
import {NotificationsService} from '../services/notifications.service';
import {map} from 'rxjs/operators';
import {SocialAuthService} from '@abacritt/angularx-social-login';
import {UserService} from "../services/user.service";

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    styleUrls: ['./app.topbar.component.scss']
})
export class AppTopBarComponent implements OnInit {

    menu: any[];
    public user: any;
    public userMenu: any[];

    loggedIn = false;

    public env = environment;

    public userLogo;
    public unreadNotifications = 0;
    public notifications;
    public notificationLink;

    // public shouldShowWizard;

    @ViewChild('input1') inputElement1: ElementRef;

    @ViewChild('input2') inputElement2: ElementRef;

    scrolled = false;

    @HostListener('window:scroll', [])
    onWindowScroll() {
        this.scrolled = window.scrollY > 20;
    }

    constructor(
        public app: AppComponent,
        public appMain: AppMainComponent,
        private profileService: ProfileService,
        private authService: SocialAuthService,
        private notificationsService: NotificationsService,
        private userService: UserService,
        private localSt: LocalStorageService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        this.localSt.observe('user')
            .subscribe((user) => {
                if (user && Object.keys(user).length > 0) {
                    this.loggedIn = true;
                    this.userLogo = user.avatar;
                    
                    this.userMenu = [
                        {
                            label: 'Profile',
                            link: ['/profile' , this.user.id]
                        },
                    ];
                    this.notificationLink = ['/profile' , this.user.id, 'notifications'];
                } else {
                    this.loggedIn = false;
                }
            });
        this.user = this.profileService.getUserData();
        if (Object.keys(this.user).length === 0) {
            this.loggedIn = false;
        } else {
            this.loggedIn = true;
            this.userLogo = this.user.avatar;
        }

        this.notifications = this.notificationsService.notifications$.pipe(map((notifs) => {
            return notifs
                .filter(
                    (notif) => !notif.read_by.find(user => user.readerId === this.profileService.getUserData().id)
                );
        }));


        //
        // this.shouldShowWizard = this.userService.registrations$.pipe(map((userRegistrations) => {
        //     return userRegistrations.filter(el => el.event === 'user_wizard').length === 1
        // }))
    }

    ngOnInit() {
        this.menu = [{
            label: 'Menu',
            items: [
                {
                    label: 'UI Kit', icon: 'pi pi-align-left',
                    items: [
                        {label: 'Form Layout', icon: 'pi pi-id-card', routerLink: ['/uikit/formlayout']},
                        {label: 'Input', icon: 'pi pi-check-square', routerLink: ['/uikit/input']},
                    ]
                },
                {
                    label: 'Hierarchy', icon: 'pi pi-align-left',
                    items: [
                        {
                            label: 'Submenu 1', icon: 'pi pi-align-left',
                            items: [
                                {label: 'Submenu 1.1', icon: 'pi pi-align-left'},
                                {label: 'Submenu 1.2', icon: 'pi pi-align-left'},
                            ]
                        },
                        {
                            label: 'Submenu 2', icon: 'pi pi-align-left',
                            items: [
                                {label: 'Submenu 2.1', icon: 'pi pi-align-left'},
                            ]
                        },
                    ]
                }
            ]
        }
        ];

        this.userMenu = [
            // {
            //     label: 'My Bookings',
            //     link: ['/profile' , this.user.id, 'bookings']
            // },
            {
                label: 'Profile',
                link: ['/profile' , this.user.id]
            },
            // {
            //     label: 'Account Settings',
            //     link: ['/profile' , this.user.id, 'settings']
            // },
        ];
        this.notificationLink = ['/profile' , this.user.id, 'notifications'];
    }

    searchFocus(event) {
        if (this.appMain.search) {
            setTimeout(() => {
                this.inputElement1.nativeElement.focus();
                this.inputElement2.nativeElement.focus();
            }, 100);
        }
    }

    logoutUser() {
        this.authService.signOut(false).then(() => {
            this.profileService.logOutUser();
            this.loggedIn = false;
            this.router.navigate(['login']);
        })
        .catch((e) => {
            this.profileService.logOutUser();
            this.loggedIn = false;
            this.router.navigate(['login']);
        });
    }

    showMenuHamburger() {
        if (this.router.url.endsWith('listing')) {
            return true;
        }
        return false;
    }

    accessNotifications(dropdown, event) {
        this.router.navigate(['/profile' , this.user.id, 'notifications'])
            .then(() => {
                dropdown.toggle(event);
            });
    }

    accessNotification(notification, dropdown, event) {
        if (notification.type === 'bookings') {
            this.router.navigate(
                ['/' , 'profile', this.profileService.getUserData().id, 'bookings', notification.data.bookingId],
            );

        } else if (notification.type === 'invites') {

            if(notification.data.teamId) {
                this.router.navigate(
                    [ 'band' ,notification.data.teamId],
                );
            } else {
                this.router.navigate(
                    ['/' , 'profile',  this.profileService.getUserData().id],
                    { queryParams: { scrollTo: 'userBands' }}
                );
            }

        }
        this.notificationsService.readNotification(notification.id)
        dropdown.toggle(event);
    }
}
