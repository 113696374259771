<div class=" grid bandbeatPage" >
    <div class="col-12 wizardSteps">
        <p-steps
            #wizardSteps
            name="setupWizard"
            [(activeIndex)]="activeIndex"
            [model]="items"
            [readonly]="false"
        ></p-steps>
    </div>
    <div class="col-12 full-width-container wizardStepsContainer">

        <div class="grid grid-nogutter "
        >
            <div class="col-12 " >
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
