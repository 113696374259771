<p-card [style]="{borderRadius : 4}" header="Band #01"
        styleClass="p-card-shadow">
    <p><strong>Room | </strong>2</p>
    <p><strong>Service | </strong>Recording</p>
    <p><strong>Members | </strong>3</p>
    <p><strong>Instruments | </strong>Guitar, Bass, Drums</p>
    <p><strong>Rentals | </strong>Yes</p>
    <ng-template pTemplate="footer">
        <div style="text-align:right">
            <button [style]="{color: '#F2B8B5' }" class="p-button-text" label="Decline" pButton pRipple
                    type="button"></button>

            <button class="p-button-text" label="Accept" pButton pRipple type="button"></button>
        </div>
    </ng-template>
</p-card>
