<div class="layout-wrapper" [ngClass]="{'layout-slim': app.menuMode === 'slim',
                                        'layout-static': app.menuMode === 'static',
                                        'layout-mobile-active': staticMenuMobileActive,
                                        'layout-static-inactive': staticMenuDesktopInactive && app.menuMode === 'static',
                                        'p-input-filled': app.inputStyle === 'filled', 'p-ripple-disabled': !app.ripple}"
     (click)="onLayoutClick()">

    <div class="grid-nogutter get-app-banner" *ngIf="showGetApp" [@fadeInOut]>
        <div class="col-5 sm:col-4 md:col-3">
            <img 
                class="get-app-google-img" 
                src="assets/images/landing/google_play_badge_eng.png" 
                alt="Get it in play store"
                (click)="openPlayStore()" />
        </div>

        <div class="col-5 sm:col-4 md:col-6 get-app-text">
            <b>Bandbeat</b> is now on <b>Google Play!</b>
        </div>

        <div class="col-1 sm:col-2 md:col-1 get-app-close-btn-container">
            <button pButton class="get-app-close-btn" icon="pi pi-times" (click)="showGetApp = !showGetApp"></button>
        </div>
    </div>

    <app-topbar></app-topbar>

    <div class="layout-content-wrapper" style="width:100%;margin-left:0px;">

        <p-toast
                [style]="{'overflow-wrap' : 'break-word'}"
                [baseZIndex]="5000"
                life="4000"
                key="globalToast"
        ></p-toast>


        <div class="layout-content">
            <router-outlet></router-outlet>
        </div>

        <app-footer [@footerUpDown]="showGetApp"></app-footer>
    </div>

</div>
<p-scrollTop [ngClass]="showGetApp?'moveScrollUp':'moveScrollDown'"></p-scrollTop>
