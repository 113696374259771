import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HelperService} from '../../../services/helper.service';
import {StudiosService} from '../../../services/studios.service';
import {filter, map} from 'rxjs/operators';
import {BandsService} from '../../../services/bands.service';

@Component({
  selector: 'app-booking-members',
  templateUrl: './booking-members.component.html',
  styleUrls: ['./booking-members.component.scss']
})
export class BookingMembersComponent implements OnInit {

  public band;
  public selectedMembers;


  private bandId;

  constructor(
      private route: ActivatedRoute,
      private router: Router ,
      private helperService: HelperService,
      private bandsService: BandsService,
  ) { }

  ngOnInit(): void {

    this.bandId = this.route.snapshot.queryParamMap.get('band');


    this.band = this.bandsService.band$.pipe(
        filter(std => Object.keys(std).length !== 0)
    );

    this.bandsService.fetchBand(this.bandId , true);
  }

  previous() {

  }

  nextPage() {
    this.router.navigate(
        ['settings' ],
        {
          queryParamsHandling: 'merge',
          relativeTo: this.route.parent,
          queryParams: {members : this.selectedMembers}
        });
  }

}
