<p-card
        [ngClass]="{'green-mode' : selected === true , 'grey-mode' : selected === false}"
        [style]="{border: '1px solid #938F99', borderRadius : 12}"
        styleClass="p-card-shadow clickable-card"
        (click)="selectMember()"
>

    <div class="grid grid-nogutter align-items-center">
        <div class="col-fixed" style="margin: auto;width: 3.4375rem;height: 3.4375rem;">
            <p-avatar
                    [image]="member.user.avatar ? member.user.avatar['link'] : 'assets/images/sample_member.png'"
                    shape="circle" styleClass="mr-2 band-avatar" ></p-avatar>
        </div>
        <div class="col ml-4">
            <p style="margin:auto" class="tn-text-big">{{member.user.name}}</p>
<!--            <div>{{band.members.length}} members</div>-->
        </div>
    </div>



</p-card>
