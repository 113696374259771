import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../services/user.service';
import {ProfileService} from '../services/profile.service';
import {filter, first, map} from 'rxjs/operators';
import {environment} from "../../environments/environment";
import {StudiosService} from "../services/studios.service";

@Component({
  selector: 'app-new-booking',
  templateUrl: './new-booking.component.html',
  styleUrls: ['./new-booking.component.scss']
})
export class NewBookingComponent implements OnInit {

    public hasTeams = false;
    public userBands;
    public userData;
    private userId;
    public bandBookingSelected = false;

    public studio;

    public bandOptions = [];

    public bookingType = 'solo';
    public serviceType = 'rehearsal';
    public selectedBand;
    public selectedMembers = [];

    public availableNext = true;

    public availableServices: any[] = [
        {name: 'Recording', code: 'recording' , available: false},
        {name: 'Rehearsal', code: 'rehearsal' , available: false},
        {name: 'Podcast', code: 'podcast' , available: false},
    ];


    public env = environment;

  constructor(
      private route: ActivatedRoute,
      private router: Router ,
      private userService: UserService,
      private studioService: StudiosService,
      private profileService: ProfileService
  ) { }

  ngOnInit(): void {
    const studioId = this.route.snapshot.queryParamMap.get('studio');
    const roomId = this.route.snapshot.queryParamMap.get('room');
      if (this.route.snapshot.queryParamMap.get('type')) {
        this.bookingType = this.route.snapshot.queryParamMap.get('type')
      }

    this.userId = this.profileService.getUserData().id;

      this.studio = this.studioService.studio$.pipe(
          filter(std => Object.keys(std).length !== 0),
          map((data) => {
                  data = this.studioService.mapStudioData(data);
                  data.rooms.forEach((room) => {
                      room.services.forEach((service) => {
                          this.availableServices.find(el => el.code === service.type).available = true;
                      });
                  });

                  this.serviceType = this.availableServices.find(el => el.code === 'rehearsal').available ? 'rehearsal' : 'recording';
                  return data;
              }
          )
      );

      this.studioService.fetchStudio(studioId);


    this.userBands = this.userService.bands$.pipe(
          map((data) => {
              this.hasTeams = data.length > 0;
              this.bandOptions = data
                  .filter(band => band.status === 'inactive')
                  .map((band) => {
                      return {label : band.name , command: (selectedBand: any) => {
                          this.startBookingDetails('band' , selectedBand.item.band.id);
                      }, band};
                  });
              if (this.route.snapshot.queryParamMap.get('band')) {
                  this.selectedBand = data.find(el => el.id === this.route.snapshot.queryParamMap.get('band'))
              }

              return data;
          })
      );

    this.userService.fetchUserBands(this.userId)
        .then();
  }

  startBookingDetails(bookingType: string = 'solo', selectedBand?: string) {
      const params: any = {
          type : bookingType
      };
      let destination = ['info' , 'settings'];
      if (selectedBand) {
          params.band = selectedBand;
          destination = ['info' , 'settings'];
      }
      this.router.navigate(
          destination,
        {
          queryParamsHandling: 'merge',
          relativeTo: this.route.parent,
          queryParams: params
        });
  }

  // selectBandType() {
  //     if (this.hasTeams) {
  //         this.bandBookingSelected = true;
  //     } else {
  //         this.createBand();
  //     }
  // }

    // createBand() {
    //     this.router.navigate(
    //         ['newband' ],
    //         {
    //             queryParamsHandling: 'merge',
    //             relativeTo: this.route.parent.parent
    //         });
    // }

    selectBand(band) {
        this.selectedBand = band;
        this.selectedMembers = [...band.members]
        this.checkNext();
    }

    checkNext() {

        if (this.bookingType === 'band' && (!this.selectedBand || this.selectedMembers.length === 0) ) {
            this.availableNext = false;
        } else if ( this.availableServices.find(el => el.code === this.serviceType).available === false ) {
            this.availableNext = false;
        }else {
            this.availableNext = true;
        }
    }

    selectBandMember(member) {
      if (this.selectedMembers.find(el => el === member)) {
          this.selectedMembers = this.selectedMembers.filter(el => el !== member);
      } else {
          this.selectedMembers.push(member);
      }
      this.checkNext();
    }


    nextPage() {
        const params: any = {
            type : this.bookingType,
            service : this.serviceType,
            step: 1
        };
        let destination = ['settings'];
        if (this.selectedBand) {
            params.band = this.selectedBand.id;
            params.members = this.selectedMembers.map(el => el.user.id);
        }
        this.router.navigate(
            destination,
            {
                queryParamsHandling: 'merge',
                relativeTo: this.route.parent,
                queryParams: params
            });
    }

    createBand() {
        this.router.navigate(['newband']);
    }
}
