import { Component, OnInit } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {HelperService} from '../../../services/helper.service';
import {StudiosService} from '../../../services/studios.service';
import {filter, map} from 'rxjs/operators';

@Component({
  selector: 'app-booking-instruments',
  templateUrl: './booking-instruments.component.html',
  styleUrls: ['./booking-instruments.component.scss']
})
export class BookingInstrumentsComponent implements OnInit {

  public instruments: any[];
  public selectedInstruments: any[];

  public studio: any;
  public env = environment;

  private studioId;
  private roomId;
  private service;

  constructor(
      private route: ActivatedRoute,
      private router: Router ,
      private helperService: HelperService,
      private studioService: StudiosService,
  ) { }

  ngOnInit(): void {
    this.studioId = this.route.snapshot.queryParamMap.get('studio');
    this.roomId = this.route.snapshot.queryParamMap.get('room');
    this.service = this.route.snapshot.queryParamMap.get('service');

    this.studio = this.studioService.studio$.pipe(
        filter(std => Object.keys(std).length !== 0),
        map((data: any) => {

          this.instruments = data.instruments
              .filter((inst) => inst.available);
              // .map((inst) => {});

          return data;
        })
    );
  }


  nextPage() {
    this.router.navigate(
        ['instrument' ],
        {
          queryParamsHandling: 'merge',
          relativeTo: this.route.parent,
          queryParams: {step: 4}
        });
  }

  previous() {
    this.router.navigate(
        ['service' ],
        {
          queryParamsHandling: 'merge',
          relativeTo: this.route.parent,
          queryParams: {step: 3}
        });
  }

  confirmBooking() {
    this.router.navigate(
        ['summary' ],
        {
          queryParamsHandling: 'merge',
          relativeTo: this.route.parent,
          queryParams: {instruments: this.selectedInstruments, step: 5}
        });
  }
}
