import { Injectable } from '@angular/core';
import {HelperService} from './helper.service';
import {environment} from '../../environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import {format} from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  private payments: BehaviorSubject<any>;
  public readonly payments$: Observable<any>;

  private payment: BehaviorSubject<any>;
  public readonly payment$: Observable<any>;

  private errors: BehaviorSubject<any>;
  public readonly errors$: Observable<any>;



  private dataStore: {
    payment: any,
    payments: any[],
  };


  constructor(
      private helperService: HelperService
  ) {



    this.payment = new BehaviorSubject({}) as BehaviorSubject<any>;
    this.payment$ = this.payment.asObservable();

    this.payments = new BehaviorSubject([]) as BehaviorSubject<any>;
    this.payments$ = this.payments.asObservable();


    this.dataStore = {
      payment: {},
      payments: [],
    };

  }

  getPaymentForm(booking , billingInfo) {
    const digestInfo: any = {
      version : '2',
      mid : environment.paymentMid,
      lang : 'en',
      deviceCategory : '0',
      orderid : '',
      orderDesc : booking.label,
      orderAmount : booking.totalPrice + '',
      currency : booking.priceCurrency ? booking.priceCurrency : 'EUR',
      payerEmail : booking.booker.email,
      payerPhone : booking.booker.phoneNumber + '',
      billCountry : billingInfo.country,
      billZip : billingInfo.zip,
      billCity : billingInfo.city,
      billAddress : billingInfo.address + ' ' + billingInfo.number,
      trType: '2',
      confirmUrl : environment.backendUrl + '/payments/booking/' + booking.id + '/response?success=true',
      cancelUrl : environment.backendUrl + '/payments/booking/' + booking.id + '/response?success=false',
      var1: booking.shortId,
      var2: booking.id,
      var3: booking.studio.id,
      var4: booking.studio.name,
      var5: booking.bookingType,
      var6: booking.bookingDate,
    };

    if (booking.recurringDates && booking.recurringDates.length > 0) {
      digestInfo.var7 = booking.recurringDates.join(',');
      digestInfo.orderAmount = `${booking.totalPrice * (booking.recurringDates.length)}`;
    }
    return digestInfo;
  }

  initiatePayment( bookingId: string, billingData: any ) {
    return this.helperService.postAction('/payments/booking/' + bookingId, billingData)
        .toPromise()
        .then((data) => {
          this.dataStore.payment = Object.assign({}, data);
          this.payment.next(this.dataStore.payment);
          return data;
        });
  }


  fetchPayments(userId: string) {
    return this.helperService.getAction('/users/' + userId + '/payments' , {limit: 10000} )
        .toPromise()
        .then((data) => {
          this.dataStore.payments = [...data.results];
          this.payments.next(this.dataStore.payments);
          return data;
        });
  }

  fetchPayment( userId: string, paymentId: string ) {
    return this.helperService.getAction('/users/' + userId + '/payments/' + paymentId)
        .toPromise()
        .then((data) => {
          this.dataStore.payment = data;
          this.payment.next(this.dataStore.payment);
          return data;
        });
  }

  updateRevolutPayment( paymentId: string , success = true) {
    return this.helperService.postAction('/payments/id/' + paymentId +'/revolut/response' , {success})
        .toPromise()
        .then((data) => {
          this.dataStore.payment = Object.assign({}, data);
          this.payment.next(this.dataStore.payment);
          return data;
        });
  }



}
