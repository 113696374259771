import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HelperService} from '../../../services/helper.service';

@Component({
  selector: 'app-booking-service',
  templateUrl: './booking-service.component.html',
  styleUrls: ['./booking-service.component.scss']
})
export class BookingServiceComponent implements OnInit {

  public selectedService;

  constructor(
      private route: ActivatedRoute,
      private router: Router ,
      private helperService: HelperService,
  ) { }

  ngOnInit(): void {
  }

  nextPage() {
    this.router.navigate(
        ['room' ],
        {
          queryParamsHandling: 'merge',
          relativeTo: this.route.parent,
          queryParams: {service : this.selectedService, step: 2}
        });
  }

  previous() {
    this.router.navigate(
        ['settings' ],
        {
          queryParamsHandling: 'merge',
          relativeTo: this.route.parent,
          queryParams: {service : this.selectedService, step: 0}
        });
  }

}
